import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import HeaderSubSection from "../components/Header/HeaderSubSection";
import BodySubSection from "../components/SubSection/BodySubSection";
import LastBlockSubSection from "../components/SubSection/LastBlockSubSection";
import Footer from "../components/Footer";
import HeatPumpPicture from "../assets/Installation/pompe-a-chaleur.webp";
import HeatPumpAirWater from "../assets/HeatPump/pompe-a-chaleur-air-eau.webp";
import { NavBarDisplayFunction } from "../functions/NavBarDisplayScrollFunction";
import { EffectSubSectionFunction } from "../functions/EffectSubSectionFunction";
import { EffectTitlePageFunction } from "../functions/EffectTitlePageFunction";
import ButtonQuote from "../components/Buttons/ButtonQuote";
import { devices } from "../style/Size";

const ContenairMain = styled.main`
  width: 100%;
  position: relative;
  margin-top: 70px;

  @media ${devices.laptopXXS} {
    margin-top: 110px;
  }
  @media ${devices.tablet} {
    margin-top: 40px;
  }
`;

export default function HeatPump() {
  useEffect(() => {
    EffectTitlePageFunction("titleHeatPumpContenair");
    setTimeout(() => {
      EffectTitlePageFunction("idBlockElement");
    }, 600);
  }, []);

  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction("headerHeatPumpContenair");
  });

  useEffect(() => {
    const titleSection = document.querySelector("#blockTitle");
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        window.addEventListener("scroll", () => {
          EffectSubSectionFunction(
            "blockTitle",
            "/chauffe-eau/pompes-a-chaleur"
          );
        });
      }
    });
    observer.observe(titleSection);
  }, []);

  window.addEventListener("resize", () => {
    const blockSection = document.querySelectorAll(`.imageSection`);
    if (blockSection) {
      EffectSubSectionFunction("blockTitle", "/chauffe-eau/pompes-a-chaleur");
      if (window.innerWidth <= 568) {
        for (let element of blockSection) {
          element.style.transform = "translateX(0vw)";
          element.style.transition = "all 300ms";
        }
      }
    }
  });

  return (
    <>
      <HeaderSubSection
        id="headerHeatPumpContenair"
        idTitle="titleHeatPumpContenair"
        src={HeatPumpPicture}
        alt="Pompe à chaleur sol, grande et longue, moderne noir et blanche, avec son groupe système dans un garage vaste et bien rangé"
        titlePage="Pompes à Chaleur"
      />
      <ContenairMain>
        <BodySubSection
          idText="textSection"
          imageClassname="imageSection"
          idBlockElement="idBlockElement"
          opacity="0.5"
          src={HeatPumpAirWater}
          alt="Pompe à chaleur air-eau design blanche et noire dans buanderie tout équipée et bien rangée"
          title={[
            "Les ",
            <strong key="1" style={{ fontWeight: "500" }}>
              Pompes à Chaleur Air-Eau
            </strong>,
          ]}
          text={[
            "Solution de chauffage à la pointe de la technologie. Système de ",
            <strong key="1" style={{ fontWeight: "100" }}>
              chauffage thermodynamique
            </strong>,
            ", modulaire et universelle. ",
            <strong key="2" style={{ fontWeight: "100" }}>
              Pompe à chaleur monobloc ou bi-bloc
            </strong>,
            " associée à une ",
            <strong key="3" style={{ fontWeight: "100" }}>
              chaudière
            </strong>,
            " neuve ou existante. Performance durable grâce à à son module de découplage entre l'",
            <strong key="4" style={{ fontWeight: "100" }}>
              installation de chauffage
            </strong>,
            " et le circuit primaire de l'installation. Plusieurs gammes sont disponibles : ",
            <strong key="5" style={{ fontWeight: "100" }}>
              pompe à chaleur basse température
            </strong>,
            ", ",
            <strong key="6" style={{ fontWeight: "100" }}>
              pompe à chaleur moyenne température
            </strong>,
            ", ",
            <strong key="7" style={{ fontWeight: "100" }}>
              pompe à chaleur haute température
            </strong>,
            ". Possibilité de système de rafraîchissement avec ",
            <strong key="8" style={{ fontWeight: "100" }}>
              installation de ventilo-convecteurs
            </strong>,
            ". Possibilité d' ",
            <strong key="9" style={{ fontWeight: "100" }}>
              installation de planchers chauffants
            </strong>,
            ". Connectivité en option. Économie d'énergie significative selon l'isolation du logement.",
            <br key="10" />,
            <br key="11" />,
            "Nos ",
            <strong key="12" style={{ fontWeight: "100" }}>
              chauffagistes à Marseille
            </strong>,
            " possèdent l'habilitation ",
            <strong key="13" style={{ fontWeight: "100" }}>
              Professionnels du Gaz
            </strong>,
            ", et se rendent sur place pour effectuer un ",
            <strong key="14" style={{ fontWeight: "100" }}>
              devis 100% gratuit et personnalisé
            </strong>,
            " afin d'étudier le ",
            <strong key="15" style={{ fontWeight: "100" }}>
              coût et temps d'installation chauffagiste
            </strong>,
            ". L'entreprise Navarro est également ",
            <strong key="16" style={{ fontWeight: "100" }}>
              Partenaire GRDF
            </strong>,
            ". ",
            <br key="17" />,
            <br key="18" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="19"
              to="https://www.dedietrich-thermique.fr/recherche?actimage_search%5Bfamily%5D%5B%5D=5628&actimage_search%5BvalidateSearchFilter%5D=&actimage_search%5Bkeyword%5D=pompe+%C3%A0+chaleur&actimage_search%5Bezxform_token%5D=WiQZoRpdXfGCFC0wwrcQQg_s8y0HGTt8akbIDUzj9Zw"
              target="_blank"
            >
              De Dietrich
            </Link>,
            " et ",
            <Link
              key="20"
              to="https://www.saunierduval.fr/particulier/nos-produits/pompe-a-chaleur/pompe-a-chaleur-air-eau/"
              target="_blank"
            >
              Saunier Duval
            </Link>,
            ".",
          ]}
        />
        <LastBlockSubSection
          imageClassname="imageSection"
          src={HeatPumpAirWater}
          alt=""
          title={[
            "Les ",
            <strong key="1" style={{ fontWeight: "500" }}>
              Pompes à Chaleur Air-Air
            </strong>,
          ]}
          text={[
            "Autre solution de chauffage high-tech. La ",
            <strong key="1" style={{ fontWeight: "100" }}>
              pompe à chaleur air-air
            </strong>,
            " prélève de la chaleur de l'air extérieur et la transfère au fluide frigorigène qui se vaporise, et augmente la température de cette vapeur par compression. Par condensation ensuite, la vapeur cède de la chaleur, ce qui alimente les unités de chauffage à l'intérieur du logement. La pression du fluide diminue par la suite grâce au détendeur jusqu'au niveau nécessaire à l'évaporation. La vapeur obtenue au contact du milieu extérieur permet la reprise d'un nouveau cycle. Connectivité en option. Économie d'énergie significative selon l'isolation du logement.",
            <br key="10" />,
            <br key="11" />,
            "Nos ",
            <strong key="12" style={{ fontWeight: "100" }}>
              chauffagistes à Marseille
            </strong>,
            " possèdent l'habilitation ",
            <strong key="13" style={{ fontWeight: "100" }}>
              Professionnels du Gaz
            </strong>,
            ", et se rendent sur place pour effectuer un ",
            <strong key="14" style={{ fontWeight: "100" }}>
              devis 100% gratuit et personnalisé
            </strong>,
            " afin d'étudier le ",
            <strong key="15" style={{ fontWeight: "100" }}>
              coût et temps d'installation chauffagiste
            </strong>,
            ". L'entreprise Navarro est également ",
            <strong key="16" style={{ fontWeight: "100" }}>
              Partenaire GRDF
            </strong>,
            ". ",
            <br key="17" />,
            <br key="18" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="19"
              to="https://www.dedietrich-thermique.fr/recherche?actimage_search%5Bfamily%5D%5B%5D=5628&actimage_search%5BvalidateSearchFilter%5D=&actimage_search%5Bkeyword%5D=pompe+%C3%A0+chaleur&actimage_search%5Bezxform_token%5D=WiQZoRpdXfGCFC0wwrcQQg_s8y0HGTt8akbIDUzj9Zw"
              target="_blank"
            >
              De Dietrich
            </Link>,
            " et ",
            <Link
              key="20"
              to="https://www.saunierduval.fr/particulier/nos-produits/pompe-a-chaleur/pompe-a-chaleur-air-eau/"
              target="_blank"
            >
              Saunier Duval
            </Link>,
            ".",
          ]}
        />
        <ButtonQuote />
      </ContenairMain>
      <Footer backgroundColor="#667788" />
    </>
  );
}
