import React, { useContext } from "react";
import styled from "styled-components";
import PlomberieUrgence from "../../assets/plomberie-urgence.png";
import "../../style/Animation/KeyFrames/ModalUrgence.css";
import "../../style/Animation/KeyFrames/ModalUrgenceTrash.css";
import { TrashContext } from "../../contexts/TrashContext";
import { devices } from "../../style/Size";

const ContenairImageMini = styled.div.attrs({
  id: "contenairImageMini",
})`
  width: 80px;
  height: 80px;
  position: absolute;
  top: 10px;
  left: 14px;
  border-radius: 50%;
  perspective: 300px;
  z-index: 10;
`;

const Block = styled.div.attrs({
  id: "blockMini",
})`
  width: 300px;
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  z-index: 10;
  background-color: transparent;
  transform: translate(calc(-50% + 25px), calc(-50% + 25px));
  opacity: 1;

  @media ${devices.tablet} {
    width: 60px;
    height: 60px;
  }
`;

const Image = styled.img.attrs({
  className: "imageLogoUrgence",
})`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
`;

export default function ContenairPicture({
  displayTrashEffect,
  setDisplayTrashEffect,
  setModalUrgenceDisplayInHome,
}) {
  const { displayTrash } = useContext(TrashContext);

  const replaceModalUrgence = (e) => {
    const blockModal = document.querySelector("#modalUrgenceDisplay");
    if (blockModal) {
      if (blockModal.getBoundingClientRect().x < 0) {
        blockModal.style.left = `${14}px`;
        blockModal.style.transition = "100ms ease-in";
      }
      if (blockModal.getBoundingClientRect().y < 60) {
        blockModal.style.top = `${60}px`;
        blockModal.style.transition = "100ms ease-in";
      }
      if (
        blockModal.getBoundingClientRect().x +
          blockModal.clientWidth -
          window.innerWidth >
        -440
      ) {
        blockModal.style.left = `${14}px`;
        blockModal.style.transition = "300ms ease-in";
      }
      if (
        blockModal.getBoundingClientRect().y +
          blockModal.clientHeight -
          window.innerHeight >
        -300
      ) {
        blockModal.style.top = `${60}px`;
        blockModal.style.transition = "300ms ease-in";
      }
    }
  };

  const effectModalDisplay = () => {
    const modalUrgenceDisplay = document.querySelector("#modalUrgenceDisplay");
    const contenairImageMini = document.querySelector("#contenairImageMini");
    const buttonClose = document.querySelector("#buttonClose");
    const icon = document.querySelector("#iconButtonCloseModalUrgence");
    const divP = document.querySelectorAll(".divP");

    if (!displayTrashEffect) {
      modalUrgenceDisplay.classList.remove("blockModalReduction");
      modalUrgenceDisplay.classList.add("blockModalDisplay");
      contenairImageMini.classList.remove("contenairImageModalMini");
      contenairImageMini.classList.add("contenairImageModalNormal");

      setTimeout(() => {
        buttonClose.style.display = "block";
        icon.style.display = "block";
        for (let element of divP) {
          element.style.display = "block";
        }
      }, 400);
    }
  };

  const modalDisplay = () => {
    const contenairImageMini = document.querySelector("#contenairImageMini");

    if (contenairImageMini.clientWidth === 50) {
      setDisplayTrashEffect(false);
      effectModalDisplay();
    }
  };

  const possibilityTrashed = (e) => {
    const modalUrgenceDisplay = document.querySelector("#modalUrgenceDisplay");
    const trash = document.querySelector("#trash");
    const iconTrashElement = document.querySelector("#iconTrashElement");
    const blockMini = document.querySelector("#blockMini");
    const imageLogoUrgence = document.querySelector(".imageLogoUrgence");

    if (modalUrgenceDisplay.clientWidth === 50) {
      if (
        blockMini.getBoundingClientRect().y +
          blockMini.clientHeight / 2 -
          e.clientY <
          25 &&
        blockMini.getBoundingClientRect().y +
          blockMini.clientHeight / 2 -
          e.clientY >
          -25 &&
        blockMini.getBoundingClientRect().x +
          blockMini.clientWidth / 2 -
          e.clientX <
          25 &&
        blockMini.getBoundingClientRect().x +
          blockMini.clientWidth / 2 -
          e.clientX >
          -25
      ) {
        blockMini.style.cursor = "pointer";
        imageLogoUrgence.style.transform = "scale(1.1)";
        imageLogoUrgence.style.transition = "200ms";
      } else {
        blockMini.style.cursor = "auto";
        imageLogoUrgence.style.transform = "scale(1)";
        imageLogoUrgence.style.transition = "200ms";
      }

      if (e.buttons === 1) {
        modalUrgenceDisplay.style.top = `${e.clientY - 34}px`;
        modalUrgenceDisplay.style.left = `${e.clientX - 30}px`;
        modalUrgenceDisplay.style.transition = "0ms";

        setDisplayTrashEffect(true);
        displayTrash("trashEffect");
        window.getSelection().removeAllRanges();

        if (trash) {
          trash.classList.remove("notApparitionTrash");
          trash.classList.add("apparitionTrash");
          if (
            trash.clientWidth === 50 &&
            trash.clientHeight === 50 &&
            trash.getBoundingClientRect().y -
              modalUrgenceDisplay.getBoundingClientRect().y <=
              59 &&
            trash.getBoundingClientRect().y -
              modalUrgenceDisplay.getBoundingClientRect().y >=
              -59 &&
            trash.getBoundingClientRect().x -
              modalUrgenceDisplay.getBoundingClientRect().x <=
              59 &&
            trash.getBoundingClientRect().x -
              modalUrgenceDisplay.getBoundingClientRect().x >=
              -59
          ) {
            trash.style.width = "80px";
            trash.style.height = "80px";
            iconTrashElement.style.width = "80px";
            iconTrashElement.style.height = "80px";
            iconTrashElement.style.fontSize = "36px";
            modalUrgenceDisplay.style.opacity = "0.5";
          }
          if (
            trash.clientWidth === 80 &&
            trash.clientHeight === 80 &&
            trash.getBoundingClientRect().y -
              modalUrgenceDisplay.getBoundingClientRect().y <=
              64 &&
            trash.getBoundingClientRect().y -
              modalUrgenceDisplay.getBoundingClientRect().y >=
              -64 &&
            trash.getBoundingClientRect().x -
              modalUrgenceDisplay.getBoundingClientRect().x <=
              64 &&
            trash.getBoundingClientRect().x -
              modalUrgenceDisplay.getBoundingClientRect().x >=
              -64
          ) {
            trash.style.width = "80px";
            trash.style.height = "80px";
            iconTrashElement.style.width = "80px";
            iconTrashElement.style.height = "80px";
            iconTrashElement.style.fontSize = "36px";
            modalUrgenceDisplay.style.opacity = "0.5";
          } else {
            trash.style.width = "50px";
            trash.style.height = "50px";
            iconTrashElement.style.width = "50px";
            iconTrashElement.style.height = "50px";
            iconTrashElement.style.fontSize = "20px";
            modalUrgenceDisplay.style.opacity = "1";
          }
        }
      }
      if (e.buttons === 0) {
        replaceModalUrgence();
        if (trash) {
          if (
            trash.getBoundingClientRect().y -
              modalUrgenceDisplay.getBoundingClientRect().y <=
              34 &&
            trash.getBoundingClientRect().y -
              modalUrgenceDisplay.getBoundingClientRect().y >=
              -59 &&
            trash.getBoundingClientRect().x -
              modalUrgenceDisplay.getBoundingClientRect().x <=
              34 &&
            trash.getBoundingClientRect().x -
              modalUrgenceDisplay.getBoundingClientRect().x >=
              -59
          ) {
            modalUrgenceDisplay.style.top = `${trash.offsetTop + 16}px`;
            modalUrgenceDisplay.style.left = `${
              trash.getBoundingClientRect().x + 18
            }px`;
            modalUrgenceDisplay.classList.add("modalUrgenceMiniTrashed");
            setTimeout(() => {
              trash.classList.remove("apparitionTrash");
              trash.classList.add("notApparitionTrash");
            }, 150);
            setTimeout(() => {
              setModalUrgenceDisplayInHome(false);
              const takeDate = Date.now();
              localStorage.setItem("mUnD", `${takeDate}`);
            }, 450);
          } else {
            setDisplayTrashEffect(false);
            trash.classList.remove("apparitionTrash");
            trash.classList.add("notApparitionTrash");
            setTimeout(() => {
              displayTrash("trashNotEffect");
            }, 300);
          }
        }
      }
    }
  };

  const possibilityTrashedTactilScreen = (e) => {
    const modalUrgenceDisplay = document.querySelector("#modalUrgenceDisplay");
    const imageLogoUrgence = document.querySelector(".imageLogoUrgence");

    if (modalUrgenceDisplay.clientWidth === 50) {
      imageLogoUrgence.style.transform = "scale(1.1)";
      imageLogoUrgence.style.transition = "200ms";

      modalUrgenceDisplay.style.top = `${e.touches[0].clientY - 34}px`;
      modalUrgenceDisplay.style.left = `${e.touches[0].clientX - 30}px`;
      modalUrgenceDisplay.style.transition = "0ms linear";
    }
  };

  const cancelPossibilityTrashedTactilScreen = (e) => {
    const modalUrgenceDisplay = document.querySelector("#modalUrgenceDisplay");
    const imageLogoUrgence = document.querySelector(".imageLogoUrgence");

    if (modalUrgenceDisplay.clientWidth === 50) {
      imageLogoUrgence.style.transform = "scale(1)";
      imageLogoUrgence.style.transition = "200ms";
      // effectModalDisplay();
      replaceModalUrgence();
    }
  };

  return (
    <>
      <ContenairImageMini
        onClick={() => modalDisplay()}
        onMouseMove={(e) => possibilityTrashed(e)}
        onTouchMove={(e) => possibilityTrashedTactilScreen(e)}
        onTouchEnd={(e) => cancelPossibilityTrashedTactilScreen(e)}
      >
        <Block />
        <Image
          src={PlomberieUrgence}
          alt="dessin plombier en tenue professionnelle qui tient une caisse à outil pour intervenir en urgence ou dépannage"
        ></Image>
      </ContenairImageMini>
    </>
  );
}
