import React from "react";
import styled from "styled-components";
import ZoomElement from "./ZoomElement";
import {
  OnZoomMMFunction,
  EffectHover,
} from "../../functions/ZoomElementsBathroomHome";
import { devices } from "../../style/Size";

const BlockImagesBathroom = styled.div.attrs({
  className: "blockImagesSalleDeBain",
})`
  width: 25%;
  min-width: 10%;
  height: 500px;
  border: 4px solid #556699;
  border-radius: 10px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1%;
  margin-right: 1%;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: 400ms;
  @media (min-width: 991px) {
    &:hover {
      width: 90%;
    }
  }

  @media ${devices.laptopXXS} {
    width: 100%;
    height: 320px;
    border: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 14px;
    border-radius: 0px;
  }
  @media ${devices.mobileL} {
    width: 100%;
    height: 300px;
  }
  @media (max-height: 480px) {
    height: 76vh;
  }
`;

const ContenairPictureBathroom = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  transition: 300ms 300ms;

  @media ${devices.laptopXXS} {
    width: 100%;
    border: none;
    border-radius: 0px;
  }
`;

export default function BlockImageAndZoomElement({
  stateZoom,
  stateZoomSet,
  stateDB,
  stateDBset,
  stateInfoBulle,
  stateInfoBulleSet,
  imageContenair,
  idContenairPrincipal,
  idPictureContenairPrincipal,
  idBlockContenairPictureZoom,
  idPictureZoomBlockContenair,
  idContenairPictureZoom,
  idPictureZoomContenair,
  alt,
}) {
  const onZoomME = () => {
    if (window.innerWidth > 991) {
      setTimeout(() => {
        stateZoomSet(true);
      }, 1000);
    }
  };

  const onZoomML = () => {
    if (window.innerWidth > 991) {
      stateZoomSet(false);
    }
  };

  const calculSizeForOnZoom = (e) => {
    const blockContenairPictureZoom = document.querySelector(
      `#${idBlockContenairPictureZoom}`
    );
    const contenairPrincipal = document.querySelector(
      `#${idContenairPrincipal}`
    );
    const imageContenairPrincipal = document.querySelector(
      `#${idPictureContenairPrincipal}`
    );
    const pictureZoomContenair = document.querySelector(
      `#${idPictureZoomContenair}`
    );
    if (blockContenairPictureZoom) {
      if (window.innerWidth > 991) {
        OnZoomMMFunction(
          e,
          idContenairPrincipal,
          idPictureContenairPrincipal,
          idBlockContenairPictureZoom,
          idContenairPictureZoom,
          idPictureZoomBlockContenair,
          idPictureZoomContenair,
          stateInfoBulleSet,
          stateDBset
        );

        EffectHover(
          e,
          blockContenairPictureZoom,
          contenairPrincipal,
          imageContenairPrincipal,
          pictureZoomContenair
        );
      }
    }
  };

  return (
    <BlockImagesBathroom
      id={idContenairPrincipal}
      onMouseMove={(e) => calculSizeForOnZoom(e)}
      onMouseEnter={() => onZoomME()}
      onMouseLeave={() => onZoomML()}
    >
      <ContenairPictureBathroom
        id={idPictureContenairPrincipal}
        src={imageContenair}
        alt={alt}
        fetchpriority="high"
      />
      <ZoomElement
        stateZoom={stateZoom}
        stateZoomSet={stateZoomSet}
        stateDB={stateDB}
        stateDBset={stateDBset}
        stateInfoBulle={stateInfoBulle}
        stateInfoBulleSet={stateInfoBulleSet}
        imageContenair={imageContenair}
        idContenairPrincipal={idContenairPrincipal}
        idPictureContenairPrincipal={idPictureContenairPrincipal}
        idBlockContenairPictureZoom={idBlockContenairPictureZoom}
        idPictureZoomBlockContenair={idPictureZoomBlockContenair}
        idContenairPictureZoom={idContenairPictureZoom}
        idPictureZoomContenair={idPictureZoomContenair}
      />
    </BlockImagesBathroom>
  );
}
