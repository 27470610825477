import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FicheRectoBlock from "./StructureBlockFiches/FicheRectoBlock";
import BlockBigFicheVerso from "./StructureBlockFiches/BigFicheVersoBlock";
import ChauffeEauGaz from "../../assets/Troubleshooting/chauffe-eau-gaz.webp";
import ChaudiereGaz from "../../assets/Troubleshooting/chaudiere-gaz.webp";
import {
  Effect3dFunction,
  NotEffect3dFunction,
  EffectButton3dFunction,
  NotEffectButton3dFunction,
  Effect3dDisplayFunction,
  NotEffect3dDisplayFunction,
  EffectInfosBigFicheFunction,
  NotDisplayFicheVersoFunction,
  EffectInfosFunctionMiniDeviceBigFiche,
  EffectButtonQuote3dFunction,
} from "../../functions/EffectFicheFunction";
import {
  OnScrollSubTitleFunctionBigFiche,
  OnScrollBlockFicheFunctionBigFiche,
} from "../../functions/TroubleshootingEffectDisplayBlockAndTitle";
import { NotEffectGlowButtonFunction } from "../../functions/EffectGlowButtonsFunction";
import { devices } from "../../style/Size";

const ContenairTitleAndBlockFiches = styled.div.attrs({
  className: "contenairTitleAndBlockFichesSection",
})`
  width: 100%;
  background-color: #eee4e1;
  padding-bottom: 200px;

  @media ${devices.laptopS} {
    padding-bottom: 100px;
  }
  @media ${devices.mobileL} {
    padding-top: 20px;
  }
`;

const ContenairBlockFicheAndInfosAndTitle = styled.div`
  width: 40%;

  @media (max-width: 684px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  @media ${devices.mobileM} {
    width: 84%;
  }
`;

const ContenairTitleSection = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const TitleSection = styled.h2`
  font-size: 30px;
  font-family: "Russo One", serif;
  color: #553322;
  text-shadow: 1px 1px 2px pink;
  letter-spacing: 2px;
  margin: 0;
  margin-left: 40px;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 50px;
  cursor: default;
  opacity: 0.5;

  @media ${devices.laptopXXS} {
    font-size: 28px;
  }
  @media (max-width: 684px) {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  @media (max-width: 520px) {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
  @media (max-height: 480px) {
    padding-bottom: 30px;
  }
`;

const ContenairBlockFiches = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media ${devices.laptopS} {
    flex-wrap: wrap;
  }
  @media (max-width: 684px) {
    flex-direction: column;
  }
`;

const BlockTitleFiche = styled.h3`
  width: 100%;
  height: 80px;
  font-size: 20px;
  font-family: "Russo One", serif;
  color: #553322;
  margin: 0;
  padding: 0;
  text-align: center;
  letter-spacing: 2px;
  opacity: 0.6;

  @media ${devices.laptopS} {
    height: 50px;
  }
  @media (max-height: 480px) {
    height: 50px;
    margin-top: 20px;
  }
`;

const ContenairBlockFicheAndInfos = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 10px;
  position: relative;

  @media ${devices.laptopS} {
    margin-bottom: 170px;
  }
  @media ${devices.tablet} {
    margin-bottom: 120px;
  }
  @media (max-height: 480px) {
    margin-bottom: 0px;
    height: 320px;
  }
`;

const ButtonDetailMiniDevice = styled.button.attrs({
  type: "button",
  value: "Voir",
  className: "buttonFicheMiniDevice",
})`
  width: 70%;
  height: 10%;
  background: radial-gradient(circle at 70% 50%, #be7a54, #553322);
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-size: 20px;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  cursor: pointer;
  margin-left: 15%;
  margin-top: 20px;
  opacity: 0.95;

  @media (max-width: 684px) {
    font-size: 18px;
  }
  @media ${devices.mobileL} {
    width: 60%;
    margin-left: 20%;
  }
`;

export default function GazWaterHeaterBlock() {
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);

  const [deviceButton1, setDeviceButton1] = useState(false);
  const [deviceButton2, setDeviceButton2] = useState(false);

  useEffect(() => {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setHover1(false);
      setHover2(false);
      setDeviceButton1(true);
      setDeviceButton2(true);
    }
  }, []);

  const BlockFicheRectoAndVerso = (
    idBlockTitleFiche,
    contentTitle,
    filtreId,
    buttonId,
    idBlockTopOrBottomWarp,
    imageSelect,
    hoverRef,
    classNameRef,
    setHoverRef,
    classNameRefButton,
    idMenuInfos,
    classNameMenuInfos,
    deviceButton,
    idButtonDetailMiniDevice,
    classNameButtonDetailMiniDevice,
    classNameRefButtonDevis,
    idImage,
    textFicheContent,
    alt
  ) => {
    return (
      <ContenairBlockFicheAndInfosAndTitle>
        <BlockTitleFiche id={`${idBlockTitleFiche}`}>
          <strong>{`${contentTitle}`}</strong>
        </BlockTitleFiche>
        <ContenairBlockFicheAndInfos>
          <FicheRectoBlock
            className="blockFicheCEG"
            idFiltre={`${filtreId}`}
            idButton={`${buttonId}`}
            idBlockTopOrBottomWarp={`${idBlockTopOrBottomWarp}`}
            idImage={`${idImage}`}
            image={imageSelect}
            hover={hoverRef}
            alt={alt}
            effect3d={(e) =>
              Effect3dFunction(e, `${classNameRef}`, setHoverRef, 60)
            }
            notEffect3d={(e) =>
              NotEffect3dFunction(e, `${classNameRef}`, setHoverRef)
            }
            effectButton3d={(e) =>
              EffectButton3dFunction(
                e,
                `${classNameRefButton}`,
                setHoverRef,
                true
              )
            }
            notEffectButton3d={() =>
              NotEffectButton3dFunction(
                `${classNameRefButton}`,
                setHoverRef,
                false
              )
            }
            effectInfos={(e) =>
              EffectInfosBigFicheFunction(e, `${classNameRef}`)
            }
            notDisplayFicheVerso={(e) =>
              NotDisplayFicheVersoFunction(
                e,
                `${classNameMenuInfos}`,
                setHoverRef,
                true
              )
            }
          />
          <BlockBigFicheVerso
            idBlockMenuInfos={`${idMenuInfos}`}
            textFiche={[textFicheContent]}
            effect3dDisplay={(e) =>
              Effect3dDisplayFunction(e, `${classNameMenuInfos}`, 60)
            }
            notEffect3dDisplay={(e) =>
              NotEffect3dDisplayFunction(
                e,
                `${classNameMenuInfos}`,
                setHoverRef,
                false
              )
            }
            effectButtonDevis3d={(e) =>
              EffectButtonQuote3dFunction(
                e,
                `${classNameRefButtonDevis}`,
                setHoverRef,
                false
              )
            }
            notEffectButton3d={(e) =>
              NotEffectGlowButtonFunction(`${classNameRefButtonDevis}`)
            }
          />{" "}
          {deviceButton && (
            <ButtonDetailMiniDevice
              id={idButtonDetailMiniDevice}
              onClick={(e) =>
                EffectInfosFunctionMiniDeviceBigFiche(
                  e,
                  `${classNameButtonDetailMiniDevice}`
                )
              }
              onTouchStart={(e) =>
                EffectInfosFunctionMiniDeviceBigFiche(
                  e,
                  `${classNameButtonDetailMiniDevice}`
                )
              }
            >
              VOIR
            </ButtonDetailMiniDevice>
          )}
        </ContenairBlockFicheAndInfos>
      </ContenairBlockFicheAndInfosAndTitle>
    );
  };

  useEffect(() => {
    const blockLeftOrRightWarpOneCEG = document.querySelector(
      "#idBlockLeftOrRightWarpOneCEG"
    );
    const blockLeftOrRightWarpTwoCEG = document.querySelector(
      "#idBlockLeftOrRightWarpTwoCEG"
    );

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (entries[0].target.id === "idBlockLeftOrRightWarpOneCEG") {
          window.addEventListener("scroll", () => {
            OnScrollSubTitleFunctionBigFiche(
              blockLeftOrRightWarpOneCEG,
              blockLeftOrRightWarpTwoCEG,
              "subTitleCategoriesLeftCEG",
              "subTitleCategoriesRightCEG"
            );
            OnScrollBlockFicheFunctionBigFiche(
              blockLeftOrRightWarpOneCEG,
              blockLeftOrRightWarpTwoCEG
            );
          });
        }
        if (entries[0].target.id === "idBlockLeftOrRightWarpTwoCEG") {
          window.addEventListener("scroll", () => {
            OnScrollSubTitleFunctionBigFiche(
              blockLeftOrRightWarpOneCEG,
              blockLeftOrRightWarpTwoCEG,
              "subTitleCategoriesLeftCEG",
              "subTitleCategoriesRightCEG"
            );
            OnScrollBlockFicheFunctionBigFiche(
              blockLeftOrRightWarpOneCEG,
              blockLeftOrRightWarpTwoCEG
            );
          });
        } else {
          window.removeEventListener("scroll", () => {
            OnScrollSubTitleFunctionBigFiche(
              blockLeftOrRightWarpOneCEG,
              blockLeftOrRightWarpTwoCEG,
              "subTitleCategoriesLeftCEG",
              "subTitleCategoriesRightCEG"
            );
            OnScrollBlockFicheFunctionBigFiche(
              blockLeftOrRightWarpOneCEG,
              blockLeftOrRightWarpTwoCEG
            );
          });
        }
      }
    });
    observer.observe(blockLeftOrRightWarpOneCEG);
    observer.observe(blockLeftOrRightWarpTwoCEG);
  }, []);

  return (
    <ContenairTitleAndBlockFiches id="contenairBlockFicheRectoCEG">
      <ContenairTitleSection>
        <TitleSection id="titleSectionCEG" className="titleSectionCEG">
          Chauffe-Eau Gaz Et Chaudière
        </TitleSection>
      </ContenairTitleSection>
      <ContenairBlockFiches id="contenairBlockFichesCEG">
        {BlockFicheRectoAndVerso(
          "subTitleCategoriesLeftCEG",
          "Chauffe-Eau Gaz",
          "idfilterAndEffect3d1CEG",
          "buttonFiche1CEG",
          "idBlockLeftOrRightWarpOneCEG",
          ChauffeEauGaz,
          hover1,
          "blockFicheCEG",
          setHover1,
          "buttonFiche",
          "idBlockMenuInfos1CEG",
          "blockMenuInfos",
          deviceButton1,
          "buttonDetailMiniDeviceOneCEG",
          "buttonFicheMiniDevice",
          "buttonDevis",
          "idImageGazWaterHeater",
          [
            "Vous n'avez ",
            <strong key="1" style={{ fontWeight: "100" }}>
              plus d'eau chaude
            </strong>,
            " ? Vous n'avez ",
            <strong key="2" style={{ fontWeight: "100" }}>
              plus de chauffage
            </strong>,
            " ? Il y a un ",
            <strong key="3" style={{ fontWeight: "100" }}>
              manque de pression dans votre débit d'eau
            </strong>,
            " ? Un ",
            <strong key="4" style={{ fontWeight: "100" }}>
              code erreur apparaît sur votre chauffe-eau gaz
            </strong>,
            " ?",
            <br key="5" />,
            "Les causes possibles à ces problèmes sont nombreuses : ",
            <strong key="6" style={{ fontWeight: "100" }}>
              circulateur
            </strong>,
            " vieillissant, ",
            <strong key="7" style={{ fontWeight: "100" }}>
              soupape de sécurité
            </strong>,
            " défaillante, ",
            <strong key="8" style={{ fontWeight: "100" }}>
              électrodes de ionisation
            </strong>,
            " dysfonctionnelles, ",
            <strong key="9" style={{ fontWeight: "100" }}>
              corps de chauffe entartré
            </strong>,
            ", ",
            <strong key="10" style={{ fontWeight: "100" }}>
              brûleur encrassé
            </strong>,
            ", ",
            <strong key="11" style={{ fontWeight: "100" }}>
              sonde de température
            </strong>,
            ", ",
            <strong key="12" style={{ fontWeight: "100" }}>
              échangeur de chaleur à plaques
            </strong>,
            ", ",
            <strong key="13" style={{ fontWeight: "100" }}>
              bloc gaz
            </strong>,
            ", ",
            <strong key="14" style={{ fontWeight: "100" }}>
              carte-mère
            </strong>,
            ".",
            <br key="15" />,
            <br key="16" />,
            "L'expérience et les compétences de nos ",
            <strong key="17" style={{ fontWeight: "100" }}>
              plombiers-chauffagistes à Marseille
            </strong>,
            " sauront trouver l'origine du problème, et le réparer.",
          ],
          "chauffe-eau gaz rectangulaire blanc"
        )}
        {BlockFicheRectoAndVerso(
          "subTitleCategoriesRightCEG",
          "Chaudière",
          "idfilterAndEffect3d2CEG",
          "buttonFiche2CEG",
          "idBlockLeftOrRightWarpTwoCEG",
          ChaudiereGaz,
          hover2,
          "blockFicheCEG",
          setHover2,
          "buttonFiche",
          "idBlockMenuInfos2CEG",
          "blockMenuInfos",
          deviceButton2,
          "buttonDetailMiniDeviceTwoCEG",
          "buttonFicheMiniDevice",
          "buttonDevis",
          "idImageChaudiere",
          [
            "Vous n'avez ",
            <strong key="1" style={{ fontWeight: "100" }}>
              plus d'eau chaude
            </strong>,
            " ? Vous n'avez ",
            <strong key="2" style={{ fontWeight: "100" }}>
              plus de chauffage
            </strong>,
            " ? Il y a un ",
            <strong key="3" style={{ fontWeight: "100" }}>
              manque de pression dans votre débit d'eau
            </strong>,
            " ? Un ",
            <strong key="4" style={{ fontWeight: "100" }}>
              code erreur apparaît sur votre chaudière
            </strong>,
            " ?",
            <br key="5" />,
            "Les causes possibles à ces problèmes sont nombreuses : ",
            <strong key="6" style={{ fontWeight: "100" }}>
              circulateur
            </strong>,
            " vieillissant, ",
            <strong key="7" style={{ fontWeight: "100" }}>
              soupape de sécurité
            </strong>,
            " défaillante, ",
            <strong key="8" style={{ fontWeight: "100" }}>
              électrodes de ionisation
            </strong>,
            " dysfonctionnelles, ",
            <strong key="9" style={{ fontWeight: "100" }}>
              corps de chauffe entartré
            </strong>,
            ", ",
            <strong key="10" style={{ fontWeight: "100" }}>
              brûleur encrassé
            </strong>,
            ", ",
            <strong key="11" style={{ fontWeight: "100" }}>
              sonde de température
            </strong>,
            ", ",
            <strong key="12" style={{ fontWeight: "100" }}>
              échangeur de chaleur à plaques
            </strong>,
            ", ",
            <strong key="13" style={{ fontWeight: "100" }}>
              bloc gaz
            </strong>,
            ", ",
            <strong key="14" style={{ fontWeight: "100" }}>
              carte-mère
            </strong>,
            ".",
            <br key="15" />,
            <br key="16" />,
            "L'expérience et les compétences de nos ",
            <strong key="17" style={{ fontWeight: "100" }}>
              plombiers-chauffagistes à Marseille
            </strong>,
            " sauront trouver l'origine du problème, et le réparer.",
          ],
          "chaudière gaz high-tech rouge et noire"
        )}
      </ContenairBlockFiches>
    </ContenairTitleAndBlockFiches>
  );
}
