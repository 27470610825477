import React from "react";
import styled from "styled-components";
import { EffectZoomDBFunction } from "../../functions/ZoomElementsBathroomHome";

const BlockContenairPictureZoom = styled.div`
  width: 192px;
  height: 152px;
  position: absolute;
  overflow: hidden;
  z-index: 100;
  opacity: 0;
`;

const ContenairPictureZoom = styled.div`
  width: 160px;
  height: 120px;
  position: absolute;
  overflow: hidden;
  z-index: 200;
  opacity: 0;
`;

const PictureZoomBlock = styled.img`
  object-fit: cover;
  filter: blur(5px);
`;

const PictureZoom = styled.img`
  object-fit: cover;
`;

const InfoBulle = styled.div.attrs({
  id: "infoBulle",
})`
  width: 274px;
  border-radius: 10px;
  border: 2px solid #556699;
  background-color: #ffffff;
  position: fixed;
  z-index: 1000;
`;

const TextInfoBulle = styled.p`
  font-family: "Russo One", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  color: #556699;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-right: 4px;
  margin: 0;
`;

export default function ZoomElement({
  stateZoom,
  imageContenair,
  idBlockContenairPictureZoom,
  idPictureZoomBlockContenair,
  idContenairPictureZoom,
  idPictureZoomContenair,
  stateDB,
  stateDBset,
  stateInfoBulle,
  stateInfoBulleSet,
}) {
  const displayOrNotInfoBulle = () => {
    if (stateInfoBulle) {
      if (window.innerWidth > 991) {
        return (
          <InfoBulle>
            <TextInfoBulle>Double-clikez pour zoomer/dézoomer</TextInfoBulle>
          </InfoBulle>
        );
      }
    }
  };

  return (
    <>
      {stateZoom && (
        <>
          {displayOrNotInfoBulle()}
          <BlockContenairPictureZoom id={idBlockContenairPictureZoom}>
            <PictureZoomBlock
              id={idPictureZoomBlockContenair}
              src={imageContenair}
              fetchpriority="high"
            />
          </BlockContenairPictureZoom>
          <ContenairPictureZoom
            id={idContenairPictureZoom}
            onDoubleClick={() =>
              EffectZoomDBFunction(
                idBlockContenairPictureZoom,
                idContenairPictureZoom,
                stateDB,
                stateDBset,
                stateInfoBulleSet
              )
            }
          >
            <PictureZoom
              id={idPictureZoomContenair}
              src={imageContenair}
              fetchpriority="high"
            />
          </ContenairPictureZoom>
        </>
      )}
    </>
  );
}
