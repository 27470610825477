import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FicheRectoBlock from "./StructureBlockFiches/FicheRectoBlock";
import FicheVersoBlock from "./StructureBlockFiches/FicheVersoBlock";
import Thermostat from "../../assets/Troubleshooting/thermostat-chauffe-eau-electrique.webp";
import ResistanceStatique from "../../assets/Troubleshooting/resistance-statique-chauffe-eau-electrqiue.webp";
import ResistanceBlindée from "../../assets/Troubleshooting/resistance-blindée-chauffe-eau-electrique.webp";
import GroupeDeSecurite from "../../assets/Troubleshooting/groupe-de-securite-chauffe-eau-electrique.webp";
import {
  Effect3dFunction,
  NotEffect3dFunction,
  EffectButton3dFunction,
  NotEffectButton3dFunction,
  Effect3dDisplayFunction,
  NotEffect3dDisplayFunction,
  EffectInfosFunction,
  NotDisplayFicheVersoFunction,
  EffectInfosFunctionMiniDevice,
} from "../../functions/EffectFicheFunction";
import {
  OnScrollSubTitleFunction,
  OnScrollBlockFicheFunction,
} from "../../functions/TroubleshootingEffectDisplayBlockAndTitle";
import { devices } from "../../style/Size";

const ContenairTitleAndBlockFiches = styled.div.attrs({
  className: "contenairTitleAndBlockFichesSection",
})`
  width: 100%;
  background-color: #eee4e1;
  padding-bottom: 80px;

  @media ${devices.mobileL} {
    padding-bottom: 0px;
    padding-top: 20px;
  }
  @media (max-height: 480px) {
    padding-bottom: 0px;
  }
`;

const ContenairBlockFicheAndInfosAndTitle = styled.div`
  width: 20%;

  @media ${devices.laptopS} {
    width: 40%;
  }
  @media (max-width: 684px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  @media ${devices.mobileM} {
    width: 84%;
  }
`;

const ContenairTitleSection = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const TitleSection = styled.h2`
  font-size: 30px;
  font-family: "Russo One", serif;
  color: #553322;
  text-shadow: 1px 1px 2px pink;
  letter-spacing: 2px;
  margin: 0;
  margin-left: 40px;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 50px;
  cursor: default;
  opacity: 0.5;

  @media ${devices.laptopXXS} {
    font-size: 28px;
  }
  @media (max-width: 684px) {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  @media (max-width: 520px) {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
  @media (max-height: 480px) {
    padding-bottom: 30px;
  }
`;

const ContenairBlockFiches = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media ${devices.laptopS} {
    flex-wrap: wrap;
  }
  @media (max-width: 684px) {
    flex-direction: column;
  }
`;

const BlockTitleFiche = styled.h3`
  width: 100%;
  height: 80px;
  font-size: 20px;
  font-family: "Russo One", serif;
  color: #553322;
  margin: 0;
  padding: 0;
  text-align: center;
  letter-spacing: 2px;
  opacity: 0.6;

  @media ${devices.laptopS} {
    height: 50px;
  }
  @media (max-height: 480px) {
    height: 50px;
    margin-top: 20px;
  }
`;

const ContenairBlockFicheAndInfos = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 10px;
  position: relative;

  @media ${devices.laptopS} {
    margin-bottom: 170px;
  }
  @media ${devices.tablet} {
    margin-bottom: 120px;
  }
  @media (max-height: 480px) {
    margin-bottom: 0px;
    height: 320px;
  }
`;

const ButtonDetailMiniDevice = styled.button.attrs({
  type: "button",
  value: "Voir",
  className: "buttonFicheMiniDevice",
})`
  width: 70%;
  height: 10%;
  background: radial-gradient(circle at 70% 50%, #be7a54, #553322);
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-size: 20px;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  cursor: pointer;
  margin-left: 15%;
  margin-top: 20px;
  opacity: 0.95;

  @media (max-width: 684px) {
    font-size: 18px;
  }
  @media ${devices.mobileL} {
    width: 60%;
    margin-left: 20%;
  }
`;

export default function ElectricWaterHeaterBlock() {
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);

  const [deviceButton1, setDeviceButton1] = useState(false);
  const [deviceButton2, setDeviceButton2] = useState(false);
  const [deviceButton3, setDeviceButton3] = useState(false);
  const [deviceButton4, setDeviceButton4] = useState(false);

  useEffect(() => {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setHover1(false);
      setHover2(false);
      setHover3(false);
      setHover4(false);
      setDeviceButton1(true);
      setDeviceButton2(true);
      setDeviceButton3(true);
      setDeviceButton4(true);
    }
  }, []);

  const BlockFicheRectoAndVerso = (
    idBlockTitleFiche,
    contentTitle,
    filtreId,
    buttonId,
    idBlockTopOrBottomWarp,
    imageSelect,
    hoverRef,
    classNameRef,
    setHoverRef,
    classNameRefButton,
    idMenuInfos,
    prix,
    textFicheContent,
    classNameMenuInfos,
    deviceButton,
    idButtonDetailMiniDevice,
    classNameButtonDetailMiniDevice,
    alt,
    idImage
  ) => {
    return (
      <ContenairBlockFicheAndInfosAndTitle>
        <BlockTitleFiche id={`${idBlockTitleFiche}`}>
          <strong>{`${contentTitle}`}</strong>
        </BlockTitleFiche>
        <ContenairBlockFicheAndInfos>
          <FicheRectoBlock
            className="blockFicheCEE"
            idFiltre={`${filtreId}`}
            idButton={`${buttonId}`}
            idBlockTopOrBottomWarp={`${idBlockTopOrBottomWarp}`}
            idImage={`${idImage}`}
            image={imageSelect}
            hover={hoverRef}
            alt={alt}
            effect3d={(e) =>
              Effect3dFunction(e, `${classNameRef}`, setHoverRef, 30)
            }
            notEffect3d={(e) =>
              NotEffect3dFunction(e, `${classNameRef}`, setHoverRef, false)
            }
            effectButton3d={(e) =>
              EffectButton3dFunction(e, `${classNameRefButton}`, setHoverRef)
            }
            notEffectButton3d={() =>
              NotEffectButton3dFunction(
                `${classNameRefButton}`,
                setHoverRef,
                false
              )
            }
            effectInfos={(e) =>
              EffectInfosFunction(
                e,
                `${classNameRef}`,
                `${classNameRefButton}`,
                setHoverRef
              )
            }
            notDisplayFicheVerso={(e) =>
              NotDisplayFicheVersoFunction(
                e,
                `${classNameMenuInfos}`,
                setHoverRef,
                true
              )
            }
          />
          <FicheVersoBlock
            idBlockMenuInfos={`${idMenuInfos}`}
            prix={`${prix}`}
            textFiche={[textFicheContent]}
            effect3dDisplay={(e) =>
              Effect3dDisplayFunction(e, `${classNameMenuInfos}`, 30)
            }
            notEffect3dDisplay={(e) =>
              NotEffect3dDisplayFunction(
                e,
                `${classNameMenuInfos}`,
                setHoverRef,
                false
              )
            }
          />{" "}
          {deviceButton && (
            <ButtonDetailMiniDevice
              id={idButtonDetailMiniDevice}
              onClick={(e) =>
                EffectInfosFunctionMiniDevice(
                  e,
                  `${classNameButtonDetailMiniDevice}`
                )
              }
              onTouchStart={(e) =>
                EffectInfosFunctionMiniDevice(
                  e,
                  `${classNameButtonDetailMiniDevice}`
                )
              }
            >
              VOIR
            </ButtonDetailMiniDevice>
          )}
        </ContenairBlockFicheAndInfos>
      </ContenairBlockFicheAndInfosAndTitle>
    );
  };

  useEffect(() => {
    const blockTopOrBottomWarpOne = document.querySelector(
      "#idBlockTopOrBottomWarpOneCEE"
    );
    const blockTopOrBottomWarpTwo = document.querySelector(
      "#idBlockTopOrBottomWarpTwoCEE"
    );
    const blockTopOrBottomWarpThree = document.querySelector(
      "#idBlockTopOrBottomWarpThreeCEE"
    );
    const blockTopOrBottomWarpFour = document.querySelector(
      "#idBlockTopOrBottomWarpFourCEE"
    );

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (entries[0].target.id === "idBlockTopOrBottomWarpOneCEE") {
          window.addEventListener("scroll", () => {
            OnScrollSubTitleFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour,
              "subTitleCategoriesTopOneCEE",
              "subTitleCategoriesTopTwoCEE",
              "subTitleCategoriesBottomOneCEE",
              "subTitleCategoriesBottomTwoCEE"
            );
            OnScrollBlockFicheFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour
            );
          });
        }
        if (entries[0].target.id === "idBlockTopOrBottomWarpTwoCEE") {
          window.addEventListener("scroll", () => {
            OnScrollSubTitleFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour,
              "subTitleCategoriesTopOneCEE",
              "subTitleCategoriesTopTwoCEE",
              "subTitleCategoriesBottomOneCEE",
              "subTitleCategoriesBottomTwoCEE"
            );
            OnScrollBlockFicheFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour
            );
          });
        }
        if (entries[0].target.id === "idBlockTopOrBottomWarpThreeCEE") {
          window.addEventListener("scroll", () => {
            OnScrollSubTitleFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour,
              "subTitleCategoriesTopOneCEE",
              "subTitleCategoriesTopTwoCEE",
              "subTitleCategoriesBottomOneCEE",
              "subTitleCategoriesBottomTwoCEE"
            );
            OnScrollBlockFicheFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour
            );
          });
        }
        if (entries[0].target.id === "idBlockTopOrBottomWarpFourCEE") {
          window.addEventListener("scroll", () => {
            OnScrollSubTitleFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour,
              "subTitleCategoriesTopOneCEE",
              "subTitleCategoriesTopTwoCEE",
              "subTitleCategoriesBottomOneCEE",
              "subTitleCategoriesBottomTwoCEE"
            );
            OnScrollBlockFicheFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour
            );
          });
        } else {
          window.removeEventListener("scroll", () => {
            OnScrollSubTitleFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour,
              "subTitleCategoriesTopOneCEE",
              "subTitleCategoriesTopTwoCEE",
              "subTitleCategoriesBottomOneCEE",
              "subTitleCategoriesBottomTwoCEE"
            );
            OnScrollBlockFicheFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour
            );
          });
        }
      }
    });
    observer.observe(blockTopOrBottomWarpOne);
    observer.observe(blockTopOrBottomWarpTwo);
    observer.observe(blockTopOrBottomWarpThree);
    observer.observe(blockTopOrBottomWarpFour);
  }, []);

  return (
    <ContenairTitleAndBlockFiches id="contenairSectionCCE">
      <ContenairTitleSection>
        <TitleSection id="titleSectionCEE" className="titleSectionCEE">
          Chauffe-Eau Électrique
        </TitleSection>
      </ContenairTitleSection>
      <ContenairBlockFiches id="contenairBlockFichesCEE">
        {BlockFicheRectoAndVerso(
          "subTitleCategoriesTopOneCEE",
          "Thermostat",
          "idfilterAndEffect3d1CEE",
          "buttonFiche1CEE",
          "idBlockTopOrBottomWarpOneCEE",
          Thermostat,
          hover1,
          "blockFicheCEE",
          setHover1,
          "buttonFiche",
          "idBlockMenuInfos1CEE",
          "80€",
          [
            "Vous n'avez ",
            <strong key="1" style={{ fontWeight: "100" }}>
              plus d'eau chaude
            </strong>,
            " ?",
            <br key="2" />,
            "Il est possible que le problème provienne du ",
            <strong key="3" style={{ fontWeight: "100" }}>
              thermostat
            </strong>,
            ".",
            <br key="4" />,
            "Son rôle est impératif afin de pouvoir régler grâce à son circuit imprimé, la température de chaleur de votre eau.",
            <br key="5" />,
            <br key="6" />,
            " Nos ",
            <strong key="7" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " interviennent avec rapidité et efficacité.",
          ],
          "blockMenuInfos",
          deviceButton1,
          "buttonDetailMiniDeviceOneCEE",
          "buttonFicheMiniDevice",
          "thermostat pour chauffe-eau électrique",
          "idImageThermostat"
        )}
        {BlockFicheRectoAndVerso(
          "subTitleCategoriesTopTwoCEE",
          "Résistance Blindée",
          "idfilterAndEffect3d2CEE",
          "buttonFiche2CEE",
          "idBlockTopOrBottomWarpTwoCEE",
          ResistanceBlindée,
          hover2,
          "blockFicheCEE",
          setHover2,
          "buttonFiche",
          "idBlockMenuInfos2CEE",
          "150€",
          [
            "Vous n'avez ",
            <strong key="1" style={{ fontWeight: "100" }}>
              plus d'eau chaude
            </strong>,
            " ?",
            <br key="2" />,
            "Une des causes probables est la ",
            <strong key="3" style={{ fontWeight: "100" }}>
              résistance blindée de votre{" "}
              <span key="4" style={{ whiteSpace: "nowrap" }}>
                chauffe-eau
              </span>
            </strong>,
            " qui ne fonctionne plus comme attendue. Soit ",
            <span key="5" style={{ whiteSpace: "nowrap" }}>
              est-elle
            </span>,
            " cassée, soit ",
            <span key="6" style={{ whiteSpace: "nowrap" }}>
              est-elle
            </span>,
            " entartrée. Dans ce dernier cas, plus que de ne délivrer de l'eau chaude que capricieusement, elle engendre une ",
            <strong key="7" style={{ fontWeight: "100" }}>
              surconsommation électrique
            </strong>,
            ".",
            <br key="8" />,
            <br key="9" />,
            " Nos ",
            <strong key="10" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " interviennent avec rapidité et efficacité.",
          ],
          "blockMenuInfos",
          deviceButton2,
          "buttonDetailMiniDeviceTwoCEE",
          "buttonFicheMiniDevice",
          "résistance blindée pour chauffe-eau électrique"
        )}
        {BlockFicheRectoAndVerso(
          "subTitleCategoriesBottomOneCEE",
          "Résistance Statique",
          "idfilterAndEffect3d3CEE",
          "buttonFiche3CEE",
          "idBlockTopOrBottomWarpThreeCEE",
          ResistanceStatique,
          hover3,
          "blockFicheCEE",
          setHover3,
          "buttonFiche",
          "idBlockMenuInfos3CEE",
          "200€",
          [
            "Votre ",
            <strong key="1" style={{ fontWeight: "100" }}>
              arrivée d'eau chaude
            </strong>,
            " est dysfonctionnelle ? ",
            <br key="2" />,
            <span key="3" style={{ whiteSpace: "nowrap" }}>
              Peut-être
            </span>,
            " est-ce dû à la ",
            <strong key="4" style={{ fontWeight: "100" }}>
              résistance stéatite
            </strong>,
            " de votre ",
            <strong key="5" style={{ fontWeight: "100", whiteSpace: "nowrap" }}>
              chauffe-eau
            </strong>,
            " qui, malgré son fourreau de protection, et son anode en titane entourée de magnésium qui brise les dépôts de calcaire, est alors en fin de durée de vie.",
            <br key="6" />,
            <br key="7" />,
            " Nos ",
            <strong key="8" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " interviennent avec rapidité et efficacité.",
          ],
          "blockMenuInfos",
          deviceButton3,
          "buttonDetailMiniDeviceThreeCEE",
          "buttonFicheMiniDevice",
          "résistance stéatique pour chauffe-eau électrique"
        )}
        {BlockFicheRectoAndVerso(
          "subTitleCategoriesBottomTwoCEE",
          "Groupe De Sécurité",
          "idfilterAndEffect3d4CEE",
          "buttonFiche4CEE",
          "idBlockTopOrBottomWarpFourCEE",
          GroupeDeSecurite,
          hover4,
          "blockFicheCEE",
          setHover4,
          "buttonFiche",
          "idBlockMenuInfos4CEE",
          "100€",
          [
            "Élément de sécurité indispensable au bon fonctionnement et à l'intégrité de votre ",
            <span key="1" style={{ whiteSpace: "nowrap" }}>
              chauffe-eau
            </span>,
            ".",
            <br key="2" />,
            "Sans sa présence, au moindre problème de votre ",
            <span key="3" style={{ whiteSpace: "nowrap" }}>
              chauffe-eau
            </span>,
            ", ce dernier peut faillir et se percer sous la pression générée par la chaleur dégagée.",
            <br key="4" />,
            <br key="5" />,
            " Nos ",
            <strong key="6" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " interviennent avec rapidité et efficacité.",
          ],
          "blockMenuInfos",
          deviceButton4,
          "buttonDetailMiniDeviceFourCEE",
          "buttonFicheMiniDevice",
          "groupe de sécurité pour chauffe-eau électrique"
        )}
      </ContenairBlockFiches>
    </ContenairTitleAndBlockFiches>
  );
}
