import React from "react";
import styled from "styled-components";
import NavBar from "../NavBar/NavBar";
import { devices } from "../../style/Size";

const Header = styled.header`
  width: 100%;
  height: 100vh;

  @media ${devices.laptopXXS} {
    height: 90vh;
  }
  @media ${devices.tablet} {
    height: 70vh;
  }
  @media (max-height: 480px) {
    height: 80vh;
  }
`;

const BlockImage = styled.div`
  width: 100%;
  height: 70vh;
  background: linear-gradient(
    to left bottom,
    #556699,
    7%,
    #667788,
    45%,
    #8092a4,
    70%,
    #7e8d9c
  );
  position: relative;
  top: 62px;

  @media ${devices.tablet} {
    height: 50vh;
    top: 32px;
  }
`;

const ContenairTitlePage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 120px;
  background-color: #ffffff;
  position: relative;
`;

const TitlePage = styled.h1`
  width: 80%;
  font-family: "Russo One", sans-serif;
  font-size: 34px;
  font-weight: 500;
  color: #667788;
  text-shadow: 1px 1px 1px pink;
  text-align: center;
  margin: 0;
  position: relative;
  top: 42px;
  transform: scale(1.1);
  opacity: 0.5;

  @media ${devices.tablet} {
    font-size: 32px;
  }
  @media ${devices.mobileL} {
    font-size: 30px;
  }
  @media ${devices.mobileM} {
    font-size: 28px;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media ${devices.laptopXXS} {
    object-fit: cover;
  }
`;

export default function HeaderSubSection(props) {
  return (
    <>
      <Header
        style={{
          backgroundColor: `${props.backgroundcolor}`,
        }}
      >
        <NavBar backgroundColor="#667788" />
        <BlockImage id={props.id}>
          <Image fetchpriority="high" src={props.src} alt={props.alt}></Image>
        </BlockImage>
        <ContenairTitlePage
          style={{
            backgroundColor: `${props.backgroundcolorContenairTitlePage}`,
          }}
        >
          <TitlePage
            id={props.idTitle}
            style={{
              paddingBottom: `${props.paddingBottom}`,
            }}
          >
            {props.titlePage}
          </TitlePage>
        </ContenairTitlePage>
      </Header>
    </>
  );
}
