import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import { TrashContext } from "../../contexts/TrashContext";

const TrashAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  10% {
    transform: rotate(0deg) scale(1.4);
  }
  20% {
    transform: rotate(0deg) scale(1);
  }
  92.49% {
    transform: rotate(0deg) scale(1);
  }
  92.5% {
    transform: rotate(-14deg);
  }
  95% {
    transform: rotate(0deg);
  }
  97.5% {
    transform: rotate(14deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
`;

const Trash = styled.div.attrs({
  id: "trash",
})`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid grey;
  background-color: #eadada;
  opacity: 0.8;
  position: fixed;
  top: 80vh;
  left: calc(50%);
  transform: translateX(-50%) scale(0);
  z-index: 10;
  text-align: center;
  font-size: 22px;
`;

const IconP = styled.p.attrs({
  id: "iconTrashElement",
})`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  animation: ${TrashAnimation} 3s 1s linear backwards;
`;

export default function TrashElement() {
  const { trashDisplay } = useContext(TrashContext);

  return (
    <>
      {trashDisplay && (
        <Trash>
          <IconP>
            <i
              style={{
                color: "grey",
                position: "absolute",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
              className="fa-solid fa-trash"
            ></i>
          </IconP>
        </Trash>
      )}
    </>
  );
}
