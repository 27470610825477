const OffsetTopFunction = (element, acc = 0) => {
  if (element) {
    if (element.offsetParent) {
      return OffsetTopFunction(element.offsetParent, acc + element.offsetTop);
    }
    return acc + element.offsetTop;
  }
};

export { OffsetTopFunction };
