import React from "react";
import styled from "styled-components";
import Pblog from "../Header/HeaderHome/Pblog";
import { devices } from "../../style/Size";

const Column5 = styled.div`
  width: 18%;
  height: 60%;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 50px;
  @media ${devices.laptopXXS} {
    width: 200px;
  }
  @media ${devices.mobileS} {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const ColumnTitleRS = styled.p`
  width: 100%;
  font-size: 22px;
  font-family: "Playfair", serif;
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 1px;
  margin: 0;
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: center;
  white-space: nowrap;
  @media ${devices.laptopL} {
    font-size: 20px;
  }
  @media ${devices.laptopS} {
    font-size: 18px;
    margin-top: 14px;
  }
`;

const BlockIcons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const P1 = styled.div.attrs({
  className: "paragraphIcons",
})`
  margin-top: 6px;
  margin-left: 6px;
  margin-right: 12px;
  color: #ffffff;
  font-size: 28px;
  cursor: pointer;
  white-space: nowrap;
  @media ${devices.laptopL} {
    font-size: 24px;
  }
  @media ${devices.laptopS} {
    font-size: 20px;
  }
`;

const ContenairPblog = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100% - 8px);
  margin-top: 20px;
`;

const BlockContact = styled.button`
  height: 28px;
  font-family: "Russo One", sans-serif;
  letter-spacing: 0.5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  border: none;
  background-color: transparent;
  width: calc(100% - 8px);
  margin-top: 20px;
  cursor: pointer;

  @media (min-width: 991px) {
    &:hover {
      transform: scale(1.05);
    }
  }
`;

const PContact = styled.div`
  color: #ffffff;
  font-size: 16px;
  white-space: nowrap;
  @media ${devices.laptopL} {
    font-size: 14px;
  }
  @media ${devices.laptopS} {
    font-size: 12px;
  }
`;

export default function ColumnFive() {
  return (
    <Column5>
      <ColumnTitleRS>SUIVEZ-NOUS</ColumnTitleRS>
      <BlockIcons>
        <a
          aria-label="Profil Facebook"
          style={{ textDecoration: "none", color: "#FFFFFF" }}
          href="https://www.facebook.com/profile.php?id=61553054431024"
          target="blanck"
        >
          <P1>
            <i className="fa-brands fa-square-facebook"></i>
          </P1>
        </a>
        <a
          aria-label="Profil Instagram"
          style={{ textDecoration: "none", color: "#FFFFFF" }}
          href="https://www.instagram.com/entreprisenavarro/"
          target="blanck"
        >
          <P1>
            <i className="fa-brands fa-instagram"></i>
          </P1>
        </a>
        <a
          aria-label="Profil Linkedin"
          style={{ textDecoration: "none", color: "#667788" }}
          href="https://www.linkedin.com/in/entreprise-navarro-marseille-plomberie-chauffagerie-climatisation"
          target="blanck"
        >
          <P1>
            <i className="fa-brands fa-linkedin"></i>
          </P1>
        </a>
      </BlockIcons>
      <ContenairPblog>
        <Pblog />
      </ContenairPblog>
      <BlockContact>
        <PContact>
          <a
            style={{ textDecoration: "none", color: "#FFFFFF" }}
            href="mailto:entreprisenavarro@outlook.fr?subject=Contact pour renseignements"
          >
            <i className="fa-solid fa-envelope"></i> CONTACT
          </a>
        </PContact>
      </BlockContact>
    </Column5>
  );
}
