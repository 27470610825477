import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ParagraphLi from "./ParagraphLi";
import {
  EffectNavBarNotHoverForParagraphFunction,
  EffectNavBarHoverForParagraphFunction,
} from "../../../functions/EffectNavBarFunction";
import { PageTransitionContext } from "../../../contexts/PageTransition";

const BlockSubMenu = styled.ul.attrs({
  id: "waterHeaterHidden",
  className: "subMenuBlock",
})`
  width: 160px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: #fcf7f7;
  border-top: 1px solid #333131;
  border-left: 1px solid #333131;
  border-right: 1px solid #333131;
  border-radius: 2px;
  cursor: pointer;
  font-size: 34px;
  position: absolute;
  top: 65px;
  transform: scaleY(0);
  transform-origin: top;
`;

export default function WaterHeater() {
  const { displayPageTransition } = useContext(PageTransitionContext);
  const navigate = useNavigate();

  const functionForFunctionHover = (e) => {
    const paragraph = document.querySelector(`#${e.target.id}`);
    EffectNavBarHoverForParagraphFunction(
      `${paragraph.id}`,
      "waterHeaterHidden",
      "chauffe-eau"
    );
  };

  const functionForFunctionNotHover = (e) => {
    const paragraph = document.querySelector(`#${e.target.id}`);
    EffectNavBarNotHoverForParagraphFunction(
      `${paragraph.id}`,
      "waterHeaterHidden",
      "chauffe-eau"
    );
  };

  const onClickNavigate = (e, route) => {
    const allParagraph = document.querySelectorAll(".paragraph");
    for (let i = 0; i < allParagraph.length; i++) {
      if (e.target.id === allParagraph[i].id) {
        allParagraph[i].parentElement.style.pointerEvents = "none";
        EffectNavBarNotHoverForParagraphFunction(
          `${e.target.id}`,
          "waterHeaterHidden",
          "chauffe-eau"
        );

        if (window.location.pathname !== `/chauffe-eau/${e.target.id}`) {
          setTimeout(() => {
            displayPageTransition("pageTransitionEffect");
          }, 900);
          setTimeout(() => {
            displayPageTransition("pageTransitionNotEffect");
            window.scrollTo(0, 0);
            navigate(`${route}`);
          }, 1700);
        } else {
          const scrollToTop = () => {
            const c =
              document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
              window.requestAnimationFrame(scrollToTop);
              window.scrollTo(0, c - c / 8);
            }
          };
          setTimeout(() => {
            scrollToTop();
            allParagraph[i].parentElement.style.pointerEvents = "auto";
          }, 600);
        }
      }
    }
  };

  return (
    <>
      <BlockSubMenu>
        <ParagraphLi
          idLi="chauffe-eau-electriques"
          idLiHome="chauffe-eau-electriques"
          marginTop="-2px"
          marginBottom="0"
          paddingTop="12px"
          onMouseEnter={functionForFunctionHover}
          onMouseLeave={functionForFunctionNotHover}
          onClick={(e) =>
            onClickNavigate(e, "/chauffe-eau/chauffe-eau-electriques")
          }
          contentLi="Électriques"
          contentLiHome="Électriques"
        />
        <ParagraphLi
          idLi="chauffe-eau-gaz-chaudieres"
          idLiHome="chauffe-eau-gaz-chaudieres"
          marginTop="0px"
          marginBottom="0"
          paddingTop="10px"
          paddingBottom="10px"
          onMouseEnter={functionForFunctionHover}
          onMouseLeave={functionForFunctionNotHover}
          onClick={(e) =>
            onClickNavigate(e, "/chauffe-eau/chauffe-eau-gaz-chaudieres")
          }
          contentLi="Gaz – Chaudières"
          contentLiHome="Gaz – Chaudières"
        />
        <ParagraphLi
          idLi="pompes-a-chaleur"
          idLiHome="pompes-a-chaleur"
          marginTop="-10px"
          paddingTop="10px"
          onMouseEnter={functionForFunctionHover}
          onMouseLeave={functionForFunctionNotHover}
          onClick={(e) => onClickNavigate(e, "/chauffe-eau/pompes-a-chaleur")}
          contentLi="Pompes à Chaleur"
          contentLiHome="Pompes à Chaleur"
        />
      </BlockSubMenu>
    </>
  );
}
