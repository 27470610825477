import React from "react";
import styled from "styled-components";
import { devices } from "../../style/Size";

const ContenairSection = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: -6px;
`;

const TitleSection = styled.p`
  font-size: 20px;
  margin: 0;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  letter-spacing: 1px;
  margin-bottom: 50px;
  text-align: center;
  white-space: nowrap;

  &::after {
    display: block;
    content: " ";
    width: 100px;
    height: 3px;
    background-color: #ffffff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  @media ${devices.laptopL} {
    font-size: 18px;
  }
  @media ${devices.laptopS} {
    font-size: 16px;
  }
  @media ${devices.tablet} {
    width: 200px;
    margin-bottom: 10px;
    margin-left: 20px;
    &::after {
      left: 70px;
      margin-top: 4px;
      transform: translateX(0%);
    }
  }
  @media ${devices.mobileS} {
    width: 100%;
    margin-left: 0px;
    &::after {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const ContenairBlockUl = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media ${devices.tablet} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const DivBlockUl = styled.div`
  display: flex;

  @media ${devices.tablet} {
    flex-direction: column;
    justify-content: flex-start;
  }
  @media ${devices.mobileS} {
    justify-content: center;
  }
`;

const BlockUl = styled.div`
  flex-wrap: wrap;
  @media ${devices.mobileS} {
    justify-content: center;
  }
`;

const Ul = styled.ul`
  width: 324px;
  margin: 10px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0;

  @media ${devices.mobileM} {
    width: 220px;
  }

  @media ${devices.mobileS} {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    text-align: center;
    list-style-type: none;
  }
`;

const Li = styled.li`
  font-size: 16px;
  margin: 0;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  letter-spacing: 1px;
  white-space: nowrap;
  @media ${devices.laptopL} {
    font-size: 16px;
  }
  @media ${devices.laptopS} {
    font-size: 14px;
  }
  @media ${devices.mobileM} {
    font-size: 12px;
  }
  @media ${devices.mobileS} {
    font-size: 10px;
  }
`;

export default function SectionServices(props) {
  return (
    <ContenairSection
      style={{
        backgroundColor: props.backgroundColor,
      }}
    >
      <TitleSection>Nos Services</TitleSection>
      <ContenairBlockUl>
        <DivBlockUl>
          <BlockUl>
            <Ul>
              <Li>
                <strong style={{ fontWeight: "100" }}>
                  Urgence Plomberie Marseille
                </strong>
              </Li>
              <Li>
                <strong style={{ fontWeight: "100" }}>
                  Urgence Chauffagiste Marseille
                </strong>
              </Li>
              <Li>
                <strong style={{ fontWeight: "100" }}>
                  Urgence Climatisation Marseille
                </strong>
              </Li>
            </Ul>
          </BlockUl>
          <BlockUl>
            <Ul>
              <Li>
                <strong style={{ fontWeight: "100" }}>
                  Dépannage Plomberie Marseille
                </strong>
              </Li>
              <Li>
                <strong style={{ fontWeight: "100" }}>
                  Dépannage Chauffagiste Marseille
                </strong>
              </Li>
              <Li>
                <strong style={{ fontWeight: "100" }}>
                  Dépannage Climatisation Marseille
                </strong>
              </Li>
            </Ul>
          </BlockUl>
        </DivBlockUl>
        <DivBlockUl>
          <BlockUl>
            <Ul>
              <Li>
                <strong style={{ fontWeight: "100" }}>
                  Entretien Plomberie Marseille
                </strong>
              </Li>
              <Li>
                <strong style={{ fontWeight: "100" }}>
                  Entretien Chauffagiste Marseille
                </strong>
              </Li>
              <Li>
                <strong style={{ fontWeight: "100" }}>
                  Entretien Climatisation Marseille
                </strong>
              </Li>
            </Ul>
          </BlockUl>
          <BlockUl>
            <Ul>
              <Li>
                <strong style={{ fontWeight: "100" }}>
                  Installation Plomberie Marseille
                </strong>
              </Li>
              <Li>
                <strong style={{ fontWeight: "100" }}>
                  Installation Chauffagiste Marseille
                </strong>
              </Li>
              <Li>
                <strong style={{ fontWeight: "100" }}>
                  Installation Climatisation Marseille
                </strong>
              </Li>
            </Ul>
          </BlockUl>
        </DivBlockUl>
      </ContenairBlockUl>
    </ContenairSection>
  );
}
