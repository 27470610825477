import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FicheRectoBlock from "./StructureBlockFiches/FicheRectoBlock";
import FicheVersoBlock from "./StructureBlockFiches/FicheVersoBlock";
import MitigeurThermo from "../../assets/Troubleshooting/mitigeur-thermostatique.webp";
import RobinetROAI from "../../assets/Troubleshooting/robinet-ROAI.webp";
import RechercheFuite from "../../assets/Troubleshooting/camera-thermique.webp";
import Debouchage from "../../assets/Troubleshooting/pompe-de-debouchage.webp";
import {
  Effect3dFunction,
  NotEffect3dFunction,
  EffectButton3dFunction,
  NotEffectButton3dFunction,
  Effect3dDisplayFunction,
  NotEffect3dDisplayFunction,
  EffectInfosFunction,
  NotDisplayFicheVersoFunction,
  EffectInfosFunctionMiniDevice,
} from "../../functions/EffectFicheFunction";
import {
  OnScrollSubTitleFunction,
  OnScrollBlockFicheFunction,
} from "../../functions/TroubleshootingEffectDisplayBlockAndTitle";
import { devices } from "../../style/Size";

const ContenairTitleAndBlockFiches = styled.div.attrs({
  className: "contenairTitleAndBlockFichesSection",
})`
  width: 100%;
  background-color: #eaeaea;
  padding-bottom: 80px;

  @media ${devices.mobileL} {
    padding-bottom: 0px;
    padding-top: 20px;
  }
  @media (max-height: 480px) {
    padding-bottom: 0px;
  }
`;

const ContenairBlockFicheAndInfosAndTitle = styled.div`
  width: 20%;

  @media ${devices.laptopS} {
    width: 40%;
  }
  @media (max-width: 684px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  @media ${devices.mobileM} {
    width: 84%;
  }
`;

const ContenairTitleSection = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const TitleSection = styled.h2`
  font-size: 30px;
  font-family: "Russo One", serif;
  color: #553322;
  text-shadow: 1px 1px 2px pink;
  letter-spacing: 2px;
  margin: 0;
  margin-left: 40px;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 50px;
  cursor: default;
  opacity: 0.5;

  @media ${devices.laptopXXS} {
    font-size: 28px;
  }
  @media (max-width: 684px) {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  @media (max-width: 520px) {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
  @media (max-height: 480px) {
    padding-bottom: 30px;
  }
`;

const ContenairBlockFiches = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media ${devices.laptopS} {
    flex-wrap: wrap;
  }
  @media (max-width: 684px) {
    flex-direction: column;
  }
`;

const BlockTitleFiche = styled.h3`
  width: 100%;
  height: 80px;
  font-size: 20px;
  font-family: "Russo One", serif;
  color: #553322;
  margin: 0;
  padding: 0;
  text-align: center;
  letter-spacing: 2px;
  opacity: 0.6;

  @media ${devices.laptopS} {
    height: 50px;
  }
  @media (max-height: 480px) {
    height: 50px;
    margin-top: 20px;
  }
`;

const ContenairBlockFicheAndInfos = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 10px;
  position: relative;

  @media ${devices.laptopS} {
    margin-bottom: 170px;
  }
  @media ${devices.tablet} {
    margin-bottom: 120px;
  }
  @media (max-height: 480px) {
    margin-bottom: 0px;
    height: 320px;
  }
`;

const ButtonDetailMiniDevice = styled.button.attrs({
  type: "button",
  value: "Voir",
  className: "buttonFicheMiniDevice",
})`
  width: 70%;
  height: 10%;
  background: radial-gradient(circle at 70% 50%, #be7a54, #553322);
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-size: 20px;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  cursor: pointer;
  margin-left: 15%;
  margin-top: 20px;
  opacity: 0.95;

  @media (max-width: 684px) {
    font-size: 18px;
  }
  @media ${devices.mobileL} {
    width: 60%;
    margin-left: 20%;
  }
`;

export default function FicheOtherBlock() {
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);

  const [deviceButton1, setDeviceButton1] = useState(false);
  const [deviceButton2, setDeviceButton2] = useState(false);
  const [deviceButton3, setDeviceButton3] = useState(false);
  const [deviceButton4, setDeviceButton4] = useState(false);

  useEffect(() => {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setHover1(false);
      setHover2(false);
      setHover3(false);
      setHover4(false);
      setDeviceButton1(true);
      setDeviceButton2(true);
      setDeviceButton3(true);
      setDeviceButton4(true);
    }
  }, []);

  const BlockFicheRectoAndVerso = (
    idBlockTitleFiche,
    contentTitle,
    filtreId,
    buttonId,
    idBlockTopOrBottomWarp,
    imageSelect,
    hoverRef,
    classNameRef,
    setHoverRef,
    classNameRefButton,
    idMenuInfos,
    prix,
    textFicheContent,
    classNameMenuInfos,
    deviceButton,
    idButtonDetailMiniDevice,
    classNameButtonDetailMiniDevice,
    alt
  ) => {
    return (
      <ContenairBlockFicheAndInfosAndTitle>
        <BlockTitleFiche id={`${idBlockTitleFiche}`}>
          <strong>{`${contentTitle}`}</strong>
        </BlockTitleFiche>
        <ContenairBlockFicheAndInfos>
          <FicheRectoBlock
            className="blockFicheOthers"
            idFiltre={`${filtreId}`}
            idButton={`${buttonId}`}
            idBlockTopOrBottomWarp={`${idBlockTopOrBottomWarp}`}
            image={imageSelect}
            hover={hoverRef}
            alt={alt}
            effect3d={(e) =>
              Effect3dFunction(e, `${classNameRef}`, setHoverRef, 30)
            }
            notEffect3d={(e) =>
              NotEffect3dFunction(e, `${classNameRef}`, setHoverRef, false)
            }
            effectButton3d={(e) =>
              EffectButton3dFunction(e, `${classNameRefButton}`, setHoverRef)
            }
            notEffectButton3d={() =>
              NotEffectButton3dFunction(
                `${classNameRefButton}`,
                setHoverRef,
                false
              )
            }
            effectInfos={(e) =>
              EffectInfosFunction(
                e,
                `${classNameRef}`,
                `${classNameRefButton}`,
                setHoverRef
              )
            }
            notDisplayFicheVerso={(e) =>
              NotDisplayFicheVersoFunction(
                e,
                `${classNameMenuInfos}`,
                setHoverRef,
                true
              )
            }
          />
          <FicheVersoBlock
            idBlockMenuInfos={`${idMenuInfos}`}
            prix={`${prix}`}
            textFiche={[textFicheContent]}
            effect3dDisplay={(e) =>
              Effect3dDisplayFunction(e, `${classNameMenuInfos}`, 30)
            }
            notEffect3dDisplay={(e) =>
              NotEffect3dDisplayFunction(
                e,
                `${classNameMenuInfos}`,
                setHoverRef,
                false
              )
            }
          />
          {deviceButton && (
            <ButtonDetailMiniDevice
              id={idButtonDetailMiniDevice}
              onClick={(e) =>
                EffectInfosFunctionMiniDevice(
                  e,
                  `${classNameButtonDetailMiniDevice}`
                )
              }
              onTouchStart={(e) =>
                EffectInfosFunctionMiniDevice(
                  e,
                  `${classNameButtonDetailMiniDevice}`
                )
              }
            >
              VOIR
            </ButtonDetailMiniDevice>
          )}
        </ContenairBlockFicheAndInfos>
      </ContenairBlockFicheAndInfosAndTitle>
    );
  };

  useEffect(() => {
    const blockTopOrBottomWarpOne = document.querySelector(
      "#idBlockTopOrBottomWarpOneOthers"
    );
    const blockTopOrBottomWarpTwo = document.querySelector(
      "#idBlockTopOrBottomWarpTwoOthers"
    );
    const blockTopOrBottomWarpThree = document.querySelector(
      "#idBlockTopOrBottomWarpThreeOthers"
    );
    const blockTopOrBottomWarpFour = document.querySelector(
      "#idBlockTopOrBottomWarpFourOthers"
    );

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (entries[0].target.id === "idBlockTopOrBottomWarpOneOthers") {
          window.addEventListener("scroll", () => {
            OnScrollSubTitleFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour,
              "subTitleCategoriesTopOneOthers",
              "subTitleCategoriesTopTwoOthers",
              "subTitleCategoriesBottomOneOthers",
              "subTitleCategoriesBottomTwoOthers"
            );
            OnScrollBlockFicheFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour
            );
          });
        }
        if (entries[0].target.id === "idBlockTopOrBottomWarpTwoOthers") {
          window.addEventListener("scroll", () => {
            OnScrollSubTitleFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour,
              "subTitleCategoriesTopOneOthers",
              "subTitleCategoriesTopTwoOthers",
              "subTitleCategoriesBottomOneOthers",
              "subTitleCategoriesBottomTwoOthers"
            );
            OnScrollBlockFicheFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour
            );
          });
        }
        if (entries[0].target.id === "idBlockTopOrBottomWarpThreeOthers") {
          window.addEventListener("scroll", () => {
            OnScrollSubTitleFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour,
              "subTitleCategoriesTopOneOthers",
              "subTitleCategoriesTopTwoOthers",
              "subTitleCategoriesBottomOneOthers",
              "subTitleCategoriesBottomTwoOthers"
            );
            OnScrollBlockFicheFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour
            );
          });
        }
        if (entries[0].target.id === "idBlockTopOrBottomWarpFourOthers") {
          window.addEventListener("scroll", () => {
            OnScrollSubTitleFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour,
              "subTitleCategoriesTopOneOthers",
              "subTitleCategoriesTopTwoOthers",
              "subTitleCategoriesBottomOneOthers",
              "subTitleCategoriesBottomTwoOthers"
            );
            OnScrollBlockFicheFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour
            );
          });
        } else {
          window.removeEventListener("scroll", () => {
            OnScrollSubTitleFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour,
              "subTitleCategoriesTopOneOthers",
              "subTitleCategoriesTopTwoOthers",
              "subTitleCategoriesBottomOneOthers",
              "subTitleCategoriesBottomTwoOthers"
            );
            OnScrollBlockFicheFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour
            );
          });
        }
      }
    });
    observer.observe(blockTopOrBottomWarpOne);
    observer.observe(blockTopOrBottomWarpTwo);
    observer.observe(blockTopOrBottomWarpThree);
    observer.observe(blockTopOrBottomWarpFour);
  }, []);

  return (
    <ContenairTitleAndBlockFiches id="contenairBlockFicheRectoOthers">
      <ContenairTitleSection>
        <TitleSection id="titleSectionOthers" className="titleSectionOthers">
          Divers
        </TitleSection>
      </ContenairTitleSection>
      <ContenairBlockFiches id="contenairBlockFichesOthers">
        {BlockFicheRectoAndVerso(
          "subTitleCategoriesTopOneOthers",
          "Tête-Mitigeur Thermostatique",
          "idfilterAndEffect3d1Others",
          "buttonFiche1Others",
          "idBlockTopOrBottomWarpOneOthers",
          MitigeurThermo,
          hover1,
          "blockFicheOthers",
          setHover1,
          "buttonFiche",
          "idBlockMenuInfos1Others",
          "100€",
          [
            <strong key="1" style={{ fontWeight: "100" }}>
              Robinet mitigeur pour lavabo
            </strong>,
            ".",
            <br key="2" />,
            "Toutes marques, tous modèles, tout budgets.",
            <br key="3" />,
            <br key="4" />,
            " Nos ",
            <strong key="5" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " interviennent avec rapidité et efficacité.",
          ],
          "blockMenuInfos",
          deviceButton1,
          "buttonDetailMiniDeviceOneOthers",
          "buttonFicheMiniDevice",
          "robinet pour lavabo tête-mitigeur thermostatique"
        )}
        {BlockFicheRectoAndVerso(
          "subTitleCategoriesTopTwoOthers",
          "Robinet ROAI",
          "idfilterAndEffect3d2Others",
          "buttonFiche2Others",
          "idBlockTopOrBottomWarpTwoOthers",
          RobinetROAI,
          hover2,
          "blockFicheOthers",
          setHover2,
          "buttonFiche",
          "idBlockMenuInfos2Others",
          "100€",
          [
            <strong key="1" style={{ fontWeight: "100" }}>
              Robinet de sécurité avec clapet{" "}
              <span key="2" style={{ whiteSpace: "nowrap" }}>
                anti-retour
              </span>
            </strong>,
            ".",
            <br key="3" />,
            "Cette pièce est essentielle pour assurer la prévention de toute ",
            <strong key="4" style={{ fontWeight: "100" }}>
              fuite de gaz
            </strong>,
            " inopinée qui interviendrait au niveau de votre gazinière.",
            <br key="5" />,
            <br key="6" />,
            " Nos ",
            <strong key="7" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " interviennent avec rapidité et efficacité.",
          ],
          "blockMenuInfos",
          deviceButton2,
          "buttonDetailMiniDeviceTwoOthers",
          "buttonFicheMiniDevice",
          "robinet de sécurité gaz robinet ROAI"
        )}
        {BlockFicheRectoAndVerso(
          "subTitleCategoriesBottomOneOthers",
          "Débouchage",
          "idfilterAndEffect3d3Others",
          "buttonFiche3Others",
          "idBlockTopOrBottomWarpThreeOthers",
          Debouchage,
          hover3,
          "blockFicheOthers",
          setHover3,
          "buttonFiche",
          "idBlockMenuInfos3Others",
          "120€",
          [
            <strong key="1" style={{ fontWeight: "100" }}>
              Conduit bouché
            </strong>,
            " ? ",
            <strong key="2" style={{ fontWeight: "100" }}>
              WC bouché
            </strong>,
            " ? ",
            <strong key="3" style={{ fontWeight: "100" }}>
              Remontées d'odeurs du siphon
            </strong>,
            " ? ",
            <br key="4" />,
            <br key="5" />,
            "Contre-pente, malfaçon... Les causes sont multiples à un ",
            <strong key="6" style={{ fontWeight: "100" }}>
              problème d'évacuation des eaux
            </strong>,
            ".",
            <br key="7" />,
            <br key="8" />,
            "Nos ",
            <strong key="9" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " interviennet avec un ",
            <strong key="10" style={{ fontWeight: "100" }}>
              déboucheur ou un furet pour déboucher toute canalisation
            </strong>,
            " qui ne remplirait plus son rôle.",
          ],
          "blockMenuInfos",
          deviceButton3,
          "buttonDetailMiniDeviceThreeOthers",
          "buttonFicheMiniDevice",
          "matériel de débouchage canalisations"
        )}
        {BlockFicheRectoAndVerso(
          "subTitleCategoriesBottomTwoOthers",
          "Recherche De Fuite",
          "idfilterAndEffect3d4Others",
          "buttonFiche4Others",
          "idBlockTopOrBottomWarpFourOthers",
          RechercheFuite,
          hover4,
          "blockFicheOthers",
          setHover4,
          "buttonFiche",
          "idBlockMenuInfos4Others",
          "400€",
          [
            "Une ",
            <strong key="1" style={{ fontWeight: "100" }}>
              fuite dans votre jardin
            </strong>,
            " ? ",
            "Une ",
            <strong key="2" style={{ fontWeight: "100" }}>
              surconsommation d'eau
            </strong>,
            " ? ",
            "Une ",
            <strong key="3" style={{ fontWeight: "100" }}>
              tuyauterie en cuivre
            </strong>,
            " défaillante ? De l'",
            <strong key="4" style={{ fontWeight: "100" }}>
              humidité au niveau de votre plancher
            </strong>,
            " ?",
            <br key="5" />,
            "Nous disposons de tout le matériel adapté, sonar sol, pompe à épreuve, caméra thermique, afin de situer la source du problème, et y remédier.",
            <br key="6" />,
            <br key="7" />,
            " Nos ",
            <strong key="8" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " interviennent avec rapidité et efficacité.",
          ],
          "blockMenuInfos",
          deviceButton4,
          "buttonDetailMiniDeviceFourOthers",
          "buttonFicheMiniDevice",
          "appareil de recherche de fuite d'eau grâce à un système infra-rouge"
        )}
      </ContenairBlockFiches>
    </ContenairTitleAndBlockFiches>
  );
}
