import React from "react";
import styled from "styled-components";
import NavBar from "../../NavBar/NavBar";
import "../../../style/BackgroundImages/HeaderParallax.css";
import SocialNetworkAndContact from "./SocialNetworkAndContact";
import ContenairTitleHeader from "../ContenairTitleHeader";
import { ParallaxFunction } from "../../../functions/ParallaxFunction";
import { NavBarDisplayFunction } from "../../../functions/NavBarDisplayScrollFunction";
import { devices } from "../../../style/Size";

const HeaderContenair = styled.header.attrs({
  id: "headerHomeContenair",
})`
  width: 100%;
  height: 100vh;
  padding-bottom: 4px;
  margin-bottom: 60px;
  background-color: #553322;

  @media ${devices.tablet} {
    height: 50vh;
    margin-bottom: 20px;
  }
  @media (max-height: 480px) {
    height: 70vh;
  }
`;

const BlockImageHeader = styled.div.attrs({
  className: "contenairParallax",
})`
  width: 100%;
  height: 100vh;
  position: relative;

  @media ${devices.tablet} {
    height: 50vh;
    margin-bottom: 10px;
  }
  @media (max-height: 480px) {
    height: 70vh;
  }
`;

const ContenairImage = styled.div.attrs({
  className: "parallax",
})`
  width: 100%;
  height: 100%;
`;

export default function Header() {
  window.addEventListener("scroll", () => {
    ParallaxFunction("parallax", "contenairParallax");
    NavBarDisplayFunction("headerHomeContenair", "/");
  });

  return (
    <>
      <HeaderContenair>
        <NavBar />
        <BlockImageHeader>
          <ContenairImage />
        </BlockImageHeader>
        <ContenairTitleHeader />
        <SocialNetworkAndContact />
      </HeaderContenair>
    </>
  );
}
