import React, { useEffect } from "react";
import styled from "styled-components";
import HeaderBlog from "../../components/Header/HeaderBlog";
import Footer from "../../components/Footer";
import { NavBarDisplayFunction } from "../../functions/NavBarDisplayScrollFunction";
import { devices } from "../../style/Size";

const ContenairMain = styled.main`
  width: 94%;
  /* background-color: #ffffff; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 134px;
  margin-bottom: 200px;

  @media ${devices.laptopXXS} {
    margin-top: 114px;
    margin-bottom: 120px;
  }
  @media ${devices.mobileL} {
    margin-top: 100px;
    margin-bottom: 80px;
  }
  @media (max-height: 480px) {
    margin-bottom: 80px;
  }
`;

const TitleArticle = styled.h1`
  font-size: 34px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  padding-top: 10px;

  @media ${devices.tablet} {
    font-size: 32px;
  }
  @media ${devices.mobileL} {
    font-size: 30px;
  }
  @media ${devices.mobileM} {
    font-size: 28px;
  }
`;

const SubTitleArticle = styled.h2`
  font-size: 28px;
  font-family: "Russo One", sans-serif;
  color: #553322;

  @media ${devices.tablet} {
    font-size: 26px;
  }
  @media ${devices.mobileL} {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
`;

const TextArticle = styled.h2`
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  text-align: justify;
  margin-bottom: 40px;

  @media ${devices.mobileL} {
    margin-bottom: 50px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

export default function WaterHeaterMaintenance() {
  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction(
      "headerBlogContenair",
      "comment-prendre-soin-de-ses-canalisations"
    );
  });

  return (
    <>
      <HeaderBlog />
      <ContenairMain>
        <TitleArticle>
          Les Secrets d'un Entretien Efficace des Canalisations : Guide
          Professionnel de Plomberie
        </TitleArticle>
        <TextArticle>
          Les canalisations sont les artères invisibles de nos foyers,
          travaillant silencieusement pour assurer un flux constant d'eau
          propre. Cependant, même les systèmes de plomberie les plus fiables
          nécessitent un entretien régulier pour rester en parfait état de
          fonctionnement. Dans cet article, nous explorerons les meilleures
          pratiques pour l'entretien des canalisations, des conseils
          professionnels qui vous aideront à éviter les maux de tête coûteux
          liés aux obstructions et aux fuites.
        </TextArticle>
        <section>
          <SubTitleArticle>Prévention des Obstructions</SubTitleArticle>
          <TextArticle>
            Les obstructions dans les canalisations sont souvent à l'origine de
            problèmes majeurs. Pour éviter cela, adoptez une approche proactive
            en installant des filtres dans les éviers et les douches pour
            retenir les débris solides. Les cheveux, les restes de nourriture et
            autres résidus peuvent rapidement s'accumuler, formant des bouchons
            redoutables. Nettoyez régulièrement ces filtres pour garantir un
            écoulement fluide de l'eau.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            L'utilisation Modérée des Produits Chimiques
          </SubTitleArticle>
          <TextArticle>
            Bien que les produits chimiques soient souvent utilisés pour
            déboucher les canalisations, leur utilisation excessive peut
            entraîner des dommages importants. Optez d'abord pour des méthodes
            mécaniques, telles que les ventouses ou les furets, avant de
            recourir à des produits chimiques. Lorsqu'ils sont nécessaires,
            choisissez des produits spécifiquement conçus pour les
            canalisations, et suivez attentivement les instructions du
            fabricant.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Entretien Régulier des Siphons</SubTitleArticle>
          <TextArticle>
            Les siphons jouent un rôle essentiel en empêchant les mauvaises
            odeurs de remonter des égouts. Inspectez régulièrement les siphons
            sous les éviers et les lavabos, démontez-les si nécessaire, et
            nettoyez-les de tout résidu accumulé. Cela garantira un écoulement
            ininterrompu et préviendra les odeurs désagréables.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            La Puissance du Vinaigre et du Bicarbonate de Soude
          </SubTitleArticle>
          <TextArticle>
            Un remède naturel et écologique pour l'entretien des canalisations
            consiste à utiliser une combinaison de vinaigre blanc et de
            bicarbonate de soude. Versez une demi-tasse de bicarbonate de soude
            suivi d'une tasse de vinaigre dans le drain, puis rincez à l'eau
            chaude après 15 minutes. Cette réaction chimique aide à désagréger
            les résidus, maintenant ainsi des canalisations propres.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>L'Inspection Professionnelle</SubTitleArticle>
          <TextArticle>
            Au moins une fois par an, envisagez de faire inspecter vos
            canalisations par un professionnel de la plomberie. L'utilisation de
            caméras d'inspection permet de détecter les problèmes potentiels
            tels que les fissures, les joints défectueux, ou l'accumulation de
            dépôts à des endroits inaccessibles. Une intervention précoce peut
            éviter des réparations coûteuses à long terme. L'Entreprise Navarro
            située à Marseille saura réaliser cette étape avec succès. Appelez
            nous pour plus de renseignements.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Conclusion</SubTitleArticle>
          <TextArticle>
            En investissant du temps et des efforts dans l'entretien régulier de
            vos canalisations, vous préservez l'intégrité de votre système de
            plomberie et réduisez le risque de pannes coûteuses. Adoptez une
            approche proactive, utilisez des méthodes sûres et naturelles, et
            n'hésitez pas à consulter un professionnel pour un entretien
            approfondi. Vos canalisations vous en remercieront par un écoulement
            fluide et une durée de vie prolongée.
          </TextArticle>
        </section>
      </ContenairMain>
      <Footer marginTop="180px" backgroundColor="#3c2114" />
    </>
  );
}
