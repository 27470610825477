import React from "react";
import styled from "styled-components";
import { devices } from "../../style/Size";

const ContenairBlockElement = styled.section`
  width: 96%;
  max-width: 1740px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 100px;

  @media ${devices.laptopXXS} {
    padding-bottom: 80px;
  }
  @media ${devices.mobileL} {
    padding-bottom: 66px;
  }
  @media (max-height: 480px) {
    margin-top: 40px;
    padding-bottom: 0px;
  }
`;

const BlockTitle = styled.h2.attrs({
  id: "blockTitle",
  className: "blockTitle",
})`
  font-size: 30px;
  color: #667788;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  transform: translateX(-1vw);

  @media ${devices.laptopXXS} {
    transform: translateX(0vw);
  }
  @media ${devices.tablet} {
    font-size: 24px;
  }
  @media ${devices.mobileS} {
    font-size: 22px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  min-height: 300px;
  color: #667788;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  text-align: justify;
  transform: translateX(-1vw);

  @media ${devices.laptopXXS} {
    transform: translateX(0vw);
  }
  @media ${devices.mobileL} {
    min-height: 200px;
  }
  @media ${devices.mobileXS} {
    font-size: 14px;
  }
`;

const Image = styled.img`
  width: 34%;
  height: 300px;
  object-fit: cover;
  float: right;
  margin-top: 20px;
  margin-left: 20px;
  transform: translateX(1vw);

  @media ${devices.laptopXXS} {
    width: 50%;
    margin-bottom: 1px;
    margin-left: 10px;
  }
  @media (max-width: 568px) {
    width: 100%;
    margin-bottom: 10px;
    transform: translateX(0vw);
  }
`;

export default function BodySubSection(props) {
  return (
    <ContenairBlockElement>
      <BlockTitle>{props.title}</BlockTitle>
      <Image
        className={props.imageClassname}
        src={props.src}
        alt={props.alt}
      ></Image>
      <Text id={props.idText}>{props.text}</Text>
    </ContenairBlockElement>
  );
}
