import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import NavBar from "../components/NavBar/NavBar";
import ContenairTitlePageSection from "../components/ContenairTitlePageSection";
import BlockFicheInstallation from "../components/BodyInstallation/BlockFicheInstallation";
import Footer from "../components/Footer";
import PlomberieInstallation from "../assets/Header/plomberie-installation.webp";
import WC from "../assets/Installation/wc.webp";
import Lavabo from "../assets/Installation/m-lavabo.webp";
import BacDouche from "../assets/Installation/m-douche-receveur.webp";
import Baignoire from "../assets/Installation/m-baignoire.avif";
import CEE from "../assets/Installation/m-chauffe-eau-electrique.webp";
import CEG from "../assets/Installation/chauffe-eau-gaz.webp";
import PompeChaleur from "../assets/Installation/m-pompe-a-chaleur.webp";
import Climatisation from "../assets/Installation/m-climatisation.webp";
import "../style/BackgroundImages/InstallationBackground.css";
import { PageTransitionContext } from "../contexts/PageTransition";
import { NavBarDisplayFunction } from "../functions/NavBarDisplayScrollFunction";
import { EffectTitlePageFunction } from "../functions/EffectTitlePageFunction";
import { OffsetTopFunction } from "../functions/OffsetTopFunction";
import { ButtonEffectScaleClickFunction } from "../functions/ButtonClickEffectScale";
import { NotEffectGlowButtonFunction } from "../functions/EffectGlowButtonsFunction";
import { devices } from "../style/Size";

const Header = styled.header`
  height: 100vh;

  @media ${devices.laptopXXS} {
    height: 90vh;
  }
  @media ${devices.tablet} {
    height: 70vh;
  }
  @media (max-height: 480px) {
    height: 80vh;
  }
`;

const BlockImage = styled.div`
  width: 100%;
  height: 70vh;
  position: relative;
  top: 62px;
  @media ${devices.tablet} {
    height: 50vh;
    top: 32px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContenairMain = styled.main`
  width: 100%;
  position: relative;
  margin-top: 60px;
  margin-bottom: 200px;

  @media ${devices.tablet} {
    margin-bottom: 160px;
  }
  @media ${devices.mobileL} {
    margin-top: 20px;
    margin-bottom: 120px;
  }
`;

export default function Installation() {
  const { displayPageTransition } = useContext(PageTransitionContext);

  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    EffectTitlePageFunction("titlePageInstallation");
    setTimeout(() => {
      EffectTitlePageFunction("blockTitleWaterHeaver");
    }, 600);
  }, []);

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction("headerInstallationContenair");

    const blockCategories = document.querySelectorAll(
      ".contenairBlockInstallation"
    );
    const screenY = window.scrollY + window.innerHeight * 0.9;
    for (let i = 0; i < blockCategories.length; i++) {
      if (OffsetTopFunction(blockCategories[i]) - screenY < -90) {
        blockCategories[i].style.transform = "translateY(0px) scale(1)";
        blockCategories[i].style.transition = "all 500ms";
      }
      if (OffsetTopFunction(blockCategories[i]) - screenY > -90) {
        blockCategories[i].style.transform = "translateY(40px) scale(1.05)";
        blockCategories[i].style.transition = "all 500ms";
      }
    }
  });

  const FunctionLink = (e, id, route) => {
    const idElement = document.querySelector(`#${id}`);

    NotEffectGlowButtonFunction("buttonSectionInstallation");
    ButtonEffectScaleClickFunction(idElement);
    idElement.style.pointerEvents = "none";
    idElement.style.cursor = "auto";

    if (e.target.id === idElement.id) {
      setTimeout(() => {
        displayPageTransition("pageTransitionEffect");
      }, 700);

      setTimeout(() => {
        displayPageTransition("pageTransitionNotEffect");
        window.scrollTo(0, 0);
        window.location.pathname = `${route}`;
      }, 1500);
    }
  };

  return (
    <>
      <Header>
        <NavBar backgroundColor="#667788" />
        <BlockImage>
          <Image
            fetchpriority="high"
            src={PlomberieInstallation}
            alt="matériel et outils avec plan de construction plomberie pour installation"
          />
        </BlockImage>
        <ContenairTitlePageSection
          backgroundColor="#FFFFFF"
          idTitle="titlePageInstallation"
          title="Installation"
        />
      </Header>
      <ContenairMain>
        <section>
          <BlockFicheInstallation
            backgroundColor="#eaeaea"
            picture={WC}
            alt="WC balnc classique dans sanitaire toilettes de couleur marron laquée"
            idPicture="installationWC"
            title="W-C"
            idButton="buttonWC"
            text={[
              " Vos ",
              <strong key="1" style={{ fontWeight: "100" }}>
                WC
              </strong>,
              " sont vieux ? Usés ? Dysfonctionnels ? Ou bien simplement démodés ? Ou bien souhaitez-vous investir dans des ",
              <strong key="2" style={{ fontWeight: "100" }}>
                WC économiques
              </strong>,
              " ?",
              <br key="3" />,
              <br key="4" />,
              " Que ce soit pour des ",
              <strong key="5" style={{ fontWeight: "100" }}>
                WC
              </strong>,
              " classiques, des ",
              <strong key="6" style={{ fontWeight: "100" }}>
                WC économiques
              </strong>,
              ", des ",
              <strong key="7" style={{ fontWeight: "100" }}>
                WC suspendus
              </strong>,
              ", ou des ",
              <strong key="8" style={{ fontWeight: "100" }}>
                toilettes intelligentes
              </strong>,
              " high tech, nos experts ",
              <strong key="9" style={{ fontWeight: "100" }}>
                plombiers à Marseille
              </strong>,
              " interviennent dans toute la ville de ",
              <strong key="10" style={{ fontWeight: "100" }}>
                Marseille
              </strong>,
              " mais aussi ses environs (",
              <strong key="11" style={{ fontWeight: "100" }}>
                Allauch
              </strong>,
              ", ",
              <strong key="12" style={{ fontWeight: "100" }}>
                {" "}
                Plan-de-Cuques
              </strong>,
              ", ",
              <strong key="13" style={{ fontWeight: "100" }}>
                {" "}
                La Bouilladisse
              </strong>,
              ", ",
              <strong key="14" style={{ fontWeight: "100" }}>
                {" "}
                Cuges-les-Pins
              </strong>,
              ", ",
              <strong key="15" style={{ fontWeight: "100" }}>
                {" "}
                Peynier
              </strong>,
              ", ",
              <strong key="16" style={{ fontWeight: "100" }}>
                {" "}
                Aubagne
              </strong>,
              ", ",
              <strong key="17" style={{ fontWeight: "100" }}>
                {" "}
                Aix-en-Provence
              </strong>,
              ", ",
              <strong key="18" style={{ fontWeight: "100" }}>
                {" "}
                Marignane
              </strong>,
              ", ",
              <strong key="19" style={{ fontWeight: "100" }}>
                {" "}
                Cassis
              </strong>,
              ", ",
              <strong key="20" style={{ fontWeight: "100" }}>
                {" "}
                La Ciotat
              </strong>,
              ") pour appliquer un travail de qualité et garanti, ce à un coût intervention plombier attractif, honnête et responsable.",
            ]}
            onClick={(e) =>
              FunctionLink(e, "buttonWC", "/installation/toilettes")
            }
            // onTouchStart={(e) =>
            //   FunctionLink(e, "buttonWC", "/installation/toilettes")
            // }
          />
        </section>
        <section>
          <BlockFicheInstallation
            backgroundColor="#eee4e1"
            picture={Lavabo}
            alt="Lavabo blanc rectangulaire raffiné sur meuble de salle de bain dans pièce aux carrelages rectangulaires blancs"
            title="LAVABOS"
            idButton="buttonLavabo"
            text={[
              "Vos ",
              <strong key="1" style={{ fontWeight: "100" }}>
                lavabos
              </strong>,
              " ne vous conviennent plus ? Vous les trouvez anciens, inconfortables, et vous désirez les changer pour de plus modernes et agréables ? ",
              <br key="2" />,
              <br key="3" />,
              <strong key="4" style={{ fontWeight: "100" }}>
                Lavabos
              </strong>,
              " contemporains et économiques, nos experts ",
              <strong key="5" style={{ fontWeight: "100" }}>
                plombiers à Marseille
              </strong>,
              " interviennent dans toute la ville de ",
              <strong key="6" style={{ fontWeight: "100" }}>
                Marseille
              </strong>,
              " mais aussi ses environs (",
              <strong key="7" style={{ fontWeight: "100" }}>
                Allauch
              </strong>,
              ", ",
              <strong key="8" style={{ fontWeight: "100" }}>
                {" "}
                Plan-de-Cuques
              </strong>,
              ", ",
              <strong key="9" style={{ fontWeight: "100" }}>
                {" "}
                La Bouilladisse
              </strong>,
              ", ",
              <strong key="10" style={{ fontWeight: "100" }}>
                {" "}
                Cuges-les-Pins
              </strong>,
              ", ",
              <strong key="11" style={{ fontWeight: "100" }}>
                {" "}
                Peynier
              </strong>,
              ", ",
              <strong key="12" style={{ fontWeight: "100" }}>
                {" "}
                Aubagne
              </strong>,
              ", ",
              <strong key="13" style={{ fontWeight: "100" }}>
                {" "}
                Aix-en-Provence
              </strong>,
              ", ",
              <strong key="14" style={{ fontWeight: "100" }}>
                {" "}
                Marignane
              </strong>,
              ", ",
              <strong key="15" style={{ fontWeight: "100" }}>
                {" "}
                Cassis
              </strong>,
              ", ",
              <strong key="16" style={{ fontWeight: "100" }}>
                {" "}
                La Ciotat
              </strong>,
              ") pour appliquer un travail de qualité et garanti, ce à un coût intervention plombier attractif, honnête et responsable.",
            ]}
            onClick={(e) =>
              FunctionLink(e, "buttonLavabo", "/installation/lavabos")
            }
            // onTouchStart={(e) =>
            //   FunctionLink(e, "buttonLavabo", "/installation/lavabos")
            // }
          />
        </section>
        <section>
          <BlockFicheInstallation
            backgroundColor="#eaeaea"
            picture={BacDouche}
            alt="Douche vitrée avec receveur de douche rectangulaire blanc dans salle de bain raffinée"
            idPicture="installationTrayToShower"
            title="BACS À DOUCHE"
            idButton="buttonBacDouche"
            text={[
              "Vous voulez rénover toute ou partie de votre salle de bain ? Vous voulez moderniser votre ",
              <strong key="1" style={{ fontWeight: "100" }}>
                bac à douche
              </strong>,
              " qui se trouve vieillissant ?",
              <br key="2" />,
              <br key="3" />,
              <strong key="4" style={{ fontWeight: "100" }}>
                Bacs à douche
              </strong>,
              " simple ou design, ",
              <strong key="5" style={{ fontWeight: "100" }}>
                cabine de douche
              </strong>,
              ", assise, ",
              <strong key="6" style={{ fontWeight: "100" }}>
                barre de maintien
              </strong>,
              ", nos experts ",
              <strong key="7" style={{ fontWeight: "100" }}>
                plombiers à Marseille
              </strong>,
              " interviennent dans toute la ville de ",
              <strong key="8" style={{ fontWeight: "100" }}>
                Marseille
              </strong>,
              " mais aussi ses environs (",
              <strong key="9" style={{ fontWeight: "100" }}>
                Allauch
              </strong>,
              ", ",
              <strong key="10" style={{ fontWeight: "100" }}>
                {" "}
                Plan-de-Cuques
              </strong>,
              ", ",
              <strong key="11" style={{ fontWeight: "100" }}>
                {" "}
                La Bouilladisse
              </strong>,
              ", ",
              <strong key="12" style={{ fontWeight: "100" }}>
                {" "}
                Cuges-les-Pins
              </strong>,
              ", ",
              <strong key="13" style={{ fontWeight: "100" }}>
                {" "}
                Peynier
              </strong>,
              ", ",
              <strong key="14" style={{ fontWeight: "100" }}>
                {" "}
                Aubagne
              </strong>,
              ", ",
              <strong key="15" style={{ fontWeight: "100" }}>
                {" "}
                Aix-en-Provence
              </strong>,
              ", ",
              <strong key="16" style={{ fontWeight: "100" }}>
                {" "}
                Marignane
              </strong>,
              ", ",
              <strong key="17" style={{ fontWeight: "100" }}>
                {" "}
                Cassis
              </strong>,
              ", ",
              <strong key="18" style={{ fontWeight: "100" }}>
                {" "}
                La Ciotat
              </strong>,
              ") pour appliquer un travail de qualité et garanti, ce à un coût intervention plombier attractif, honnête et responsable.",
            ]}
            onClick={(e) =>
              FunctionLink(e, "buttonBacDouche", "/installation/bacs-a-douche")
            }
            // onTouchStart={(e) =>
            //   FunctionLink(e, "buttonBacDouche", "/installation/bacs-a-douche")
            // }
          />
        </section>
        <section>
          <BlockFicheInstallation
            backgroundColor="#eee4e1"
            picture={Baignoire}
            alt="Baignoire rectangulaire blanche confortable aux parois couvrées par plaques en plâtres style béton"
            title="BAIGNOIRES"
            idButton="buttonBaignoires"
            text={[
              "Votre ",
              <strong key="1" style={{ fontWeight: "100" }}>
                baignoire
              </strong>,
              " est cassée ou fendue ? Ou vous décidez d'opter pour une baignoire plus spacieuse et confortable ? Ou vous venez de faire construire et vous voulez installer une salle de bain selon vos goûts ?",
              <br key="2" />,
              <br key="3" />,
              <strong key="4" style={{ fontWeight: "100" }}>
                Baignoire classique
              </strong>,
              ", ",
              <strong key="5" style={{ fontWeight: "100" }}>
                baignoire à l'ancienne
              </strong>,
              ", ",
              <strong key="6" style={{ fontWeight: "100" }}>
                baignoire hydro-massant
              </strong>,
              ", ",
              <strong key="7" style={{ fontWeight: "100" }}>
                bain à bulles
              </strong>,
              ", nos experts ",
              <strong key="8" style={{ fontWeight: "100" }}>
                plombiers à Marseille
              </strong>,
              " interviennent dans toute la ville de ",
              <strong key="9" style={{ fontWeight: "100" }}>
                Marseille
              </strong>,
              " mais aussi ses environs (",
              <strong key="10" style={{ fontWeight: "100" }}>
                Allauch
              </strong>,
              ", ",
              <strong key="11" style={{ fontWeight: "100" }}>
                {" "}
                Plan-de-Cuques
              </strong>,
              ", ",
              <strong key="12" style={{ fontWeight: "100" }}>
                {" "}
                La Bouilladisse
              </strong>,
              ", ",
              <strong key="13" style={{ fontWeight: "100" }}>
                {" "}
                Cuges-les-Pins
              </strong>,
              ", ",
              <strong key="14" style={{ fontWeight: "100" }}>
                {" "}
                Peynier
              </strong>,
              ", ",
              <strong key="15" style={{ fontWeight: "100" }}>
                {" "}
                Aubagne
              </strong>,
              ", ",
              <strong key="16" style={{ fontWeight: "100" }}>
                {" "}
                Aix-en-Provence
              </strong>,
              ", ",
              <strong key="17" style={{ fontWeight: "100" }}>
                {" "}
                Marignane
              </strong>,
              ", ",
              <strong key="18" style={{ fontWeight: "100" }}>
                {" "}
                Cassis
              </strong>,
              ", ",
              <strong key="19" style={{ fontWeight: "100" }}>
                {" "}
                La Ciotat
              </strong>,
              ") pour appliquer un travail de qualité et garanti, ce à un coût intervention plombier attractif, honnête et responsable.",
            ]}
            onClick={(e) =>
              FunctionLink(e, "buttonBaignoires", "/installation/baignoires")
            }
            // onTouchStart={(e) =>
            //   FunctionLink(e, "buttonBaignoires", "/installation/baignoires")
            // }
          />
        </section>
        <section>
          <BlockFicheInstallation
            backgroundColor="#eaeaea"
            picture={CEE}
            alt="Grand et long chauffe-eau électrique sol au design noir et blanc placé dans un placard de la cuisine"
            idPicture="installationElectricWaterHeater"
            title="CHAUFFE-EAU ÉLECTRIQUES"
            idButton="buttonCEE"
            text={[
              "Votre ",
              <strong key="1" style={{ fontWeight: "100" }}>
                chauffe-eau électrique
              </strong>,
              " ne fonctionne plus ? Ou vous coûte-t-il trop cher de par sa consommation énergétique de plus en plus élevée ? Sinon que votre famille s'agrandit et la contenance de son ballon ne suffit plus ? Vous décidez donc de le changer ?",
              <br key="2" />,
              <br key="3" />,
              "Que ce soit pour un ",
              <strong key="4" style={{ fontWeight: "100" }}>
                chauffe-eau électrique blindé
              </strong>,
              ", un ",
              <strong key="5" style={{ fontWeight: "100" }}>
                chauffe-eau électrique stéatite
              </strong>,
              ", un ",
              <strong key="6" style={{ fontWeight: "100" }}>
                chauffe-eau électrique thermodynamique
              </strong>,
              ", nos experts ",
              <strong key="7" style={{ fontWeight: "100" }}>
                chauffagistes à Marseille
              </strong>,
              " interviennent dans toute la ville de ",
              <strong key="8" style={{ fontWeight: "100" }}>
                Marseille
              </strong>,
              " mais aussi ses environs (",
              <strong key="9" style={{ fontWeight: "100" }}>
                Allauch
              </strong>,
              ", ",
              <strong key="10" style={{ fontWeight: "100" }}>
                {" "}
                Plan-de-Cuques
              </strong>,
              ", ",
              <strong key="11" style={{ fontWeight: "100" }}>
                {" "}
                La Bouilladisse
              </strong>,
              ", ",
              <strong key="12" style={{ fontWeight: "100" }}>
                {" "}
                Cuges-les-Pins
              </strong>,
              ", ",
              <strong key="13" style={{ fontWeight: "100" }}>
                {" "}
                Peynier
              </strong>,
              ", ",
              <strong key="14" style={{ fontWeight: "100" }}>
                {" "}
                Aubagne
              </strong>,
              ", ",
              <strong key="15" style={{ fontWeight: "100" }}>
                {" "}
                Aix-en-Provence
              </strong>,
              ", ",
              <strong key="16" style={{ fontWeight: "100" }}>
                {" "}
                Marignane
              </strong>,
              ", ",
              <strong key="17" style={{ fontWeight: "100" }}>
                {" "}
                Cassis
              </strong>,
              ", ",
              <strong key="18" style={{ fontWeight: "100" }}>
                {" "}
                La Ciotat
              </strong>,
              ") pour appliquer un travail de qualité et garanti, ce à un coût intervention plombier attractif, honnête et responsable.",
            ]}
            onClick={(e) =>
              FunctionLink(
                e,
                "buttonCEE",
                "/chauffe-eau/chauffe-eau-electriques"
              )
            }
            // onTouchStart={(e) =>
            //   FunctionLink(
            //     e,
            //     "buttonCEE",
            //     "/chauffe-eau/chauffe-eau-electriques"
            //   )
            // }
          />
        </section>
        <section>
          <BlockFicheInstallation
            backgroundColor="#eee4e1"
            picture={CEG}
            alt="Chauffe-eau gaz mural rectangulaire et compact blanc dans salle de bain de couleur blanche"
            title="CHAUFFE-EAU GAZ & CHAUDIÈRES"
            idButton="buttonCEG"
            text={[
              "Votre ",
              <strong key="1" style={{ fontWeight: "100" }}>
                chauffe-eau gaz
              </strong>,
              " ne fonctionne plus ? Il vous faut le changer ? Ou vous envisagez de rénover votre ",
              <strong key="2" style={{ fontWeight: "100" }}>
                installation chauffage
              </strong>,
              " pour une ",
              <strong key="3" style={{ fontWeight: "100" }}>
                chaudière
              </strong>,
              " avec ou non ",
              <strong key="4" style={{ fontWeight: "100" }}>
                installation de radiateurs
              </strong>,
              " pour un tout plus efficace et économique ? Ou bien simplement déplacer votre ",
              <strong key="5" style={{ fontWeight: "100" }}>
                installation chauffage
              </strong>,
              " ? Sinon besoin d'un ",
              <strong key="6" style={{ fontWeight: "100" }}>
                désembouage radiateurs
              </strong>,
              " ?",
              <br key="7" />,
              <br key="8" />,
              "Nos experts ",
              <strong key="9" style={{ fontWeight: "100" }}>
                chauffagistes à Marseille
              </strong>,
              ", habilités ",
              <strong key="10" style={{ fontWeight: "100" }}>
                Professionnels du Gaz
              </strong>,
              ", interviennent dans toute la ville de ",
              <strong key="11" style={{ fontWeight: "100" }}>
                Marseille
              </strong>,
              " mais aussi ses environs (",
              <strong key="12" style={{ fontWeight: "100" }}>
                Allauch
              </strong>,
              ", ",
              <strong key="13" style={{ fontWeight: "100" }}>
                {" "}
                Plan-de-Cuques
              </strong>,
              ", ",
              <strong key="14" style={{ fontWeight: "100" }}>
                {" "}
                La Bouilladisse
              </strong>,
              ", ",
              <strong key="15" style={{ fontWeight: "100" }}>
                {" "}
                Cuges-les-Pins
              </strong>,
              ", ",
              <strong key="16" style={{ fontWeight: "100" }}>
                {" "}
                Peynier
              </strong>,
              ", ",
              <strong key="17" style={{ fontWeight: "100" }}>
                {" "}
                Aubagne
              </strong>,
              ", ",
              <strong key="18" style={{ fontWeight: "100" }}>
                {" "}
                Aix-en-Provence
              </strong>,
              ", ",
              <strong key="19" style={{ fontWeight: "100" }}>
                {" "}
                Marignane
              </strong>,
              ", ",
              <strong key="20" style={{ fontWeight: "100" }}>
                {" "}
                Cassis
              </strong>,
              ", ",
              <strong key="21" style={{ fontWeight: "100" }}>
                {" "}
                La Ciotat
              </strong>,
              ") pour appliquer un travail de qualité et garanti, ce à un coût intervention plombier attractif, honnête et responsable.",
            ]}
            onClick={(e) =>
              FunctionLink(
                e,
                "buttonCEG",
                "/chauffe-eau/chauffe-eau-gaz-chaudieres"
              )
            }
            // onTouchStart={(e) =>
            //   FunctionLink(
            //     e,
            //     "buttonCEG",
            //     "/chauffe-eau/chauffe-eau-gaz-chaudieres"
            //   )
            // }
          />
        </section>
        <section>
          <BlockFicheInstallation
            backgroundColor="#eaeaea"
            picture={PompeChaleur}
            alt="Pompe à chaleur sol, grande et longue, moderne noir et blanche, avec son groupe système dans un garage vaste et bien rangé"
            title="POMPES À CHALEUR"
            idButton="buttonPompesChaleur"
            text={[
              "Vous avez entendu parler de cette nouvelle technologie des ",
              <strong key="1" style={{ fontWeight: "100" }}>
                pompes à chaleur
              </strong>,
              " ? Vous comprenez tout l'intérêt de confort et économique de posséder cette installation révolutionnaire ?",
              <br key="2" />,
              <br key="3" />,
              <strong key="4" style={{ fontWeight: "100" }}>
                Pompes à chaleur air-eau
              </strong>,
              " ou ",
              <strong key="5" style={{ fontWeight: "100" }}>
                pompes à chaleur air-air
              </strong>,
              ", Nos experts ",
              <strong key="6" style={{ fontWeight: "100" }}>
                chauffagistes à Marseille
              </strong>,
              " interviennent dans toute la ville de ",
              <strong key="7" style={{ fontWeight: "100" }}>
                Marseille
              </strong>,
              " mais aussi ses environs (",
              <strong key="8" style={{ fontWeight: "100" }}>
                Allauch
              </strong>,
              ", ",
              <strong key="9" style={{ fontWeight: "100" }}>
                {" "}
                Plan-de-Cuques
              </strong>,
              ", ",
              <strong key="10" style={{ fontWeight: "100" }}>
                {" "}
                La Bouilladisse
              </strong>,
              ", ",
              <strong key="11" style={{ fontWeight: "100" }}>
                {" "}
                Cuges-les-Pins
              </strong>,
              ", ",
              <strong key="12" style={{ fontWeight: "100" }}>
                {" "}
                Peynier
              </strong>,
              ", ",
              <strong key="13" style={{ fontWeight: "100" }}>
                {" "}
                Aubagne
              </strong>,
              ", ",
              <strong key="14" style={{ fontWeight: "100" }}>
                {" "}
                Aix-en-Provence
              </strong>,
              ", ",
              <strong key="15" style={{ fontWeight: "100" }}>
                {" "}
                Marignane
              </strong>,
              ", ",
              <strong key="16" style={{ fontWeight: "100" }}>
                {" "}
                Cassis
              </strong>,
              ", ",
              <strong key="17" style={{ fontWeight: "100" }}>
                {" "}
                La Ciotat
              </strong>,
              ") pour appliquer un travail de qualité et garanti, ce à un coût intervention plombier attractif, honnête et responsable.",
            ]}
            onClick={(e) =>
              FunctionLink(
                e,
                "buttonPompesChaleur",
                "/chauffe-eau/pompes-a-chaleur-air-air"
              )
            }
            // onTouchStart={(e) =>
            //   FunctionLink(
            //     e,
            //     "buttonPompesChaleur",
            //     "/chauffe-eau/pompes-a-chaleur-air-air"
            //   )
            // }
          />
        </section>
        <section>
          <BlockFicheInstallation
            backgroundColor="#eee4e1"
            picture={Climatisation}
            alt="Climatisation murale blanche avec son groupe système"
            title="CLIMATISATIONS"
            idButton="buttonClimatisation"
            text={[
              "Assez de suffoquer les étés ? Ou besoin d'une solution qui vous soit utile par toute saison grâce à son procédé de réversibilité ? Sinon que votre ",
              <strong key="1" style={{ fontWeight: "100" }}>
                climatisation
              </strong>,
              "se fait vieille et fatigue ?",
              <br key="2" />,
              <br key="3" />,
              "Nos experts ",
              <strong key="4" style={{ fontWeight: "100" }}>
                climatiseurs à Marseille
              </strong>,
              " interviennent dans toute la ville de ",
              <strong key="5" style={{ fontWeight: "100" }}>
                Marseille
              </strong>,
              " mais aussi ses environs (",
              <strong key="6" style={{ fontWeight: "100" }}>
                Allauch
              </strong>,
              ", ",
              <strong key="7" style={{ fontWeight: "100" }}>
                {" "}
                Plan-de-Cuques
              </strong>,
              ", ",
              <strong key="8" style={{ fontWeight: "100" }}>
                {" "}
                La Bouilladisse
              </strong>,
              ", ",
              <strong key="9" style={{ fontWeight: "100" }}>
                {" "}
                Cuges-les-Pins
              </strong>,
              ", ",
              <strong key="10" style={{ fontWeight: "100" }}>
                {" "}
                Peynier
              </strong>,
              ", ",
              <strong key="11" style={{ fontWeight: "100" }}>
                {" "}
                Aubagne
              </strong>,
              ", ",
              <strong key="12" style={{ fontWeight: "100" }}>
                {" "}
                Aix-en-Provence
              </strong>,
              ", ",
              <strong key="13" style={{ fontWeight: "100" }}>
                {" "}
                Marignane
              </strong>,
              ", ",
              <strong key="14" style={{ fontWeight: "100" }}>
                {" "}
                Cassis
              </strong>,
              ", ",
              <strong key="15" style={{ fontWeight: "100" }}>
                {" "}
                La Ciotat
              </strong>,
              ") pour appliquer un travail de qualité et garanti, ce à un coût intervention plombier attractif, honnête et responsable.",
            ]}
            onClick={(e) =>
              FunctionLink(e, "buttonClimatisation", "/climatisation")
            }
            // onTouchStart={(e) =>
            //   FunctionLink(e, "buttonClimatisation", "/climatisation")
            // }
          />
        </section>
      </ContenairMain>
      <Footer marginTop="140px" backgroundColor="#667788" />
    </>
  );
}
