import React, { useContext } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { PageTransitionContext } from "../contexts/PageTransition";
import { devices } from "../style/Size";

const BlockPage = styled.div`
  width: 100%;
  height: 100vh;
`;

const Contenair = styled.div`
  width: 300px;
  height: 360px;
  position: relative;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);

  @media ${devices.mobileL} {
    width: 100%;
  }
`;

const P1 = styled.p`
  font-size: 68px;
  margin: 0;
  padding: 0;
  font-family: "Russo One", sans-serif;
  color: rgba(102, 119, 136, 0.94);

  @media ${devices.tablet} {
    font-size: 48px;
  }
  @media ${devices.mobileL} {
    font-size: 32px;
  }
`;

const P2 = styled.p`
  font-size: 148px;
  margin: 0;
  padding: 0;
  margin-top: -42px;
  font-family: "Russo One", sans-serif;
  color: rgba(102, 119, 136, 0.94);

  @media ${devices.tablet} {
    font-size: 100px;
    margin-top: -32px;
  }
  @media ${devices.mobileL} {
    font-size: 66px;
    margin-top: -18px;
  }
`;

const P3 = styled.p`
  font-size: 32px;
  margin: 0;
  padding: 0;
  font-family: "Russo One", sans-serif;
  color: rgba(102, 119, 136, 0.94);

  @media ${devices.tablet} {
    font-size: 22px;
  }
  @media ${devices.mobileL} {
    font-size: 15px;
  }
`;

const P4 = styled.p`
  width: 100%;
  font-size: 20px;
  font-weight: 100;
  font-family: "Russo One", sans-serif;
  position: absolute;
  top: 300px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.5;

  @media ${devices.tablet} {
    font-size: 18px;
    top: 200px;
  }
  @media ${devices.mobileL} {
    font-size: 16px;
    top: 180px;
  }
`;

export default function NotFoundPage() {
  const { displayPageTransition } = useContext(PageTransitionContext);
  const navigate = useNavigate();
  const link = document.querySelector("#link");
  if (link) {
    link.style.opacity = "1";
  }

  const navigateLink = (route) => {
    if (window.innerWidth > 991) {
      setTimeout(() => {
        displayPageTransition("pageTransitionEffect");
      }, 500);
      setTimeout(() => {
        displayPageTransition("pageTransitionNotEffect");
        window.scrollTo(0, 0);
        navigate(`${route}`);
      }, 1300);
    }
    if (window.innerWidth > 480 && window.innerWidth <= 991) {
      setTimeout(() => {
        displayPageTransition("pageTransitionEffect");
      }, 500);
      setTimeout(() => {
        displayPageTransition("pageTransitionNotEffect");
        window.scrollTo(0, 0);
        navigate(`${route}`);
      }, 1100);
    }
    if (window.innerWidth < 480) {
      setTimeout(() => {
        displayPageTransition("pageTransitionEffect");
      }, 500);
      setTimeout(() => {
        displayPageTransition("pageTransitionNotEffect");
        window.scrollTo(0, 0);
        navigate(`${route}`);
      }, 1000);
    }
  };

  const effectHover = () => {
    const link = document.querySelector("#link");

    if (link) {
      link.style.opacity = "1";
      link.style.transition = "300ms";
    }
  };

  const notEffectHover = () => {
    const link = document.querySelector("#link");

    if (link) {
      link.style.opacity = "0.5";
      link.style.transition = "300ms";
    }
  };

  return (
    <BlockPage>
      <Contenair>
        <P1>ERREUR</P1>
        <P2>404</P2>
        <P3>Page Non Trouvée</P3>
        <P4
          id="link"
          onClick={() => navigateLink("/")}
          // onTouchStart={() => navigateLink("/")}
          onMouseEnter={() => effectHover()}
          onMouseLeave={() => notEffectHover()}
        >
          <Link style={{ color: "#cf3131" }}>Revenir à la page d'accueil</Link>
        </P4>
      </Contenair>
    </BlockPage>
  );
}
