import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import HeaderSubSection from "../components/Header/HeaderSubSection";
import ButtonQuote from "../components/Buttons/ButtonQuote";
import Footer from "../components/Footer";
import Bathroom1 from "../assets/Bathroom/salleDeBainHeader.jpg";
import BlockBathroomPicture1 from "../assets/Bathroom/salle-de-bain-1.webp";
import BlockBathroomPicture2 from "../assets/Bathroom/salle-de-bain-2.webp";
import BlockBathroomPicture4 from "../assets/Bathroom/salle-de-bain-4.webp";
import BlockBathroomPicture5 from "../assets/Bathroom/salle-de-bain-5.webp";
import BlockBathroomPicture6 from "../assets/Bathroom/salle-de-bain-6.webp";
import BlockBathroomPicture7 from "../assets/Bathroom/salle-de-bain-7.webp";
import BlockBathroomPicture8 from "../assets/Bathroom/salle-de-bain-8.webp";
import BlockBathroomPicture9 from "../assets/Bathroom/salle-de-bain-9.webp";
import BlockBathroomPicture11 from "../assets/Bathroom/salle-de-bain-10.webp";
import BlockBathroomPicture12 from "../assets/Bathroom/salle-de-bain-11.webp";
import { NavBarDisplayFunction } from "../functions/NavBarDisplayScrollFunction";
import { EffectTitlePageFunction } from "../functions/EffectTitlePageFunction";
import "../style/Animation/KeyFrames/BathroomTextBlockElement.css";
import { devices } from "../style/Size";

const effectHoverIcons = keyframes`
  0% {
    opacity: 1;
    transform: scaleX(0) scaleY(0);
  }
  4% {
    opacity: 1;
  }
  10% {
    opacity: 0;
    transform: scaleX(1) scaleY(1);
  }
  10.5% {
    opacity: 0;
    transform: scaleX(0) scaleY(0);
  }
  11% {
    opacity: 1;
    transform: scaleX(0) scaleY(0);
  }
  15% {
    opacity: 1;
  }
  21% {
    opacity: 0;
    transform: scaleX(1) scaleY(1);
  }
  21.5% {
    opacity: 0;
    transform: scaleX(0) scaleY(0);
  }
  51% {
    opacity: 1;
    transform: scaleX(0) scaleY(0);
  }
  55% {
    opacity: 1;
  }
  61% {
    opacity: 0;
    transform: scaleX(1) scaleY(1);    
  }
  61.5% {
    opacity: 0;
    transform: scaleX(0) scaleY(0);
  }
  62% {
    opacity: 1;
    transform: scaleX(0) scaleY(0);
  }
  66% {
    opacity: 1;
  }
  72% {
    opacity: 0;
    transform: scaleX(1) scaleY(1);
  }
  100% {
    opacity: 0;
    transform: scaleX(0) scaleY(0);
  }
`;

const effectLogoHover = keyframes`
  0%{
    transform: rotate(0) scale(1.2);
  } 
  95% {
    transform: rotate(0deg) scale(1.2);
  }
  96.25%{
    transform: rotate(4deg) scale(1.2);
  } 
  97.5%{
    transform: rotate(-4deg) scale(1.2);
  } 
  98.75%{
    transform: rotate(4deg) scale(1.2);
  } 
  100%{
    transform: rotate(0) scale(1.2);
  }
`;

const ContenairBlockElementBathroom = styled.main`
  width: 100%;
  background-color: #f4eff0;

  @media (max-width: 654px) {
    padding-top: 30px;
  }
  @media (max-height: 480px) {
    padding-top: 40px;
  }
`;

const BlockTitleSectionTop = styled.div`
  width: 90%;
  margin-left: 5%;
  margin-bottom: 30px;
  padding-top: 40px;

  @media ${devices.mobileL} {
    padding-top: 20px;
  }
  @media (max-height: 480px) {
    padding-top: 20px;
  }
`;

const BlockTitleSectionOthers = styled.div`
  width: 90%;
  margin-left: 5%;
  margin-bottom: 30px;
  margin-top: 130px;

  @media ${devices.laptopXXS} {
    margin-top: 100px;
  }
  @media ${devices.tablet} {
    margin-top: 80px;
  }
  @media ${devices.mobileL} {
    margin-top: 60px;
  }
`;

const TitleSection = styled.h2`
  font-family: "Russo One", serif;
  font-size: 30px;
  font-weight: 500;
  color: #667788;
  margin: 0;
  padding: 0;
  text-align: center;
  letter-spacing: 2px;
  opacity: 0.5;

  @media ${devices.tablet} {
    font-size: 24px;
  }
  @media ${devices.mobileL} {
    font-size: 22px;
  }
`;

const ContenairPictureAndTextImpair = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 654px) {
    flex-direction: column;
  }
`;

const ContenairPictureAndTextPair = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 654px) {
    flex-direction: column-reverse;
  }
`;

const ContenairPicture = styled.div`
  width: 50%;
  height: 540px;

  @media (max-width: 654px) {
    width: 100%;
    height: 400px;
  }
  @media (max-height: 480px) {
    height: 100vh;
    min-height: 375px;
  }
`;

const Picture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Text = styled.div`
  width: 50%;
  height: 540px;
  position: relative;

  @media (max-width: 654px) {
    width: 100%;
    height: 0%;
  }
  @media (max-height: 480px) {
    height: 100vh;
    min-height: 375px;
  }
`;

const PLeft = styled.div`
  width: 80%;
  font-size: 18px;
  color: #667788;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  text-align: justify;
  padding: 0;
  margin: 0;
  padding-left: 4px;
  padding-right: 4px;
  position: absolute;
  bottom: 0;

  @media (max-width: 1014px) {
    width: 94%;
  }
  @media (max-width: 780px) {
    width: 98%;
    font-size: 16px;
  }
  @media (max-width: 654px) {
    position: static;
    width: 94%;
    margin-bottom: 30px;
  }
  @media (max-height: 480px) {
    font-size: 15px;
    width: 96%;
  }
`;

const PRight = styled.div`
  width: 80%;
  font-size: 18px;
  color: #667788;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  text-align: justify;
  padding: 0;
  margin: 0;
  padding-left: 4px;
  padding-right: 4px;
  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: 1014px) {
    width: 94%;
  }
  @media (max-width: 780px) {
    width: 98%;
    font-size: 16px;
  }
  @media (max-width: 654px) {
    position: static;
    width: 94%;
    margin-bottom: 30px;
  }
  @media (max-height: 480px) {
    font-size: 15px;
    width: 96%;
  }
`;

const TitleBlockIcons = styled.h3`
  width: 90%;
  font-family: "Playfair", serif;
  font-size: 36px;
  font-weight: bold;
  color: #667788;
  margin: 0;
  padding: 0;
  margin-bottom: 40px;
  margin-left: 5%;
  text-align: center;
  letter-spacing: 2px;
  @media ${devices.laptopS} {
    font-size: 30px;
  }
  @media ${devices.laptopXXS} {
    font-size: 28px;
  }
  @media (max-width: 814px) {
    font-size: 26px;
  }
  @media ${devices.tablet} {
    font-size: 24px;
  }
  @media ${devices.mobileL} {
    font-size: 20px;
  }
  @media ${devices.mobileM} {
    font-size: 18px;
  }
  @media ${devices.mobileS} {
    font-size: 16px;
  }
`;

const BlockIcons = styled.nav`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const P1 = styled.div.attrs({
  className: "paragraphIcons",
})`
  margin: 0;
  margin-left: 30px;
  margin-right: 30px;
  color: #667788;
  font-size: 58px;
  cursor: pointer;
  &::after {
    display: block;
    content: "";
    width: 110%;
    height: 56px;
    background: radial-gradient(circle at 90% 10%, #dadce4, transparent);
    border-radius: 4px;
    position: absolute;
    top: 1px;
    left: 0;
    transform: scaleX(0) scaleY(0);
    transform-origin: 0% 100%;
  }
  @media (min-width: 991px) {
    &:hover::after {
      animation-name: ${effectHoverIcons};
      animation-duration: 8s;
      animation-iteration-count: infinite;
    }
    &:hover {
      animation-name: ${effectLogoHover};
      animation-duration: 8s;
      animation-iteration-count: infinite;
    }
  }
  @media ${devices.laptopS} {
    font-size: 50px;
    &::after {
      height: 48px;
    }
  }
  @media ${devices.laptopXXS} {
    font-size: 48px;
    &::after {
      height: 46px;
    }
  }
  @media (max-width: 814px) {
    font-size: 46px;
    &::after {
      height: 44px;
    }
  }
  @media ${devices.tablet} {
    font-size: 44px;
    &::after {
      height: 42px;
    }
  }
  @media ${devices.mobileL} {
    font-size: 40px;
    &::after {
      height: 38px;
    }
  }
  @media ${devices.mobileM} {
    font-size: 36px;
    margin-left: 20px;
    margin-right: 20px;
    &::after {
      height: 34px;
    }
  }
  @media ${devices.mobileS} {
    font-size: 32px;
    &::after {
      height: 30px;
    }
  }
`;

const P2 = styled.div.attrs({
  className: "paragraphIcons",
})`
  margin: 0;
  margin-left: 30px;
  margin-right: 30px;
  color: #667788;
  font-size: 58px;
  cursor: pointer;
  &::after {
    display: block;
    content: "";
    width: 100%;
    height: 50px;
    background: radial-gradient(circle at 90% 10%, #717686, transparent);
    border-radius: 4px;
    position: absolute;
    top: 1px;
    left: 0;
    transform: scaleX(0) scaleY(0);
    transform-origin: 0% 100%;
  }
  @media (min-width: 991px) {
    &:hover::after {
      animation-name: ${effectHoverIcons};
      animation-duration: 8s;
      animation-iteration-count: infinite;
    }
    &:hover {
      animation-name: ${effectLogoHover};
      animation-duration: 8s;
      animation-iteration-count: infinite;
    }
  }
  @media ${devices.laptopS} {
    font-size: 50px;
    &::after {
      height: 42px;
    }
  }
  @media ${devices.laptopXXS} {
    font-size: 48px;
    &::after {
      height: 40px;
    }
  }
  @media (max-width: 814px) {
    font-size: 46px;
    &::after {
      height: 38px;
    }
  }
  @media ${devices.tablet} {
    font-size: 44px;
    &::after {
      height: 36px;
    }
  }
  @media ${devices.mobileL} {
    font-size: 40px;
    &::after {
      height: 32px;
    }
  }
  @media ${devices.mobileM} {
    font-size: 36px;
    margin-left: 20px;
    margin-right: 20px;
    &::after {
      height: 30px;
    }
  }
  @media ${devices.mobileS} {
    font-size: 32px;
    &::after {
      height: 26px;
    }
  }
`;

const P3 = styled.div.attrs({
  className: "paragraphIcons",
})`
  margin: 0;
  margin-left: 30px;
  margin-right: 30px;
  color: #667788;
  font-size: 58px;
  cursor: pointer;
  &::after {
    display: block;
    content: "";
    width: 110%;
    height: 56px;
    background: radial-gradient(circle at 90% 10%, #dadce4, transparent);
    border-radius: 4px;
    position: absolute;
    top: 1px;
    left: 0;
    transform: scaleX(0) scaleY(0);
    transform-origin: 0% 100%;
  }
  @media (min-width: 991px) {
    &:hover::after {
      animation-name: ${effectHoverIcons};
      animation-duration: 8s;
      animation-iteration-count: infinite;
    }
    &:hover {
      animation-name: ${effectLogoHover};
      animation-duration: 8s;
      animation-iteration-count: infinite;
    }
  }
  @media ${devices.laptopS} {
    font-size: 50px;
    &::after {
      height: 48px;
    }
  }
  @media ${devices.laptopXXS} {
    font-size: 48px;
    &::after {
      height: 46px;
    }
  }
  @media (max-width: 814px) {
    font-size: 46px;
    &::after {
      height: 44px;
    }
  }
  @media ${devices.tablet} {
    font-size: 44px;
    &::after {
      height: 42px;
    }
  }
  @media ${devices.mobileL} {
    font-size: 40px;
    &::after {
      height: 38px;
    }
  }
  @media ${devices.mobileM} {
    font-size: 36px;
    margin-left: 20px;
    margin-right: 20px;
    &::after {
      height: 34px;
    }
  }
  @media ${devices.mobileS} {
    font-size: 32px;
    &::after {
      height: 30px;
    }
  }
`;

const Ul = styled.ul`
  @media ${devices.mobileL} {
    padding-left: 30px;
  }
  @media ${devices.mobileM} {
    padding-left: 20px;
  }
`;

const SeparatorOne = styled.div`
  width: 100%;
  height: 160px;

  @media ${devices.tablet} {
    height: 140px;
  }
  @media ${devices.mobileL} {
    height: 60px;
  }
  @media (max-height: 480px) {
    height: 0px;
  }
`;

const ContenairSN = styled.div`
  width: 100%;
  height: 260px;

  @media ${devices.mobileL} {
    height: 200px;
  }
  @media (max-height: 480px) {
    height: 240px;
  }
`;

export default function Bathroom() {
  useEffect(() => {
    EffectTitlePageFunction("titleBathroomContenair");
    setTimeout(() => {
      EffectTitlePageFunction("blockTitleBathroomOne");
      EffectTitlePageFunction("blockTitleBathroomTwo");
      EffectTitlePageFunction("blockTitleBathroomThree");
    }, 600);
  }, []);

  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction("headerBathroomContenair");
  });

  return (
    <>
      <HeaderSubSection
        id="headerBathroomContenair"
        idTitle="titleBathroomContenair"
        src={Bathroom1}
        alt="Salle de bain effet pierre mas meuble en bois double lavabos carré blanc surélevés, grand miroir recatangulaire, petit miroir rond movible, robinet mitigeur, bac à douche blanc, colonne de douche"
        titlePage="Salles de Bain"
        backgroundcolor="#f4eff0"
        backgroundcolorContenairTitlePage="#f4eff0"
      />
      <ContenairBlockElementBathroom>
        <section>
          <BlockTitleSectionTop>
            <TitleSection id="blockTitleBathroomOne">
              Quelques simples...
            </TitleSection>
          </BlockTitleSectionTop>
          <ContenairPictureAndTextImpair>
            <ContenairPicture>
              <Picture
                src={BlockBathroomPicture8}
                alt="Salle de bain complète blanche et bleue avec bac à douche d'angle céramique blanc, cabine de douche quart de rond, colonne de douche, long meuble de salle de bain bois, lavabo rectangulaire blanc, robinet mitigeur, miroir led"
              />
            </ContenairPicture>
            <Text>
              <PLeft>
                <p style={{ padding: "0", paddingLeft: "6px" }}>
                  Salle de bain créee en septembre 2020 à La Ciotat
                </p>
                <Ul>
                  <li>Dalles sol PVC anti-dérapant</li>
                  <li>
                    Bac à douche d'angle en céramique blanc anti-dérapant Idéal
                    Standard
                  </li>
                  <li>
                    Cabine de douche d'angle avec porte coulissante Idéal
                    Standard
                  </li>
                  <li>
                    Mitigeur thermostatique avec pommeau de douche et colonne de
                    douche Jacob Delafon
                  </li>
                  <li>Meuble de salle de bain Decotec</li>
                  <li>Lavabo Decotec</li>
                  <li>Robinet mitigeur lavabo Cristina</li>
                </Ul>
              </PLeft>
            </Text>
          </ContenairPictureAndTextImpair>
          <ContenairPictureAndTextPair>
            <Text>
              <PRight>
                <p style={{ padding: "0", paddingLeft: "6px" }}>
                  Salle de bain rénovée en Décembre 2021 à Plan-de-Cuques
                </p>
                <Ul>
                  <li>
                    Bac à douche en céramique blanc anti-dérapant Jacob Delafon
                  </li>
                  <li>Paroi de douche fixe Alterna</li>
                </Ul>
              </PRight>
            </Text>
            <ContenairPicture>
              <Picture
                src={BlockBathroomPicture4}
                alt="Salle de bain complète blanche et bleue avec bac à douche céramique blanc, paroi de douche, colonne de douche, petit meuble de salle de bain blanc, lavabo blanc, robinet mitigeur, miroir led, sèche-serviette blanc"
              />
            </ContenairPicture>
          </ContenairPictureAndTextPair>
        </section>
        <section>
          <BlockTitleSectionOthers>
            <TitleSection id="blockTitleBathroomTwo">
              Quelques élaborées...
            </TitleSection>
          </BlockTitleSectionOthers>
          <ContenairPictureAndTextImpair>
            <ContenairPicture>
              <Picture
                src={BlockBathroomPicture6}
                alt="Salle de bain complète carreaux muraux marbrés sol dalle effet bois, avec bac à douche céramique blanc, paroi de douche, colonne de douche, siège de douche rabattable, meuble de salle de bain blanc, lavabo rectangulaire blanc, robinet mitigeur, miroir rond, sèche-serviette blanc"
              />
            </ContenairPicture>
            <Text>
              <PLeft>
                <p style={{ padding: "0", paddingLeft: "6px" }}>
                  Salle de bain créee en janvier 2023 à Marseille 13012
                </p>
                <Ul>
                  <li>Carreaux muraux blancs marbrés</li>
                  <li>Bac à douche en céramique blanc anti-dérapant AKW</li>
                  <li>Paroi de douche fixe avec porte coulissante AKW</li>
                  <li>Siège de douche rabattable AKW</li>
                  <li>
                    Mitigeur thermostatique avec pommeau de douche et colonne de
                    douche Grohe
                  </li>
                  <li>Meuble de salle de bain Domao</li>
                  <li>Lavabo Domao</li>
                  <li>Robinet mitigeur lavabo Domao</li>
                  <li>Miroir à lumineux LED anti-buée</li>
                  <li>Sèche-serviette Domao</li>
                </Ul>
              </PLeft>
            </Text>
          </ContenairPictureAndTextImpair>
          <ContenairPictureAndTextPair>
            <Text>
              <PRight>
                <p style={{ padding: "0", paddingLeft: "6px" }}>
                  Salle de bain créee en mars 2023 à Marseille 13011
                </p>
                <Ul>
                  <li>Dalles PVC anti-dérapant au sol</li>
                  <li>Carreaux muraux blans marbrés</li>
                  <li>
                    Bac à douche en céramique blanc anti-dérapant Jacob Delafon
                  </li>
                  <li>Paroi de douche fixe avec porte coulissante AKW</li>
                  <li>Siège de douche rabattable AKW</li>
                  <li>
                    Mitigeur thermostatique avec pommeau de douche et colonne de
                    douche Jacob Delafon
                  </li>
                  <li>Meuble de salle de bain Alterna</li>
                  <li>Lavabo Alterna</li>
                  <li>Robinet mitigeur lavabo Alterna</li>
                  <li>Miroir lumineux à LED</li>
                  <li>Sèche-serviette électrique Acova</li>
                </Ul>
              </PRight>
            </Text>
            <ContenairPicture>
              <Picture
                src={BlockBathroomPicture1}
                alt="Salle de bain complète blanche marbrée avec bac à douche céramique blanc, paroi de douche, colonne de douche, long meuble de salle de bain blanc, lavabo rectangulaire blanc, robinet mitigeur, miroir led, sèche-serviette blanc"
              />
            </ContenairPicture>
          </ContenairPictureAndTextPair>
          <ContenairPictureAndTextImpair>
            <ContenairPicture>
              <Picture
                src={BlockBathroomPicture5}
                alt="Salle de bain PMR complète couleur crème avec bac à douche céramique blanc, paroi de douche, colonne de douche, barre de maintien, lave-main blanc, robinet mitigeur, miroir, WC suspendu blanc"
              />
            </ContenairPicture>
            <Text>
              <PLeft>
                <p style={{ padding: "0", paddingLeft: "6px" }}>
                  Salle de bain PMR créee en mars 2023 à Allauch
                </p>
                <Ul>
                  <li>Carreaux muraux effet pierre</li>
                  <li>
                    Bac à douche en céramique blanc anti-dérapant Jacob Delafon
                  </li>
                  <li>
                    Paroi de douche fixe en verre sécurite 8mm Jacob Delafon
                  </li>
                  <li>Siège de douche rabattable AKW</li>
                  <li>
                    Mitigeur thermostatique avec pommeau de douche et colonne de
                    douche avec barre de maintien intégrée AKW
                  </li>
                  <li>Lavabo avec meuble lave-main Jacob Delafon</li>
                  <li>WC suspendu Geberit</li>
                  <li>Bâti support Geberit</li>
                </Ul>
              </PLeft>
            </Text>
          </ContenairPictureAndTextImpair>
          <ContenairPictureAndTextPair>
            <Text>
              <PRight>
                <p style={{ padding: "0", paddingLeft: "6px" }}>
                  Salle de bain créee en avril 2023 à Marseille 13013
                </p>
                <Ul>
                  <li>Panneaux muraux décoratifs spécial salle de bain</li>
                  <li>
                    Bac à douche en céramique blanc anti-dérapant Jacob Delafon
                  </li>
                  <li>Paroi de douche fixe avec porte coulissante Alterna</li>
                  <li>Siège de douche rabattable AKW</li>
                  <li>
                    Mitigeur thermostatique avec pommeau de douche et colonne de
                    douche Jacob Delafon
                  </li>
                  <li>Sèche-serviette à eau Acova</li>
                  <li>Meuble de salle de bain Alterna</li>
                  <li>Lavabo Alterna</li>
                  <li>Robinet mitigeur lavabo Alterna</li>
                  <li>Miroir lumineux à LED</li>
                </Ul>
              </PRight>
            </Text>
            <ContenairPicture>
              <Picture
                src={BlockBathroomPicture2}
                alt="Salle de bain complète mur effet béton avec bac à douche céramique blanc, paroi de douche, colonne de douche, meuble de salle de bain blanc, lavabo rectangulaire blanc, robinet mitigeur, miroir led, sèche-serviette blanc"
              />
            </ContenairPicture>
          </ContenairPictureAndTextPair>
        </section>
        <section>
          <BlockTitleSectionOthers>
            <TitleSection id="blockTitleBathroomThree">
              Quelques prestigieuses...
            </TitleSection>
          </BlockTitleSectionOthers>
          <ContenairPictureAndTextImpair>
            <ContenairPicture>
              <Picture
                src={BlockBathroomPicture12}
                alt="Salle de bain complète blanche très grands carreaux marbrées avec bac à douche céramique blanc, paroi de douche, colonne de douche dorée, WC suspendu, long meuble de salle de bain noir, grand lavabo bol ovale blanc, robinet mitigeur, miroir led"
              />
            </ContenairPicture>
            <Text>
              <PLeft>
                <p style={{ padding: "0", paddingLeft: "6px" }}>
                  Salle de bain créee en mai 2023 à Allauch
                </p>
                <Ul>
                  <li>Grands carreaux blancs marbrés</li>
                  <li>Bac à douche en céramique blanc anti-dérapant Kinedo</li>
                  <li>Paroi de douche fixe Samo</li>
                  <li>
                    Mitigeur thermostatique avec pommeau de douche et colonne de
                    douche Cristina
                  </li>
                  <li>Meuble de salle de bain Decotec</li>
                  <li>Lavabo bol oval Decotec</li>
                  <li>Robinet mitigeur lavabo Cristian</li>
                  <li>Sèche-serviette électrique Atlantic</li>
                  <li>Miroir à LED anti-buée Decotec</li>
                  <li>WC suspendu Geberit</li>
                  <li>Bâti support Geberit</li>
                </Ul>
              </PLeft>
            </Text>
          </ContenairPictureAndTextImpair>
          <ContenairPictureAndTextPair>
            <Text>
              <PRight>
                <p style={{ padding: "0", paddingLeft: "6px" }}>
                  Salle de bain créee en mai 2023 à Allauch
                </p>
                <Ul>
                  <li>Carreaux muraux noirs marbrés</li>
                  <li>Receveur de douche en marbre anti-dérapant</li>
                  <li>Paroi de douche fixe Cristina</li>
                  <li>
                    Mitigeur thermostatique avec pommeau de douche et colonne de
                    douche Cristina
                  </li>
                  <li>Meuble de salle de bain Decotec</li>
                  <li>Lavabo Decotec</li>
                  <li>Robinet mitigeur lavabo Cristian</li>
                  <li>Miroir rond à LED anti-buée Decotec</li>
                  <li>Sèche-serviette électrique Atlantic</li>
                  <li>WC suspendu Geberit</li>
                  <li>Bâti support Geberit</li>
                </Ul>
              </PRight>
            </Text>
            <ContenairPicture>
              <Picture
                src={BlockBathroomPicture11}
                alt="Salle de bain d'appoint complète carreaux noirs marbrés avec bac à douche marbre, paroi de douche, colonne de douche, meuble de salle de bain bois clair, lavabo blanc, robinet mitigeur, miroir rond à LED anti-buée, sèche-serviette noir, WC suspendu"
              />
            </ContenairPicture>
          </ContenairPictureAndTextPair>
          <ContenairPictureAndTextImpair>
            <ContenairPicture>
              <Picture
                src={BlockBathroomPicture7}
                alt="Salle de bain complète carreaux effet pierre romaine avec bac à douche céramique blanc, paroi de douche, colonne de douche, meuble de salle de bain, lavabo bol blanc, robinet mitigeur, miroir rond, sèche-serviette blanc, baignoire d'angle blanche balnéo"
              />
            </ContenairPicture>
            <Text>
              <PLeft>
                <p style={{ padding: "0", paddingLeft: "6px" }}>
                  Salle de bain créee en juin 2023 à Cassis
                </p>
                <Ul>
                  <li>Carreaux travertin effet pierre</li>
                  <li>Baignoire d'angle balnéo / hydo-massant Kinedo</li>
                  <li>Bac à douche en céramique blanc anti-dérapant Kinedo</li>
                  <li>Paroi de douche fixe Kinedo</li>
                  <li>Siège de douche rabattable</li>
                  <li>
                    Mitigeur thermostatique avec pommeau de douche et colonne de
                    douche Jacob Delafon
                  </li>
                  <li>Meuble de salle de bain Jacob Delafon</li>
                  <li>Lavabo bol Jacob Delafon</li>
                  <li>Robinet mitigeur lavabo Jacob Delafon</li>
                </Ul>
              </PLeft>
            </Text>
          </ContenairPictureAndTextImpair>
          <ContenairPictureAndTextPair>
            <Text>
              <PRight>
                <p style={{ padding: "0", paddingLeft: "6px" }}>
                  Salle de bain créee en août 2023 à Plan-de-Cuques
                </p>
                <Ul>
                  <li>Carreaux muraux effet pierre</li>
                  <li>Création d'une niche</li>
                  <li>Bac à douche en céramique blanc anti-dérapant AKW</li>
                  <li>
                    Paroi de douche fixe avec volet rabattable Jacob Delafon
                  </li>
                  <li>
                    Mitigeur thermostatique avec pommeau de douche et colonne de
                    douche AKW
                  </li>
                  <li>Meuble de salle de bain Decotec</li>
                  <li>Lavabo Decotec</li>
                  <li>Robinet mitigeur lavabo Cristina</li>
                </Ul>
              </PRight>
            </Text>
            <ContenairPicture>
              <Picture
                src={BlockBathroomPicture9}
                alt="Salle de bain complète carreaux effet pierre romaine avec bac à douche céramique blanc, paroi de douche, colonne de douche, meuble de salle de bain bois clair, lavabo bol blanc, robinet mitigeur, miroir rond, sèche-serviette blanc"
              />
            </ContenairPicture>
          </ContenairPictureAndTextPair>
        </section>
        <SeparatorOne />
        <ButtonQuote />
        <section>
          <ContenairSN>
            <TitleBlockIcons>
              Suivez-nous sur les réseaux pour découvrir plus de
              <span style={{ whiteSpace: "nowrap" }}> contenus !</span>
            </TitleBlockIcons>
            <BlockIcons>
              <a
                aria-label="Profil Facebook"
                style={{ textDecoration: "none", color: "#667788" }}
                href="https://www.facebook.com/profile.php?id=61553054431024"
                target="blanck"
              >
                <P1>
                  <i className="fa-brands fa-square-facebook"></i>
                </P1>
              </a>
              <a
                aria-label="Profil Instagram"
                style={{ textDecoration: "none", color: "#667788" }}
                href="https://www.instagram.com/entreprisenavarro/"
                target="blanck"
              >
                <P2>
                  <i className="fa-brands fa-instagram"></i>
                </P2>
              </a>
              <a
                aria-label="Profil Linkedin"
                style={{ textDecoration: "none", color: "#667788" }}
                href="https://www.linkedin.com/in/entreprise-navarro-marseille-plomberie-chauffagerie-climatisation"
                target="blanck"
              >
                <P3>
                  <i className="fa-brands fa-linkedin"></i>
                </P3>
              </a>
            </BlockIcons>
          </ContenairSN>
        </section>
      </ContenairBlockElementBathroom>
      <Footer backgroundColor="#667788" />
    </>
  );
}
