import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { ModalFormContext } from "../../contexts/ModalForm";
import "../../style/Animation/KeyFrames/ModalForm.css";
import ButtonCloseModalForm from "../../components/Buttons/ModalForm/ButtonCloseModalForm";
import InputLastName from "../../components/InputsForm/InputLastName";
import InputFirstName from "../../components/InputsForm/InputFirstName";
import InputNumberPhone from "../../components/InputsForm/InputNumberPhone";
import InputMail from "../../components/InputsForm/InputMail";
import InputPostalCode from "../../components/InputsForm/InputPostalCode";
import InputMailContent from "../../components/InputsForm/InputContentMail";
import SubmitFormButton from "../../components/Buttons/ModalForm/SubmitFormButton";
import { devices } from "../../style/Size";

const ContenairModal = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to left,
    rgba(102, 119, 136, 0.8),
    rgba(102, 119, 136, 0.8)
  );
  position: fixed;
  z-index: 700;
  cursor: auto;

  @media (max-width: 250px) {
    height: 100%;
  }
`;

const ModalForm = styled.form.attrs({
  id: "modalForm",
  className: "displayModalForm",
})`
  width: 700px;
  height: 670px;
  background-color: #ffffff;
  border: 2px solid #2f3d4a;
  border-radius: 4px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 700;
  pointer-events: auto;
  transform: scale(0) translate(-50%, -46%);
  transform-origin: top left;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  @media ${devices.laptopXXS} {
    width: 680px;
    height: 580px;
  }

  @media (max-width: 768px) {
    width: 90%;
    height: 82vh;
    padding-bottom: 20px;
    top: 52%;
  }
  @media (max-height: 480px) {
    width: 90%;
    height: 82vh;
    padding-bottom: 20px;
    top: 52%;
  }
`;

const ContenairInput = styled.ul`
  display: flex;
  justify-content: space-around;
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;

  @media ${devices.laptopXXS} {
    margin-top: 34px;
  }
  @media (max-width: 702px) {
    width: 100%;
    flex-direction: column;
  }
`;

const AlertP = styled.p`
  width: 100%;
  height: 28px;
  font-size: 12px;
  font-family: "Russo One", sans-serif;
  color: red;
  letter-spacing: 1px;
  padding: 0;
  margin: 0;
  padding-top: 8px;
`;

const AlertPostalCode = styled.p`
  width: 96%;
  height: 28px;
  font-size: 12px;
  font-family: "Russo One", sans-serif;
  color: red;
  letter-spacing: 1px;
  padding: 0;
  margin: 0;
  padding-top: 8px;

  @media (max-width: 702px) {
    width: 92%;
    margin-left: 4%;
  }
`;

const BlockInputAndAlertInfo = styled.div`
  width: 46%;
  display: flex;
  flex-direction: column;

  @media (max-width: 702px) {
    width: 92%;
    margin-left: 4%;
  }
`;

const ContenairModalConfirmation = styled.form.attrs({
  id: "contenairModalConfirmation",
  className: "displayAndHiddenModalConfirmation",
})`
  width: 480px;
  background-color: #ffffff;
  border: 2px solid #2f3d4a;
  border-radius: 14px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 700;
  pointer-events: auto;
  transform: scale(0) translate(-50%, -46%);
  transform-origin: top left;

  @media (max-width: 702px) {
    width: 78%;
  }
`;

const ContenairModalConfirmationError = styled.form.attrs({
  id: "contenairModalConfirmation",
  className: "displayAndHiddenModalConfirmationError",
})`
  width: 480px;
  background-color: #ffffff;
  border: 2px solid #2f3d4a;
  border-radius: 14px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 700;
  pointer-events: auto;
  transform: scale(0) translate(-50%, -46%);
  transform-origin: top left;

  @media (max-width: 702px) {
    width: 78%;
  }
`;

const TextModalConfirmation = styled.p`
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  color: #2f3d4a;
  letter-spacing: 1px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;

  @media (max-width: 545px) {
    font-size: 16px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  @media (max-width: 375px) {
    font-size: 12px;
  }
`;

export default function ModalFormPage() {
  const {
    modalForm,
    contenairModalForm,
    modalConfirmation,
    modalConfirmationError,
  } = useContext(ModalFormContext);

  const [elementFormOne, setElementFormOne] = useState(false);
  const [elementFormTwo, setElementFormTwo] = useState(false);
  const [elementFormThree, setElementFormThree] = useState(false);
  const [elementFormFour, setElementFormFour] = useState(false);
  const [elementFormFive, setElementFormFive] = useState(false);
  const [elementFormSix, setElementFormSix] = useState(0);

  const [lastNameValue, setLastNameValue] = useState(false);
  const [firstNameValue, setFirstNameValue] = useState(false);
  const [numberPhoneValue, setNumberPhoneValue] = useState(false);
  const [mailValue, setMailValue] = useState(false);
  const [postalCodeValue, setPostalCodeValue] = useState(false);
  const [contentMailValue, setContentMailValue] = useState(false);

  const alertInfo = (stateElement) => {
    if (stateElement) {
      return <AlertP>{stateElement}</AlertP>;
    } else {
      return (
        <AlertP>
          {" "}
          <br />{" "}
        </AlertP>
      );
    }
  };

  const colorButton = () => {
    const buttonSubmit = document.querySelector("#buttonSubmit");
    if (buttonSubmit) {
      if (
        lastNameValue &&
        firstNameValue &&
        numberPhoneValue &&
        mailValue &&
        postalCodeValue &&
        contentMailValue
      ) {
        buttonSubmit.style.backgroundColor = "#667788";
        buttonSubmit.style.color = "#FFFFFF";
      }
      if (
        !lastNameValue ||
        !firstNameValue ||
        !numberPhoneValue ||
        !mailValue ||
        !postalCodeValue ||
        !contentMailValue
      ) {
        buttonSubmit.style.backgroundColor = "#dfe3e7";
        buttonSubmit.style.color = "grey";
      }
    }
  };
  colorButton();

  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    if (blockNavAndMenu) {
      if (contenairModalForm) {
        document.body.style.position = "fixed";
        blockNavAndMenu.style.pointerEvents = "none";
      }
      if (!contenairModalForm) {
        document.body.style.position = "static";
        blockNavAndMenu.style.pointerEvents = "auto";
      }
    }
  }, [contenairModalForm]);

  return (
    <>
      {contenairModalForm && <ContenairModal />}
      {modalForm && (
        <ModalForm>
          <ButtonCloseModalForm
            setElementFormOne={setElementFormOne}
            setElementFormTwo={setElementFormTwo}
            setElementFormThree={setElementFormThree}
            setElementFormFour={setElementFormFour}
            setElementFormFive={setElementFormFive}
            setElementFormSix={setElementFormSix}
            lastNameValue={lastNameValue}
            firstNameValue={firstNameValue}
            numberPhoneValue={numberPhoneValue}
            mailValue={mailValue}
            postalCodeValue={postalCodeValue}
            contentMailValue={contentMailValue}
            setLastNameValue={setLastNameValue}
            setFirstNameValue={setFirstNameValue}
            setNumberPhoneValue={setNumberPhoneValue}
            setMailValue={setMailValue}
            setPostalCodeValue={setPostalCodeValue}
            setContentMailValue={setContentMailValue}
          />
          <ContenairInput id="form">
            <BlockInputAndAlertInfo>
              <InputLastName
                setElementFormOne={setElementFormOne}
                elementFormOne={elementFormOne}
                setLastNameValue={setLastNameValue}
              />
              {alertInfo(elementFormOne)}
            </BlockInputAndAlertInfo>
            <BlockInputAndAlertInfo>
              <InputFirstName
                setElementFormTwo={setElementFormTwo}
                elementFormTwo={elementFormTwo}
                setFirstNameValue={setFirstNameValue}
              />
              {alertInfo(elementFormTwo)}
            </BlockInputAndAlertInfo>
            <BlockInputAndAlertInfo>
              <InputNumberPhone
                setElementFormThree={setElementFormThree}
                setNumberPhoneValue={setNumberPhoneValue}
              />
              {alertInfo(elementFormThree)}
            </BlockInputAndAlertInfo>
            <BlockInputAndAlertInfo>
              <InputMail
                setElementFormFour={setElementFormFour}
                setMailValue={setMailValue}
              />
              {alertInfo(elementFormFour)}
            </BlockInputAndAlertInfo>
            <InputPostalCode
              setElementFormFive={setElementFormFive}
              setPostalCodeValue={setPostalCodeValue}
            />
            <AlertPostalCode>{elementFormFive}</AlertPostalCode>
            <InputMailContent
              setElementFormSix={setElementFormSix}
              elementFormSix={elementFormSix}
              setContentMailValue={setContentMailValue}
            />
          </ContenairInput>
          <SubmitFormButton
            setElementFormOne={setElementFormOne}
            setElementFormTwo={setElementFormTwo}
            setElementFormThree={setElementFormThree}
            setElementFormFour={setElementFormFour}
            setElementFormFive={setElementFormFive}
            setElementFormSix={setElementFormSix}
            setLastNameValue={setLastNameValue}
            setFirstNameValue={setFirstNameValue}
            setNumberPhoneValue={setNumberPhoneValue}
            setMailValue={setMailValue}
            setPostalCodeValue={setPostalCodeValue}
            setContentMailValue={setContentMailValue}
            lastNameValue={lastNameValue}
            firstNameValue={firstNameValue}
            numberPhoneValue={numberPhoneValue}
            mailValue={mailValue}
            postalCodeValue={postalCodeValue}
            contentMailValue={contentMailValue}
          />
        </ModalForm>
      )}
      {modalConfirmation && (
        <ContenairModalConfirmation>
          <TextModalConfirmation>
            Votre message nous a bien été envoyé.
            <br />
            Nous vous répondons au plus{" "}
            <span style={{ whiteSpace: "nowrap" }}>vite !</span>
          </TextModalConfirmation>
        </ContenairModalConfirmation>
      )}
      {modalConfirmationError && (
        <ContenairModalConfirmationError>
          <TextModalConfirmation>
            Oups...
            <br />
            Une erreur inattendue s'est produite.
            <br />
            <br />
            Veuillez réessayer dans quelques instants, ou appelez-nous
            directement.
          </TextModalConfirmation>
        </ContenairModalConfirmationError>
      )}
    </>
  );
}
