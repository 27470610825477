import React, { useEffect } from "react";
import { styled } from "styled-components";
import "../../style/Animation/KeyFrames/EffectScroll.css";
import { OffsetTopFunction } from "../../functions/OffsetTopFunction";
import { devices } from "../../style/Size";

const ContenairAllElements = styled.div.attrs({
  id: "contenairTitleMain",
})`
  width: 100%;
  margin-bottom: 60px;
  overflow: hidden;

  @media ${devices.mobileL} {
    margin-bottom: 0px;
  }
  @media ${devices.mobileM} {
    display: none;
  }
  @media (max-height: 480px) {
    margin-bottom: 50px;
  }
`;

const ContenairTitleMain = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const BlockTitleMain = styled.div`
  width: 830px;
  height: 400px;
  position: relative;
  top: 20px;
  @media ${devices.laptopXXS} {
    width: 700px;
    height: 360px;
  }
  @media ${devices.tablet} {
    width: 560px;
    height: 300px;
  }
  @media ${devices.tabletM} {
    width: 464px;
    height: 280px;
  }
  @media ${devices.mobileL} {
    width: 371px;
  }
`;

const TitleMain = styled.h2.attrs({
  id: "titleMain",
})`
  font-family: "Russo One", "sans serif";
  letter-spacing: 2px;
  font-size: 36px;
  color: #553322;
  margin: 0;
  transform: translateX(-200px);
  opacity: 0;
  position: absolute;
  top: 0;
  @media ${devices.laptopXXS} {
    font-size: 28px;
  }
  @media ${devices.tablet} {
    font-size: 22px;
  }
  @media ${devices.tabletM} {
    font-size: 18px;
  }
  @media ${devices.mobileL} {
    transform: translateX(-30px);
    font-size: 13px;
  }
`;

const Span1 = styled.span.attrs({
  id: "spanTitle",
})`
  display: block;
  width: 200px;
  height: 3px;
  background-color: #553322;
  margin-bottom: 20px;
  position: absolute;
  top: 42px;
  left: 27%;
  transform: scaleX(0);
  transform-origin: left;
  @media ${devices.laptopXXS} {
    width: 140px;
    top: 38px;
    left: 26%;
  }
  @media ${devices.tablet} {
    width: 120px;
    top: 28px;
    left: 29%;
  }
  @media ${devices.tabletM} {
    width: 98px;
    top: 24px;
    left: 26%;
  }
  @media ${devices.mobileL} {
    width: 82px;
    height: 2px;
    top: 18px;
    left: 25%;
  }
`;

const BlockP1 = styled.div`
  display: flex;
  justify-content: flex-start;
  position: absolute;
  right: 4%;
  top: 50px;
  @media ${devices.laptopXXS} {
    top: 48px;
    right: 5%;
  }
  @media ${devices.tablet} {
    top: 36px;
    right: 0%;
  }
  @media ${devices.tabletM} {
    top: 32px;
  }
  @media ${devices.mobileL} {
    top: 28px;
  }
`;

const P1 = styled.p.attrs({
  className: "paragraph1",
})`
  height: 28px;
  font-family: "Russo One", "sans serif";
  letter-spacing: 1px;
  font-size: 20px;
  color: #553322;
  margin: 0;
  margin-left: 20px;
  margin-right: 20px;
  transform: scale(1.2);
  opacity: 0;
  @media ${devices.laptopXXS} {
    font-size: 18px;
  }
  @media ${devices.tablet} {
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
  }
  @media ${devices.tabletM} {
    font-size: 14px;
    margin-left: 8px;
    margin-right: 8px;
  }
  @media ${devices.mobileL} {
    font-size: 12px;
    margin-left: 4px;
    margin-right: 4px;
  }
`;

const SpanP1 = styled.span.attrs({
  id: "span1",
})`
  display: block;
  min-width: 3px;
  height: 40px;
  background-color: #553322;
  margin-top: -13px;
  transform: scaleY(0);
  transform-origin: top;

  @media ${devices.mobileL} {
    min-width: 2px;
  }
`;

const P2 = styled.p.attrs({
  className: "paragraph1",
})`
  height: 28px;
  font-family: "Russo One", "sans serif";
  letter-spacing: 1px;
  font-size: 20px;
  color: #553322;
  margin: 0;
  margin-left: 20px;
  margin-right: 20px;
  transform: scale(1.2);
  opacity: 0;
  @media ${devices.laptopXXS} {
    font-size: 18px;
  }
  @media ${devices.tablet} {
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
  }
  @media ${devices.tabletM} {
    font-size: 14px;
    margin-left: 8px;
    margin-right: 8px;
  }
  @media ${devices.mobileL} {
    font-size: 12px;
    margin-left: 4px;
    margin-right: 4px;
  }
`;

const SpanP2 = styled.span.attrs({
  id: "span2",
})`
  display: block;
  min-width: 3px;
  height: 19px;
  background-color: #553322;
  margin-top: 4px;
  transform: scaleY(0);
  transform-origin: top;

  @media ${devices.mobileL} {
    min-width: 2px;
  }
`;

const P3 = styled.p.attrs({
  className: "paragraph1",
})`
  height: 28px;
  font-family: "Russo One", "sans serif";
  letter-spacing: 1px;
  font-size: 20px;
  color: #553322;
  margin: 0;
  margin-left: 20px;
  margin-right: 20px;
  transform: scale(1.2);
  opacity: 0;
  @media ${devices.laptopXXS} {
    font-size: 18px;
  }
  @media ${devices.tablet} {
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
  }
  @media ${devices.tabletM} {
    font-size: 14px;
    margin-left: 8px;
    margin-right: 8px;
  }
  @media ${devices.mobileL} {
    font-size: 12px;
    margin-left: 4px;
    margin-right: 4px;
  }
`;

const SpanP3 = styled.span.attrs({
  id: "span3",
})`
  display: block;
  min-width: 3px;
  height: 40px;
  background-color: #553322;
  margin-top: 4px;
  transform: scaleY(0);
  transform-origin: top;

  @media ${devices.mobileL} {
    min-width: 2px;
  }
`;

const SpanP4 = styled.span.attrs({
  id: "span4",
})`
  display: block;
  width: 700px;
  height: 3px;
  background-color: #553322;
  position: absolute;
  top: 80px;
  left: 12.8%;
  transform: scaleX(0);
  transform-origin: left;
  @media ${devices.laptopXXS} {
    width: 620px;
    left: 8%;
  }
  @media ${devices.tablet} {
    width: 520px;
    left: 8%;
    top: 66px;
  }
  @media ${devices.tabletM} {
    width: 430px;
    left: 8%;
    top: 62px;
  }
  @media ${devices.mobileL} {
    width: 342px;
    height: 2px;
  }
`;

const P4 = styled.p.attrs({
  id: "p4",
})`
  width: 280px;
  height: 28px;
  font-family: "Russo One", "sans serif";
  letter-spacing: 1px;
  font-size: 20px;
  color: #553322;
  margin: 0;
  margin-left: 20px;
  margin-right: 20px;
  transform: translateX(-80px);
  opacity: 0;
  position: absolute;
  top: 90px;
  left: 14%;
  @media ${devices.laptopXXS} {
    font-size: 18px;
    left: 5.4%;
  }
  @media ${devices.tablet} {
    font-size: 16px;
    top: 76px;
  }
  @media ${devices.tabletM} {
    font-size: 14px;
    top: 72px;
    left: 0%;
    margin-left: 10px;
  }
  @media ${devices.mobileL} {
    font-size: 12px;
    left: -12px;
  }
`;

const BlockP2 = styled.div`
  display: flex;
  justify-content: flex-start;
  position: absolute;
  top: 88px;
  right: 50%;
  @media ${devices.tablet} {
    top: 74px;
  }
  @media ${devices.tabletM} {
    top: 74px;
  }
`;

const SpanP5 = styled.span.attrs({
  id: "span5",
})`
  display: block;
  width: 3px;
  height: 58px;
  background-color: #553322;
  margin-top: -12px;
  position: absolute;
  left: 12px;
  transform: scaleY(0);
  transform-origin: bottom;

  @media ${devices.laptopXXS} {
    left: -24px;
  }
  @media ${devices.tablet} {
    height: 40px;
    left: 2px;
  }
  @media ${devices.tabletM} {
    margin-top: -16px;
    left: -10px;
  }
  @media ${devices.mobileL} {
    width: 2px;
    left: -7px;
  }
`;

const SpanP6 = styled.span.attrs({
  id: "span6",
})`
  display: block;
  width: 24px;
  height: 3px;
  background-color: #553322;
  margin-top: 38px;
  position: absolute;
  left: 8px;
  transform: scaleX(0);
  transform-origin: right;

  @media ${devices.laptopXXS} {
    left: -28px;
  }
  @media ${devices.tablet} {
    margin-top: 20px;
    width: 20px;
    left: -2px;
  }
  @media ${devices.tabletM} {
    margin-top: 16px;
    left: -12px;
  }
  @media ${devices.mobileL} {
    height: 2px;
    left: -12px;
  }
`;

const SpanP7 = styled.span.attrs({
  id: "span7",
})`
  display: block;
  width: 3px;
  height: 164px;
  background-color: #553322;
  margin-top: 34px;
  position: absolute;
  left: 24px;
  transform: scaleY(0);
  transform-origin: top;

  @media ${devices.laptopXXS} {
    left: -11px;
  }
  @media ${devices.tablet} {
    height: 120px;
    margin-top: 12px;
    left: 10px;
  }
  @media ${devices.tabletM} {
    margin-top: 8px;
    left: -2px;
  }
  @media ${devices.mobileL} {
    width: 2px;
    left: -0.5px;
  }
`;

const BlockP3 = styled.div`
  margin-top: 50px;
  position: absolute;

  @media ${devices.tablet} {
    margin-top: 20px;
  }
  @media ${devices.mobileL} {
    margin-top: 20px;
  }
`;

const Plist = styled.p.attrs({
  className: "pList",
})`
  height: 28px;
  font-family: "Russo One", "sans serif";
  letter-spacing: 1px;
  font-size: 22px;
  color: #553322;
  margin: 0;
  margin-left: 42px;
  margin-top: 14px;
  white-space: nowrap;
  transform: translateX(180px);
  opacity: 0;

  @media ${devices.laptopXXS} {
    /* transform: translateX(100px); */
    font-size: 18px;
    margin-top: 12px;
    height: 24px;
  }
  @media ${devices.tablet} {
    /* transform: translateX(60px); */
    font-size: 16px;
    height: 20px;
    margin-left: 40px;
  }
  @media ${devices.tabletM} {
    /* transform: translateX(10px); */
    font-size: 14px;
    margin-left: 20px;
  }
  @media ${devices.mobileL} {
    /* transform: translateX(0px); */
    font-size: 11.4px;
    margin-top: 8px;
  }
`;

const SpanP8 = styled.span.attrs({
  id: "span8",
})`
  display: block;
  width: 3px;
  height: 90px;
  background-color: #553322;
  margin-top: 40px;
  position: absolute;
  left: 300px;
  transform: scaleY(0);
  transform-origin: bottom;

  @media ${devices.laptopXXS} {
    margin-top: 36px;
    left: 240px;
  }
  @media ${devices.tablet} {
    margin-top: 32px;
    height: 60px;
    left: 220px;
  }
  @media ${devices.tabletM} {
    left: 200px;
  }
  @media ${devices.mobileL} {
    height: 40px;
    width: 2px;
    left: 160px;
  }
`;

const SpanP9 = styled.span.attrs({
  id: "span9",
})`
  display: block;
  width: 700px;
  height: 3px;
  background-color: #553322;
  margin-top: 50px;
  position: absolute;
  right: 0px;
  transform: scaleX(0);
  transform-origin: right;

  @media ${devices.laptopXXS} {
    width: 500px;
  }
  @media ${devices.tablet} {
    margin-top: 40px;
  }
  @media ${devices.tabletM} {
    width: 450px;
  }
  @media ${devices.mobileL} {
    height: 2px;
    width: 370px;
  }
`;

const SpanP10 = styled.span.attrs({
  id: "span10",
})`
  display: block;
  width: 3px;
  height: 20px;
  background-color: #553322;
  margin-top: 41px;
  position: absolute;
  right: 680px;
  transform: scaleY(0);
  transform-origin: top;

  @media ${devices.laptopXXS} {
    right: 480px;
  }
  @media ${devices.tablet} {
    margin-top: 31px;
  }
  @media ${devices.tabletM} {
    right: 440px;
  }
  @media ${devices.mobileL} {
    width: 2px;
    right: 360px;
  }
`;

export default function Presentation() {
  useEffect(() => {
    if (window.innerWidth <= 768) {
      setTimeout(() => {
        effectPresentationScroll();
      }, 1800);
    }
  }, []);

  const effectPresentationScroll = () => {
    const titleMain = document.querySelector("#titleMain");
    const paragraph1 = document.querySelectorAll(".paragraph1");
    const spanTitle = document.querySelector("#spanTitle");
    const span1 = document.querySelector("#span1");
    const span2 = document.querySelector("#span2");
    const span3 = document.querySelector("#span3");
    const span4 = document.querySelector("#span4");
    const p4 = document.querySelector("#p4");
    const span5 = document.querySelector("#span5");
    const span6 = document.querySelector("#span6");
    const span7 = document.querySelector("#span7");
    const pList1 = document.querySelector("#pList1");
    const pList2 = document.querySelector("#pList2");
    const pList3 = document.querySelector("#pList3");
    const pList4 = document.querySelector("#pList4");
    const span8 = document.querySelector("#span8");
    const span9 = document.querySelector("#span9");
    const span10 = document.querySelector("#span10");

    const screenY = window.scrollY + window.innerHeight * 0.98;

    if (titleMain) {
      if (
        OffsetTopFunction(titleMain) + titleMain.offsetHeight * 0.98 - screenY <
        0
      ) {
        titleMain.style.opacity = "1";
        titleMain.style.transform = "translateX(0)";
        titleMain.style.transition = "300ms";
        titleMain.style.transitionDelay = "50ms";
        spanTitle.style.transform = "scaleX(1)";
        spanTitle.style.transition = "500ms ease-in-out 400ms";
      }
      if (OffsetTopFunction(span1) + span1.offsetHeight * 0.98 - screenY < 0) {
        let x = 1;
        paragraph1.forEach((element) => {
          element.style.transform = "scale(1)";
          element.style.opacity = "1";
          element.style.transition = "800ms";
          element.style.transitionDelay = x++ / 1.8 + "s";
        });
        span1.style.transform = "scaleY(1)";
        span1.style.transition = "500ms ease-in-out 800ms";
        span2.style.transform = "scaleY(1)";
        span2.style.transition = "500ms ease-in-out 1500ms";
        span3.style.transform = "scaleY(1)";
        span3.style.transition = "700ms ease-out 2250ms";
        span4.style.transform = "scaleX(1)";
        span4.style.transition = "2800ms ease-out";
      }
      if (OffsetTopFunction(p4) + p4.offsetHeight * 0.98 - screenY < 0) {
        p4.style.opacity = "1";
        p4.style.transform = "translateX(0)";
        p4.style.transition = "500ms";
        p4.style.transitionDelay = "250ms";
        span5.style.transform = "scaleY(1)";
        span5.style.transition = "3400ms ease-in-out";
      }
      if (
        OffsetTopFunction(pList1) + pList1.offsetHeight * 0.98 - screenY <
        40
      ) {
        span6.style.transform = "scaleX(1)";
        span6.style.transition = "3000ms ease-in-out";
        span7.style.transform = "scaleY(1)";
        span7.style.transition = "1400ms ease-in-out";
        pList1.classList.add("effectScroll");
        pList1.style.animationDelay = "400ms";
      }
      if (
        OffsetTopFunction(pList2) + pList2.offsetHeight * 0.98 - screenY <
        40
      ) {
        pList2.classList.add("effectScroll");
        pList2.style.animationDelay = "500ms";
      }
      if (
        OffsetTopFunction(pList3) + pList3.offsetHeight * 0.98 - screenY <
        40
      ) {
        pList3.classList.add("effectScroll");
        pList3.style.animationDelay = "600ms";
      }
      if (
        OffsetTopFunction(pList4) + pList4.offsetHeight * 0.98 - screenY <
        40
      ) {
        pList4.classList.add("effectScroll");
        pList4.style.animationDelay = "700ms";
        span8.style.transform = "scaleY(1)";
        span8.style.transition = "2000ms ease-in-out";
        span9.style.transform = "scaleX(1)";
        span9.style.transition = "2700ms ease-out";
        span10.style.transform = "scaleX(1)";
        span10.style.transition = "2100ms linear 1000ms";
      }
    }
  };

  useEffect(() => {
    const titleMain = document.querySelector("#titleMain");
    if (titleMain) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (window.innerWidth > 768) {
            window.addEventListener("scroll", () => {
              effectPresentationScroll();
            });
          }
        } else {
          window.removeEventListener("scroll", () => {
            effectPresentationScroll();
          });
        }
      });
      observer.observe(titleMain);
    }
  }, []);

  return (
    <ContenairAllElements>
      <ContenairTitleMain>
        <BlockTitleMain>
          <TitleMain className="effectPresentation">
            Entreprise Navarro
          </TitleMain>
          <Span1 className="effectPresentation"></Span1>
          <BlockP1 id="blockP1">
            <P1 className="effectPresentation">Plomberie</P1>
            <SpanP1 className="effectPresentation"></SpanP1>
            <P2 className="effectPresentation">Chauffagerie</P2>
            <SpanP2 className="effectPresentation"></SpanP2>
            <P3 className="effectPresentation">Climatisation</P3>
            <SpanP3 className="effectPresentation"></SpanP3>
          </BlockP1>
          <SpanP4 className="effectPresentation"></SpanP4>
          <P4 className="effectPresentation">Création de salle de bain</P4>
          <BlockP2>
            <SpanP5 id="span5" className="effectPresentation"></SpanP5>
            <SpanP6 id="span6" className="effectPresentation"></SpanP6>
            <SpanP7></SpanP7>
            <BlockP3>
              <Plist id="pList1" className="effectPresentation">
                Professionnels agrées
              </Plist>
              <Plist id="pList2" className="effectPresentation">
                Qualifications certifiées
              </Plist>
              <Plist id="pList3" className="effectPresentation">
                Prix partenaires
              </Plist>
              <div style={{ display: "flex" }}>
                <Plist id="pList4" className="effectPresentation">
                  Devis 100% gratuit
                </Plist>
                <SpanP8 className="effectPresentation"></SpanP8>
                <SpanP9 id="span9" className="effectPresentation"></SpanP9>
                <SpanP10 className="effectPresentation"></SpanP10>
              </div>
            </BlockP3>
          </BlockP2>
        </BlockTitleMain>
      </ContenairTitleMain>
    </ContenairAllElements>
  );
}
