import React, { useEffect } from "react";
import styled from "styled-components";
import HeaderBlog from "../../components/Header/HeaderBlog";
import Footer from "../../components/Footer";
import { NavBarDisplayFunction } from "../../functions/NavBarDisplayScrollFunction";
import { devices } from "../../style/Size";

const ContenairMain = styled.main`
  width: 94%;
  /* background-color: #ffffff; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 134px;
  margin-bottom: 200px;

  @media ${devices.laptopXXS} {
    margin-top: 114px;
    margin-bottom: 120px;
  }
  @media ${devices.mobileL} {
    margin-top: 100px;
    margin-bottom: 80px;
  }
  @media (max-height: 480px) {
    margin-bottom: 80px;
  }
`;

const TitleArticle = styled.h1`
  font-size: 34px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  padding-top: 10px;

  @media ${devices.tablet} {
    font-size: 32px;
  }
  @media ${devices.mobileL} {
    font-size: 30px;
  }
  @media ${devices.mobileM} {
    font-size: 28px;
  }
`;

const SubTitleArticle = styled.h2`
  font-size: 28px;
  font-family: "Russo One", sans-serif;
  color: #553322;

  @media ${devices.tablet} {
    font-size: 26px;
  }
  @media ${devices.mobileL} {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
`;
const TitleArticleSub = styled.h3`
  font-size: 28px;
  font-family: "Russo One", sans-serif;
  color: #553322;

  @media ${devices.tablet} {
    font-size: 26px;
  }
  @media ${devices.mobileL} {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
`;

const TextArticle = styled.h2`
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  text-align: justify;
  margin-bottom: 40px;

  @media ${devices.mobileL} {
    margin-bottom: 50px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

const Ul = styled.ul`
  max-width: 80%;
  @media ${devices.mobileL} {
    margin-left: 10%;
    padding-left: 0;
  }
`;

export default function WaterHeaterMaintenance() {
  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction(
      "headerBlogContenair",
      "comment-entretenir-son-chauffe-eau"
    );
  });

  return (
    <>
      <HeaderBlog />
      <ContenairMain>
        <TitleArticle>Comment entretenir son chauffe-eau ?</TitleArticle>
        <TextArticle>
          Que ce soit du détartrage régulier jusque l’entretien du groupe de
          sécurité, votre chauffe-eau comporte plusieurs étapes indispensables à
          remplir pour son bon entretien. Ce dernier, parfois imposé par la loi,
          vous permet de profiter d’un appareil plus performant tout en lui
          conférant une durée de vie supérieure.
        </TextArticle>
        <section>
          <SubTitleArticle>
            Comment détartrer un chauffe-eau électrique ?
          </SubTitleArticle>
          <TextArticle>
            Voici l’opération essentielle à réaliser pour un entretien efficace
            d’un chauffe-eau électrique. Au fur et à mesure des mois et des
            années qui passent, le calcaire contenu dans l’eau courante qui
            circule ou qui stagne à l’intérieur de votre cumulus s’accumule et
            altère le fonctionnement naturel de votre équipement (voir plus
            bas). Dans la plupart des situations, l’opération de détartrage d’un
            chauffe-eau électrique se constitue de ces étapes : <br />
            <br />
            <Ul>
              <li>Arrêter l’alimentation électrique de votre chauffe-eau</li>
              <li>Arrêter l’alimentation en eau</li>
              <li>
                Réaliser une vidange de la cuve par l’ouverture du groupe de
                sécurité et d’un robinet d’eau chaude
              </li>
              <li>
                Accéder au boîtier électrique en dévissant le capot inférieur
              </li>
              <li>Démonter le thermostat</li>
              <li>Ouvrir la trappe de visite (ou bride)</li>
              <li>
                Nettoyer le réservoir à l’aide d’un chiffon afin d’ôter tous les
                dépôts de calcaire
              </li>
              <li>Nettoyer la résistance de la même manière</li>
              <li>
                Nettoyer l’emplacement du joint d’étanchéité après l’avoir
                déposé
              </li>
              <li>Refermer la trappe de visite</li>
              <li>Remettre en place le thermostat</li>
              <li>
                Procéder au remplissage de la cuve en vérifiant l’absence de
                fuite
              </li>
              <li>
                Mettre le chauffe-eau électrique en état de fonctionnement
              </li>
            </Ul>
            <br />
            La cadence de détartrage d’un chauffe-eau électrique diffère selon
            la composition de l’eau utilisée. Dans certaines régions (où l’eau
            est particulièrement dure ou calcaire), il est ainsi recommandé de
            procéder à un détartrage du chauffe-eau au moins une fois par an.
            Pour les résidents de régions bénéficiant d’une eau plus douce et/ou
            équipées d’un adoucisseur d’eau pour limiter les dépôts de calcaire
            au sein de leur appareil, un détartrage moins régulier du
            chauffe-eau électrique, environ tous les 3 ans, doit suffire à
            conserver l'intégrité de votre appareil.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Comment entretenir le groupe de sécurité d’un chauffe-eau électrique
          </SubTitleArticle>
          <TextArticle>
            L’entretien du groupe de sécurité d’un chauffe-eau électrique est
            l’autre étape crucial de l’entretien correct d’un cumulus. Cet pièce
            indépendante de la cuve et de la résistance nécessite un entretien
            régulier pour remplir dûment son rôle lors de l’évacuation de l’eau
            présente dans la cuve (vidange). Pour cela : <br />
            <br />
            <Ul>
              <li>
                Placez un seau ou un récipient équivalent sous le groupe de
                sécurité
              </li>
              <li>Positionnez le groupe de sécurité en position vidange</li>
              <li>
                Laissez couler quelques instants (cette étape permet
                l’évacuation des résidus de calcaire et de tartre)
              </li>
              <li>Refermez la purge en vérifiant l’absence d’écoulement</li>
            </Ul>
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Quand entretenir le groupe de sécurité d’un chauffe-eau électrique ?
          </SubTitleArticle>
          <TextArticle>
            Il est recommandé d’entretenir ou d’actionner le groupe de sécurité
            d’un chauffe-eau électrique une fois par mois. Selon le type
            d’appareil, il est également suggéré de changer le groupe de
            sécurité entre une période comprise entre 5 et 10 ans. <br />
            <br />
          </TextArticle>
          <TitleArticleSub>
            L’entretien d’un chauffe-eau électrique avec résistance blindée
          </TitleArticleSub>
          <TextArticle>
            Si votre logement est équipé d’un chauffe-eau électrique avec
            résistance blindée, il est recommandé pour l’entretien et le
            détartrage de votre appareil de :
            <br />
            <br />
            <Ul>
              <li>Arrêter l’alimentation électrique du chauffe-eau</li>
              <li>Débrancher le thermostat</li>
              <li>Procéder à une vidange totale de la cuve</li>
              <li>Démonter la résistance située sous le capot</li>
              <li>
                Plonger la résistance dans un produit de détartrage adapté et
                laisser agir quelques heures
              </li>
              <li>
                Nettoyer la résistance à l’aide d’une brosse métallique souple
                puis rincer abondamment à l’eau chaude
              </li>
              <li>Remettre en place la résistance, la platine et le capot</li>
              <li>
                Remplir la cuve et alimenter le chauffe-eau à résistance blindée
                en électricité
              </li>
            </Ul>
          </TextArticle>
          <TitleArticleSub>
            L’entretien d’un chauffe-eau électrique avec résistance stéatite
          </TitleArticleSub>
          <TextArticle>
            Si votre chauffe-eau électrique est un modèle avec résistance
            stéatite, vous devez savoir que cette résistance est protégée par un
            fourreau en acier, ce qui la préserve d’un contact direct avec l’eau
            et peut donc être nettoyée sans avoir à vider la cuve de votre
            chauffe-eau. Sur les modèles les plus récents de chauffe-eau
            électrique avec résistance stéatite, la technologie ACI garantit par
            ailleurs la préservation de l’appareil de la corrosion, rendant
            ainsi les opérations d’entretien moins fréquentes que sur les autres
            types de modèles. Pour l’entretien de votre chauffe-eau électrique
            avec résistance stéatite, il vous suffit de : <br />
            <br />
            <Ul>
              <li>Couper l’alimentation électrique du chauffe-eau</li>
              <li>Ôter la résistance de son fourreau</li>
              <li>
                Plonger l’ensemble dans un produit de détartrage adapté et
                laisser agir quelques heures
              </li>
              <li>
                Nettoyer la résistance à l’aide d’une brosse en fer douce puis
                rincer à l’eau chaude
              </li>
              <li>Remettre en place la résistance et son fourreau</li>
              <li>Alimenter le chauffe-eau en électricité</li>
            </Ul>
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Quels sont les risques et conséquences en cas de mauvais entretien
            d’un chauffe-eau électrique ?
          </SubTitleArticle>
          <TextArticle>
            En cas de mauvais entretien d’un cumulus, sachez que vous vous
            exposez : <br />
            <br />
            <Ul>
              <li>
                À une perte de performances de votre appareil (rendement) avec
                une augmentation de sa consommation électrique (et donc de votre
                facture)
              </li>
              <li>
                À une corrosion accélérée de votre chauffe-eau avec un risque
                précoce de fuite et/ou de changement d’appareil
              </li>
              <li>À une panne de votre chauffe-eau tout aussi précoce</li>
              <li>
                Au développement de bactéries, comme la légionellose, qui
                prolifèrent dans une eau qui n'est pas assez chauffée
              </li>
            </Ul>
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Quel entretien pour un chauffe-eau au gaz ?
          </SubTitleArticle>
          <TextArticle>
            L’entretien d’un chauffe-eau au gaz diffère sensiblement de
            l’entretien d’un chauffe-eau électrique. Obligatoire selon la loi,
            l’entretien d’un chauffe-eau au gaz est par définition confié à un
            professionnel. Il se résume le plus souvent : <br />
            <br />
            <Ul>
              <li>Au nettoyage de l’injecteur</li>
              <li>Au nettoyage de la veilleuse et de son filtre</li>
              <li>Au remplacement du clapet et du joint de l’arrivée d’eau</li>
              <li>A nettoyage des ailettes du corps de chauffe</li>
              <li>Au nettoyage des becs du brûleur</li>
              <li>Au détartrage du corps de chauffe</li>
            </Ul>
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Entretien du chauffe-eau : est-il obligatoire ?
          </SubTitleArticle>
          <TextArticle>
            La loi ne fixe aucune obligation d’entretien d’un chauffe-eau pour
            les propriétaires d’un appareil électrique. En revanche, vous êtes
            légalement tenu de faire entretenir par un professionnel votre
            chauffe-eau au gaz, au moins une fois par an. Pour cela, deux
            options : <br />
            <br />
            <Ul>
              <li>la souscription d’un contrat annuel d’entretien</li>
              <li>la visite d’entretien annuelle</li>
            </Ul>
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Confier l’entretien de son chauffe-eau à un professionnel
          </SubTitleArticle>
          <TextArticle>
            Souvent présentées comme accessibles aux bricoleurs avertis, les
            opérations d’entretien d’un chauffe-eau électrique nécessitent
            certaines compétences et un savoir-faire particulier. Pour éviter
            les risques d’un mauvais entretien et/ou les conséquences d’un
            manquement aux règles de sécurité, mieux vaut confier l’entretien de
            votre chauffe-eau à un professionnel. Dans le cas de l’entretien
            d’un chauffe-eau au gaz, le recours à un professionnel est
            d’ailleurs obligatoire pour disposer d’une attestation annuelle
            d’entretien. <br />
            <br />
            L'entreprise Navarro située à Marseille se charge pour vous de cet
            entretien. Appelez nous pour plus de renseignements.
          </TextArticle>
        </section>
      </ContenairMain>
      <Footer marginTop="180px" backgroundColor="#3c2114" />
    </>
  );
}
