import React from "react";
import styled from "styled-components";
import {
  NotEffectGlowButtonFunction,
  HoverButtonBlogFunction,
} from "../../functions/EffectGlowButtonsFunction";
import { devices } from "../../style/Size";

const ContenairButton = styled.div`
  width: 316px;
  height: 50px;
  margin-top: 20px;
  position: absolute;
  bottom: 20px;
  right: 1.9999%;

  @media ${devices.laptopXXS} {
    width: 314px;
    height: 40px;
    bottom: 29px;
  }
  @media ${devices.tablet} {
    width: 100%;
    right: 0;
    bottom: 16px;
    display: flex;
    justify-content: flex-end;
  }
  @media (max-width: 694px) {
    justify-content: center;
  }
  @media ${devices.mobileL} {
    height: 32px;
    bottom: 18px;
  }
  @media (max-width: 320px) {
    bottom: 10px;
  }
`;

const Button = styled.button.attrs({
  type: "button",
  value: "LIRE CET ARTICLE",
  className: "buttonSectionBlog",
})`
  width: 316px;
  height: 50px;
  border: none;
  background-color: #ffffff;
  font-size: 14px;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  font-weight: 100;
  color: #3c2114;
  cursor: pointer;

  @media ${devices.tablet} {
    height: 40px;
    width: 280px;
    margin-left: auto;
    margin-right: 3%;
  }
  @media (max-width: 694px) {
    width: 240px;
    margin-left: 0;
    margin-right: 0;
  }
  @media (max-width: 572px) {
    width: 220px;
  }
  @media ${devices.mobileL} {
    height: 32px;
    width: 200px;
    margin-top: 4px;
  }
  @media ${devices.mobileM} {
    width: 184px;
    margin-top: 0px;
  }
  @media ${devices.mobileS} {
    width: 160px;
  }
`;

export default function ButtonBlog(props) {
  return (
    <ContenairButton>
      <Button
        id={props.idButton}
        onClick={props.onClick}
        // onTouchStart={props.onTouchStart}
        onMouseLeave={() => NotEffectGlowButtonFunction("buttonSectionBlog")}
        onMouseEnter={(e) => HoverButtonBlogFunction(e, "buttonSectionBlog")}
      >
        LIRE CET ARTICLE
      </Button>
    </ContenairButton>
  );
}
