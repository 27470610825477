import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Header from "../components/Header/HeaderHome/Header";
import ModalUrgenceDisplay from "../components/ModalUrgence/ModalUrgenceDisplay";
import Presentation from "../components/BodyHome/Presentation";
import PresentationEntreprise from "../components/PresentationEntreprise";
import BlockPromotion from "../components/BodyHome/BlockPromotion";
import BlockArticleTop from "../components/BodyHome/BlockArticleTop";
import BlockBathroom from "../components/BodyHome/BlockBathroom";
import Footer from "../components/Footer";
import "../style/BackgroundImages/Main.css";

const EffectAngleDown = keyframes`
  0% {
    transform: translateX(-50%) scale(1);
    opacity: 0.5;
  }
  2.5% {
    opacity: 0.9;
  }
  12.5% {
    transform: translateX(-50%) scale(1.2);
    opacity: 0.9;
  }
  22.5% {
    opacity: 0.9;
  }
  25% {
    transform: translateX(-50%) scale(1);
    opacity: 0.5;
  }
  75% {
    transform: translateX(-50%) scale(1);
    opacity: 0.5;
  }
  75.01% {
    transform: translateX(-50%) scale(1);
    opacity: 0.5;
  }
  77.5% {
    opacity: 0.9;
  }
  87.5% {
    transform: translateX(-50%) scale(1.2);
    opacity: 0.9;
  }
  97.5% {
    opacity: 0.9;
  }
  100% {
    transform: translateX(-50%) scale(1);
    opacity: 0.5;
  }
`;

const BlockBody = styled.main.attrs({
  id: "blockBody",
})`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const ContenairMenuArticle = styled.div.attrs({
  id: "contenairMenuArticle",
})`
  width: 100%;
`;

const AngleDown = styled.div`
  width: 100px;
  height: 70px;
  color: #eadada;
  position: absolute;
  top: 89vh;
  left: 50%;
  z-index: 300;
  transform: translateX(-50%);
  opacity: 0.5;
  filter: brightness(2);
  animation: ${EffectAngleDown} 6s linear 5s forwards;
`;

export default function Home() {
  const [angleDownDisplay, setAngleDownDisplay] = useState(false);

  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (window.innerWidth > 768) {
      setAngleDownDisplay(true);
    }
  }, []);

  useEffect(() => {
    const lS = localStorage.getItem("aDnD");
    const elementAngleDownHidden = document.querySelector("#angleDownHidden");

    if (lS === "false") {
      setAngleDownDisplay(false);
      if (elementAngleDownHidden) {
        elementAngleDownHidden.style.display = "none";
      }
    }
  }, [angleDownDisplay]);

  window.addEventListener("scroll", () => {
    const elementAngleDownHidden = document.querySelector("#angleDownHidden");
    setAngleDownDisplay(false);
    if (window.scrollY > 0) {
      if (elementAngleDownHidden) {
        elementAngleDownHidden.style.transform = "translateX(-50%) scale(1)";
        elementAngleDownHidden.style.opacity = "0";
        elementAngleDownHidden.style.transition = "1s";
      }
      localStorage.setItem("aDnD", false);
    }
  });
  return (
    <>
      <Header />
      {angleDownDisplay && (
        <AngleDown>
          <i
            style={{
              fontSize: "90px",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            className="fa-solid fa-angle-down"
          ></i>
        </AngleDown>
      )}
      <BlockBody>
        <ModalUrgenceDisplay />
        <Presentation />
        <PresentationEntreprise />
        <ContenairMenuArticle>
          <BlockPromotion />
          <BlockArticleTop />
          <BlockBathroom />
        </ContenairMenuArticle>
      </BlockBody>
      <Footer backgroundColor="#667788" />
    </>
  );
}
