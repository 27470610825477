import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import HeaderSubSection from "../components/Header/HeaderSubSection";
import BodySubSection from "../components/SubSection/BodySubSection";
import LastBlockSubSection from "../components/SubSection/LastBlockSubSection";
import ButtonQuote from "../components/Buttons/ButtonQuote";
import Footer from "../components/Footer";
import ElectricWaterHeaterPicture from "../assets/Installation/chauffe-eau-electrique.webp";
import CEEBlinde from "../assets/ElectricWaterHeater/chauffe-eau-electrique-blinde.webp";
import CEESteatis from "../assets/ElectricWaterHeater/chauffe-eau-electrique-steatis.webp";
import CEEDC from "../assets/ElectricWaterHeater/chauffe-eau-electrique-duralis-connect.webp";
import { NavBarDisplayFunction } from "../functions/NavBarDisplayScrollFunction";
import { EffectSubSectionFunction } from "../functions/EffectSubSectionFunction";
import { EffectTitlePageFunction } from "../functions/EffectTitlePageFunction";
import { devices } from "../style/Size";

const ContenairMain = styled.main`
  width: 100%;
  position: relative;
  margin-top: 70px;

  @media ${devices.laptopXXS} {
    margin-top: 110px;
  }
  @media ${devices.tablet} {
    margin-top: 40px;
  }
`;

export default function ElectricWaterHeater() {
  useEffect(() => {
    EffectTitlePageFunction("titleElectricWaterHeaterContenair");
    setTimeout(() => {
      EffectTitlePageFunction("idBlockElement");
    }, 600);
  }, []);

  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction("headerElectricWaterHeaterContenair");
  });

  useEffect(() => {
    const titleSection = document.querySelector("#blockTitle");
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        window.addEventListener("scroll", () => {
          EffectSubSectionFunction(
            "blockTitle",
            "/chauffe-eau/chauffe-eau-electriques"
          );
        });
      }
    });
    observer.observe(titleSection);
  }, []);

  window.addEventListener("resize", () => {
    const blockSection = document.querySelectorAll(`.imageSection`);
    if (blockSection) {
      EffectSubSectionFunction(
        "blockTitle",
        "/chauffe-eau/chauffe-eau-electriques"
      );
      if (window.innerWidth <= 568) {
        for (let element of blockSection) {
          element.style.transform = "translateX(0vw)";
          element.style.transition = "all 300ms";
        }
      }
    }
  });

  return (
    <>
      <HeaderSubSection
        id="headerElectricWaterHeaterContenair"
        idTitle="titleElectricWaterHeaterContenair"
        src={ElectricWaterHeaterPicture}
        alt="Grand et long chauffe-eau électrique sol au design noir et blanc placé dans un placard de la cuisine"
        titlePage="Chauffe-eau Électriques"
      />
      <ContenairMain>
        <BodySubSection
          idText="textSection"
          imageClassname="imageSection"
          idBlockElement="idBlockElement"
          opacity="0.5"
          src={CEEBlinde}
          alt="Grand et long chauffe-eau électrique sol au design noir et blanc placé dans un placard de la cuisine"
          title="Les Blindés"
          text={[
            <strong key="1" style={{ fontWeight: "100" }}>
              Chauffe-eau électriques entrées de gamme
            </strong>,
            ". Contenance de 50 à 300 litres. Résistance blindée, moins durable dans le temps comparée à une résistance stéatite. Équipé d'une protection anticorrosion qui se dissout dans le temps. Différents formats sont disponibles pour une variété de pose : vertical, horizontal, mural ou stable. Choix de ",
            <strong key="2" style={{ fontWeight: "100" }}>
              chauffe-eau électriques instantanés
            </strong>,
            ". La solution idéale pour les petits budgets.",
            <br key="3" />,
            <br key="4" />,
            "Nos ",
            <strong key="5" style={{ fontWeight: "100" }}>
              plombiers-chauffagistes à Marseille
            </strong>,
            " se rendent sur place pour effectuer un devis 100% gratuit et personnalisé afin d'estimer le ",
            <strong key="6" style={{ fontWeight: "100" }}>
              coût et temps d'installation chauffagiste
            </strong>,
            ". Le temps de pose est généralement aux environs de 4 heures sans modification d'installation.",
            <br key="7" />,
            <br key="8" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="9"
              to="https://www.dedietrich-thermique.fr/recherche?actimage_search%5Bfamily%5D%5B%5D=5630&actimage_search%5BvalidateSearchFilter%5D=&actimage_search%5Bkeyword%5D=chauffe-eau&actimage_search%5Bezxform_token%5D=WiQZoRpdXfGCFC0wwrcQQg_s8y0HGTt8akbIDUzj9Zw"
              target="_blank"
            >
              De Dietrich
            </Link>,
            ", ",
            <Link
              key="10"
              to="https://www.thermor.fr/produits/chauffe-eau/chauffe-eau-electrique/blinde/blinde-vertical-mural-compact-100-l"
              target="_blank"
            >
              Thermor
            </Link>,
            ", et ",
            <Link
              key="11"
              to="https://www.atlantic.fr/Chauffer-l-eau/Chauffe-eau/Chauffe-eau-electrique"
              target="_blank"
            >
              Atlantic
            </Link>,
            ".",
          ]}
        />
        <BodySubSection
          imageClassname="imageSection"
          src={CEESteatis}
          alt="Chauffe-eau blanc cylindrique grand et long dans buanderie tout équipée et bien rangée"
          title={[
            "Les ",
            <strong key="1" style={{ fontWeight: "500" }}>
              Stéatis
            </strong>,
          ]}
          text={[
            <strong key="1" style={{ fontWeight: "100" }}>
              Chauffe-eau électriques moyens de gamme
            </strong>,
            ". Contenance de 50 à 300 litres. Résistance stéatite disposant d'une étanchéité due à son fourreau de protection, et son anode en titane entourée de magnésium qui brise les dépôts de calcaire, qui confère ainsi au chauffe-eau une durée de vie plus conséquente. Choix de ",
            <strong key="2" style={{ fontWeight: "100" }}>
              chauffe-eau électriques instantanés
            </strong>,
            ".",
            <br key="3" />,
            <br key="4" />,
            "Nos ",
            <strong key="5" style={{ fontWeight: "100" }}>
              plombiers-chauffagistes à Marseille
            </strong>,
            " se rendent sur place pour effectuer un devis 100% gratuit et personnalisé afin d'estimer le ",
            <strong key="6" style={{ fontWeight: "100" }}>
              coût et temps d'installation chauffagiste
            </strong>,
            ". Le temps de pose est généralement aux environs de 4 heures sans modification d'installation.",
            <br key="7" />,
            <br key="8" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="9"
              to="https://www.dedietrich-thermique.fr/recherche?actimage_search%5Bfamily%5D%5B%5D=5630&actimage_search%5BvalidateSearchFilter%5D=&actimage_search%5Bkeyword%5D=chauffe-eau&actimage_search%5Bezxform_token%5D=WiQZoRpdXfGCFC0wwrcQQg_s8y0HGTt8akbIDUzj9Zw"
              target="_blank"
            >
              De Dietrich
            </Link>,
            ", ",
            <Link
              key="10"
              to="https://www.thermor.fr/produits/chauffe-eau/chauffe-eau-electrique/steatis/steatis-vertical-mural-compact-100-l"
              target="_blank"
            >
              Thermor
            </Link>,
            ", et ",
            <Link
              key="11"
              to="https://www.atlantic.fr/Chauffer-l-eau/Chauffe-eau/Chauffe-eau-electrique"
              target="_blank"
            >
              Atlantic
            </Link>,
            ".",
          ]}
        />
        <LastBlockSubSection
          imageClassname="imageSection"
          src={CEEDC}
          alt="Chauffe-eau blanc cylindrique high tech grand et long dans salle de bain vaste et espacée"
          title={[
            "Les ",
            <strong key="1" style={{ fontWeight: "500" }}>
              Duralis Connect
            </strong>,
          ]}
          text={[
            <strong key="1" style={{ fontWeight: "100" }}>
              Chauffe-eau électriques hauts de gamme
            </strong>,
            ". Contenance de 50 à 300 litres.  Résistance stéatite disposant d'une étanchéité due à son fourreau de protection, et son anode en titane entourée de magnésium qui brise les dépôts de calcaire, qui confère ainsi au chauffe-eau une durée de vie plus conséquente. Un logiciel informatique optimise la production d'eau chaude pour des économies énergétiques conséquentes sur le long terme et une disponibilité d'eau chaude quasi constante et/ou instantanée.",
            <br key="2" />,
            <br key="3" />,
            "Nos ",
            <strong key="4" style={{ fontWeight: "100" }}>
              plombiers-chauffagistes à Marseille
            </strong>,
            " se rendent sur place pour effectuer un devis 100% gratuit et personnalisé afin d'estimer le ",
            <strong key="5" style={{ fontWeight: "100" }}>
              coût et temps d'installation chauffagiste
            </strong>,
            ". Le temps de pose est généralement aux environs de 4 heures sans modification d'installation.",
            <br key="6" />,
            <br key="7" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="9"
              to="https://www.dedietrich-thermique.fr/recherche?actimage_search%5Bfamily%5D%5B%5D=5630&actimage_search%5BvalidateSearchFilter%5D=&actimage_search%5Bkeyword%5D=chauffe-eau&actimage_search%5Bezxform_token%5D=WiQZoRpdXfGCFC0wwrcQQg_s8y0HGTt8akbIDUzj9Zw"
              target="_blank"
            >
              De Dietrich
            </Link>,
            ", ",
            <Link
              key="10"
              to="https://www.thermor.fr/produits/chauffe-eau/chauffe-eau-electrique/duralis-connect/duralis-connect-vertical-stable-300-l"
              target="_blank"
            >
              Thermor
            </Link>,
            ", et ",
            <Link
              key="11"
              to="https://www.atlantic.fr/Chauffer-l-eau/Chauffe-eau/Chauffe-eau-electrique"
              target="_blank"
            >
              Atlantic
            </Link>,
            ".",
          ]}
        />
        <ButtonQuote />
      </ContenairMain>
      <Footer backgroundColor="#667788" />
    </>
  );
}
