import "../style/Animation/KeyFrames/EffectMenuNavBar.css";

const EffectNavBarEnterFunction = (e, idElementLi, idElementHidden) => {
  const elementLi = document.getElementById(idElementLi);
  const blockAllParagraphs = document.getElementById(idElementHidden);
  if (e.target.id === elementLi.id) {
    blockAllParagraphs.classList.remove("notHoverMenuNavBar");
    blockAllParagraphs.style.transform = `scaleY(0)`;
    setTimeout(() => {
      blockAllParagraphs.style.transform = `scaleY(1)`;
      blockAllParagraphs.style.transition = `300ms`;
      blockAllParagraphs.style.zIndex = `20`;
    }, 10);
  }
};

const EffectNavBarLeaveFunction = (e, idElementLi, idElementHidden) => {
  const elementLi = document.getElementById(idElementLi);
  const blockAllParagraphs = document.getElementById(idElementHidden);
  if (e.target.id === elementLi.id) {
    blockAllParagraphs.style.transform = `scaleY(0)`;
    blockAllParagraphs.style.transition = `300ms`;
    blockAllParagraphs.style.zIndex = `1`;
  }
};

const EffectNavBarHoverForParagraphFunction = (
  paragraphId,
  idElementHidden,
  idElementLi
) => {
  const blockAllParagraphs = document.getElementById(idElementHidden);
  const elementLi = document.getElementById(idElementLi);
  for (let paragraph of blockAllParagraphs.childNodes) {
    blockAllParagraphs.classList.remove("notHoverMenuNavBar");
    paragraph.style.fontSize = `12px`;
    if (paragraphId === paragraph.id) {
      paragraph.style.fontSize = `14px`;
      blockAllParagraphs.style.transform = `scale(1.25) scaleY(1) translateY(0px)`;
      blockAllParagraphs.style.transform = `300ms`;
      elementLi.style.fontSize = `16px`;
      elementLi.style.transform = `translateY(1.5px)`;
    }
  }
};

const EffectNavBarNotHoverForParagraphFunction = (
  paragraphId,
  idElementHidden,
  idElementLi
) => {
  const blockAllParagraphs = document.getElementById(idElementHidden);
  const elementLi = document.getElementById(idElementLi);
  if (paragraphId === elementLi.id) {
    blockAllParagraphs.style.transform = `scale(1) translateY(0px)`;
    blockAllParagraphs.style.transform = `300ms`;
    elementLi.style.fontSize = `18px`;
    elementLi.style.transform = `translateY(0px)`;
    blockAllParagraphs.classList.add("notHoverMenuNavBar");
  }
  for (let paragraph of blockAllParagraphs.childNodes) {
    paragraph.style.fontSize = `14px`;
    if (paragraphId === paragraph.id) {
      blockAllParagraphs.style.transform = `scale(1) translateY(0px)`;
      blockAllParagraphs.style.transform = `300ms`;
      elementLi.style.fontSize = `18px`;
      elementLi.style.transform = `translateY(0px)`;
      blockAllParagraphs.classList.add("notHoverMenuNavBar");
    }
  }
};

export {
  EffectNavBarEnterFunction,
  EffectNavBarLeaveFunction,
  EffectNavBarNotHoverForParagraphFunction,
  EffectNavBarHoverForParagraphFunction,
};
