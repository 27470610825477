import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FicheRectoBlock from "./StructureBlockFiches/FicheRectoBlock";
import FicheVersoBlock from "./StructureBlockFiches/FicheVersoBlock";
import Flotteur from "../../assets/Troubleshooting/flotteur-wc.webp";
import RobinetFlotteur from "../../assets/Troubleshooting/robinet-flotteur.webp";
import Mecanisme from "../../assets/Troubleshooting/mecansime-de-chasse-et-flotteur.webp";
import PipeWC from "../../assets/Troubleshooting/pipe-wc.webp";
import {
  Effect3dFunction,
  NotEffect3dFunction,
  EffectButton3dFunction,
  NotEffectButton3dFunction,
  Effect3dDisplayFunction,
  NotEffect3dDisplayFunction,
  EffectInfosFunction,
  NotDisplayFicheVersoFunction,
  EffectInfosFunctionMiniDevice,
} from "../../functions/EffectFicheFunction";
import {
  OnScrollSubTitleFunction,
  OnScrollBlockFicheFunction,
} from "../../functions/TroubleshootingEffectDisplayBlockAndTitle";
import { devices } from "../../style/Size";

const ContenairTitleAndBlockFiches = styled.div.attrs({
  className: "contenairTitleAndBlockFichesSection",
})`
  width: 100%;
  background-color: #eaeaea;
  padding-bottom: 80px;
  padding-top: 120px;

  @media ${devices.mobileL} {
    padding-bottom: 0px;
    padding-top: 20px;
  }
  @media (max-height: 480px) {
    padding-bottom: 0px;
    padding-top: 20px;
  }
`;

const ContenairBlockFicheAndInfosAndTitle = styled.div`
  width: 20%;

  @media ${devices.laptopS} {
    width: 40%;
  }
  @media (max-width: 684px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  @media ${devices.mobileM} {
    width: 84%;
  }
`;

const ContenairTitleSection = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const TitleSection = styled.h2`
  font-size: 30px;
  font-family: "Russo One", serif;
  color: #553322;
  text-shadow: 1px 1px 2px pink;
  letter-spacing: 2px;
  margin: 0;
  margin-left: 40px;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 50px;
  cursor: default;
  opacity: 0.5;

  @media ${devices.laptopXXS} {
    font-size: 28px;
  }
  @media (max-width: 684px) {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  @media (max-width: 520px) {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
  @media (max-height: 480px) {
    padding-bottom: 30px;
  }
`;

const ContenairBlockFiches = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media ${devices.laptopS} {
    flex-wrap: wrap;
  }
  @media (max-width: 684px) {
    flex-direction: column;
  }
`;

const BlockTitleFiche = styled.h3`
  width: 100%;
  height: 80px;
  font-size: 20px;
  font-family: "Russo One", serif;
  color: #553322;
  margin: 0;
  padding: 0;
  text-align: center;
  letter-spacing: 2px;
  opacity: 0.6;

  @media ${devices.laptopS} {
    height: 50px;
  }
  @media (max-height: 480px) {
    height: 50px;
    margin-top: 20px;
  }
`;

const ContenairBlockFicheAndInfos = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 10px;
  position: relative;

  @media ${devices.laptopS} {
    margin-bottom: 170px;
  }
  @media ${devices.tablet} {
    margin-bottom: 120px;
  }
  @media (max-height: 480px) {
    margin-bottom: 0px;
    height: 320px;
  }
`;

const ButtonDetailMiniDevice = styled.button.attrs({
  type: "button",
  value: "Voir",
  className: "buttonFicheMiniDevice",
})`
  width: 70%;
  height: 10%;
  background: radial-gradient(circle at 70% 50%, #be7a54, #553322);
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-size: 20px;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  cursor: pointer;
  margin-left: 15%;
  margin-top: 20px;
  opacity: 0.95;

  @media (max-width: 684px) {
    font-size: 18px;
  }
  @media ${devices.mobileL} {
    width: 60%;
    margin-left: 20%;
  }
`;

export default function FicheWCBlock() {
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);

  const [deviceButton1, setDeviceButton1] = useState(false);
  const [deviceButton2, setDeviceButton2] = useState(false);
  const [deviceButton3, setDeviceButton3] = useState(false);
  const [deviceButton4, setDeviceButton4] = useState(false);

  useEffect(() => {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setHover1(false);
      setHover2(false);
      setHover3(false);
      setHover4(false);
      setDeviceButton1(true);
      setDeviceButton2(true);
      setDeviceButton3(true);
      setDeviceButton4(true);
    }
  }, []);

  const BlockFicheRectoAndVerso = (
    idBlockTitleFiche,
    contentTitle,
    filtreId,
    buttonId,
    idBlockTopOrBottomWarp,
    imageSelect,
    hoverRef,
    classNameRef,
    setHoverRef,
    classNameRefButton,
    idMenuInfos,
    prix,
    textFicheContent,
    classNameMenuInfos,
    deviceButton,
    idButtonDetailMiniDevice,
    classNameButtonDetailMiniDevice,
    alt,
    idImage
  ) => {
    return (
      <ContenairBlockFicheAndInfosAndTitle>
        <BlockTitleFiche id={`${idBlockTitleFiche}`}>
          <strong>{`${contentTitle}`}</strong>
        </BlockTitleFiche>
        <ContenairBlockFicheAndInfos>
          <FicheRectoBlock
            className="blockFicheWC"
            idFiltre={`${filtreId}`}
            idButton={`${buttonId}`}
            idBlockTopOrBottomWarp={`${idBlockTopOrBottomWarp}`}
            idImage={`${idImage}`}
            image={imageSelect}
            hover={hoverRef}
            alt={alt}
            effect3d={(e) =>
              Effect3dFunction(e, `${classNameRef}`, setHoverRef, 30)
            }
            notEffect3d={(e) =>
              NotEffect3dFunction(e, `${classNameRef}`, setHoverRef, false)
            }
            effectButton3d={(e) =>
              EffectButton3dFunction(e, `${classNameRefButton}`, setHoverRef)
            }
            notEffectButton3d={() =>
              NotEffectButton3dFunction(
                `${classNameRefButton}`,
                setHoverRef,
                false
              )
            }
            effectInfos={(e) =>
              EffectInfosFunction(
                e,
                `${classNameRef}`,
                `${classNameRefButton}`,
                setHoverRef
              )
            }
            notDisplayFicheVerso={(e) =>
              NotDisplayFicheVersoFunction(
                e,
                `${classNameMenuInfos}`,
                setHoverRef,
                true
              )
            }
          />
          <FicheVersoBlock
            idBlockMenuInfos={`${idMenuInfos}`}
            prix={`${prix}`}
            textFiche={[textFicheContent]}
            effect3dDisplay={(e) =>
              Effect3dDisplayFunction(e, `${classNameMenuInfos}`, 30)
            }
            notEffect3dDisplay={(e) =>
              NotEffect3dDisplayFunction(
                e,
                `${classNameMenuInfos}`,
                setHoverRef,
                false
              )
            }
          />
          {deviceButton && (
            <ButtonDetailMiniDevice
              id={idButtonDetailMiniDevice}
              onClick={(e) =>
                EffectInfosFunctionMiniDevice(
                  e,
                  `${classNameButtonDetailMiniDevice}`
                )
              }
              // onTouchStart={(e) =>
              //   EffectInfosFunctionMiniDevice(
              //     e,
              //     `${classNameButtonDetailMiniDevice}`
              //   )
              // }
            >
              VOIR
            </ButtonDetailMiniDevice>
          )}
        </ContenairBlockFicheAndInfos>
      </ContenairBlockFicheAndInfosAndTitle>
    );
  };

  useEffect(() => {
    const blockTopOrBottomWarpOne = document.querySelector(
      "#idBlockTopOrBottomWarpOneWC"
    );
    const blockTopOrBottomWarpTwo = document.querySelector(
      "#idBlockTopOrBottomWarpTwoWC"
    );
    const blockTopOrBottomWarpThree = document.querySelector(
      "#idBlockTopOrBottomWarpThreeWC"
    );
    const blockTopOrBottomWarpFour = document.querySelector(
      "#idBlockTopOrBottomWarpFourWC"
    );

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (entries[0].target.id === "idBlockTopOrBottomWarpOneWC") {
          window.addEventListener("scroll", () => {
            OnScrollSubTitleFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour,
              "subTitleCategoriesTopOneWC",
              "subTitleCategoriesTopTwoWC",
              "subTitleCategoriesBottomOneWC",
              "subTitleCategoriesBottomTwoWC"
            );
            OnScrollBlockFicheFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour
            );
          });
        }
        if (entries[0].target.id === "idBlockTopOrBottomWarpTwoWC") {
          window.addEventListener("scroll", () => {
            OnScrollSubTitleFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour,
              "subTitleCategoriesTopOneWC",
              "subTitleCategoriesTopTwoWC",
              "subTitleCategoriesBottomOneWC",
              "subTitleCategoriesBottomTwoWC"
            );
            OnScrollBlockFicheFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour
            );
          });
        }
        if (entries[0].target.id === "idBlockTopOrBottomWarpThreeWC") {
          window.addEventListener("scroll", () => {
            OnScrollSubTitleFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour,
              "subTitleCategoriesTopOneWC",
              "subTitleCategoriesTopTwoWC",
              "subTitleCategoriesBottomOneWC",
              "subTitleCategoriesBottomTwoWC"
            );
            OnScrollBlockFicheFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour
            );
          });
        }
        if (entries[0].target.id === "idBlockTopOrBottomWarpFourWC") {
          window.addEventListener("scroll", () => {
            OnScrollSubTitleFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour,
              "subTitleCategoriesTopOneWC",
              "subTitleCategoriesTopTwoWC",
              "subTitleCategoriesBottomOneWC",
              "subTitleCategoriesBottomTwoWC"
            );
            OnScrollBlockFicheFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour
            );
          });
        } else {
          window.removeEventListener("scroll", () => {
            OnScrollSubTitleFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour,
              "subTitleCategoriesTopOneWC",
              "subTitleCategoriesTopTwoWC",
              "subTitleCategoriesBottomOneWC",
              "subTitleCategoriesBottomTwoWC"
            );
            OnScrollBlockFicheFunction(
              blockTopOrBottomWarpOne,
              blockTopOrBottomWarpTwo,
              blockTopOrBottomWarpThree,
              blockTopOrBottomWarpFour
            );
          });
        }
      }
    });
    observer.observe(blockTopOrBottomWarpOne);
    observer.observe(blockTopOrBottomWarpTwo);
    observer.observe(blockTopOrBottomWarpThree);
    observer.observe(blockTopOrBottomWarpFour);
  }, []);

  return (
    <ContenairTitleAndBlockFiches id="contenairSectionWC">
      <ContenairTitleSection>
        <TitleSection id="titleSectionWC" className="titleSectionWC">
          W-C
        </TitleSection>
      </ContenairTitleSection>
      <ContenairBlockFiches id="contenairBlockFichesWC">
        {BlockFicheRectoAndVerso(
          "subTitleCategoriesTopOneWC",
          "Flotteur",
          "idfilterAndEffect3d1WC",
          "buttonFiche1WC",
          "idBlockTopOrBottomWarpOneWC",
          Flotteur,
          hover1,
          "blockFicheWC",
          setHover1,
          "buttonFiche",
          "idBlockMenuInfos1WC",
          "80€",
          [
            "Votre ",
            <strong key="1" style={{ fontWeight: "100" }}>
              WC coule
            </strong>,
            " au niveau de la cuvette ?",
            <br key="2" />,
            "Sans aucun doute, le ",
            <strong key="3" style={{ fontWeight: "100" }}>
              flotteur
            </strong>,
            " ne remplit plus son rôle qui est de réguler le niveau d'eau du réservoir pour ainsi stopper l'eau à la juste contenance de votre ",
            <strong key="4" style={{ fontWeight: "100" }}>
              réservoir WC
            </strong>,
            ".",
            <br key="5" />,
            <br key="6" />,
            "Nos ",
            <strong key="7" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " interviennent avec rapidité et efficacité.",
          ],
          "blockMenuInfos",
          deviceButton1,
          "buttonDetailMiniDeviceOneWC",
          "buttonFicheMiniDevice",
          "flotteur pour WC"
        )}
        {BlockFicheRectoAndVerso(
          "subTitleCategoriesTopTwoWC",
          "Robinet-Flotteur",
          "idfilterAndEffect3d2WC",
          "buttonFiche2WC",
          "idBlockTopOrBottomWarpTwoWC",
          RobinetFlotteur,
          hover2,
          "blockFicheWC",
          setHover2,
          "buttonFiche",
          "idBlockMenuInfos2WC",
          "100€",
          [
            "Une pièce de sécurité indispensable pour pouvoir fermer en urgence l'",
            <strong key="1" style={{ fontWeight: "100" }}>
              arrivée d'eau
            </strong>,
            " de manière ciblée en cas de ",
            <strong key="2" style={{ fontWeight: "100" }}>
              fuite
            </strong>,
            " impromptue. Cela évite en de tels cas de devoir fermer l'",
            <strong key="3" style={{ fontWeight: "100" }}>
              arrivée d'eau générale
            </strong>,
            ". Vous pouvez donc continuer à profiter des arrivées d'eau dans toutes les autres pièces de votre logement.",
            <br key="4" />,
            <br key="5" />,
            " Nos ",
            <strong key="6" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " interviennent avec rapidité et efficacité.",
          ],
          "blockMenuInfos",
          deviceButton2,
          "buttonDetailMiniDeviceTwoWC",
          "buttonFicheMiniDevice",
          "robinet-flotteur pour WC"
        )}
        {BlockFicheRectoAndVerso(
          "subTitleCategoriesBottomOneWC",
          "Mécanisme Complet",
          "idfilterAndEffect3d3WC",
          "buttonFiche3WC",
          "idBlockTopOrBottomWarpThreeWC",
          Mecanisme,
          hover3,
          "blockFicheWC",
          setHover3,
          "buttonFiche",
          "idBlockMenuInfos3WC",
          "100€",
          [
            <strong key="1" style={{ fontWeight: "100" }}>
              Mécanisme de chasse complet
            </strong>,
            " qui comprend le ",
            <strong key="2" style={{ fontWeight: "100" }}>
              flotteur
            </strong>,
            ", qui sert à la régulation du niveau d'eau du réservoir, et le ",
            <strong key="3" style={{ fontWeight: "100" }}>
              poussoir
            </strong>,
            ", pièce qui se trouve sur la cuvette WC et qui permet la vidange des eaux usées.",
            <br key="4" />,
            <br key="5" />,
            " Nos ",
            <strong key="6" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " interviennent avec rapidité et efficacité.",
          ],
          "blockMenuInfos",
          deviceButton3,
          "buttonDetailMiniDeviceThreeWC",
          "buttonFicheMiniDevice",
          "mécanisme complet pour WC"
        )}
        {BlockFicheRectoAndVerso(
          "subTitleCategoriesBottomTwoWC",
          "Pipe W-C",
          "idfilterAndEffect3d4WC",
          "buttonFiche4WC",
          "idBlockTopOrBottomWarpFourWC",
          PipeWC,
          hover4,
          "blockFicheWC",
          setHover4,
          "buttonFiche",
          "idBlockMenuInfos4WC",
          "150€",
          [
            "Des ",
            <strong key="1" style={{ fontWeight: "100" }}>
              gouttes coulent
            </strong>,
            ", ou pire, de l'",
            <strong key="2" style={{ fontWeight: "100" }}>
              eau fuit de l'arrière de vos WC
            </strong>,
            " ?",
            <br key="3" />,
            "La cause est certainement due à une ",
            <strong key="4" style={{ fontWeight: "100" }}>
              pipe WC fendue
            </strong>,
            ", ou bien que la lèvre n'assure plus une totale étanchéité lors des évacuations, sinon les joins intermédiaires de la ",
            <strong key="5" style={{ fontWeight: "100" }}>
              cuvette réservoir
            </strong>,
            " qui n'ont plus une intègre rétention.",
            <br key="6" />,
            <br key="7" />,
            " Nos ",
            <strong key="8" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " interviennent avec rapidité et efficacité.",
          ],
          "blockMenuInfos",
          deviceButton4,
          "buttonDetailMiniDeviceFourWC",
          "buttonFicheMiniDevice",
          "pipe WC",
          "idImagePipeWC"
        )}
      </ContenairBlockFiches>
    </ContenairTitleAndBlockFiches>
  );
}
