import React from "react";
import { styled } from "styled-components";
import { devices } from "../../../style/Size";

const BlockMenuInfos = styled.div.attrs({
  className: "blockMenuInfos",
})`
  width: 100%;
  height: 400px;
  border-radius: 10px;
  perspective: 1000px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(-90deg);
  transition-duration: 300ms;
  box-shadow: 0 0 8px black;
  cursor: default;

  @media (max-height: 480px) {
    height: 240px;
  }
`;

const FilterFiche = styled.div`
  width: 100%;
  height: 400px;
  opacity: 0.4;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  background: radial-gradient(circle at 50% 50%, #a0bedf, #1c3377);
  z-index: 10;

  @media (max-height: 480px) {
    height: 240px;
  }
`;

const MenuInfos = styled.div.attrs({
  className: "menuInfos",
})`
  width: 100%;
  height: 304px;
  overflow: hidden;
  position: absolute;
  top: 60px;
  z-index: 100;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  @media ${devices.mobileL} {
  }
  @media (max-height: 480px) {
    height: 160px;
    top: 24%;
  }
`;

const Infos = styled.div.attrs({
  className: "infos",
})`
  width: 84%;
  font-size: 18px;
  color: #553322;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  font-weight: 100;
  margin: auto;
  transition-duration: 10ms;
`;

const Prix = styled.div.attrs({
  className: "prix",
})`
  width: 200px;
  background: radial-gradient(circle at 80% 50%, #da6060, #c24a4a);
  font-size: 20px;
  color: #ffffff;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  font-weight: 100;
  text-align: center;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  z-index: 12;
  top: 0;
  margin-left: -20px;
  transform: rotate(-14deg) scale(1.8);
  opacity: 0;
  transition-delay: 400ms;
  transition-duration: 500ms;

  @media ${devices.mobileM} {
    font-size: 18px;
    width: 200px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  @media (max-height: 480px) {
    font-size: 16px;
    width: 180px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;

export default function FicheVersoBlock(props) {
  return (
    <BlockMenuInfos
      id={props.idBlockMenuInfos}
      onMouseMove={props.effect3dDisplay}
      onMouseLeave={props.notEffect3dDisplay}
    >
      <Prix>À partir de {props.prix} !</Prix>
      <FilterFiche></FilterFiche>
      <MenuInfos>
        <Infos>{props.textFiche}</Infos>
      </MenuInfos>
    </BlockMenuInfos>
  );
}
