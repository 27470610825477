import React, { useEffect } from "react";
import styled from "styled-components";
import HeaderBlog from "../../components/Header/HeaderBlog";
import Footer from "../../components/Footer";
import { NavBarDisplayFunction } from "../../functions/NavBarDisplayScrollFunction";
import { devices } from "../../style/Size";

const ContenairMain = styled.main`
  width: 94%;
  /* background-color: #ffffff; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 134px;
  margin-bottom: 200px;

  @media ${devices.laptopXXS} {
    margin-top: 114px;
    margin-bottom: 120px;
  }
  @media ${devices.mobileL} {
    margin-top: 100px;
    margin-bottom: 80px;
  }
  @media (max-height: 480px) {
    margin-bottom: 80px;
  }
`;

const TitleArticle = styled.h1`
  font-size: 34px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  padding-top: 10px;

  @media ${devices.tablet} {
    font-size: 32px;
  }
  @media ${devices.mobileL} {
    font-size: 30px;
  }
  @media ${devices.mobileM} {
    font-size: 28px;
  }
`;

const SubTitleArticle = styled.h2`
  font-size: 28px;
  font-family: "Russo One", sans-serif;
  color: #553322;

  @media ${devices.tablet} {
    font-size: 26px;
  }
  @media ${devices.mobileL} {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
`;

const TextArticle = styled.h2`
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  text-align: justify;
  margin-bottom: 40px;

  @media ${devices.mobileL} {
    margin-bottom: 50px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

export default function WaterHeaterMaintenance() {
  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction(
      "headerBlogContenair",
      "comment-entretenir-sa-climatisation"
    );
  });

  return (
    <>
      <HeaderBlog />
      <ContenairMain>
        <TitleArticle>
          Guide d'Entretien des Climatiseurs : Assurez-vous d'une Fraîcheur
          Optimale
        </TitleArticle>
        <TextArticle>
          Les climatiseurs sont des alliés indispensables pour maintenir un
          climat intérieur confortable, surtout pendant les mois chauds de
          l'année. Cependant, pour garantir leur efficacité à long terme et
          éviter des pannes coûteuses, un entretien régulier est essentiel.
          Suivez ce guide pratique pour assurer que votre climatisation
          fonctionne de manière optimale, économise de l'énergie et a une durée
          de vie prolongée.
        </TextArticle>
        <section>
          <SubTitleArticle>Nettoyage des Filtres</SubTitleArticle>
          <TextArticle>
            Les filtres de votre climatiseur jouent un rôle crucial dans la
            qualité de l'air intérieur. Avec le temps, ils accumulent la
            poussière et d'autres particules, ce qui peut entraver le flux d'air
            et réduire l'efficacité de l'appareil. Nettoyez ou remplacez les
            filtres tous les un à trois mois pour maintenir une circulation
            d'air optimale.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Vérification des Connexions électriques
          </SubTitleArticle>
          <TextArticle>
            Assurez-vous que toutes les connexions électriques de votre
            climatiseur sont sécurisées. Les connexions lâches peuvent entraîner
            des problèmes de fonctionnement et même endommager des composants
            électriques. Éteignez l'appareil avant de vérifier les connexions
            et, si nécessaire, resserrez-les avec précaution.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Nettoyage des Bobines</SubTitleArticle>
          <TextArticle>
            Les bobines du condenseur et de l'évaporateur accumulent également
            de la saleté au fil du temps. Un nettoyage régulier avec un
            aspirateur ou une brosse douce peut améliorer l'efficacité du
            système. Pour un nettoyage plus approfondi, envisagez de faire appel
            à un professionnel.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Vérification du Niveau de Réfrigérant
          </SubTitleArticle>
          <TextArticle>
            Le niveau de réfrigérant de votre climatiseur doit être surveillé
            régulièrement. Si le niveau est trop bas, cela peut indiquer une
            fuite, ce qui peut entraîner une diminution de la performance. Si
            vous soupçonnez une fuite, contactez un technicien qualifié pour une
            réparation.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Calibrage du Thermostat</SubTitleArticle>
          <TextArticle>
            Un thermostat mal calibré peut entraîner un fonctionnement excessif
            ou insuffisant de votre climatiseur. Vérifiez régulièrement la
            précision de votre thermostat et ajustez-le si nécessaire. Si vous
            utilisez un thermostat programmable, assurez-vous que les paramètres
            correspondent à vos besoins actuels.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Élagage des Plantes Autour de l'Unité Extérieure
          </SubTitleArticle>
          <TextArticle>
            Si votre climatiseur a une unité extérieure, assurez-vous qu'il n'y
            a pas de plantes ou d'obstructions à proximité. Un espace dégagé
            permet une circulation d'air adéquate et empêche la surchauffe du
            système.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Conclusion</SubTitleArticle>
          <TextArticle>
            Un entretien régulier de votre climatiseur peut non seulement vous
            faire économiser de l'argent à long terme, mais aussi assurer un
            environnement intérieur frais et sain. En suivant ces étapes
            simples, vous contribuerez à prolonger la durée de vie de votre
            appareil tout en maintenant un confort optimal dans votre maison ou
            votre bureau. Si vous n'êtes pas sûr de la meilleure façon
            d'entretenir votre climatisation, n'hésitez pas à consulter un
            professionnel pour des conseils personnalisés. L'Entreprise Navarro
            située à Marseille saura réaliser cette étape avec succès. Appelez
            nous pour plus de renseignements.
          </TextArticle>
        </section>
      </ContenairMain>
      <Footer marginTop="180px" backgroundColor="#3c2114" />
    </>
  );
}
