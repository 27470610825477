import React, { useEffect } from "react";
import styled from "styled-components";
import HeaderBlog from "../../components/Header/HeaderBlog";
import Footer from "../../components/Footer";
import { NavBarDisplayFunction } from "../../functions/NavBarDisplayScrollFunction";
import { devices } from "../../style/Size";

const ContenairMain = styled.main`
  width: 94%;
  /* background-color: #ffffff; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 134px;
  margin-bottom: 200px;

  @media ${devices.laptopXXS} {
    margin-top: 114px;
    margin-bottom: 120px;
  }
  @media ${devices.mobileL} {
    margin-top: 100px;
    margin-bottom: 80px;
  }
  @media (max-height: 480px) {
    margin-bottom: 80px;
  }
`;

const TitleArticle = styled.h1`
  font-size: 34px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  padding-top: 10px;

  @media ${devices.tablet} {
    font-size: 32px;
  }
  @media ${devices.mobileL} {
    font-size: 30px;
  }
  @media ${devices.mobileM} {
    font-size: 28px;
  }
`;

const SubTitleArticle = styled.h2`
  font-size: 28px;
  font-family: "Russo One", sans-serif;
  color: #553322;

  @media ${devices.tablet} {
    font-size: 26px;
  }
  @media ${devices.mobileL} {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
`;

const TextArticle = styled.h2`
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  text-align: justify;
  margin-bottom: 40px;

  @media ${devices.mobileL} {
    margin-bottom: 50px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

export default function MaPrimeAdapt() {
  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction("headerBlogContenair", "ma-prime-adapt");
  });

  return (
    <>
      <HeaderBlog />
      <ContenairMain>
        <TitleArticle>
          <strong>MaPrimeAdap't</strong> : Comment Bénéficier d'Aides pour vos
          Travaux de Plomberie
        </TitleArticle>
        <TextArticle>
          Lorsque des problèmes de plomberie surviennent à la maison, il est
          essentiel d'intervenir rapidement pour éviter des dégâts plus
          importants. Cependant, les coûts associés à ces réparations peuvent
          parfois représenter une charge financière conséquente. Heureusement,
          il existe des programmes gouvernementaux tels que MaPrimeAdap't qui
          peuvent alléger le fardeau financier et encourager les propriétaires à
          entreprendre des travaux de rénovation.
        </TextArticle>
        <section>
          <SubTitleArticle>
            Qu'est-ce que <strong>MaPrimeAdap't </strong>?
          </SubTitleArticle>
          <TextArticle>
            MaPrimeAdap't est une aide financière mise en place par l'État pour
            soutenir les propriétaires dans leurs projets de rénovation visant à
            améliorer la performance énergétique de leur logement. Si vous
            envisagez des travaux de plomberie pour réparer des fuites,
            remplacer des installations vétustes ou améliorer l'efficacité de
            votre système, MaPrimeAdap't pourrait vous offrir un soutien
            financier précieux.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Comment en bénéficier pour des
            <strong> travaux de plomberie </strong>?
          </SubTitleArticle>
          <TextArticle>
            1. Éligibilité : <br />
            <br />
            Vérifiez votre éligibilité en fonction de vos revenus et de la
            nature des travaux à entreprendre. MaPrimeAdap't est accessible à un
            large éventail de propriétaires, sous réserve de certaines
            conditions.
            <br />
            <br />
            <br />
            2. Devis : <br />
            <br />
            Obtenez des devis détaillés pour vos travaux de plomberie. Ces devis
            seront nécessaires pour évaluer le montant de l'aide financière que
            vous pourriez recevoir. <br />
            <br />
            <br />
            3. Demande :
            <br />
            <br />
            Effectuez votre demande en ligne sur le site officiel de
            MaPrimeAdap't. Assurez-vous de fournir toutes les informations
            requises et les pièces justificatives, y compris les devis de
            plomberie.
            <br />
            <br />
            4. Validation :
            <br />
            <br />
            Votre demande sera examinée, et si elle est acceptée, vous recevrez
            une notification de validation. Cela déclenchera le versement de
            l'aide financière pour soutenir vos travaux.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Quels travaux de plomberie sont couverts ?
          </SubTitleArticle>
          <TextArticle>
            MaPrimeAdap't couvre une variété de travaux visant à améliorer
            l'efficacité énergétique d'une habitation. Cela inclut la rénovation
            de systèmes de chauffage, la réparation de fuites, le remplacement
            de canalisations obsolètes, et d'autres améliorations liées à la
            plomberie.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Conclusion</SubTitleArticle>
          <TextArticle>
            Si vous envisagez des travaux de plomberie et que vous cherchez des
            moyens de financer ces rénovations, MaPrimeAdap't peut être une
            solution avantageuse. En plus d'améliorer le confort de votre
            maison, vous contribuez également à la transition énergétique.
            N'hésitez pas à explorer cette opportunité et à investir dans la
            durabilité de votre logement. Appelez nous pour plus de
            renseignements.
          </TextArticle>
        </section>
      </ContenairMain>
      <Footer marginTop="180px" backgroundColor="#3c2114" />
    </>
  );
}
