import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import MaPrimeAdaptImage from "../assets/Blog/MaPrimeAdapt.png";
import Adoucisseur from "../assets/Blog/adoucisseur.jpg";
import Canalisation from "../assets/Blog/canalisation.webp";
import ElectricWaterHeater from "../assets/Blog/chauffe-eau-electrique.webp";
import WHnoReservoir from "../assets/Blog/chauffe-eau-sans-reservoir.jpg";
import ThermodynamicWH from "../assets/Blog/chauffe-eau-thermodynamique.jpg";
import FuiteWaterHidden from "../assets/Blog/fuite-eau-maison.webp";
import Radiateur from "../assets/Blog/radiateur.webp";
import Toilettes from "../assets/Blog/toilettes-blanches.jpg";
import TuyauxPlomberie from "../assets/Blog/tuyaux-plomberie.png";
import Climatisation from "../assets/Installation/m-climatisation.webp";
import PompeChaleur from "../assets/Installation/m-pompe-a-chaleur.webp";
import RechercheFuite from "../assets/Troubleshooting/camera-thermique.webp";
import BlockBlog from "../components/BodyBlog/BlockBlog";
import Footer from "../components/Footer";
import HeaderBlog from "../components/Header/HeaderBlog";
import { PageTransitionContext } from "../contexts/PageTransition";
import { ButtonBlogEffectScaleClickFunction } from "../functions/ButtonClickEffectScale";
import { NotEffectGlowButtonFunction } from "../functions/EffectGlowButtonsFunction";
import { NavBarDisplayFunction } from "../functions/NavBarDisplayScrollFunction";
import { OffsetTopFunction } from "../functions/OffsetTopFunction";
import { devices } from "../style/Size";

const ContenairMain = styled.main`
  width: 100%;
  position: relative;
`;

const P = styled.p`
  font-size: 40px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 200px;

  @media ${devices.laptopM} {
    font-size: 36px;
  }
  @media ${devices.laptopS} {
    font-size: 32px;
  }
  @media ${devices.laptopXXS} {
    font-size: 30px;
    margin-bottom: 160px;
  }
  @media (max-width: 664px) {
    font-size: 28px;
  }
  @media (max-width: 554px) {
    font-size: 26px;
  }
  @media (max-width: 524px) {
    font-size: 24px;
  }
  @media ${devices.mobileL} {
    width: 90%;
    margin-left: 5%;
    font-size: 22px;
  }
  @media (max-width: 424px) {
    margin-top: 60px;
    margin-bottom: 120px;
  }
  @media ${devices.mobileM} {
    font-size: 20px;
  }
`;

export default function Blog() {
  const { displayPageTransition } = useContext(PageTransitionContext);
  const navigate = useNavigate();

  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction("headerBlogContenair", "/blog");

    const blockCategories = document.querySelectorAll(".contenairBlockBlog");
    const screenY = window.scrollY + window.innerHeight * 0.9;
    for (let i = 0; i < blockCategories.length; i++) {
      if (OffsetTopFunction(blockCategories[i]) - screenY < -90) {
        blockCategories[i].style.transform = "translateY(0px) scale(1)";
        blockCategories[i].style.transition = "all 500ms";
      }
      if (OffsetTopFunction(blockCategories[i]) - screenY > -90) {
        blockCategories[i].style.transform = "translateY(60px) scale(1.05)";
        blockCategories[i].style.transition = "all 500ms";
      }
    }
  });

  const navigateLink = (e, id, route) => {
    const button = document.querySelector(`#${id}`);

    NotEffectGlowButtonFunction("buttonSectionBlog");
    ButtonBlogEffectScaleClickFunction(button);
    button.style.pointerEvents = "none";
    button.style.cursor = "auto";

    if (e.target.id === button.id) {
      if (window.innerWidth > 991) {
        setTimeout(() => {
          displayPageTransition("pageTransitionEffect");
        }, 500);
        setTimeout(() => {
          displayPageTransition("pageTransitionNotEffect");
          window.scrollTo(0, 0);
          navigate(`${route}`);
        }, 1300);
      }
      if (window.innerWidth > 480 && window.innerWidth <= 991) {
        setTimeout(() => {
          displayPageTransition("pageTransitionEffect");
        }, 500);
        setTimeout(() => {
          displayPageTransition("pageTransitionNotEffect");
          window.scrollTo(0, 0);
          navigate(`${route}`);
        }, 1100);
      }
      if (window.innerWidth < 480) {
        setTimeout(() => {
          displayPageTransition("pageTransitionEffect");
        }, 500);
        setTimeout(() => {
          displayPageTransition("pageTransitionNotEffect");
          window.scrollTo(0, 0);
          navigate(`${route}`);
        }, 1000);
      }
    }
  };

  return (
    <>
      <HeaderBlog />
      <ContenairMain>
        <section>
          <BlockBlog
            idButton="button1"
            title="Comment entretenir son chauffe-eau ?"
            src={ElectricWaterHeater}
            alt="Chauffe-eau électrique blanc mural oval sur mur teinte bleue"
            text={[
              " Que ce soit du détartrage régulier jusque l’entretien du groupe de sécurité, votre chauffe-eau comporte plusieurs étapes indispensables à remplir pour son bon entretien. Ce dernier, parfois imposé par la loi, vous permet de profiter d’un appareil plus performant tout en lui conférant une durée de vie supérieure.",
              <br key="1" />,
              <br key="2" />,
              "Comment détartrer un chauffe-eau électrique ?",
              <br key="3" />,
              <br key="4" />,
              "Voici l’opération essentielle à réaliser pour un entretien efficace d’un chauffe-eau électrique. Au fur et à mesure des mois et des années qui passent, le calcaire contenu dans l’eau courante qui circule ou qui stagne à l’intérieur de votre cumulus s’accumule et altère le fonctionnement naturel de votre équipement (voir plus bas). Dans la plupart des situations, l’opération de détartrage d’un chauffe-eau électrique se constitue de ces étapes...",
            ]}
            onClick={(e) =>
              navigateLink(
                e,
                "button1",
                "/blog/comment-entretenir-son-chauffe-eau"
              )
            }
            // onTouchStart={(e) =>
            //   navigateLink(
            //     e,
            //     "button1",
            //     "/blog/comment-entretenir-son-chauffe-eau"
            //   )
            // }
          />
          <BlockBlog
            idButton="button2"
            title="Comment prendre soin de ses canalisations ?"
            src={Canalisation}
            alt="Canalisation en acier"
            text={[
              "Les canalisations sont les artères invisibles de nos foyers, travaillant silencieusement pour assurer un flux constant d'eau propre. Cependant, même les systèmes de plomberie les plus fiables nécessitent un entretien régulier pour rester en parfait état de fonctionnement. Dans cet article, nous explorerons les meilleures pratiques pour l'entretien des canalisations, des conseils professionnels qui vous aideront à éviter les maux de tête coûteux liés aux obstructions et aux fuites.",
              <br key="1" />,
              <br key="2" />,
              "Prévention des Obstructions",
              <br key="3" />,
              <br key="4" />,
              "Les obstructions dans les canalisations sont souvent à l'origine de problèmes majeurs. Pour éviter cela, adoptez une approche proactive en installant des filtres dans les éviers et les douches pour retenir les débris solides. Les cheveux, les restes de nourriture et autres résidus peuvent rapidement s'accumuler, formant des bouchons redoutables...",
            ]}
            onClick={(e) =>
              navigateLink(
                e,
                "button2",
                "/blog/comment-prendre-soin-de-ses-canalisations"
              )
            }
          />
          <BlockBlog
            idButton="button3"
            title="Comment entretenir sa climatisation ?"
            src={Climatisation}
            alt="Climatisation murale blanche avec son groupe système"
            text={[
              "Les climatiseurs sont des alliés indispensables pour maintenir un climat intérieur confortable, surtout pendant les mois chauds de l'année. Cependant, pour garantir leur efficacité à long terme et éviter des pannes coûteuses, un entretien régulier est essentiel. Suivez ce guide pratique pour assurer que votre climatisation fonctionne de manière optimale, économise de l'énergie et a une durée de vie prolongée.",
              <br key="1" />,
              <br key="2" />,
              "Nettoyage des Filtres",
              <br key="3" />,
              <br key="4" />,
              "Les filtres de votre climatiseur jouent un rôle crucial dans la qualité de l'air intérieur. Avec le temps, ils accumulent la poussière et d'autres particules, ce qui peut entraver le flux d'air et réduire l'efficacité de l'appareil...",
            ]}
            onClick={(e) =>
              navigateLink(
                e,
                "button3",
                "/blog/comment-entretenir-sa-climatisation"
              )
            }
          />
          <BlockBlog
            idButton="button4"
            title="Comment désembouer ses radiateurs ?"
            src={Radiateur}
            alt="Radiateur mural blanc"
            text={[
              "Avec l'arrivée des saisons froides, il est crucial de s'assurer que votre système de chauffage fonctionne de manière optimale. L'un des problèmes courants qui peuvent affecter l'efficacité de votre radiateur est l'accumulation de boue et de débris, un phénomène connu sous le nom de ",
              <strong key="1" style={{ fontWeight: "100" }}>
                désembouage
              </strong>,
              ".",
              <br key="2" />,
              <br key="3" />,
              "Comprendre le Désembouage de Radiateur",
              <br key="4" />,
              <br key="5" />,
              "Le désembouage de radiateur est un processus essentiel pour maintenir la performance de votre système de chauffage central. Au fil du temps, des particules de boue, de la rouille et d'autres impuretés peuvent s'accumuler dans le circuit d'eau du radiateur. Cela peut entraîner une diminution de l'efficacité du chauffage, des bruits étranges et même des pannes...",
            ]}
            onClick={(e) =>
              navigateLink(e, "button4", "/blog/desembouage-radiateur")
            }
          />
          <BlockBlog
            idButton="button5"
            title="Détection de fuites par caméra"
            src={RechercheFuite}
            alt="Caméra portable de détection de fuites"
            text={[
              "Les fuites d'eau peuvent rapidement se transformer en un problème majeur pour les propriétaires, provoquant des dommages coûteux et des perturbations importantes. Heureusement, la technologie moderne offre des solutions avancées pour résoudre ce problème. La ",
              <strong key="1" style={{ fontWeight: "100" }}>
                détection de fuites d'eau par caméra
              </strong>,
              " est l'une de ces innovations, offrant une approche précise et efficace pour identifier et résoudre les problèmes liés aux fuites d'eau.",
              <br key="2" />,
              <br key="3" />,
              "Localisation Précise des Fuites",
              <br key="4" />,
              <br key="5" />,
              "La ",
              <strong key="1" style={{ fontWeight: "100" }}>
                détection de fuites d'eau par caméra
              </strong>,
              " permet aux plombiers d'identifier rapidement et avec précision l'emplacement exact des fuites...",
            ]}
            onClick={(e) =>
              navigateLink(
                e,
                "button5",
                "/blog/les-avantages-de-la-detection-de-fuites-par-camera"
              )
            }
          />
        </section>
        <section>
          <BlockBlog
            idButton="button6"
            title="Les signes cachés des fuites d'eau"
            src={FuiteWaterHidden}
            alt="Robinet en acier qui coule"
            text={[
              "Les fuites d'eau cachées peuvent être des problèmes insidieux provoquant des dégâts coûteux avant même que vous ne vous en rendiez compte. Il est crucial de repérer les signes avant-coureurs dès le début. Dans cet article, nous examinerons les indicateurs subtils qui peuvent indiquer une fuite d'eau cachée dans votre maison.",
              <br key="1" />,
              <br key="2" />,
              "Taches d'",

              <strong key="1" style={{ fontWeight: "100" }}>
                humidité persistantes
              </strong>,
              ".",
              <br key="2" />,
              <br key="3" />,
              "Les taches d'",
              <strong key="1" style={{ fontWeight: "100" }}>
                humidité
              </strong>,
              " sur les murs ou les plafonds peuvent signaler une fuite d'eau cachée. Soyez attentif à tout changement de couleur ou d'aspect, car cela pourrait indiquer un problème sous-jacent...",
            ]}
            onClick={(e) =>
              navigateLink(e, "button6", "/blog/les-signes-des-fuites-d'eau")
            }
          />
        </section>
        <section>
          <BlockBlog
            idButton="button7"
            title="Les adoucisseurs d'eau"
            src={Adoucisseur}
            alt="Adoucisseur d'eau"
            text={[
              "L'eau dure peut poser de sérieux problèmes dans nos foyers. Les dépôts de ",
              <strong key="1" style={{ fontWeight: "100" }}>
                calcaire
              </strong>,
              " peuvent endommager vos appareils ménagers, réduire l'efficacité de vos systèmes de chauffage, et même affecter la qualité de votre peau et de vos cheveux. C'est là qu'interviennent les ",
              <strong key="2" style={{ fontWeight: "100" }}>
                adoucisseurs d'eau
              </strong>,
              ". Ces dispositifs ingénieux peuvent transformer votre eau dure en une ressource plus douce et plus bénéfique. Découvrez comment ces alliés de plomberie peuvent améliorer votre quotidien.",
              <br key="3" />,
              <br key="4" />,
              "Les Fondements des Adoucisseurs d'Eau",
              <br key="5" />,
              <br key="6" />,
              "Les ",
              <strong key="7" style={{ fontWeight: "100" }}>
                adoucisseurs d'eau
              </strong>,
              " sont des appareils conçus pour éliminer les minéraux tels que le ",
              <strong key="8" style={{ fontWeight: "100" }}>
                calcium
              </strong>,
              " et le ",
              <strong key="9" style={{ fontWeight: "100" }}>
                magnésium
              </strong>,
              " responsables de la dureté de l'eau...",
            ]}
            onClick={(e) =>
              navigateLink(e, "button7", "/blog/les-adoucisseurs-d-eau")
            }
          />
        </section>
        <section>
          <BlockBlog
            idButton="button8"
            title="MaPrimeAdap't"
            src={MaPrimeAdaptImage}
            backgroundColor="white"
            alt="Logo Ma Prime Adap't"
            text={[
              "Lorsque des problèmes de plomberie surviennent à la maison, il est essentiel d'intervenir rapidement pour éviter des dégâts plus importants. Cependant, les coûts associés à ces réparations peuvent parfois représenter une charge financière conséquente. Heureusement, il existe des programmes gouvernementaux tels que MaPrimeAdap't qui peuvent alléger le fardeau financier et encourager les propriétaires à entreprendre des travaux de rénovation.",
              <br key="1" />,
              <br key="2" />,
              "Qu'est-ce que ",
              <strong style={{ fontWeight: "100" }}>MaPrimeAdap't</strong>,
              " ?",
              <br key="3" />,
              <br key="4" />,
              "MaPrimeAdap't est une aide financière mise en place par l'État pour soutenir les propriétaires dans leurs projets de rénovation visant à améliorer la performance énergétique de leur logement.",
            ]}
            onClick={(e) => navigateLink(e, "button8", "/blog/ma-prime-adapt")}
          />
        </section>
        <section>
          <BlockBlog
            idButton="button9"
            title="Pompes à Chaleur"
            src={PompeChaleur}
            backgroundColor="white"
            alt="Pompe à chaleur"
            text={[
              "Dans le monde de la plomberie moderne, les pompes à chaleur ont émergé comme une alternative révolutionnaire pour chauffer nos foyers. Contrairement aux systèmes de chauffage traditionnels, les pompes à chaleur exploitent les ressources naturelles pour offrir une efficacité énergétique exceptionnelle, tout en réduisant l'empreinte carbone de nos habitations. Plongeons dans le monde fascinant des pompes à chaleur et découvrons leurs conditions, avantages et bien plus encore.",
              <br key="1" />,
              <br key="2" />,
            ]}
            onClick={(e) =>
              navigateLink(e, "button9", "/blog/les-pompes-a-chaleur")
            }
          />
        </section>
        <section>
          <BlockBlog
            idButton="button10"
            title="Les différents types de tuyaux en plomberie"
            src={TuyauxPlomberie}
            backgroundColor="white"
            alt="Tuyau de plomberie"
            text={[
              "Lorsque vous envisagez un projet de plomberie, choisir le bon type de tuyaux est essentiel pour assurer la durabilité et l'efficacité de votre système. Il existe plusieurs matériaux de tuyauterie disponibles sur le marché, chacun avec ses propres avantages et inconvénients. Dans cet article, nous examinerons les différents types de tuyaux couramment utilisés en plomberie et les avantages qu'ils offrent.",
              <br key="1" />,
              <br key="2" />,
            ]}
            onClick={(e) =>
              navigateLink(
                e,
                "button10",
                "/blog/les-differents-tuyaux-en-plomberie"
              )
            }
          />
        </section>
        <section>
          <BlockBlog
            idButton="button11"
            title="Les chauffes-eau thermodynamiques"
            src={ThermodynamicWH}
            backgroundColor="white"
            alt="Chauffe-eau thermodynamique blanc"
            text={[
              "Les chauffe-eau thermodynamiques sont une solution innovante et efficace pour chauffer l'eau tout en réduisant la consommation d'énergie. Dans cet article, nous allons explorer en profondeur le fonctionnement, les avantages, les inconvénients et les considérations importantes concernant ces systèmes.",
              <br key="1" />,
              <br key="2" />,
            ]}
            onClick={(e) =>
              navigateLink(
                e,
                "button11",
                "/blog/les-chauffes-eau-thermodynamiques"
              )
            }
          />
        </section>
        <section>
          <BlockBlog
            idButton="button12"
            title="Que Faire en Cas de Toilettes Qui Refoulent"
            src={Toilettes}
            backgroundColor="white"
            alt="WC blanc"
            text={[
              "Le refoulement des toilettes peut être un problème alarmant et inconfortable dans une maison. Lorsque les toilettes refoulent, il est important de prendre des mesures immédiates pour éviter tout dégât supplémentaire et rétablir le bon fonctionnement du système de plomberie. Dans cet article, nous allons examiner les étapes à suivre en cas de toilettes qui refoulent.",
              <br key="1" />,
              <br key="2" />,
            ]}
            onClick={(e) =>
              navigateLink(
                e,
                "button12",
                "/blog/que-faire-en-cas-de-toilettes-qui-refoulent"
              )
            }
          />
        </section>
        <section>
          <BlockBlog
            idButton="button13"
            title="Les Avantages des Systèmes de Chauffage Sans Réservoir"
            src={WHnoReservoir}
            backgroundColor="white"
            alt="Chauffe-eau sans réservoir blanc"
            text={[
              "Les systèmes de chauffage sans réservoir, également connus sous le nom de chauffe-eau sans réservoir ou chauffe-eau instantané, gagnent en popularité en tant qu'alternative efficace aux chauffe-eau traditionnels avec réservoir. Ces systèmes offrent plusieurs avantages distincts qui les rendent attrayants pour les propriétaires soucieux de l'efficacité énergétique et de la commodité. Dans cet article, nous examinerons en détail les principaux avantages des systèmes de chauffage sans réservoir.",
              <br key="1" />,
              <br key="2" />,
            ]}
            onClick={(e) =>
              navigateLink(
                e,
                "button13",
                "/blog/les-chauffes-eau-sans-reservoir"
              )
            }
          />
        </section>
        <P>
          D'autres articles arrivent{" "}
          <span style={{ whiteSpace: "nowrap" }}>bientôt !</span>
        </P>
      </ContenairMain>
      <Footer marginTop="200px" backgroundColor="#3c2114" />
    </>
  );
}
