import React, { useState } from "react";
import styled from "styled-components";
import ContenairPicture from "./ContenairPicture";
import ButtonCloseAndIcon from "./ButtonCloseAndIcon";
import BlockNumberPhone from "./BlockNumberPhone";
import { devices } from "../../style/Size";

const DivModal = styled.div.attrs({
  id: "modalUrgenceDisplay",
})`
  width: 440px;
  height: 340px;
  background: linear-gradient(to right, red, 20%, #cf3131, 88%, #b80606);
  border-radius: 10px;
  position: fixed;
  top: 68px;
  left: 22px;
  z-index: 500;
  transform: translateX(-120%);

  @media (max-width: 490px) {
    width: 90%;
    left: 5%;
  }
  @media (max-width: 375px) {
    height: 400px;
  }
  @media (max-width: 290px) {
    height: 430px;
  }
  @media (max-width: 274px) {
    height: 450px;
  }
`;

const Div = styled.div`
  width: 100%;
  text-align: center;
`;

const DivP = styled.div.attrs({
  className: "divP",
})`
  opacity: 1;
`;

const P = styled.p`
  width: calc(100% - 20px);
  font-size: 30px;
  font-family: "Russo One", sans-serif;
  margin-top: 26px;
  margin-left: 20px;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 27px;
  }
  @media (max-width: 490px) {
    font-size: 25px;
    margin-top: 38px;
  }
  @media (max-width: 404px) {
    font-size: 22px;
  }
  @media (max-width: 375px) {
    width: 100%;
    margin-left: 0;
    margin-top: 90px;
    font-size: 21px;
  }
`;

const P2 = styled.p`
  width: 100%;
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  margin-top: 0px;
  color: #ffffff;
  text-align: center;

  @media (max-width: 490px) {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 28px;
  }
  @media (max-width: 404px) {
    margin-top: 38px;
  }
  @media (max-width: 375px) {
    margin-top: 20px;
  }
  @media ${devices.mobileXS} {
    padding-bottom: 16px;
    font-size: 15px;
  }
`;

const P3 = styled.p`
  width: 100%;
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  position: relative;
  top: 8x;
  color: #ffffff;
  text-align: center;

  @media (max-width: 490px) {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
  }
  @media ${devices.mobileXS} {
    padding-bottom: 16px;
  }
`;

const P4 = styled.p`
  width: 100%;
  font-size: 24px;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  text-align: center;
  margin-top: 0px;

  @media (max-width: 768px) {
    font-size: 22px;
  }
  @media (max-width: 490px) {
    font-size: 20px;
  }
`;

export default function Content({ setModalUrgenceDisplayInHome }) {
  const [displayTrashEffect, setDisplayTrashEffect] = useState(false);

  return (
    <DivModal>
      <ContenairPicture
        displayTrashEffect={displayTrashEffect}
        setDisplayTrashEffect={setDisplayTrashEffect}
        setModalUrgenceDisplayInHome={setModalUrgenceDisplayInHome}
      />
      <ButtonCloseAndIcon />
      <Div>
        <DivP>
          <P>
            Une
            <strong style={{ fontWeight: "100" }}> Urgence</strong> ?
          </P>
        </DivP>
      </Div>
      <DivP>
        <P2>
          <span style={{ whiteSpace: "nowrap" }}>
            <strong style={{ fontWeight: "100" }}>Plomberie</strong>
            {" - "}
            <strong style={{ fontWeight: "100" }}>Chauffe-eau</strong>
            <br />
            <strong style={{ fontWeight: "100" }}>Chaudière</strong>{" "}
          </span>
          <br />
          <strong style={{ fontWeight: "100" }}>Climatisation</strong>
          {" - "}
          <strong style={{ fontWeight: "100" }}>Fuite d'eau</strong>
        </P2>
        <P3>Intervention en moins de 4 heures garantie</P3>
        <P4>Devis 100% gratuit</P4>
        <BlockNumberPhone />
      </DivP>
    </DivModal>
  );
}
