import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Pblog from "./Pblog";
import { devices } from "../../../style/Size";

const effectHoverIcons = keyframes`
  0% {
    opacity: 1;
    transform: scaleX(0) scaleY(0);
  }
  4% {
    opacity: 1;
  }
  10% {
    opacity: 0;
    transform: scaleX(1) scaleY(1);
  }
  10.5% {
    opacity: 0;
    transform: scaleX(0) scaleY(0);
  }
  11% {
    opacity: 1;
    transform: scaleX(0) scaleY(0);
  }
  15% {
    opacity: 1;
  }
  21% {
    opacity: 0;
    transform: scaleX(1) scaleY(1);
  }
  21.5% {
    opacity: 0;
    transform: scaleX(0) scaleY(0);
  }
  51% {
    opacity: 1;
    transform: scaleX(0) scaleY(0);
  }
  55% {
    opacity: 1;
  }
  61% {
    opacity: 0;
    transform: scaleX(1) scaleY(1);    
  }
  61.5% {
    opacity: 0;
    transform: scaleX(0) scaleY(0);
  }
  62% {
    opacity: 1;
    transform: scaleX(0) scaleY(0);
  }
  66% {
    opacity: 1;
  }
  72% {
    opacity: 0;
    transform: scaleX(1) scaleY(1);
  }
  100% {
    opacity: 0;
    transform: scaleX(0) scaleY(0);
  }
`;

const effectLogoHover = keyframes`
  0%{
    transform: rotate(0) scale(1.2);
  } 
  95% {
    transform: rotate(0deg) scale(1.2);
  }
  96.25%{
    transform: rotate(4deg) scale(1.2);
  } 
  97.5%{
    transform: rotate(-4deg) scale(1.2);
  } 
  98.75%{
    transform: rotate(4deg) scale(1.2);
  } 
  100%{
    transform: rotate(0) scale(1.2);
  }
`;

const ContenairBlocks = styled.nav.attrs({
  id: "blockIcons",
})`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 94vh;
`;

const BlockInContenair = styled.div`
  display: flex;
  margin-right: 20px;

  @media ${devices.laptopM} {
    margin-right: 10px;
  }
`;

const BlockContact = styled.button`
  height: 28px;
  font-family: "Russo One", sans-serif;
  letter-spacing: 0.5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  border: 2px solid #ffffff;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;

  @media (min-width: 991px) {
    &:hover {
      transform: scale(1.05);
    }
  }
`;

const PContact = styled.div`
  color: #ffffff;
  font-size: 12px;
  white-space: nowrap;
`;

const BlockIcons = styled.div`
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
`;

const P1 = styled.div.attrs({
  className: "paragraphIcons",
})`
  margin: 0;
  margin-left: 10px;
  margin-right: 10px;
  color: #ffffff;
  font-size: 28px;
  cursor: pointer;
  &::after {
    display: block;
    content: "";
    width: 100%;
    height: 28px;
    background: radial-gradient(circle at 90% 10%, #717686, transparent);
    border-radius: 4px;
    position: absolute;
    top: 1px;
    left: 0;
    transform: scaleX(0) scaleY(0);
    transform-origin: 0% 100%;
  }
  @media (min-width: 991px) {
    &:hover::after {
      animation-name: ${effectHoverIcons};
      animation-duration: 8s;
      animation-iteration-count: infinite;
    }
    &:hover {
      animation-name: ${effectLogoHover};
      animation-duration: 8s;
      animation-iteration-count: infinite;
    }
  }
`;

const P2 = styled.div.attrs({
  className: "paragraphIcons",
})`
  margin: 0;
  margin-left: 10px;
  margin-right: 10px;
  color: #ffffff;
  font-size: 28px;
  cursor: pointer;
  &::after {
    display: block;
    content: "";
    width: 100%;
    height: 28px;
    background: radial-gradient(circle at 90% 10%, #717686, transparent);
    border-radius: 4px;
    position: absolute;
    top: 1px;
    left: 0;
    transform: scaleX(0) scaleY(0);
    transform-origin: 0% 100%;
  }
  @media (min-width: 991px) {
    &:hover::after {
      animation-name: ${effectHoverIcons};
      animation-duration: 8s;
      animation-iteration-count: infinite;
    }
    &:hover {
      animation-name: ${effectLogoHover};
      animation-duration: 8s;
      animation-iteration-count: infinite;
    }
  }
`;

const P3 = styled.div.attrs({
  className: "paragraphIcons",
})`
  margin: 0;
  margin-left: 10px;
  margin-right: 10px;
  color: #ffffff;
  font-size: 28px;
  cursor: pointer;
  &::after {
    display: block;
    content: "";
    width: 100%;
    height: 28px;
    background: radial-gradient(circle at 90% 10%, #717686, transparent);
    border-radius: 4px;
    position: absolute;
    top: 1px;
    left: 0;
    transform: scaleX(0) scaleY(0);
    transform-origin: 0% 100%;
  }
  @media (min-width: 991px) {
    &:hover::after {
      animation-name: ${effectHoverIcons};
      animation-duration: 8s;
      animation-iteration-count: infinite;
    }
    &:hover {
      animation-name: ${effectLogoHover};
      animation-duration: 8s;
      animation-iteration-count: infinite;
    }
  }
`;

export default function IconsAndContact() {
  const [blockSocialNetworkAndContact, setBlockSocialNetworkAndContact] =
    useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1140) {
      setBlockSocialNetworkAndContact(false);
    }
    if (window.innerWidth > 1140) {
      setBlockSocialNetworkAndContact(true);
    }
  }, []);

  window.addEventListener("resize", () => {
    if (window.innerWidth <= 1140) {
      setBlockSocialNetworkAndContact(false);
    }
    if (window.innerWidth > 1140) {
      setBlockSocialNetworkAndContact(true);
    }
  });

  return (
    <>
      {blockSocialNetworkAndContact && (
        <ContenairBlocks>
          <BlockInContenair>
            <Pblog />
            <BlockIcons>
              <a
                aria-label="Profil Facebook"
                style={{ textDecoration: "none", color: "#FFFFFF" }}
                href="https://www.facebook.com/profile.php?id=61553054431024"
                target="blanck"
              >
                <P1>
                  <i className="fa-brands fa-square-facebook"></i>
                </P1>
              </a>
              <a
                aria-label="Profil Instagram"
                style={{ textDecoration: "none", color: "#FFFFFF" }}
                href="https://www.instagram.com/entreprisenavarro/"
                target="blanck"
              >
                <P2>
                  <i className="fa-brands fa-instagram"></i>
                </P2>
              </a>
              <a
                aria-label="Profil Linkedin"
                style={{ textDecoration: "none", color: "#667788" }}
                href="https://www.linkedin.com/in/entreprise-navarro-marseille-plomberie-chauffagerie-climatisation"
                target="blanck"
              >
                <P3>
                  <i className="fa-brands fa-linkedin"></i>
                </P3>
              </a>
            </BlockIcons>
            <BlockContact>
              <PContact>
                <a
                  style={{ textDecoration: "none", color: "#FFFFFF" }}
                  href="mailto:entreprisenavarro@outlook.fr?subject=Contact pour renseignements"
                >
                  <i className="fa-solid fa-envelope"></i> CONTACT
                </a>
              </PContact>
            </BlockContact>
          </BlockInContenair>
        </ContenairBlocks>
      )}
    </>
  );
}
