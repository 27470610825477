import React, { useEffect } from "react";
import styled from "styled-components";
import NavBar from "../components/NavBar/NavBar";
import ContenairTitlePageSection from "../components/ContenairTitlePageSection";
import FicheWCBlock from "../components/BodyTroubleshooting/FicheWCBlock";
import ElectricWaterHeaterBlock from "../components/BodyTroubleshooting/ElectricWaterHeaterBlock";
import FicheOtherBlock from "../components/BodyTroubleshooting/FicheOtherBlock";
import GazWaterHeaterBlock from "../components/BodyTroubleshooting/GazWaterHeaterBlock";
import Footer from "../components/Footer";
import PlomberieDepannage from "../assets/Header/plomberie-depannage.webp";
import { NavBarDisplayFunction } from "../functions/NavBarDisplayScrollFunction";
import { NotDisplayFicheVersoOnScrollFunction } from "../functions/EffectFicheFunction";
import { EffectTitlePageFunction } from "../functions/EffectTitlePageFunction";
import { OffsetTopFunction } from "../functions/OffsetTopFunction";
import { devices } from "../style/Size";

const BlockImage = styled.div`
  width: 100%;
  height: 70vh;
  @media ${devices.tablet} {
    height: 50vh;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContenairMain = styled.main`
  width: 100%;
  position: relative;
`;

export default function Troubleshooting() {
  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    EffectTitlePageFunction("titlePageDepannage");
  }, []);

  const effectTitleSection = (elementClassName) => {
    const titleSection = document.querySelector(`#${elementClassName}`);
    titleSection.style.opacity = "1";
    titleSection.style.transition = "all 500ms";
  };

  const onScrollTitleSection = () => {
    const titleSectionWC = document.querySelector("#titleSectionWC");
    const titleSectionCEE = document.querySelector("#titleSectionCEE");
    const titleSectionOthers = document.querySelector("#titleSectionOthers");
    const titleSectionCEG = document.querySelector("#titleSectionCEG");

    const screenY = window.scrollY + window.innerHeight * 0.95;

    if (OffsetTopFunction(titleSectionWC) - screenY < 0) {
      effectTitleSection("titleSectionWC");
    }
    if (OffsetTopFunction(titleSectionCEE) - screenY < 0) {
      effectTitleSection("titleSectionCEE");
    }
    if (OffsetTopFunction(titleSectionOthers) - screenY < 0) {
      effectTitleSection("titleSectionOthers");
    }
    if (OffsetTopFunction(titleSectionCEG) - screenY < 0) {
      effectTitleSection("titleSectionCEG");
    }
  };

  useEffect(() => {
    const contenairSectionWC = document.querySelector("#contenairSectionWC");
    const contenairSectionCEE = document.querySelector("#contenairSectionCCE");
    const contenairBlockFicheRectoOthers = document.querySelector(
      "#contenairBlockFicheRectoOthers"
    );
    const contenairBlockFicheRectoCEG = document.querySelector(
      "#contenairBlockFicheRectoCEG"
    );

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (entries[0].target.id === "contenairSectionWC") {
          window.addEventListener("scroll", () => {
            onScrollTitleSection();
          });
        } else {
          window.removeEventListener("scroll", () => {
            onScrollTitleSection();
          });
        }
      }
    });
    observer.observe(contenairSectionWC);
    observer.observe(contenairSectionCEE);
    observer.observe(contenairBlockFicheRectoOthers);
    observer.observe(contenairBlockFicheRectoCEG);
  }, []);

  document.addEventListener("scroll", () => {
    if (window.innerWidth > 991) {
      NotDisplayFicheVersoOnScrollFunction("blockMenuInfos");
    }
    NavBarDisplayFunction("headerTroubleshootingContenair");
  });

  return (
    <>
      <header>
        <NavBar backgroundColor="#667788" />
        <BlockImage>
          <Image
            src={PlomberieDepannage}
            alt="matériel et outils plomberie pour dépannage"
          />
        </BlockImage>
        <ContenairTitlePageSection
          idTitle="titlePageDepannage"
          title="Dépannage"
        />
      </header>
      <ContenairMain>
        <section>
          <FicheWCBlock />
        </section>
        <section>
          <ElectricWaterHeaterBlock />
        </section>
        <section>
          <FicheOtherBlock />
        </section>
        <section>
          <GazWaterHeaterBlock />
        </section>
      </ContenairMain>
      <Footer backgroundColor="#667788" />
    </>
  );
}
