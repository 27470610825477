import React, { useState, useEffect } from "react";
import styled from "styled-components";

const P = styled.li.attrs({
  className: "paragrahMenuHidden groupElementsDisplayMenu",
})`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -10px;
  margin-bottom: auto;
  font-size: 14px;
  font-family: "Russo One", "sans serif";
  text-align: center;
  letter-spacing: 0.5px;
  transition: 100ms;
  &::before {
    display: block;
    content: "";
    width: 100%;
    height: 36px;
    margin-top: -10px;
    position: absolute;
    transform-origin: 50% 50%;
    transition: all 300ms ease;
    z-index: -1;
  }
  &::after {
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    border-bottom: solid 2px #1f1e1e;
    margin-top: 8px;
  }
  &:hover::before {
    background-color: #667788;
  }
  &:hover {
    color: #ffffff;
    font-size: 12px;
  }
`;

const PHome = styled.li.attrs({
  className: "paragrahMenuHidden groupElementsDisplayMenu",
})`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -10px;
  margin-bottom: auto;
  font-size: 14px;
  font-family: "Russo One", "sans serif";
  color: #3c2114;
  text-align: center;
  letter-spacing: 0.5px;
  transition: 100ms;
  &::before {
    display: block;
    content: "";
    width: 100%;
    height: 36px;
    margin-top: -10px;
    position: absolute;
    transform-origin: 50% 50%;
    transition: all 300ms ease;
    z-index: -1;
  }
  &::after {
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    border-bottom: solid 2px #1f1e1e;
    margin-top: 8px;
  }
  &:hover::before {
    background-color: #3c2114;
  }
  &:hover {
    color: #ffffff;
    font-size: 12px;
  }
`;

export default function ParagraphLi(props) {
  const [locationHome, setLocationHome] = useState(false);

  useEffect(() => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/blog"
    ) {
      setLocationHome(true);
    } else {
      setLocationHome(false);
    }
  }, []);

  const effectParagraphLiHomeAndBlog = () => {
    const headerContenairHome = document.querySelector("#headerHomeContenair");
    const headerBlogContenair = document.querySelector("#headerBlogContenair");
    const headerBlogArticleContenair = document.querySelector(
      "#headerBlogArticleContenair"
    );
    if (
      headerContenairHome &&
      headerContenairHome.getBoundingClientRect().y +
        headerContenairHome.offsetHeight <
        0
    ) {
      setLocationHome(false);
    }
    if (
      headerContenairHome &&
      headerContenairHome.getBoundingClientRect().y +
        headerContenairHome.offsetHeight >=
        0
    ) {
      setLocationHome(true);
    }
    if (
      (headerBlogContenair &&
        headerBlogContenair.getBoundingClientRect().y +
          headerBlogContenair.offsetHeight <
          0) ||
      (headerBlogArticleContenair &&
        headerBlogArticleContenair.getBoundingClientRect().y +
          headerBlogArticleContenair.offsetHeight <
          0)
    ) {
      setLocationHome(true);
    }
    if (
      (headerBlogContenair &&
        headerBlogContenair.getBoundingClientRect().y +
          headerBlogContenair.offsetHeight >=
          0) ||
      (headerBlogArticleContenair &&
        headerBlogArticleContenair.getBoundingClientRect().y +
          headerBlogArticleContenair.offsetHeight >=
          0)
    ) {
      setLocationHome(true);
    }
  };

  window.addEventListener("scroll", () => {
    effectParagraphLiHomeAndBlog();
  });

  useEffect(() => {
    effectParagraphLiHomeAndBlog();
  });

  return (
    <>
      {locationHome ? (
        <PHome
          className="paragraph"
          id={props.idLiHome}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
          onClick={props.onClick}
          style={{
            marginTop: `${props.marginTop}`,
            marginBottom: `${props.marginBottom}`,
            paddingTop: `${props.paddingTop}`,
            paddingBottom: `${props.paddingBottom}`,
          }}
        >
          {props.contentLiHome}
        </PHome>
      ) : (
        <P
          className="paragraph"
          id={props.idLi}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
          onClick={props.onClick}
          style={{
            marginTop: `${props.marginTop}`,
            marginBottom: `${props.marginBottom}`,
            paddingTop: `${props.paddingTop}`,
            paddingBottom: `${props.paddingBottom}`,
          }}
        >
          {props.contentLi}
        </P>
      )}
    </>
  );
}
