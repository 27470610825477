import React from "react";
import styled from "styled-components";
import ButtonInstallation from "../Buttons/ButtonInstallation";
import { devices } from "../../style/Size";

const ContenairBlockInstallation = styled.div`
  width: 80%;
  height: 400px;
  border: 4px solid #667788;
  border-radius: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  overflow: hidden;
  transform: translateY(40px) scale(1.05);

  @media ${devices.laptopXXS} {
    width: 90%;
  }
  @media (max-width: 934px) {
    height: 720px;
    margin-bottom: 30px;
  }
  @media (max-height: 480px) {
    height: 550px;
    margin-bottom: 30px;
  }
`;

const BlockInstallation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(
    to left bottom,
    #556699,
    7%,
    #667788,
    45%,
    #8092a4,
    70%,
    #7e8d9c
  );

  @media (max-width: 934px) {
    border: 0px solid transparent;
    margin-left: 0px;
    box-shadow: 0px 10px 20px #aea9a9;
  }
`;

const BlockTitle = styled.h2`
  font-size: 20px;
  color: #ffffff;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  margin-top: 18px;
  margin-bottom: 8px;
  margin-left: 20px;

  @media (max-width: 934px) {
    height: 40px;
    max-width: 240px;
  }
  @media (max-width: 290px) {
    max-width: 190px;
    font-size: 17px;
  }
`;

const ContenairTextAndImage = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 14px;

  @media (max-width: 934px) {
    flex-direction: column-reverse;
    padding-top: 10px;
  }
`;

const Text = styled.p`
  display: inline-block;
  width: calc(100% - 380px);
  height: 340px;
  font-size: 18px;
  color: #ffffff;
  font-family: "Russo One", sans-serif;
  font-weight: 100;
  letter-spacing: 1px;
  text-align: justify;
  margin: 0;
  margin-left: 20px;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  @media ${devices.laptopS} {
    width: calc(100% - 340px);
    height: 300px;
  }
  @media (max-width: 934px) {
    width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;
    height: 282px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
  @media (max-height: 480px) {
    height: 168px;
  }
`;

const ContenairImage = styled.div`
  width: 320px;
  height: 250px;
  margin-right: 20px;
  position: relative;
  overflow: hidden;

  @media ${devices.laptopS} {
    width: 280px;
  }
  @media (max-width: 934px) {
    width: calc(100% - 40px);
    height: 270px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  @media (max-height: 480px) {
    height: 200px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default function BlockFicheInstallation(props) {
  return (
    <ContenairBlockInstallation className="contenairBlockInstallation">
      <BlockInstallation>
        <BlockTitle>
          <strong style={{ fontWeight: "500" }}>{props.title}</strong>
        </BlockTitle>
        <ContenairTextAndImage>
          <Text>{props.text}</Text>
          <ContenairImage>
            <Image
              className={props.classNameBackground}
              id={props.idPicture}
              src={props.picture}
              alt={props.alt}
            />
          </ContenairImage>
        </ContenairTextAndImage>
        <ButtonInstallation
          idButton={props.idButton}
          onClick={props.onClick}
          onTouchStart={props.onTouchStart}
        />
      </BlockInstallation>
    </ContenairBlockInstallation>
  );
}
