import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ButtonArticleTopHome from "../Buttons/ButtonArticleTopHome";
import BodyArticleTopMobileScreen from "./BodyArticleTopMobileScreen";
import { OnScrollBlockTopFunction } from "../../functions/EffectArticleFunction";
import { devices } from "../../style/Size";

const ContenairBlockAndArticle = styled.div`
  width: 80%;
  height: 560px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;

  @media ${devices.laptopXXS} {
    width: 100%;
    margin-bottom: 14px;
  }
  @media ${devices.tablet} {
  }
  @media ${devices.mobileL} {
    width: 100%;
    height: 460px;
  }
  @media (max-height: 480px) {
    height: 76vh;
  }
`;

const BlockArticle = styled.div`
  width: 100%;
  height: 560px;
  position: absolute;
  top: 0;
  left: 0;

  @media ${devices.mobileL} {
    width: 100%;
    height: 460px;
  }
  @media (max-height: 480px) {
    height: 76vh;
  }
`;

const ContenairArticleAndButton = styled.div`
  display: flex;
  width: 100%;
  height: 90px;
  background: linear-gradient(
    rgba(102, 119, 136, 0.94),
    rgba(102, 119, 136, 0.94)
  );
  position: absolute;
  bottom: 0;
  flex-direction: column;
`;

const TitleArticle = styled.div`
  display: flex;
  min-width: 200px;
`;

const PTitleArticle = styled.h3`
  font-size: 30px;
  font-family: "Russo One", cursive, sans-serif;
  color: #ffffff;
  letter-spacing: 2px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 16px;
  padding-right: 20px;
  margin: 0;
  margin-bottom: auto;
  margin-left: 3%;
  transform: translateY(-4%);

  @media ${devices.tablet} {
    font-size: 24px;
    margin-left: 7%;
    padding-bottom: 30px;
  }
  @media ${devices.mobileXS} {
    font-size: 22px;
  }
  @media (max-height: 480px) {
    padding-bottom: 12px;
  }
`;

const ContenairArticle = styled.div`
  width: 94%;
  height: 360px;
  margin-left: 3%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  @media ${devices.laptopXXS} {
    margin-top: 10px;
    height: 408px;
  }
  @media ${devices.tablet} {
    width: 86%;
    margin-left: 7%;
    height: 410px;
    margin-top: 0px;
  }
  @media ${devices.mobileL} {
    height: 322px;
  }
  @media ${devices.mobileXS} {
    height: 318px;
    margin-top: -4px;
  }
  @media (max-height: 480px) {
    height: 150px;
    margin-top: 10px;
  }
`;

const Article = styled.div`
  display: flex;
`;

const P = styled.p`
  width: 100%;
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  font-weight: 100;
  color: #ffffff;
  text-align: justify;
  opacity: 0;
  /* transform: translateY(14%); */

  @media ${devices.mobileXS} {
    font-size: 16px;
  }
  @media (max-height: 480px) {
    margin-top: 0;
  }
`;

export default function BodyArticleToHome(props, classname) {
  const [widthScreen, setWidthScreen] = useState();

  useEffect(() => {
    if (window.innerWidth > 480) {
      setWidthScreen(true);
    }
    if (window.innerWidth <= 480) {
      setWidthScreen(false);
    }
  }, []);

  // window.addEventListener("resize", () => {
  //   if (window.innerWidth > 480) {
  //     setWidthScreen(true);
  //   }
  //   if (window.innerWidth <= 480) {
  //     setWidthScreen(false);
  //   }
  // });

  const effectScrollBlockOne = () => {
    window.addEventListener("scroll", () => {
      OnScrollBlockTopFunction(
        "blockArticle1",
        "contenairArticle1",
        "pTitleArticle1",
        "pArticle1",
        "button1"
      );
    });
  };
  const resizeEffectScrollBlockOne = () => {
    window.addEventListener("resize", () => {
      OnScrollBlockTopFunction(
        "blockArticle1",
        "contenairArticle1",
        "pTitleArticle1",
        "pArticle1",
        "button1"
      );
    });
  };
  const notEffectScrollBlockOne = () => {
    window.removeEventListener("scroll", () => {
      OnScrollBlockTopFunction(
        "blockArticle1",
        "contenairArticle1",
        "pTitleArticle1",
        "pArticle1",
        "button1"
      );
    });
  };
  const resizeNotEffectScrollBlockOne = () => {
    window.removeEventListener("resize", () => {
      OnScrollBlockTopFunction(
        "blockArticle1",
        "contenairArticle1",
        "pTitleArticle1",
        "pArticle1",
        "button1"
      );
    });
  };

  const effectScrollBlockTwo = () => {
    window.addEventListener("scroll", () => {
      OnScrollBlockTopFunction(
        "blockArticle2",
        "contenairArticle2",
        "pTitleArticle2",
        "pArticle2",
        "button2"
      );
    });
  };
  const notEffectScrollBlockTwo = () => {
    window.addEventListener("resize", () => {
      OnScrollBlockTopFunction(
        "blockArticle2",
        "contenairArticle2",
        "pTitleArticle2",
        "pArticle2",
        "button2"
      );
    });
  };
  const resizeEffectScrollBlockTwo = () => {
    window.removeEventListener("scroll", () => {
      OnScrollBlockTopFunction(
        "blockArticle2",
        "contenairArticle2",
        "pTitleArticle2",
        "pArticle2",
        "button2"
      );
    });
  };
  const resizeNotEffectScrollBlockTwo = () => {
    window.removeEventListener("resize", () => {
      OnScrollBlockTopFunction(
        "blockArticle2",
        "contenairArticle2",
        "pTitleArticle2",
        "pArticle2",
        "button2"
      );
    });
  };

  const effectScrollBlockThree = () => {
    window.addEventListener("scroll", () => {
      OnScrollBlockTopFunction(
        "blockArticle3",
        "contenairArticle3",
        "pTitleArticle3",
        "pArticle3",
        "button3"
      );
    });
  };
  const notEffectScrollBlockThree = () => {
    window.addEventListener("resize", () => {
      OnScrollBlockTopFunction(
        "blockArticle3",
        "contenairArticle3",
        "pTitleArticle3",
        "pArticle3",
        "button3"
      );
    });
  };
  const resizeEffectScrollBlockThree = () => {
    window.removeEventListener("scroll", () => {
      OnScrollBlockTopFunction(
        "blockArticle3",
        "contenairArticle3",
        "pTitleArticle3",
        "pArticle3",
        "button3"
      );
    });
  };
  const resizeNotEffectScrollBlockThree = () => {
    window.removeEventListener("resize", () => {
      OnScrollBlockTopFunction(
        "blockArticle3",
        "contenairArticle3",
        "pTitleArticle3",
        "pArticle3",
        "button3"
      );
    });
  };

  const effectScrollBlockFour = () => {
    window.addEventListener("scroll", () => {
      OnScrollBlockTopFunction(
        "blockArticle4",
        "contenairArticle4",
        "pTitleArticle4",
        "pArticle4",
        "button4"
      );
    });
  };
  const notEffectScrollBlockFour = () => {
    window.addEventListener("resize", () => {
      OnScrollBlockTopFunction(
        "blockArticle4",
        "contenairArticle4",
        "pTitleArticle4",
        "pArticle4",
        "button4"
      );
    });
  };
  const resizeEffectScrollBlockFour = () => {
    window.removeEventListener("scroll", () => {
      OnScrollBlockTopFunction(
        "blockArticle4",
        "contenairArticle4",
        "pTitleArticle4",
        "pArticle4",
        "button4"
      );
    });
  };
  const resizeNotEffectScrollBlockFour = () => {
    window.removeEventListener("resize", () => {
      OnScrollBlockTopFunction(
        "blockArticle4",
        "contenairArticle4",
        "pTitleArticle4",
        "pArticle4",
        "button4"
      );
    });
  };

  useEffect(() => {
    if (widthScreen) {
      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          if (entry.target.id === "blockArticle1") {
            effectScrollBlockOne();
            resizeEffectScrollBlockOne();
          } else {
            notEffectScrollBlockOne();
            resizeNotEffectScrollBlockOne();
          }
          if (entry.target.id === "blockArticle2") {
            effectScrollBlockTwo();
            resizeEffectScrollBlockTwo();
          } else {
            notEffectScrollBlockTwo();
            resizeNotEffectScrollBlockTwo();
          }
          if (entry.target.id === "blockArticle3") {
            effectScrollBlockThree();
            resizeEffectScrollBlockThree();
          } else {
            notEffectScrollBlockThree();
            resizeNotEffectScrollBlockThree();
          }
          if (entry.target.id === "blockArticle4") {
            effectScrollBlockFour();
            resizeEffectScrollBlockFour();
          } else {
            notEffectScrollBlockFour();
            resizeNotEffectScrollBlockFour();
          }
        }
      });
      observer.observe(document.querySelector("#blockArticle1"));
      observer.observe(document.querySelector("#blockArticle2"));
      observer.observe(document.querySelector("#blockArticle3"));
      observer.observe(document.querySelector("#blockArticle4"));
    }
  }, [widthScreen]);

  return (
    <>
      {widthScreen ? (
        <ContenairBlockAndArticle>
          <BlockArticle className={props.classNameBlock} id={props.idBlock} />
          <ContenairArticleAndButton id={props.idContenair}>
            <TitleArticle>
              <PTitleArticle id={props.idPTitleArticle}>
                {props.contentTitle}
              </PTitleArticle>
            </TitleArticle>
            <ContenairArticle>
              <Article id={props.idArticle}>
                <P id={props.idPArticle}>{props.contentText}</P>
              </Article>
            </ContenairArticle>
            <ButtonArticleTopHome
              idButton={props.idButton}
              element1={props.element1}
              element2={props.element2}
              element3={props.element3}
              element4={props.element4}
              element5={props.element5}
              element6={props.element6}
            />
          </ContenairArticleAndButton>
        </ContenairBlockAndArticle>
      ) : (
        <BodyArticleTopMobileScreen
          classNameBlock={props.classNameBlock}
          contentTitle={props.contentTitle}
          contentText={props.contentText}
          idButton={props.idButton}
          element1={props.element1}
          element2={props.element2}
          element3={props.element3}
          element4={props.element4}
          element5={props.element5}
          element6={props.element6}
        />
      )}
    </>
  );
}
