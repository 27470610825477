import React from "react";
import styled from "styled-components";

const BlockSubMenu = styled.ul.attrs({
  id: "salleDeBainHidden",
  className: "subMenuBlock",
})`
  width: 160px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: #fcf7f7;
  border-top: 1px solid #333131;
  border-left: 1px solid #333131;
  border-right: 1px solid #333131;
  border-radius: 2px;
  cursor: pointer;
  font-size: 34px;
  position: absolute;
  top: 65px;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
`;

export default function Bathroom() {
  return <BlockSubMenu />;
}
