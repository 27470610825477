import { OffsetTopFunction } from "./OffsetTopFunction";

const effectSubTitleCategories = (elementClassName) => {
  const subTitle = document.querySelector(`${elementClassName}`);
  if (subTitle) {
    subTitle.style.opacity = "1";
    subTitle.style.transition = `all 400ms`;
  }
};

const OnScrollSubTitleFunction = (
  blockOne,
  blockTwo,
  blockThree,
  blockFour,
  idSubtitleOne,
  idSubtitleTwo,
  idSubtitleThree,
  idSubtitleFour
) => {
  const screenY = window.scrollY + window.innerHeight * 0.96;

  if (window.innerWidth > 1140) {
    if (OffsetTopFunction(blockOne) - screenY < 0) {
      effectSubTitleCategories(`#${idSubtitleOne}`);
    }
    if (OffsetTopFunction(blockTwo) - screenY < 0) {
      setTimeout(() => {
        effectSubTitleCategories(`#${idSubtitleTwo}`);
      }, 280);
    }
    if (OffsetTopFunction(blockThree) - screenY < 0) {
      setTimeout(() => {
        effectSubTitleCategories(`#${idSubtitleThree}`);
      }, 560);
    }
    if (OffsetTopFunction(blockFour) - screenY < 0) {
      setTimeout(() => {
        effectSubTitleCategories(`#${idSubtitleFour}`);
      }, 840);
    }
  }

  if (window.innerWidth > 684 && window.innerWidth <= 1140) {
    if (OffsetTopFunction(blockOne) - screenY < 0) {
      effectSubTitleCategories(`#${idSubtitleOne}`);
    }
    if (OffsetTopFunction(blockTwo) - screenY < 0) {
      setTimeout(() => {
        effectSubTitleCategories(`#${idSubtitleTwo}`);
      }, 280);
    }
    if (OffsetTopFunction(blockThree) - screenY < 0) {
      effectSubTitleCategories(`#${idSubtitleThree}`);
    }
    if (OffsetTopFunction(blockFour) - screenY < 0) {
      setTimeout(() => {
        effectSubTitleCategories(`#${idSubtitleFour}`);
      }, 280);
    }
  }

  if (window.innerWidth <= 684) {
    if (OffsetTopFunction(blockOne) - screenY < 0) {
      effectSubTitleCategories(`#${idSubtitleOne}`);
    }
    if (OffsetTopFunction(blockTwo) - screenY < 0) {
      effectSubTitleCategories(`#${idSubtitleTwo}`);
    }
    if (OffsetTopFunction(blockThree) - screenY < 0) {
      effectSubTitleCategories(`#${idSubtitleThree}`);
    }
    if (OffsetTopFunction(blockFour) - screenY < 0) {
      effectSubTitleCategories(`#${idSubtitleFour}`);
    }
  }
};

const OnScrollSubTitleFunctionBigFiche = (
  blockOne,
  blockTwo,
  idSubtitleOne,
  idSubtitleTwo
) => {
  const screenY = window.scrollY + window.innerHeight * 0.96;

  if (window.innerWidth > 1140) {
    if (OffsetTopFunction(blockOne) - screenY < 0) {
      effectSubTitleCategories(`#${idSubtitleOne}`);
    }
    if (OffsetTopFunction(blockTwo) - screenY < 0) {
      setTimeout(() => {
        effectSubTitleCategories(`#${idSubtitleTwo}`);
      }, 280);
    }
  }

  if (window.innerWidth > 684 && window.innerWidth <= 1140) {
    if (OffsetTopFunction(blockOne) - screenY < 0) {
      effectSubTitleCategories(`#${idSubtitleOne}`);
    }
    if (OffsetTopFunction(blockTwo) - screenY < 0) {
      setTimeout(() => {
        effectSubTitleCategories(`#${idSubtitleTwo}`);
      }, 280);
    }
  }

  if (window.innerWidth <= 684) {
    if (OffsetTopFunction(blockOne) - screenY < 0) {
      effectSubTitleCategories(`#${idSubtitleOne}`);
    }
    if (OffsetTopFunction(blockTwo) - screenY < 0) {
      effectSubTitleCategories(`#${idSubtitleTwo}`);
    }
  }
};

const effectBlockFiche = (blockFiche) => {
  if (blockFiche) {
    blockFiche.style.transform = "scale(1)";
    blockFiche.style.opacity = "1";
    blockFiche.style.transition = `all 400ms`;
  }
};

const OnScrollBlockFicheFunction = (
  blockOne,
  blockTwo,
  blockThree,
  blockFour
) => {
  const screenY = window.scrollY + window.innerHeight * 0.9;

  if (window.innerWidth > 1140) {
    if (OffsetTopFunction(blockOne) - screenY < 0) {
      effectBlockFiche(blockOne);
    }
    if (OffsetTopFunction(blockTwo) - screenY < 0) {
      setTimeout(() => {
        effectBlockFiche(blockTwo);
      }, 280);
    }
    if (OffsetTopFunction(blockThree) - screenY < 0) {
      setTimeout(() => {
        effectBlockFiche(blockThree);
      }, 560);
    }
    if (OffsetTopFunction(blockFour) - screenY < 0) {
      setTimeout(() => {
        effectBlockFiche(blockFour);
      }, 840);
    }
  }

  if (window.innerWidth > 684 && window.innerWidth <= 1140) {
    if (OffsetTopFunction(blockOne) - screenY < 0) {
      effectBlockFiche(blockOne);
    }
    if (OffsetTopFunction(blockTwo) - screenY < 0) {
      setTimeout(() => {
        effectBlockFiche(blockTwo);
      }, 280);
    }
    if (OffsetTopFunction(blockThree) - screenY < 0) {
      effectBlockFiche(blockThree);
    }
    if (OffsetTopFunction(blockFour) - screenY < 0) {
      setTimeout(() => {
        effectBlockFiche(blockFour);
      }, 280);
    }
  }

  if (window.innerWidth <= 684) {
    if (OffsetTopFunction(blockOne) - screenY < 0) {
      effectBlockFiche(blockOne);
    }
    if (OffsetTopFunction(blockTwo) - screenY < 0) {
      effectBlockFiche(blockTwo);
    }
    if (OffsetTopFunction(blockThree) - screenY < 0) {
      effectBlockFiche(blockThree);
    }
    if (OffsetTopFunction(blockFour) - screenY < 0) {
      effectBlockFiche(blockFour);
    }
  }
};

const OnScrollBlockFicheFunctionBigFiche = (blockOne, blockTwo) => {
  const screenY = window.scrollY + window.innerHeight * 0.9;

  if (window.innerWidth > 1140) {
    if (OffsetTopFunction(blockOne) - screenY < 0) {
      effectBlockFiche(blockOne);
    }
    if (OffsetTopFunction(blockTwo) - screenY < 0) {
      setTimeout(() => {
        effectBlockFiche(blockTwo);
      }, 280);
    }
  }

  if (window.innerWidth > 684 && window.innerWidth <= 1140) {
    if (OffsetTopFunction(blockOne) - screenY < 0) {
      effectBlockFiche(blockOne);
    }
    if (OffsetTopFunction(blockTwo) - screenY < 0) {
      setTimeout(() => {
        effectBlockFiche(blockTwo);
      }, 280);
    }
  }

  if (window.innerWidth <= 684) {
    if (OffsetTopFunction(blockOne) - screenY < 0) {
      effectBlockFiche(blockOne);
    }
    if (OffsetTopFunction(blockTwo) - screenY < 0) {
      effectBlockFiche(blockTwo);
    }
  }
};

export {
  OnScrollSubTitleFunction,
  OnScrollBlockFicheFunction,
  OnScrollSubTitleFunctionBigFiche,
  OnScrollBlockFicheFunctionBigFiche,
};
