import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import HeaderSubSection from "../components/Header/HeaderSubSection";
import BodySubSection from "../components/SubSection/BodySubSection";
import LastBlockSubSection from "../components/SubSection/LastBlockSubSection";
import ButtonQuote from "../components/Buttons/ButtonQuote";
import Footer from "../components/Footer";
import TrayToShowerPicture from "../assets/Installation/douche-receveur.webp";
import TrayToShowerClassic from "../assets/TrayToShower/receveur-de-douche-ceramique.jpg";
import TrayToShowerMust from "../assets/TrayToShower/receveur-de-douche-plat.webp";
import TrayToShowerTop from "../assets/TrayToShower/receveur-douche-souple.webp";
import { NavBarDisplayFunction } from "../functions/NavBarDisplayScrollFunction";
import { EffectSubSectionFunction } from "../functions/EffectSubSectionFunction";
import { EffectTitlePageFunction } from "../functions/EffectTitlePageFunction";
import { devices } from "../style/Size";

const ContenairMain = styled.main`
  width: 100%;
  position: relative;
  margin-top: 70px;

  @media ${devices.laptopXXS} {
    margin-top: 110px;
  }
  @media ${devices.tablet} {
    margin-top: 40px;
  }
`;

export default function TrayToShower() {
  useEffect(() => {
    EffectTitlePageFunction("titleTrayToShowerContenair");
    setTimeout(() => {
      EffectTitlePageFunction("idBlockElement");
    }, 600);
  }, []);

  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction("headerTrayToShowerContenair");
  });

  useEffect(() => {
    const titleSection = document.querySelector("#blockTitle");
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        window.addEventListener("scroll", () => {
          EffectSubSectionFunction("blockTitle", "/installation/bacs-a-douche");
        });
      }
    });
    observer.observe(titleSection);
  }, []);

  window.addEventListener("resize", () => {
    const blockSection = document.querySelectorAll(`.imageSection`);
    if (blockSection) {
      EffectSubSectionFunction("blockTitle", "/installation/bacs-a-douche");
      if (window.innerWidth <= 568) {
        for (let element of blockSection) {
          element.style.transform = "translateX(0vw)";
          element.style.transition = "all 300ms";
        }
      }
    }
  });

  return (
    <>
      <HeaderSubSection
        id="headerTrayToShowerContenair"
        idTitle="titleTrayToShowerContenair"
        src={TrayToShowerPicture}
        alt="Douche vitrée avec receveur de douche rectangulaire blanc dans salle de bain raffinée"
        titlePage="Bacs à Douche"
      />
      <ContenairMain>
        <BodySubSection
          idText="textSection"
          imageClassname="imageSection"
          idBlockElement="idBlockElement"
          opacity="0.5"
          src={TrayToShowerClassic}
          alt="Bac à douche avec rebors en céramique blanc entrée de gamme dans salle de bain simple et blanche"
          title="Les Classiques"
          text={[
            "Des ",
            <strong key="1" style={{ fontWeight: "100" }}>
              bacs à douche entrée de gamme
            </strong>,
            ". En ",
            <strong key="2" style={{ fontWeight: "100" }}>
              céramique classique extra plat encastré à poser
            </strong>,
            ". Épaisseur 4cm. Absence de système anti-dérapant. Dimension standard, allant de 70 x 70 à 90 x 90. Coloris monochrome blanc.",
            <br key="3" />,
            <br key="4" />,
            "Tous nos ",
            <strong key="5" style={{ fontWeight: "100" }}>
              bacs à douche
            </strong>,
            " peuvent être accompagnés d'accessoires : ",
            <strong key="6" style={{ fontWeight: "100" }}>
              cabine de douche
            </strong>,
            ", ",
            <strong key="7" style={{ fontWeight: "100" }}>
              assise
            </strong>,
            ", ",
            <strong key="8" style={{ fontWeight: "100" }}>
              poignet de maintien
            </strong>,
            ", ",
            <strong key="9" style={{ fontWeight: "100" }}>
              robinetterie
            </strong>,
            ".",
            <br key="10" />,
            <br key="11" />,
            "Nos ",
            <strong key="12" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " se rendent sur place pour effectuer un ",
            <strong key="13" style={{ fontWeight: "100" }}>
              devis 100% gratuit et personnalisé
            </strong>,
            " afin d'estimer le ",
            <strong key="14" style={{ fontWeight: "100" }}>
              coût et temps d'installation plomberie
            </strong>,
            ". Le temps de pose pour un bac à douche seul sans remplacement est environ de quatre heures et une journée avec remplacement.",
            <br key="15" />,
            <br key="16" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="17"
              to="https://www.cedeo.fr/c/receveur-de-douche/x3snv3_dig_2025689R4?filters=f/catalogue_xml_lb_marque/alterna"
              target="_blank"
            >
              Alterna
            </Link>,
            " et Domao.",
          ]}
        />
        <BodySubSection
          imageClassname="imageSection"
          src={TrayToShowerMust}
          alt="Bac à douche en céramique blanc sans rebords dans douche vitrée dans salle de bain style médidérranéen"
          title="Les Complets"
          text={[
            "Des ",
            <strong key="1" style={{ fontWeight: "100" }}>
              bacs à douche
            </strong>,
            " plus variés et aboutis. En ",
            <strong key="2" style={{ fontWeight: "100" }}>
              céramique renforcée d'une charge minérale acrylique anti-bactérien
              dans la masse
            </strong>,
            " pour une meilleure hygiène et facilité d'entretien. Présence de ",
            <strong key="3" style={{ fontWeight: "100" }}>
              système anti-dérapant classe CPN24
            </strong>,
            ". Plusieurs dimensions, allant de 70 x 70 à 120 x 90. Épaisseur de 2 à 3cm. ",
            <strong key="4" style={{ fontWeight: "100" }}>
              Bonde centrée ou excentrée
            </strong>,
            ", avec système d'évacuation horizontal ou vertical. Coloris monochrome blanc. ",
            <strong key="5" style={{ fontWeight: "100" }}>
              Bacs à douche à poser ou à encastrer
            </strong>,
            ".",
            <br key="6" />,
            <br key="7" />,
            "Tous nos ",
            <strong key="8" style={{ fontWeight: "100" }}>
              bacs à douche
            </strong>,
            " peuvent être accompagnés d'accessoires : ",
            <strong key="9" style={{ fontWeight: "100" }}>
              cabine de douche
            </strong>,
            ", ",
            <strong key="10" style={{ fontWeight: "100" }}>
              assise
            </strong>,
            ", ",
            <strong key="11" style={{ fontWeight: "100" }}>
              poignet de maintien
            </strong>,
            ", ",
            <strong key="12" style={{ fontWeight: "100" }}>
              robinetterie
            </strong>,
            ".",
            <br key="13" />,
            <br key="14" />,
            "Nos ",
            <strong key="15" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " se rendent sur place pour effectuer un ",
            <strong key="16" style={{ fontWeight: "100" }}>
              devis 100% gratuit et personnalisé
            </strong>,
            " afin d'estimer le ",
            <strong key="17" style={{ fontWeight: "100" }}>
              coût et temps d'installation plomberie
            </strong>,
            ". Le temps de pose pour un bac à douche seul sans remplacement est environ de quatre heures et une journée avec remplacement.",
            <br key="18" />,
            <br key="19" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="20"
              to="https://www.jacobdelafon.fr/douche"
              target="_blank"
            >
              Jacob Delafon
            </Link>,
            " et ",
            <Link
              key="21"
              to="https://www.kinedo.com/produits/receveurs/"
              target="_blank"
            >
              Kinedo
            </Link>,
            ".",
          ]}
        />
        <LastBlockSubSection
          imageClassname="imageSection"
          src={TrayToShowerTop}
          alt="Bac à douche en céramique style ardoise bleu nuit dans douche vitrée dans salle de bain espacée"
          title="Les Hauts De Gamme"
          text={[
            "Des ",
            <strong key="1" style={{ fontWeight: "100" }}>
              bacs à douche haut de gamme
            </strong>,
            ". De toutes dimensions, de tous coloris, de tous matériaux. ",
            <strong key="2" style={{ fontWeight: "100" }}>
              Bacs à douche néoquartz
            </strong>,
            ", texture ardoise, ciment... Redécoupable pour une parfaite adaptation aux dimensions voulues. Épaisseur de 2 à 3cm. Avec capot en inox intégré. ",
            <strong key="3" style={{ fontWeight: "100" }}>
              Bonde centrée ou excentrée
            </strong>,
            ", avec système d'évacuation horizontal ou vertical. ",
            <strong key="4" style={{ fontWeight: "100" }}>
              Renforcée d'une charge minérale acrylique anti-bactérien dans la
              masse
            </strong>,
            " pour une meilleure hygiène et facilité d'entretien. Présence de ",
            <strong key="5" style={{ fontWeight: "100" }}>
              système anti-dérapant évolué classe APN12
            </strong>,
            ". ",
            <strong key="6" style={{ fontWeight: "100" }}>
              Bacs à douche à poser ou à encastrer
            </strong>,
            ".",
            <br key="7" />,
            <br key="8" />,
            "Tous nos ",
            <strong key="9" style={{ fontWeight: "100" }}>
              bacs à douche
            </strong>,
            " peuvent être accompagnés d'accessoires : ",
            <strong key="10" style={{ fontWeight: "100" }}>
              cabine de douche
            </strong>,
            ", ",
            <strong key="11" style={{ fontWeight: "100" }}>
              assise
            </strong>,
            ", ",
            <strong key="12" style={{ fontWeight: "100" }}>
              poignet de maintien
            </strong>,
            ", ",
            <strong key="13" style={{ fontWeight: "100" }}>
              robinetterie
            </strong>,
            ".",
            <br key="14" />,
            <br key="15" />,
            "Nos ",
            <strong key="16" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " se rendent sur place pour effectuer un ",
            <strong key="17" style={{ fontWeight: "100" }}>
              devis 100% gratuit et personnalisé
            </strong>,
            " afin d'estimer le ",
            <strong key="18" style={{ fontWeight: "100" }}>
              coût et temps d'installation plomberie
            </strong>,
            ". Le temps de pose pour un bac à douche seul sans remplacement est environ de quatre heures et une journée avec remplacement.",
            <br key="19" />,
            <br key="20" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="21"
              to="https://www.jacobdelafon.fr/douche"
              target="_blank"
            >
              Jacob Delafon
            </Link>,
            " et ",
            <Link
              key="22"
              to="https://www.kinedo.com/produits/receveurs/"
              target="_blank"
            >
              Kinedo
            </Link>,
            ".",
          ]}
        />
        <ButtonQuote />
      </ContenairMain>
      <Footer marginTop="300px" backgroundColor="#667788" />
    </>
  );
}
