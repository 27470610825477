import React, { useRef } from "react";
import styled from "styled-components";
import { devices } from "../../style/Size";

const NumberPhone = styled.input`
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  margin: 0;
  margin-top: 8px;
  border: 2px solid #667788;
  box-sizing: border-box;
  background-color: #dfe3e7;
  border-radius: 2px;
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  font-weight: 100;
  color: #000000;
  outline: none;

  @media ${devices.laptopXXS} {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 40px;
    margin-top: 6px;
  }
  @media (max-width: 702px) {
    margin-top: 4px;
  }
  @media (max-width: 375px) {
    font-size: 16px;
  }
`;

export default function InputNumberPhone({
  setElementFormThree,
  setNumberPhoneValue,
}) {
  const refNumberPhone = useRef();

  const onKeyUpForm = (e) => {
    if (e.target.id === "numberPhone") {
      if (refNumberPhone.current.value.length > 10) {
        setElementFormThree(
          "Votre numéro de téléphone ne peut contenir plus de dix chiffres"
        );
        refNumberPhone.current.style.border = "2px solid red";
        setNumberPhoneValue(false);
      }
      for (let letter of refNumberPhone.current.value) {
        if (!letter.match("^[0-9]*$")) {
          setElementFormThree(
            "Votre numéro de téléphone ne peut contenir que des chiffres"
          );
          refNumberPhone.current.style.border = "2px solid red";
          setNumberPhoneValue(false);
        }
        if (
          refNumberPhone.current.value.length <= 10 &&
          letter.match("^[0-9]*$")
        ) {
          setElementFormThree(false);
          refNumberPhone.current.style.border = "2px solid #667788";
          setNumberPhoneValue(false);
        }
        if (
          refNumberPhone.current.value.length === 10 &&
          letter.match("^[0-9]*$")
        ) {
          setElementFormThree(false);
          refNumberPhone.current.style.border = "2px solid #667788";
          setNumberPhoneValue(refNumberPhone.current.value);
        }
      }
    }
  };

  const onKeyDownForm = (e) => {
    if (e.target.id === "numberPhone") {
      if (refNumberPhone.current.value.length > 10) {
        if (e.code !== "Backspace" && e.code !== "Delete") {
          e.preventDefault();
          setNumberPhoneValue(false);
        }
      }
      for (let letter of refNumberPhone.current.value) {
        if (
          !letter.match("^[0-9]*$") &&
          e.code !== "Backspace" &&
          e.code !== "Delete"
        ) {
          e.preventDefault();
          setNumberPhoneValue(false);
        }
      }
    }
  };

  const onBlurForm = (e) => {
    if (e.target.id === "numberPhone") {
      if (refNumberPhone.current.value.length !== 10) {
        for (let letter of refNumberPhone.current.value) {
          if (!letter.match("^[0-9]*$")) {
            setElementFormThree(
              "Votre numéro de téléphone ne peut contenir que des chiffres"
            );
            refNumberPhone.current.style.border = "2px solid red";
            setNumberPhoneValue(false);
          } else if (letter.match("^[0-9]*$")) {
            setElementFormThree(
              "Votre numéro de téléphone doit contenir dix chiffres"
            );
            refNumberPhone.current.style.border = "2px solid red";
            setNumberPhoneValue(false);
          }
        }
      }
    }
  };

  return (
    <NumberPhone
      aria-label="Votre numéro de téléphone"
      ref={refNumberPhone}
      id="numberPhone"
      className="elementForm"
      placeholder="Téléphone"
      onKeyUp={(e) => onKeyUpForm(e)}
      onKeyDown={(e) => onKeyDownForm(e)}
      onBlur={(e) => onBlurForm(e)}
    ></NumberPhone>
  );
}
