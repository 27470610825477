import React, { useEffect } from "react";
import styled from "styled-components";
import HeaderBlog from "../../components/Header/HeaderBlog";
import Footer from "../../components/Footer";
import { NavBarDisplayFunction } from "../../functions/NavBarDisplayScrollFunction";
import { devices } from "../../style/Size";

const ContenairMain = styled.main`
  width: 94%;
  /* background-color: #ffffff; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 134px;
  margin-bottom: 200px;

  @media ${devices.laptopXXS} {
    margin-top: 114px;
    margin-bottom: 120px;
  }
  @media ${devices.mobileL} {
    margin-top: 100px;
    margin-bottom: 80px;
  }
  @media (max-height: 480px) {
    margin-bottom: 80px;
  }
`;

const TitleArticle = styled.h1`
  font-size: 34px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  padding-top: 10px;

  @media ${devices.tablet} {
    font-size: 32px;
  }
  @media ${devices.mobileL} {
    font-size: 30px;
  }
  @media ${devices.mobileM} {
    font-size: 28px;
  }
`;

const SubTitleArticle = styled.h2`
  font-size: 28px;
  font-family: "Russo One", sans-serif;
  color: #553322;

  @media ${devices.tablet} {
    font-size: 26px;
  }
  @media ${devices.mobileL} {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
`;

const TextArticle = styled.h2`
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  text-align: justify;
  margin-bottom: 40px;

  @media ${devices.mobileL} {
    margin-bottom: 50px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

export default function HiddenFuiteWater() {
  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction("headerBlogContenair", "les-signes-des-fuites-d'eau");
  });

  return (
    <>
      <HeaderBlog />
      <ContenairMain>
        <TitleArticle>
          Les Signes Cachés de Fuites d'Eau : Comment Détecter un Problème Avant
          qu'il ne Devienne Critique
        </TitleArticle>
        <TextArticle>
          Les fuites d'eau cachées peuvent être des problèmes insidieux,
          provoquant des dégâts coûteux avant même que vous ne vous en rendiez
          compte. Il est crucial de repérer les signes avant-coureurs dès le
          début. Dans cet article, nous examinerons les indicateurs subtils qui
          peuvent indiquer une fuite d'eau cachée dans votre maison.
        </TextArticle>
        <section>
          <SubTitleArticle>
            Taches d'<strong>humidité persistantes</strong>
          </SubTitleArticle>
          <TextArticle>
            Les taches d'<strong>humidité</strong> sur les murs ou les plafonds
            peuvent signaler une fuite d'eau cachée. Soyez attentif à tout
            changement de couleur ou d'aspect, car cela pourrait indiquer un
            problème sous-jacent.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Augmentation soudaine de la <strong>facture d'eau</strong>
          </SubTitleArticle>
          <TextArticle>
            Si votre consommation d'<strong>eau</strong> augmente soudainement
            sans explication apparente, cela peut être le signe d'une fuite.
            Surveillez vos <strong>factures d'eau</strong> de près et soyez
            vigilant quant à toute variation inexpliquée.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Bruits de l'<strong>eau qui coule</strong> dans les murs
          </SubTitleArticle>
          <TextArticle>
            Si vous entendez des bruits d'<strong>eau qui coule</strong> dans
            les murs, cela peut indiquer une fuite. Ne sous-estimez pas
            l'importance de signaler ces sons à un professionnel de la
            plomberie.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            <strong>Moisissures</strong> inexpliquées
          </SubTitleArticle>
          <TextArticle>
            Les <strong>moisissures</strong> peuvent se développer en présence
            d'<strong>humidité</strong> constante. Si vous découvrez
            soudainement des <strong>moisissures</strong> dans des endroits
            inhabituels, cela pourrait être lié à une fuite d'eau cachée.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Fluctuations de <strong>pression d'eau</strong>
          </SubTitleArticle>
          <TextArticle>
            Des fluctuations inexpliquées de la <strong>pression d'eau</strong>{" "}
            peuvent indiquer une fuite dans le système. Surveillez attentivement
            la <strong>pression d'eau</strong> et consultez un professionnel si
            vous remarquez des changements fréquents. En cas de soupçon de fuite
            d'eau cachée, il est essentiel de faire appel à un plombier
            expérimenté. La détection précoce peut éviter des dommages
            importants et des coûts de réparation considérables. N'oubliez pas
            de prendre des mesures immédiates si vous identifiez l'un de ces
            signes. La prévention est la clé pour éviter les problèmes majeurs
            liés aux fuites d'eau cachées.
          </TextArticle>
        </section>
      </ContenairMain>
      <Footer marginTop="180px" backgroundColor="#3c2114" />
    </>
  );
}
