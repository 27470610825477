import React, { useState, useEffect } from "react";
import Content from "./Content";

export default function ModalUrgenceDisplay() {
  const [modalUrgenceDisplayInHome, setModalUrgenceDisplayInHome] =
    useState(true);

  useEffect(() => {
    const lS = localStorage.getItem("mUnD");
    if (lS !== null) {
      const dateTaken = Number(localStorage.mUnD / 1000);
      const dateConnection = Date.now() / 1000;
      const delayDate = 60 * 60 * 24;

      if (dateTaken + delayDate > dateConnection) {
        setModalUrgenceDisplayInHome(false);
      } else {
        localStorage.removeItem("mUnD");
      }
    }
    const modalUrgence = document.querySelector("#modalUrgenceDisplay");
    const divP = document.querySelectorAll(".divP");

    if (modalUrgence) {
      modalUrgence.classList.add("startModal");
      for (let element of divP) {
        element.style.opacity = "1";
      }
    }
  }, []);

  useEffect(() => {
    const blockModal = document.querySelector("#modalUrgenceDisplay");

    if (blockModal) {
      if (window.innerWidth > 2330) {
        blockModal.style.left = `${(window.innerWidth - 2330) / 2 + 22}px`;
      }
      if (window.innerWidth <= 2330) {
        blockModal.style.left = `22px`;
      }
      if (window.innerWidth <= 490) {
        blockModal.style.left = `5%`;
      }
    }
  }, []);

  window.addEventListener("resize", () => {
    const blockModal = document.querySelector("#modalUrgenceDisplay");

    if (blockModal) {
      if (window.innerWidth > 2330) {
        blockModal.style.left = `${(window.innerWidth - 2330) / 2 + 22}px`;
      }
      if (window.innerWidth <= 2330) {
        blockModal.style.left = `22px`;
      }
      if (window.innerWidth <= 490) {
        blockModal.style.left = `5%`;
      }
    }
  });

  return (
    <>
      {modalUrgenceDisplayInHome && (
        <Content setModalUrgenceDisplayInHome={setModalUrgenceDisplayInHome} />
      )}
    </>
  );
}
