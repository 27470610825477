import React from "react";
import styled, { keyframes } from "styled-components";
import { devices } from "../../../style/Size";

const blockEffect = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
  transform: scale(1.08);
  }
`;

const Div = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  margin-left: 20px;
  margin-top: 5px;
  background-color: transparent;
  z-index: 1;

  @media ${devices.laptopM} {
    margin-left: 10px;
  }
  @media ${devices.tablet} {
    margin-top: 0px;
  }
  @media ${devices.mobileL} {
    margin-left: 8px;
  }
  @media ${devices.mobileM} {
    margin-left: 6px;
  }
  @media ${devices.mobileS} {
    margin-left: 4px;
  }
`;

const SubDiv = styled.div`
  display: flex;
`;

const BlockPhone = styled.div`
  font-size: 15px;
  font-family: "Russo One", sans-serif;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border: 2px solid #ffffff;
  border-radius: 8px;
  margin-top: 6px;
  @media (min-width: 991px) {
    &:hover {
      animation: ${blockEffect} 100ms linear;
      animation-fill-mode: forwards;
    }
  }
  @media ${devices.mobileL} {
    font-size: 14px;
    margin-top: 4px;
    margin-left: 0px;
    padding-left: 6px;
    padding-right: 6px;
  }
  @media ${devices.mobileM} {
    padding-left: 4px;
    padding-right: 4px;
  }
  @media ${devices.mobileS} {
    font-size: 13px;
    padding-left: 2px;
    padding-right: 2px;
  }
  @media ${devices.mobileXS} {
    margin-left: 4px;
    font-size: 10.6px;
    padding-left: 2px;
    padding-right: 2px;
  }
  @media (max-height: 420px) {
    font-size: 14px;
    margin-top: 6px;
    margin-left: 0px;
    padding-left: 6px;
    padding-right: 6px;
  }
`;

const BlockMobile = styled.div`
  font-size: 15px;
  font-family: "Russo One", sans-serif;
  margin: auto;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 4px;
  padding-bottom: 4px;
  border: 2px solid #ffffff;
  border-radius: 8px;
  margin-top: 6px;
  margin-left: 18px;
  @media (min-width: 991px) {
    &:hover {
      animation: ${blockEffect} 100ms linear;
      animation-fill-mode: forwards;
    }
  }
  @media ${devices.laptopM} {
    margin-left: 10px;
  }
  @media ${devices.mobileL} {
    font-size: 14px;
    margin-top: 4px;
    margin-left: 10px;
    padding-left: 8px;
    padding-right: 8px;
  }
  @media ${devices.mobileM} {
    margin-left: 6px;
    padding-left: 4px;
    padding-right: 4px;
  }
  @media ${devices.mobileS} {
    margin-left: 4px;
    font-size: 13px;
    padding-left: 2px;
    padding-right: 2px;
  }
  @media ${devices.mobileXS} {
    margin-left: 4px;
    font-size: 10.6px;
    padding-left: 2px;
    padding-right: 2px;
  }
  @media (max-height: 420px) {
    font-size: 14px;
    margin-top: 6px;
    margin-left: 6px;
    padding-left: 6px;
    padding-right: 6px;
  }
`;

export default function LogoPhones() {
  return (
    <>
      <Div>
        <SubDiv>
          <a
            style={{
              textDecoration: "none",
              color: "#FFFFFF",
              padding: "0",
              margin: "0",
            }}
            href="tel:+33426850682"
          >
            <BlockPhone>
              <i
                style={{
                  color: "#FFFFFF",
                  fontSize: "12px",
                  padding: "0",
                  margin: "0",
                }}
                className="fa-solid fa-phone"
              ></i>{" "}
              04 26 85 06 82
            </BlockPhone>
          </a>
          <a
            style={{ textDecoration: "none", color: "#FFFFFF" }}
            href="tel:+33625996359"
          >
            <BlockMobile>
              <i
                style={{ color: "#FFFFFF", fontSize: "12px" }}
                className="fa-solid fa-mobile-screen-button"
              ></i>{" "}
              06 25 99 63 59
            </BlockMobile>
          </a>
        </SubDiv>
      </Div>
    </>
  );
}
