import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ChauffeEau from "../../assets/BlockPromotion/chauffe-eau-promotion.webp";
import Climatisation from "../../assets/BlockPromotion/climatisation-reversible-promotion.webp";
import { OffsetTopFunction } from "../../functions/OffsetTopFunction";
import {
  EffectBlockPromotionAutomn,
  EffectBlockPromotionWinter,
  EffectBlockPromotionSpring,
  EffectBlockPromotionSummer,
} from "../../functions/BlockPromotionEffect";
import { devices } from "../../style/Size";

const ContenairPicture = styled.div`
  width: 80%;
  height: 560px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;

  @media ${devices.laptopXXS} {
    width: 100%;
  }
  @media ${devices.tablet} {
    margin-bottom: 14px;
  }
  @media (max-height: 480px) {
    height: 76vh;
  }
`;

const BlockTextOne = styled.div.attrs({
  id: "blockTextOne",
})`
  width: 100%;
  height: 144px;
  background: linear-gradient(to right, red, 20%, #cf3131, 88%, #b80606);
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
  transform: scaleY(0);
  transform-origin: bottom;
`;

const P = styled.p.attrs({
  id: "pBlockText",
})`
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 42px;
  padding-bottom: 12px;
  margin: 0;
  opacity: 0;

  @media ${devices.tablet} {
    font-size: 16px;
  }
  @media (max-width: 544px) {
    font-size: 15px;
  }
  @media ${devices.mobileL} {
    padding-top: 32px;
  }
  @media (max-width: 334px) {
    padding-top: 22px;
  }
  @media (max-width: 260px) {
    padding-top: 12px;
  }
`;

const ContenairPictureMobileDevice = styled.div`
  width: 100%;
  height: 560px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;

  @media ${devices.tablet} {
    margin-bottom: 14px;
  }
  @media ${devices.mobileL} {
    margin-bottom: 0px;
  }
  @media (max-height: 480px) {
    height: 76vh;
    margin-bottom: 0px;
  }
`;
const BlockTextMobileDevice = styled.div.attrs({
  id: "blockTextOne",
})`
  width: 100%;
  height: 144px;
  background: linear-gradient(to right, red, 20%, #cf3131, 88%, #b80606);
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
  transform-origin: bottom;
`;

const PMobileDevice = styled.p.attrs({
  id: "pBlockText",
})`
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 42px;
  padding-bottom: 12px;
  margin: 0;

  @media ${devices.tablet} {
    font-size: 16px;
  }
  @media (max-width: 544px) {
    font-size: 15px;
  }
  @media ${devices.mobileL} {
    padding-top: 32px;
  }
  @media (max-width: 334px) {
    padding-top: 22px;
  }
  @media (max-width: 260px) {
    padding-top: 12px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default function BlockPromotion() {
  const [seasonText, setSeasonText] = useState();
  const [materialText, setMaterialText] = useState();
  const [pictureSeason, setPictureSeason] = useState();
  const [textAlt, setTextAlt] = useState();
  const [widthScreenBlockPromotion, setWidthScreenBlockPromotion] = useState();

  useEffect(() => {
    if (window.innerWidth > 780) {
      setWidthScreenBlockPromotion(true);
    }
    if (window.innerWidth <= 780) {
      setWidthScreenBlockPromotion(false);
    }
  }, []);

  // window.addEventListener("resize", () => {
  //   if (window.innerWidth > 780) {
  //     setWidthScreenBlockPromotion(true);
  //   }
  //   if (window.innerWidth <= 780) {
  //     setWidthScreenBlockPromotion(false);
  //   }
  // });

  useEffect(() => {
    let pictureContenair;
    if (widthScreenBlockPromotion) {
      pictureContenair = document.querySelector(
        "#contenairPictureBlockPromotion"
      );
    }
    if (!widthScreenBlockPromotion) {
      pictureContenair = document.querySelector(
        "#contenairPictureBlockPromotionMobileDevice"
      );
    }
    const dateMonth = new Date().getMonth();
    const dateDay = new Date().getDay();
    console.log(dateMonth);
    console.log(dateDay);
    if (pictureContenair) {
      if (
        (dateMonth === 8 && dateDay >= 20) ||
        (dateMonth > 8 && dateDay < 11) ||
        (dateMonth === 11 && dateDay < 20)
      ) {
        setSeasonText("L'automne ");
        setMaterialText(" chauffe-eau et chaudières");
        setPictureSeason(ChauffeEau);
        setTextAlt(
          "Chauffe-eau cylindrique mural blanc posé sur carrelage bleu clair"
        );
      }
      if (
        (dateMonth === 11 && dateDay >= 20) ||
        dateMonth >= 0 ||
        (dateMonth === 2 && dateDay < 20)
      ) {
        setSeasonText("L'hiver ");
        setMaterialText(" chauffe-eau et chaudières");
        setPictureSeason(ChauffeEau);
        setTextAlt(
          "Chauffe-eau cylindrique mural blanc posé sur carrelage bleu clair"
        );
      }
      if (
        (dateMonth === 2 && dateDay >= 20) ||
        (dateMonth > 3 && dateDay < 5) ||
        (dateMonth === 5 && dateDay < 20)
      ) {
        setSeasonText("Le printemps ");
        setMaterialText(" climatisations");
        setPictureSeason(Climatisation);
        setTextAlt(
          "Climatisation réversible blanche avec dessin flocons de neige"
        );
      }
      if (
        (dateMonth === 5 && dateDay >= 20) ||
        (dateMonth > 6 && dateDay < 8) ||
        (dateMonth === 8 && dateDay < 20)
      ) {
        setSeasonText("L'été ");
        setMaterialText(" climatisations");
        setPictureSeason(Climatisation);
        setTextAlt(
          "Climatisation réversible blanche avec dessin flocons de neige"
        );
      }
    }
  }, []);

  const effectBlockPromotion = () => {
    const dateMonth = new Date().getMonth();
    const dateDay = new Date().getDay();
    if (
      (dateMonth === 8 && dateDay >= 20) ||
      (dateMonth > 8 && dateDay < 11) ||
      (dateMonth === 11 && dateDay < 20)
    ) {
      EffectBlockPromotionAutomn();
    } else if (
      (dateMonth === 11 && dateDay >= 20) ||
      dateMonth >= 0 ||
      (dateMonth === 2 && dateDay < 20)
    ) {
      EffectBlockPromotionWinter();
    } else if (
      (dateMonth === 2 && dateDay >= 20) ||
      (dateMonth > 3 && dateDay < 5) ||
      (dateMonth === 5 && dateDay < 20)
    ) {
      EffectBlockPromotionSpring();
    } else if (
      (dateMonth === 5 && dateDay >= 20) ||
      (dateMonth > 6 && dateDay < 8) ||
      (dateMonth === 8 && dateDay < 20)
    ) {
      EffectBlockPromotionSummer();
    }
  };

  const effectBlockText = (idElement) => {
    const contenairPictureBlockPromotion = document.querySelector(
      `#${idElement}`
    );
    const blockText = document.querySelector("#blockTextOne");
    const pBlockText = document.querySelector("#pBlockText");
    const screenY = window.scrollY + window.innerHeight;

    if (contenairPictureBlockPromotion) {
      if (
        OffsetTopFunction(contenairPictureBlockPromotion) - screenY <
        -contenairPictureBlockPromotion.offsetHeight
      ) {
        blockText.style.transform = "scaleY(1)";
        blockText.style.transition = "500ms";
        pBlockText.style.opacity = "1";
        pBlockText.style.transition = "300ms";
        pBlockText.style.transitionDelay = "500ms";
      }
    }
  };

  useEffect(() => {
    if (widthScreenBlockPromotion) {
      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          if (entry.target.id === "contenairPictureBlockPromotion") {
            window.addEventListener("scroll", () => {
              effectBlockPromotion();
              effectBlockText("contenairPictureBlockPromotion");
            });
          } else {
            window.removeEventListener("scroll", () => {
              effectBlockPromotion();
              effectBlockText("contenairPictureBlockPromotion");
            });
          }
        }
      });
      observer.observe(
        document.querySelector("#contenairPictureBlockPromotion")
      );
    }
  }, [widthScreenBlockPromotion]);

  return (
    <>
      {widthScreenBlockPromotion ? (
        <ContenairPicture id="contenairPictureBlockPromotion" className=" ">
          <Image src={pictureSeason} alt={textAlt} />
          <BlockTextOne>
            <P>
              {seasonText} est là ! Pensez aux{" "}
              <span style={{ whiteSpace: "nowrap" }}>{materialText} !</span>
              <br />
              Renseignez-vous pour connaître les promotions{" "}
              <span style={{ whiteSpace: "nowrap" }}>constructeurs !</span>{" "}
              <br />
              Possibilité de paiement en{" "}
              <span style={{ whiteSpace: "nowrap" }}>plusieurs fois !</span>
            </P>
          </BlockTextOne>
        </ContenairPicture>
      ) : (
        <ContenairPictureMobileDevice
          id="contenairPictureBlockPromotionMobileDevice"
          className=" "
        >
          <Image src={pictureSeason} alt={textAlt} />
          <BlockTextMobileDevice>
            <PMobileDevice>
              {seasonText} est là ! Pensez aux{" "}
              <span style={{ whiteSpace: "nowrap" }}>{materialText} !</span>
              <br />
              Renseignez-vous pour connaître les promotions{" "}
              <span style={{ whiteSpace: "nowrap" }}>constructeurs !</span>{" "}
              <br />
              Possibilité de paiement en{" "}
              <span style={{ whiteSpace: "nowrap" }}>plusieurs fois !</span>
            </PMobileDevice>
          </BlockTextMobileDevice>
        </ContenairPictureMobileDevice>
      )}
    </>
  );
}
