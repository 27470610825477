import React, { useEffect } from "react";
import styled from "styled-components";
import Footer from "../../components/Footer";
import HeaderBlog from "../../components/Header/HeaderBlog";
import { NavBarDisplayFunction } from "../../functions/NavBarDisplayScrollFunction";
import { devices } from "../../style/Size";

const ContenairMain = styled.main`
  width: 94%;
  /* background-color: #ffffff; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 134px;
  margin-bottom: 200px;

  @media ${devices.laptopXXS} {
    margin-top: 114px;
    margin-bottom: 120px;
  }
  @media ${devices.mobileL} {
    margin-top: 100px;
    margin-bottom: 80px;
  }
  @media (max-height: 480px) {
    margin-bottom: 80px;
  }
`;

const TitleArticle = styled.h1`
  font-size: 34px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  padding-top: 10px;

  @media ${devices.tablet} {
    font-size: 32px;
  }
  @media ${devices.mobileL} {
    font-size: 30px;
  }
  @media ${devices.mobileM} {
    font-size: 28px;
  }
`;

const SubTitleArticle = styled.h2`
  font-size: 28px;
  font-family: "Russo One", sans-serif;
  color: #553322;

  @media ${devices.tablet} {
    font-size: 26px;
  }
  @media ${devices.mobileL} {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
`;

const TextArticle = styled.h2`
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  text-align: justify;
  margin-bottom: 40px;

  @media ${devices.mobileL} {
    margin-bottom: 50px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

export default function PompesChaleur() {
  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction("headerBlogContenair", "les-pompes-a-chaleur");
  });

  return (
    <>
      <HeaderBlog />
      <ContenairMain>
        <TitleArticle>
          Pompes à chaleur : L'Innovation qui réchauffe nos Foyers
        </TitleArticle>
        <TextArticle>
          Dans le monde de la plomberie moderne, les pompes à chaleur ont émergé
          comme une alternative révolutionnaire pour chauffer nos foyers.
          Contrairement aux systèmes de chauffage traditionnels, les pompes à
          chaleur exploitent les ressources naturelles pour offrir une
          efficacité énergétique exceptionnelle, tout en réduisant l'empreinte
          carbone de nos habitations. Plongeons dans le monde fascinant des
          pompes à chaleur et découvrons leurs conditions, avantages et bien
          plus encore.
        </TextArticle>
        <section>
          <SubTitleArticle>
            Conditions Idéales pour l'Installation :
          </SubTitleArticle>
          <TextArticle>
            Les pompes à chaleur fonctionnent de manière optimale dans des
            environnements où les températures hivernales ne plongent pas trop
            bas. Idéalement, elles conviennent parfaitement aux régions aux
            climats modérés, mais avec des progrès technologiques constants,
            elles deviennent de plus en plus viables même dans des climats plus
            froids. En outre, un espace extérieur adéquat pour l'installation de
            l'unité extérieure est essentiel, ainsi qu'un système de
            distribution de chaleur efficace à l'intérieur de la maison.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Avantages des Pompes à Chaleur :</SubTitleArticle>
          <TextArticle>
            <strong>Efficacité Énergétique</strong> :
            <br />
            <br />
            Les pompes à chaleur exploitent les sources naturelles de chaleur,
            telles que l'air, l'eau ou le sol, pour chauffer nos maisons. Par
            conséquent, elles peuvent produire jusqu'à quatre fois plus
            d'énergie qu'elles n'en consomment, ce qui se traduit par des
            factures de chauffage considérablement réduites.
            <br />
            <br />
            <strong>Écologique</strong> :
            <br />
            <br />
            En utilisant les ressources naturelles comme source de chaleur, les
            pompes à chaleur émettent significativement moins de gaz à effet de
            serre par rapport aux systèmes de chauffage traditionnels. Cela
            contribue à réduire notre empreinte carbone et à préserver
            l'environnement.
            <br />
            <br />
            Polyvalence :
            <br />
            <br />
            Les pompes à chaleur ne se contentent pas de chauffer nos maisons ;
            elles peuvent également être inversées pour fournir un
            refroidissement pendant les mois d'été. Cette polyvalence en fait un
            investissement tout au long de l'année pour le confort de votre
            foyer.
            <br />
            <br />
            Durabilité :
            <br />
            <br />
            Avec un entretien régulier, les pompes à chaleur ont une durée de
            vie plus longue que de nombreux autres systèmes de chauffage. Leur
            conception simple et leur faible nombre de pièces mobiles réduisent
            les risques de panne et prolongent leur efficacité au fil du temps.
            <br />
            <br />
            Avantage Budgétaire :
            <br />
            <br />
            Bien que l'investissement initial dans une pompe à chaleur puisse
            sembler plus élevé que celui d'un système de chauffage traditionnel,
            les économies réalisées sur les factures d'énergie peuvent
            rapidement compenser cette dépense supplémentaire. En effet, avec
            une efficacité énergétique accrue, les pompes à chaleur peuvent
            réduire les coûts de chauffage de manière significative, offrant
            ainsi un retour sur investissement à long terme.
            <br />
            <br />
            Subventions et Incitations :
            <br />
            <br />
            De nombreuses régions offrent des incitations financières, telles
            que des subventions et des crédits d'impôt, pour encourager
            l'installation de systèmes de chauffage écologiques, y compris les
            pompes à chaleur. Cela peut rendre l'investissement initial plus
            abordable et accélérer le retour sur investissement.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Installation et Maintenance</SubTitleArticle>
          <TextArticle>
            L'installation d'une pompe à chaleur nécessite généralement
            l'intervention de professionnels qualifiés. Ils évalueront votre
            domicile pour déterminer la taille et le type de pompe à chaleur le
            mieux adapté à vos besoins. Une fois installée, une maintenance
            régulière est essentielle pour assurer un fonctionnement optimal.
            Cela peut inclure le remplacement des filtres, l'inspection des
            composants électriques et mécaniques, ainsi que le nettoyage des
            unités intérieures et extérieures.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Types de Pompes à Chaleur</SubTitleArticle>
          <TextArticle>
            Il existe plusieurs types de pompes à chaleur, chacun adapté à des
            besoins spécifiques :
            <br />
            <br />
            Les pompes à chaleur air-air : Elles extraient la chaleur de l'air
            extérieur et la transfèrent à l'intérieur de la maison via un
            système de soufflerie.
            <br />
            <br />
            Les pompes à chaleur air-eau : Elles chauffent l'eau qui circule
            dans un système de chauffage central, fournissant ainsi de la
            chaleur à travers des radiateurs ou un plancher chauffant.
            <br />
            <br />
            Les pompes à chaleur géothermiques : Elles exploitent la chaleur
            stockée dans le sol ou l'eau souterraine pour chauffer la maison,
            offrant une solution particulièrement efficace et stable.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Conclusion</SubTitleArticle>
          <TextArticle>
            Les pompes à chaleur représentent bien plus qu'une simple innovation
            dans le domaine de la plomberie ; elles incarnent un changement
            significatif vers des systèmes de chauffage plus durables et
            économes en énergie. Leur efficacité, leur écologie, leur
            polyvalence et leur avantage budgétaire en font un choix attrayant
            pour les propriétaires soucieux de l'environnement et de leur
            budget. Si vous envisagez une mise à niveau de votre système de
            chauffage, les pompes à chaleur pourraient bien être la solution
            moderne que vous recherchez. N'hésitez pas à contacter un
            professionnel pour en savoir plus sur la manière dont les pompes à
            chaleur peuvent transformer votre domicile en un espace chaleureux
            et confortable, tout en contribuant à la préservation de notre
            planète.
          </TextArticle>
        </section>
      </ContenairMain>
      <Footer marginTop="180px" backgroundColor="#3c2114" />
    </>
  );
}
