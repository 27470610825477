import React from "react";
import styled from "styled-components";
import PlomberieUrgence from "../../assets/plomberie-urgence.png";
import { devices } from "../../style/Size";

const Column3 = styled.div`
  width: 18%;
  height: 60%;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 50px;
  @media ${devices.laptopXXS} {
    width: 200px;
  }
  @media ${devices.mobileS} {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const ColumnTitle = styled.p`
  width: 100%;
  font-size: 20px;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  @media ${devices.laptopL} {
    font-size: 18px;
  }
  @media ${devices.laptopS} {
    font-size: 16px;
  }
`;

const BlockImageMini = styled.div.attrs({
  id: "blockImageMini",
})`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: linear-gradient(to right, red, 20%, #cf3131, 88%, #b80606);
  margin-left: auto;
  margin-right: auto;
  @media ${devices.laptopS} {
    width: 60px;
    height: 60px;
    margin-top: 20px;
  }
  @media ${devices.laptopXXS} {
    margin-top: 0px;
  }
`;

const ImageLogo = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  @media ${devices.laptopS} {
    width: 60px;
    height: 60px;
  }
`;

const NumberPhone = styled.div`
  font-size: 16px;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 30px;
  white-space: nowrap;
  @media ${devices.laptopS} {
    font-size: 14px;
    margin-top: 26px;
  }
  @media ${devices.laptopXXS} {
    margin-top: 12px;
    margin-bottom: 16px;
  }
`;

export default function ColumnThree() {
  return (
    <Column3>
      <ColumnTitle>URGENCE</ColumnTitle>
      <BlockImageMini>
        <ImageLogo
          src={PlomberieUrgence}
          alt="dessin plombier en tenue professionnelle qui tient une caisse à outil pour intervenir en urgence ou dépannage"
        ></ImageLogo>
      </BlockImageMini>
      <NumberPhone>06 25 99 63 59</NumberPhone>
    </Column3>
  );
}
