import React, { useEffect } from "react";
import styled from "styled-components";
import HeaderBlog from "../../components/Header/HeaderBlog";
import Footer from "../../components/Footer";
import { NavBarDisplayFunction } from "../../functions/NavBarDisplayScrollFunction";
import { devices } from "../../style/Size";

const ContenairMain = styled.main`
  width: 94%;
  /* background-color: #ffffff; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 134px;
  margin-bottom: 200px;

  @media ${devices.laptopXXS} {
    margin-top: 114px;
    margin-bottom: 120px;
  }
  @media ${devices.mobileL} {
    margin-top: 100px;
    margin-bottom: 80px;
  }
  @media (max-height: 480px) {
    margin-bottom: 80px;
  }
`;

const TitleArticle = styled.h1`
  font-size: 34px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  padding-top: 10px;

  @media ${devices.tablet} {
    font-size: 32px;
  }
  @media ${devices.mobileL} {
    font-size: 30px;
  }
  @media ${devices.mobileM} {
    font-size: 28px;
  }
`;

const SubTitleArticle = styled.h2`
  font-size: 28px;
  font-family: "Russo One", sans-serif;
  color: #553322;

  @media ${devices.tablet} {
    font-size: 26px;
  }
  @media ${devices.mobileL} {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
`;

const TextArticle = styled.h2`
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  text-align: justify;
  margin-bottom: 40px;

  @media ${devices.mobileL} {
    margin-bottom: 50px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

export default function WaterHeaterMaintenance() {
  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction(
      "headerBlogContenair",
      "les-avantages-de-la-detection-de-fuites-par-camera"
    );
  });

  return (
    <>
      <HeaderBlog />
      <ContenairMain>
        <TitleArticle>
          <strong>
            La Détection de Fuites d'Eau par Caméra : Les Avantages
            Incontestables
          </strong>
        </TitleArticle>
        <TextArticle>
          Les fuites d'eau peuvent rapidement se transformer en un problème
          majeur pour les propriétaires, provoquant des dommages coûteux et des
          perturbations importantes. Heureusement, la technologie moderne offre
          des solutions avancées pour résoudre ce problème. La{" "}
          <strong>détection de fuites d'eau par caméra</strong> est l'une de ces
          innovations, offrant une approche précise et efficace pour identifier
          et résoudre les problèmes liés aux fuites d'eau.
        </TextArticle>
        <section>
          <SubTitleArticle>Localisation Précise des Fuites</SubTitleArticle>
          <TextArticle>
            La <strong>détection de fuites d'eau par caméra</strong> permet aux
            plombiers d'identifier rapidement et avec précision l'emplacement
            exact des fuites. Cette technologie avancée utilise des caméras
            spéciales pour inspecter les conduites d'eau, permettant ainsi de
            cibler le problème sans avoir besoin de travaux d'excavation
            coûteux.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Réduction des Coûts de Réparation</SubTitleArticle>
          <TextArticle>
            En localisant rapidement les fuites, les plombiers peuvent
            intervenir de manière ciblée, réduisant ainsi les coûts de
            réparation. La précision de la <strong>détection</strong> permet
            d'éviter les travaux inutiles et les dommages collatéraux, ce qui se
            traduit par des économies significatives pour les propriétaires.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Prévention des Dommages Structurels</SubTitleArticle>
          <TextArticle>
            En identifiant les fuites d'eau à un stade précoce, la{" "}
            <strong>détection par caméra</strong> aide à prévenir les dommages
            structurels. Les infiltrations d'eau non détectées peuvent causer
            des dégâts importants aux fondations, aux murs et aux planchers,
            mais cette technologie permet d'intervenir avant que ces problèmes
            ne deviennent critiques.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Économie d'Eau Précieuse</SubTitleArticle>
          <TextArticle>
            La <strong>détection</strong> rapide des fuites permet également de
            préserver l'eau, une ressource précieuse. En réparant rapidement les
            fuites, les propriétaires contribuent à la conservation de l'eau et
            réduisent leurs factures d'eau mensuelles.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Conclusion</SubTitleArticle>
          <TextArticle>
            La <strong>détection de fuites d'eau par caméra</strong> représente
            une avancée significative dans le domaine de la plomberie, offrant
            des avantages considérables tant pour les plombiers que pour les
            propriétaires. La précision, la réduction des coûts et la prévention
            des dommages font de cette technologie un outil indispensable pour
            assurer la santé à long terme des systèmes de plomberie. Investir
            dans la <strong>détection précoce des fuites par caméra</strong> est
            non seulement judicieux sur le plan financier, mais aussi crucial
            pour la préservation des infrastructures et de l'environnement.
          </TextArticle>
        </section>
      </ContenairMain>
      <Footer marginTop="180px" backgroundColor="#3c2114" />
    </>
  );
}
