import React, { useEffect } from "react";
import styled from "styled-components";
import HeaderBlog from "../../components/Header/HeaderBlog";
import Footer from "../../components/Footer";
import { NavBarDisplayFunction } from "../../functions/NavBarDisplayScrollFunction";
import { devices } from "../../style/Size";

const ContenairMain = styled.main`
  width: 94%;
  /* background-color: #ffffff; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 134px;
  margin-bottom: 200px;

  @media ${devices.laptopXXS} {
    margin-top: 114px;
    margin-bottom: 120px;
  }
  @media ${devices.mobileL} {
    margin-top: 100px;
    margin-bottom: 80px;
  }
  @media (max-height: 480px) {
    margin-bottom: 80px;
  }
`;

const TitleArticle = styled.h1`
  font-size: 34px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  padding-top: 10px;

  @media ${devices.tablet} {
    font-size: 32px;
  }
  @media ${devices.mobileL} {
    font-size: 30px;
  }
  @media ${devices.mobileM} {
    font-size: 28px;
  }
`;

const SubTitleArticle = styled.h2`
  font-size: 28px;
  font-family: "Russo One", sans-serif;
  color: #553322;

  @media ${devices.tablet} {
    font-size: 26px;
  }
  @media ${devices.mobileL} {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
`;

const TextArticle = styled.h2`
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  text-align: justify;
  margin-bottom: 40px;

  @media ${devices.mobileL} {
    margin-bottom: 50px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

export default function DesembouageRadiateur() {
  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction("headerBlogContenair", "desembouage-radiateur");
  });

  return (
    <>
      <HeaderBlog />
      <ContenairMain>
        <TitleArticle>
          <strong>Désembouage de Radiateur</strong> : Un Guide Complet pour un
          Chauffage Efficace
        </TitleArticle>
        <TextArticle>
          Avec l'arrivée des saisons froides, il est crucial de s'assurer que
          votre système de chauffage fonctionne de manière optimale. L'un des
          problèmes courants qui peuvent affecter l'efficacité de votre
          radiateur est l'accumulation de boue et de débris, un phénomène connu
          sous le nom de <strong>désembouage</strong>.
        </TextArticle>
        <section>
          <SubTitleArticle>
            Comprendre le <strong>Désembouage de Radiateur</strong>
          </SubTitleArticle>
          <TextArticle>
            Le <strong>désembouage de radiateur</strong> est un processus
            essentiel pour maintenir la performance de votre système de
            chauffage central. Au fil du temps, des particules de boue, de la
            rouille et d'autres impuretés peuvent s'accumuler dans le circuit
            d'eau du radiateur. Cela peut entraîner une diminution de
            l'efficacité du chauffage, des bruits étranges et même des pannes.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Pourquoi le <strong>Désembouage</strong> est-il nécessaire?
          </SubTitleArticle>
          <TextArticle>
            1. Optimisation de la Performance <br />
            <br />
            Un <strong>radiateur</strong> encombré de boue ne peut pas
            fonctionner de manière optimale. Le désembouage permet de restaurer
            la circulation normale de l'eau, assurant ainsi une meilleure
            distribution de la chaleur dans toute la maison.
            <br />
            <br />
            <br />
            2. Économie d'Énergie <br />
            <br />
            Un système de chauffage propre fonctionne de manière plus efficace,
            ce qui se traduit par une consommation d'énergie réduite. En
            éliminant les obstructions, le <strong>désembouage</strong>{" "}
            contribue à optimiser l'efficacité énergétique de votre
            installation. <br />
            <br />
            <br />
            3. Durée de Vie Prolongée du <strong>Radiateur</strong>
            <br />
            <br />
            La boue accumulée peut causer des dommages à long terme au
            radiateur. En effectuant un désembouage régulier, vous prolongez la
            durée de vie de votre système de chauffage, ce qui peut vous faire
            économiser des coûts de remplacement.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Comment Effectuer un <strong>Désembouage de Radiateur</strong>
          </SubTitleArticle>
          <TextArticle>
            Le désembouage peut être réalisé par un professionnel ou en
            utilisant des kits de désembouage disponibles dans le commerce.
            <br />
            <br />
            <br />
            Voici les étapes générales pour un désembouage efficace : <br />
            <br />
            <br />
            1. Identification du Problème <br />
            <br />
            Avant de commencer le désembouage, il est essentiel de diagnostiquer
            le problème. Des signes tels que des radiateurs froids en bas, des
            bruits étranges ou des fluctuations de pression peuvent indiquer la
            présence de boue. <br />
            <br />
            <br />
            2. Vidange du <strong>Système de Chauffage</strong> <br />
            <br />
            La première étape consiste à vidanger le système pour éliminer l'eau
            contaminée. Cette eau peut être remplacée par une solution
            désembouante. <br />
            <br />
            <br />
            3. Utilisation de Produits <strong>Désembouants</strong>
            <br />
            <br />
            Les produits désembouants sont conçus pour éliminer les dépôts de
            boue et de rouille. Suivez attentivement les instructions du
            fabricant pour obtenir les meilleurs résultats. <br />
            <br />
            <br />
            4. Rinçage et Recharge <br />
            <br />
            Après avoir laissé agir le produit désembouant, rincez le système
            pour éliminer les résidus. Rechargez ensuite le système avec de
            l'eau propre et remettez-le en marche.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Conclusion</SubTitleArticle>
          <TextArticle>
            Le désembouage de radiateur est une étape cruciale pour assurer un
            chauffage efficace et économique. En investissant dans l'entretien
            de votre système de chauffage, vous pouvez non seulement améliorer
            votre confort thermique, mais aussi prolonger la durée de vie de vos
            radiateurs. Planifiez un désembouage régulier pour tirer le meilleur
            parti de votre installation de chauffage central. N'oubliez pas de
            consulter un professionnel si vous n'êtes pas sûr du processus ou si
            vous préférez avoir l'assistance d'un expert. Un système de
            chauffage bien entretenu est la clé d'un hiver confortable et sans
            tracas. L'Entreprise Navarro située à Marseille saura réaliser cette
            étape avec succès. Appelez nous pour plus de renseignements.
          </TextArticle>
        </section>
      </ContenairMain>
      <Footer marginTop="180px" backgroundColor="#3c2114" />
    </>
  );
}
