import React from "react";
import styled from "styled-components";
import { devices } from "../../style/Size";

const DivTitle = styled.div`
  display: flex;
  position: absolute;
  top: 40vh;
  left: 16%;
  filter: brightness(1.6);

  @media ${devices.tablet} {
    left: 0;
    margin-left: 50%;
    transform: translateX(-50%);
    top: 16vh;
  }
  @media ${devices.mobileXS} {
    top: 20vh;
    transform: translateX(0%);
    margin-left: 4px;
  }
  @media (max-height: 480px) {
    top: 14vh;
  }
`;

const ContenairTitle = styled.div`
  text-align: start;
`;

const H1 = styled.h1`
  font-size: 48px;
  font-weight: 100;
  margin: 0;
  padding-top: 4px;
  color: #ffffff;
  font-family: "Russo One", sans-serif;
  white-space: nowrap;

  @media ${devices.laptopS} {
    font-size: 40px;
  }
  @media ${devices.tablet} {
    font-size: 32px;
  }
  @media ${devices.mobileL} {
    font-size: 30px;
  }
  @media ${devices.mobileS} {
    font-size: 28px;
  }
  @media ${devices.mobileXS} {
    font-size: 25px;
  }
`;

const H2 = styled.p`
  font-size: 28px;
  font-weight: 100;
  margin: 0;
  padding-bottom: 10px;
  color: #ffffff;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  white-space: nowrap;

  @media ${devices.laptopS} {
    font-size: 26px;
  }
  @media ${devices.tablet} {
    font-size: 22.5px;
  }
  @media (max-width: 540px) {
    font-size: 16.5px;
    padding-bottom: 4px;
  }
  @media ${devices.mobileM} {
    font-size: 14px;
  }
  @media ${devices.mobileS} {
    font-size: 12px;
  }
  @media ${devices.mobileXS} {
    font-size: 10px;
  }
`;

const P3 = styled.p`
  font-size: 28px;
  font-weight: 100;
  margin: 0;
  color: #ffffff;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  white-space: nowrap;

  @media ${devices.laptopS} {
    font-size: 26px;
  }
  @media ${devices.tablet} {
    font-size: 22.5px;
  }
  @media (max-width: 540px) {
    font-size: 16.5px;
    margin-top: -4px;
  }
  @media ${devices.mobileM} {
    font-size: 14px;
  }
  @media ${devices.mobileS} {
    font-size: 12px;
  }
  @media ${devices.mobileXS} {
    font-size: 10px;
  }
`;

const P4 = styled.p`
  font-size: 44px;
  font-weight: 100;
  margin: 0;
  color: #ffffff;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  white-space: nowrap;

  @media ${devices.laptopS} {
    font-size: 36px;
  }
  @media ${devices.tablet} {
    font-size: 28px;
  }
  @media (max-width: 640px) {
    font-size: 24px;
  }
`;

export default function ContenairTitleHeader() {
  return (
    <DivTitle>
      <ContenairTitle>
        <H1>Entreprise Navarro</H1>
        <H2>Plombier - Chauffagiste - Climaticien</H2>
        <P3>de père en fils depuis plus de 50ans</P3>
        <P4>
          à <strong style={{ fontWeight: "100" }}>Marseille</strong>
        </P4>
      </ContenairTitle>
    </DivTitle>
  );
}
