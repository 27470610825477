import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import HeaderSubSection from "../components/Header/HeaderSubSection";
import BodySubSection from "../components/SubSection/BodySubSection";
import LastBlockSubSection from "../components/SubSection/LastBlockSubSection";
import ButtonQuote from "../components/Buttons/ButtonQuote";
import Footer from "../components/Footer";
import WashbasinPicture from "../assets/Installation/lavabo.webp";
import WahsbasinClassic from "../assets/Wahsbasin/lavabo-classique.webp";
import WahsbasinMust from "../assets/Wahsbasin/lavabo-design.webp";
import { NavBarDisplayFunction } from "../functions/NavBarDisplayScrollFunction";
import { EffectSubSectionFunction } from "../functions/EffectSubSectionFunction";
import { EffectTitlePageFunction } from "../functions/EffectTitlePageFunction";
import { devices } from "../style/Size";

const ContenairMain = styled.main`
  width: 100%;
  position: relative;
  margin-top: 70px;

  @media ${devices.laptopXXS} {
    margin-top: 110px;
  }
  @media ${devices.tablet} {
    margin-top: 40px;
  }
`;

export default function Washbasin() {
  useEffect(() => {
    EffectTitlePageFunction("titleWashbasinContenair");
    setTimeout(() => {
      EffectTitlePageFunction("idBlockElement");
    }, 600);
  }, []);

  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction("headerWashbasinContenair");
  });

  useEffect(() => {
    const titleSection = document.querySelector("#blockTitle");
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        window.addEventListener("scroll", () => {
          EffectSubSectionFunction("blockTitle", "/installation/lavabos");
        });
      }
    });
    observer.observe(titleSection);
  }, []);

  window.addEventListener("resize", () => {
    const blockSection = document.querySelectorAll(`.imageSection`);
    if (blockSection) {
      EffectSubSectionFunction("blockTitle", "/installation/lavabos");
      if (window.innerWidth <= 568) {
        for (let element of blockSection) {
          element.style.transform = "translateX(0vw)";
          element.style.transition = "all 300ms";
        }
      }
    }
  });

  return (
    <>
      <HeaderSubSection
        id="headerWashbasinContenair"
        idTitle="titleWashbasinContenair"
        src={WashbasinPicture}
        alt="Lavabo blanc rectangulaire raffiné sur meuble de salle de bain dans pièce aux carrelages rectangulaires blancs"
        titlePage="Lavabos"
      />
      <ContenairMain>
        <BodySubSection
          idText="textSection"
          imageClassname="imageSection"
          idBlockElement="idBlockElement"
          opacity="0.5"
          src={WahsbasinClassic}
          alt="Lavabo classique rectangulaire en céramique blanc sur meuble de salle de bain blanc laqué avec tirroirs"
          title="Les Classiques & Aboutis"
          text={[
            "Des ",
            <strong key="1" style={{ fontWeight: "100" }}>
              lavabos classiques
            </strong>,
            " à des ",
            <strong key="2" style={{ fontWeight: "100" }}>
              lavabos
            </strong>,
            " plus aboutis. ",
            <strong key="3" style={{ fontWeight: "100" }}>
              Lavabos en céramique
            </strong>,
            ". Résistants et confortables. Avec ",
            <strong key="4" style={{ fontWeight: "100" }}>
              mitigeur classique
            </strong>,
            ". Plusieurs références pour un large choix de dimensions, de formes et de coloris.",
            <br key="5" />,
            <br key="6" />,
            "Le plus généralement, nos clients désirent accompagner leur lavabo de ",
            <strong key="7" style={{ fontWeight: "100" }}>
              meubles de salle de bain
            </strong>,
            ". Toutes sortes de meubles sont disponibles. Des meubles compacts, travaillés, résistants. Des meubles aux finitions laquées ou mates, de toutes dimensions et couleurs, comportant de nombreux rangements.",
            <br key="8" />,
            <br key="9" />,
            "Nos ",
            <strong key="10" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " se rendent sur place pour effectuer un ",
            <strong key="11" style={{ fontWeight: "100" }}>
              devis 100% gratuit et personnalisé
            </strong>,
            " afin d'étudier le ",
            <strong key="12" style={{ fontWeight: "100" }}>
              coût et temps d'installation plomberie
            </strong>,
            ". Le temps de pose est généralement aux environs de quatre heures.",
            <br key="13" />,
            <br key="14" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="15"
              to="https://catalog.geberit.fr/fr-FR/systems/CH1_298911"
              target="_blank"
            >
              Geberit
            </Link>,
            ", ",
            <Link
              key="16"
              to="https://www.grohe.fr/fr_fr/pour-la-salle-de-bains/ceramics/"
              target="_blank"
            >
              Grohe
            </Link>,
            ", et ",
            <Link
              key="17"
              to="https://www.jacobdelafon.fr/vasquelavabo"
              target="_blank"
            >
              Jacob Delafon
            </Link>,
            ".",
          ]}
        />
        <LastBlockSubSection
          imageClassname="imageSection"
          src={WahsbasinMust}
          alt="Lavabo ovale effet bois lissé sur meuble gris anthracite élégant"
          title="Les Luxueux"
          text={[
            "Des ",
            <strong key="1" style={{ fontWeight: "100" }}>
              lavabos de luxe
            </strong>,
            ". Avec ",
            <strong key="2" style={{ fontWeight: "100" }}>
              mitigeur thermostatique
            </strong>,
            " avec ou sans ",
            <strong key="3" style={{ fontWeight: "100" }}>
              capteur de mouvement
            </strong>,
            " pour leur déclenchement. ",
            <strong key="4" style={{ fontWeight: "100" }}>
              Mitigeur en acier, bronze, ou cuivré
            </strong>,
            ". Des ",
            <strong key="5" style={{ fontWeight: "100" }}>
              lavabos
            </strong>,
            " de toutes dimensions, de toutes formes, de tous coloris. ",
            <strong key="6" style={{ fontWeight: "100" }}>
              Lavabos en céramique, en marbre, en ciment, en cuivre
            </strong>,
            ", plusieurs matériaux pour leur composition sont disponibles. Habillés de ",
            <strong key="7" style={{ fontWeight: "100" }}>
              meubles de salle de bain de haute qualité en bois noble
            </strong>,
            ", avec installation ou non de plan de toilettes",
            ". Optez sinon pour un ",
            <strong key="8" style={{ fontWeight: "100" }}>
              plan de toilettes moulé
            </strong>,
            ". ",
            <br key="9" />,
            <br key="10" />,
            "Nos ",
            <strong key="11" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " se rendent sur place pour effectuer un ",
            <strong key="12" style={{ fontWeight: "100" }}>
              devis 100% gratuit et personnalisé
            </strong>,
            " afin d'étudier le ",
            <strong key="13" style={{ fontWeight: "100" }}>
              coût et temps d'installation plomberie
            </strong>,
            ". Le temps de pose est généralement aux environs de quatre heures pour un ",
            <strong key="14" style={{ fontWeight: "100" }}>
              lavabo
            </strong>,
            " seul.",
            <br key="15" />,
            <br key="16" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="17"
              to="https://catalog.geberit.fr/fr-FR/systems/CH1_298911"
              target="_blank"
            >
              Geberit
            </Link>,
            ", ",
            <Link
              key="18"
              to="https://www.grohe.fr/fr_fr/pour-la-salle-de-bains/ceramics/"
              target="_blank"
            >
              Grohe
            </Link>,
            ", et ",
            <Link
              key="19"
              to="https://www.jacobdelafon.fr/vasquelavabo"
              target="_blank"
            >
              Jacob Delafon
            </Link>,
            ".",
          ]}
        />
        <ButtonQuote />
      </ContenairMain>
      <Footer backgroundColor="#667788" />
    </>
  );
}
