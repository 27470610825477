import React from "react";
import styled from "styled-components";
import "../../style/Animation/KeyFrames/ModalUrgence.css";

const ButtonClose = styled.button.attrs({
  id: "buttonClose",
})`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  z-index: 2;
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
  &:hover + #iconButtonCloseModalUrgence {
    filter: brightness(1.1);
    transform: scale(1.125);
    transition: all 140ms ease-in-out;
  }
`;

const IconP = styled.p.attrs({
  id: "iconButtonCloseModalUrgence",
})`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #556699;
  position: absolute;
  top: -10px;
  right: 10px;
  z-index: 1;
`;

export default function ButtonCloseAndIcon() {
  const effectModalReduction = () => {
    const modalUrgenceDisplay = document.querySelector("#modalUrgenceDisplay");
    const contenairImageMini = document.querySelector("#contenairImageMini");
    const buttonClose = document.querySelector("#buttonClose");
    const icon = document.querySelector("#iconButtonCloseModalUrgence");
    const divP = document.querySelectorAll(".divP");
    modalUrgenceDisplay.classList.remove("blockModalDisplay");
    modalUrgenceDisplay.classList.add("blockModalReduction");
    contenairImageMini.classList.remove("contenairImageModalNormal");
    contenairImageMini.classList.add("contenairImageModalMini");

    setTimeout(() => {
      buttonClose.style.display = "none";
      icon.style.display = "none";
      for (let element of divP) {
        element.style.display = "none";
      }
    }, 20);
  };

  return (
    <>
      <ButtonClose
        aria-label="Fermer bulle info urgence"
        onClick={effectModalReduction}
      />
      <IconP>
        <i
          style={{
            fontSize: "20px",
            color: "#FFFFFF",
            position: "absolute",
            top: "5px",
            left: "7.5px",
          }}
          className="fa-solid fa-xmark"
        ></i>
      </IconP>
    </>
  );
}
