import React from "react";
import styled from "styled-components";
import LogoPhones from "../Header/HeaderHome/LogoPhones";
import ListeMenu from "./ListeMenu";
import { devices } from "../../style/Size";
import NavBarMini from "./NavBarMini/NavBarMini";

const BlockNavAndMenu = styled.div.attrs({
  id: "blockNavAndMenu",
})`
  width: 100%;
  min-width: 250px;
  max-width: 2330px;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 600;
  @media ${devices.tablet} {
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;

const Nav = styled.nav`
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: space-between;
  font-family: "Russo One", sans-serif;
  margin-bottom: 0;
  @media ${devices.mobileL} {
    height: 34px;
  }
`;

export default function NavBar(props) {
  return (
    <>
      <BlockNavAndMenu style={{ backgroundColor: `${props.backgroundColor}` }}>
        <Nav>
          <LogoPhones />
          <ListeMenu />
          <NavBarMini />
        </Nav>
      </BlockNavAndMenu>
    </>
  );
}
