import "../style/Animation/KeyFrames/EffectDisplayPrixBigFiche.css";

const Effect3dFunction = (e, classNameFiche, buttonState, ratio) => {
  const allFiches = document.querySelectorAll(`.${classNameFiche}`);

  if (
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    buttonState(false);
  } else {
    buttonState(true);
    for (let elementFiche of allFiches) {
      if (e.target.id === elementFiche.children[0].id) {
        let elRect = elementFiche.getBoundingClientRect();
        let x = e.clientX - elRect.x;
        let y = e.clientY - elRect.y;
        let middleCardWidth = elRect.width / 2;
        let middleCardHeight = elRect.height / 2;

        let angleY = -(x - middleCardWidth) / ratio;
        let angleX = (y - middleCardHeight) / ratio;
        let glowX = (x / elRect.width) * 100;
        let glowY = (y / elRect.height) * 100;

        elementFiche.style.transform = `rotateX(${angleX}deg) rotateY(${angleY}deg) scale(1.1)`;
        elementFiche.style.transition = `all 15ms`;
        elementFiche.style.boxShadow = `0px 0px 8px black`;

        elementFiche.children[0].style.background = `radial-gradient(circle at ${glowX}% ${glowY}%, #a0bedf, #1c3377)`;
        elementFiche.children[0].style.transform = `scaleX(1)`;
      }
    }
  }
};

const EffectButton3dFunction = (e, buttonFiche, buttonState) => {
  buttonFiche = document.querySelectorAll(`.${buttonFiche}`);
  if (
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    buttonState(false);
  } else {
    buttonState(true);
    for (let button of buttonFiche) {
      if (e.target.id === button.id) {
        let elRect = button.parentElement.getBoundingClientRect();
        let x = e.clientX - elRect.x;
        let y = e.clientY - elRect.y;
        let glowX = (x / elRect.width) * 100;
        let glowY = (y / elRect.height) * 100;

        button.parentElement.style.transform = `rotateX(${0}deg) rotateY(${0}deg) scale(1.1)`;
        button.parentElement.style.transition = `all 15ms`;
        button.parentElement.style.boxShadow = `0px 0px 8px black`;

        button.style.background = `radial-gradient(circle at ${glowX}% ${glowY}%, #be7a54, #553322)`;
      }
    }
  }
};

const NotEffect3dFunction = (e, classNameFiche, elementState, statutFalse) => {
  const allFiches = document.querySelectorAll(`.${classNameFiche}`);
  elementState(statutFalse);

  for (let elementFiche of allFiches) {
    if (e.target.id === elementFiche.children[0].id) {
      elementFiche.style.transform = `rotateX(0deg) rotateY(0deg) scale(1)`;
      elementFiche.style.transition = `all 15ms`;
      elementFiche.style.boxShadow = `0px 0px 0px black`;
    }
  }
};

const NotEffectButton3dFunction = (buttonFiche, buttonState, statutFalse) => {
  buttonFiche = document.querySelectorAll(`.${buttonFiche}`);
  buttonState(statutFalse);
};

const Effect3dDisplayFunction = (e, elementBlockMenuInfos, ratio) => {
  const menuInfosBlock = document.querySelectorAll(`.${elementBlockMenuInfos}`);

  if (
    !navigator.userAgent.match(/iPhone/i) &&
    !navigator.userAgent.match(/webOS/i) &&
    !navigator.userAgent.match(/Android/i) &&
    !navigator.userAgent.match(/iPad/i) &&
    !navigator.userAgent.match(/iPod/i) &&
    !navigator.userAgent.match(/BlackBerry/i) &&
    !navigator.userAgent.match(/Windows Phone/i)
  ) {
    for (let elementInfos of menuInfosBlock) {
      if (
        e.target.parentElement.id === elementInfos.id &&
        e.target !== elementInfos.children[3]
      ) {
        let elRect = elementInfos.getBoundingClientRect();
        let x = e.clientX - elRect.x;
        let y = e.clientY - elRect.y;
        let middleCardWidth = elRect.width / 2;
        let middleCardHeight = elRect.height / 2;

        let angleY = -(x - middleCardWidth) / ratio;
        let angleX = (y - middleCardHeight) / ratio;
        let glowX = (x / elRect.width) * 100;
        let glowY = (y / elRect.height) * 100;

        elementInfos.style.transform = `rotateX(${angleX}deg) rotateY(${angleY}deg) scale(1.1)`;
        elementInfos.style.transition = `all 15ms`;
        elementInfos.style.boxShadow = `0px 0px 8px black`;

        elementInfos.children[1].style.background = `radial-gradient(circle at ${glowX}% ${glowY}%, #a0bedf, #1c3377)`;
      }
      if (
        e.target.parentElement.id === elementInfos.id &&
        e.target === elementInfos.children[3]
      ) {
        elementInfos.style.transform = `rotateX(${0}deg) rotateY(${0}deg) scale(1.1)`;
        elementInfos.style.transition = `all 15ms`;
        elementInfos.style.boxShadow = `0px 0px 8px black`;
      }
    }
  }
};

const NotEffect3dDisplayFunction = (
  e,
  elementBlockMenuInfos,
  elementState,
  statutFalse
) => {
  const menuInfosBlock = document.querySelectorAll(`.${elementBlockMenuInfos}`);

  if (
    !navigator.userAgent.match(/iPhone/i) &&
    !navigator.userAgent.match(/webOS/i) &&
    !navigator.userAgent.match(/Android/i) &&
    !navigator.userAgent.match(/iPad/i) &&
    !navigator.userAgent.match(/iPod/i) &&
    !navigator.userAgent.match(/BlackBerry/i) &&
    !navigator.userAgent.match(/Windows Phone/i)
  ) {
    elementState(statutFalse);
    for (let elementInfos of menuInfosBlock) {
      if (e.target.parentElement.id === elementInfos.id) {
        if (
          e.target.parentElement.parentElement.children[0].children[0].style
            .transform === "scale(1.1) rotateY(90deg)"
        ) {
          elementInfos.style.transform = `rotateX(0deg) rotateY(0deg) scale(1)`;
          elementInfos.style.transition = `all 0ms`;
          elementInfos.style.boxShadow = `0px 0px 0px black`;
        }
      }
    }
  }
};

const EffectInfosFunction = (e, classNameElement) => {
  const blockFiches = document.querySelectorAll(`.${classNameElement}`);
  const elementMenuInfos =
    e.target.parentElement.parentElement.parentElement.children;

  for (let elementFiche of blockFiches) {
    if (
      !navigator.userAgent.match(/iPhone/i) &&
      !navigator.userAgent.match(/webOS/i) &&
      !navigator.userAgent.match(/Android/i) &&
      !navigator.userAgent.match(/iPad/i) &&
      !navigator.userAgent.match(/iPod/i) &&
      !navigator.userAgent.match(/BlackBerry/i) &&
      !navigator.userAgent.match(/Windows Phone/i)
    ) {
      if (e.target.id === elementFiche.children[1].id) {
        elementFiche.style.pointerEvents = "none";
        elementFiche.children[1].style.opacity = "0";
        elementFiche.style.transform = "scale(1.1) rotateY(90deg)";
        elementFiche.style.boxShadow = `0px 0px 0px black`;
        elementFiche.style.transition = "all 300ms";
        elementMenuInfos[1].style.transform = "scale(1.1) rotateY(0deg)";
        elementMenuInfos[1].style.boxShadow = `0px 0px 8px black`;
        elementMenuInfos[1].style.transition = "300ms";
        elementMenuInfos[1].style.transitionDelay = "300ms";
        elementMenuInfos[1].children[0].style.transform =
          "rotate(-14deg) scale(1)";
        elementMenuInfos[1].children[0].style.opacity = "1";
      }
    }
  }
};

const EffectInfosFunctionMiniDevice = (e, classNameElement) => {
  const allButtons = document.querySelectorAll(`.${classNameElement}`);
  const elementFiche = e.target.parentElement.children[0];
  const elementMenuInfos = e.target.parentElement.children[1];

  if (
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    for (let button of allButtons) {
      if (e.target.id === button.id) {
        elementFiche.children[0].style.transform = "scale(1) rotateY(90deg)";
        elementFiche.children[0].style.boxShadow = `0px 0px 0px black`;
        elementFiche.children[0].style.transition = "all 300ms";
        elementMenuInfos.style.transform = "scale(1) rotateY(0deg)";
        elementMenuInfos.style.boxShadow = `0px 0px 8px black`;
        elementMenuInfos.style.transition = "300ms";
        elementMenuInfos.style.transitionDelay = "300ms";
        elementMenuInfos.children[0].style.transform =
          "rotate(-14deg) scale(1)";
        elementMenuInfos.children[0].style.opacity = "1";
      }
      if (e.target.id !== button.id) {
        button.parentElement.children[0].children[0].style.transform =
          "rotateY(0deg)";
        button.parentElement.children[0].children[0].style.transition = "300ms";
        button.parentElement.children[0].children[0].style.transitionDelay =
          "300ms";
        button.parentElement.children[1].style.transform = "rotateY(-90deg)";
        button.parentElement.children[1].style.boxShadow = `0px 0px 8px black`;
        button.parentElement.children[1].style.transition = "300ms";
      }
    }
  }
};

const EffectInfosFunctionMiniDeviceBigFiche = (e, classNameElement) => {
  const allButtons = document.querySelectorAll(`.${classNameElement}`);
  const elementFiche = e.target.parentElement.children[0];
  const elementMenuInfos = e.target.parentElement.children[1];

  if (
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    for (let button of allButtons) {
      if (e.target.id === button.id) {
        elementFiche.children[0].style.transform = "scale(1) rotateY(90deg)";
        elementFiche.children[0].style.boxShadow = `0px 0px 0px black`;
        elementFiche.children[0].style.transition = "all 300ms";
        elementMenuInfos.style.transform = "scale(1) rotateY(0deg)";
        elementMenuInfos.style.boxShadow = `0px 0px 8px black`;
        elementMenuInfos.style.transition = "300ms";
        elementMenuInfos.style.transitionDelay = "300ms";
        elementMenuInfos.children[0].classList.add("effectBigFichePrix");
      }
      if (e.target.id !== button.id) {
        button.parentElement.children[0].children[0].style.transform =
          "rotateY(0deg)";
        button.parentElement.children[0].children[0].style.transition = "300ms";
        button.parentElement.children[0].children[0].style.transitionDelay =
          "300ms";
        button.parentElement.children[1].style.transform = "rotateY(-90deg)";
        button.parentElement.children[1].style.boxShadow = `0px 0px 8px black`;
        button.parentElement.children[1].style.transition = "300ms";
      }
    }
  }
};

const EffectInfosBigFicheFunction = (e, classNameElement) => {
  const blockFiches = document.querySelectorAll(`.${classNameElement}`);
  const elementMenuInfos =
    e.target.parentElement.parentElement.parentElement.children;
  if (
    !navigator.userAgent.match(/iPhone/i) &&
    !navigator.userAgent.match(/webOS/i) &&
    !navigator.userAgent.match(/Android/i) &&
    !navigator.userAgent.match(/iPad/i) &&
    !navigator.userAgent.match(/iPod/i) &&
    !navigator.userAgent.match(/BlackBerry/i) &&
    !navigator.userAgent.match(/Windows Phone/i)
  ) {
    for (let elementFiche of blockFiches) {
      if (e.target.id === elementFiche.children[1].id) {
        elementFiche.style.pointerEvents = "none";
        elementFiche.style.transform = "scale(1.1) rotateY(90deg)";
        elementFiche.style.boxShadow = `0px 0px 0px black`;
        elementFiche.style.transition = "all 300ms";
        elementMenuInfos[1].style.transform = "scale(1.1) rotateY(0deg)";
        elementMenuInfos[1].style.boxShadow = `0px 0px 8px black`;
        elementMenuInfos[1].style.transition = "300ms";
        elementMenuInfos[1].style.transitionDelay = "300ms";
        elementMenuInfos[1].children[0].classList.add("effectBigFichePrix");
      }
    }
  }
};

const NotDisplayFicheVersoFunction = (e, classNameElement) => {
  const blockFiches = document.querySelectorAll(`.${classNameElement}`);
  if (
    !navigator.userAgent.match(/iPhone/i) &&
    !navigator.userAgent.match(/webOS/i) &&
    !navigator.userAgent.match(/Android/i) &&
    !navigator.userAgent.match(/iPad/i) &&
    !navigator.userAgent.match(/iPod/i) &&
    !navigator.userAgent.match(/BlackBerry/i) &&
    !navigator.userAgent.match(/Windows Phone/i)
  ) {
    for (let elementFiche of blockFiches) {
      if (
        e.target.id !==
        elementFiche.parentElement.children[0].children[0].children[0].id
      ) {
        elementFiche.style.transform = "rotateY(-90deg)";
        elementFiche.style.transition = "300ms";
        elementFiche.parentElement.children[0].children[0].style.transform = `rotateY(0deg)`;
        elementFiche.parentElement.children[0].children[0].style.pointerEvents =
          "auto";
        elementFiche.parentElement.children[0].children[0].style.transition =
          "300ms";
        elementFiche.parentElement.children[0].children[0].style.transitionDelay =
          "300ms";
      }
      if (
        elementFiche.style.transform === "rotateX(0deg) rotateY(0deg) scale(1)"
      ) {
        elementFiche.style.transform = "rotateY(-90deg)";
        elementFiche.style.transition = "300ms";
        elementFiche.parentElement.children[0].children[0].style.transform = `rotateY(0deg)`;
        elementFiche.parentElement.children[0].children[0].style.pointerEvents =
          "auto";
        elementFiche.parentElement.children[0].children[0].style.transition =
          "300ms";
        elementFiche.parentElement.children[0].children[0].style.transitionDelay =
          "300ms";
      }
    }
  }
};

const NotDisplayFicheVersoOnScrollFunction = (classNameElement) => {
  const blockFiches = document.querySelectorAll(`.${classNameElement}`);
  for (let elementFiche of blockFiches) {
    setTimeout(() => {
      elementFiche.style.transform = "rotateY(-90deg)";
      elementFiche.style.transition = "300ms";
      elementFiche.parentElement.children[0].children[0].style.transform = `rotateY(0deg)`;
      elementFiche.parentElement.children[0].children[0].style.pointerEvents =
        "auto";
      elementFiche.parentElement.children[0].children[0].style.transition =
        "300ms";
      elementFiche.parentElement.children[0].children[0].style.transitionDelay =
        "300ms";
    }, 600);
  }
};

const EffectButtonQuote3dFunction = (e, buttonFiche) => {
  buttonFiche = document.querySelectorAll(`.${buttonFiche}`);
  for (let button of buttonFiche) {
    if (e.target.parentElement === button.parentElement) {
      let elRect = button.parentElement.getBoundingClientRect();
      let x = e.clientX - elRect.x;
      let y = e.clientY - elRect.y;

      let glowX = (x / elRect.width) * 100;
      let glowY = (y / elRect.height) * 100;

      button.style.background = `radial-gradient(circle at ${glowX}% ${glowY}%, #8894c4, #556699)`;
      button.style.transform = `scale(1.05)`;
      button.style.transition = `all 50ms`;
    }
  }
};

export {
  Effect3dFunction,
  EffectButton3dFunction,
  NotEffectButton3dFunction,
  NotEffect3dFunction,
  Effect3dDisplayFunction,
  NotEffect3dDisplayFunction,
  EffectInfosFunction,
  NotDisplayFicheVersoFunction,
  EffectInfosBigFicheFunction,
  EffectInfosFunctionMiniDeviceBigFiche,
  NotDisplayFicheVersoOnScrollFunction,
  EffectInfosFunctionMiniDevice,
  EffectButtonQuote3dFunction,
};
