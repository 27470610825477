import { OffsetTopFunction } from "./OffsetTopFunction";

const EffectArticleFunction = (filter, contenair, title, article, button) => {
  if (filter && contenair && title && article && button) {
    if (window.innerWidth > 991) {
      filter.style.filter = "brightness(0.7)";
      filter.style.transition = "10ms ease-in-out";
      contenair.style.height = "520px";
      contenair.style.transition = "300ms ease";
      title.style.transform = "translateY(12%)";
      title.style.transition = "300ms ease-in-out 320ms";
      article.style.opacity = "1";
      // article.style.transform = "translateY(0%)";
      article.style.transition = "500ms ease-in-out 320ms";
      button.style.transform = "translateY(0%)";
      button.style.opacity = "1";
      button.style.transition = "500ms ease-in-out 320ms";
    }
    if (window.innerWidth <= 991) {
      filter.style.filter = "brightness(0.7)";
      filter.style.transition = "10ms ease-in-out";
      contenair.style.height = "100%";
      contenair.style.transition = "300ms ease";
      title.style.transform = "translateY(12%)";
      title.style.transition = "300ms ease-in-out 320ms";
      article.style.opacity = "1";
      // article.style.transform = "translateY(0%)";
      article.style.transition = "500ms ease-in-out 320ms";
      button.style.transform = "translateY(0%)";
      button.style.opacity = "1";
      button.style.transition = "500ms ease-in-out 320ms";
    }
  }
};

const NotEffectArticleFunction = (
  filter,
  contenair,
  title,
  article,
  button
) => {
  if (filter && contenair && title && article && button) {
    if (window.innerWidth > 991) {
      filter.style.filter = "brightness(1)";
      filter.style.transition = "0ms ease-in-out";
      contenair.style.height = "90px";
      contenair.style.transition = "300ms linear";
      title.style.transform = "translateY(0%)";
      title.style.transition = "0ms ease-in-out";
      article.style.opacity = "0";
      // article.style.transform = "translateY(14%)";
      article.style.transition = "0ms ease-in-out";
      button.style.transform = "translateY(24%)";
      button.style.opacity = "0";
      button.style.transition = "0ms";
    }
    if (window.innerWidth <= 991) {
      filter.style.filter = "brightness(1)";
      filter.style.transition = "0ms ease-in-out";
      contenair.style.height = "60px";
      contenair.style.transition = "800ms ease";
      title.style.transform = "translateY(0%)";
      title.style.transition = "0ms ease-in-out";
      article.style.opacity = "0";
      // article.style.transform = "translateY(14%)";
      article.style.transition = "0ms ease-in-out";
      button.style.transform = "translateY(24%)";
      button.style.opacity = "0";
      button.style.transition = "0ms";
    }
  }
};

const OnScrollBlockTopFunction = (
  filterId,
  contenairId,
  titleId,
  articleId,
  buttonId
) => {
  const screenY = window.scrollY + window.innerHeight;

  const filter = document.querySelector(`.${filterId}`);
  const contenair = document.querySelector(`#${contenairId}`);
  const title = document.querySelector(`#${titleId}`);
  const article = document.querySelector(`#${articleId}`);
  const button = document.querySelector(`#${buttonId}`);

  if (filter && contenair && title && article && button) {
    if (OffsetTopFunction(filter) - screenY < -filter.offsetHeight - 40) {
      EffectArticleFunction(filter, contenair, title, article, button);
    }
    if (OffsetTopFunction(filter) - screenY > -filter.offsetHeight - 40) {
      NotEffectArticleFunction(filter, contenair, title, article, button);
    }
  }
};

export {
  EffectArticleFunction,
  NotEffectArticleFunction,
  OnScrollBlockTopFunction,
};
