const EffectHover = (
  e,
  blockContenairPictureZoom,
  contenairPrincipal,
  imageContenairPrincipal,
  pictureZoomContenair
) => {
  if (blockContenairPictureZoom) {
    if (
      e.clientX -
        contenairPrincipal.getBoundingClientRect().x -
        blockContenairPictureZoom.clientWidth / 2 >
        0 &&
      e.clientY -
        contenairPrincipal.getBoundingClientRect().y -
        blockContenairPictureZoom.clientHeight / 2 >
        0 &&
      e.clientX -
        contenairPrincipal.getBoundingClientRect().x -
        +contenairPrincipal.clientWidth +
        blockContenairPictureZoom.clientWidth / 2 <
        0 &&
      e.clientY -
        contenairPrincipal.getBoundingClientRect().y -
        +contenairPrincipal.clientHeight +
        blockContenairPictureZoom.clientHeight / 2 <
        0
    ) {
      imageContenairPrincipal.style.transform = "scale(1.1)";
      pictureZoomContenair.style.transform = "scale(1.1)";
    } else {
      imageContenairPrincipal.style.transform = "scale(1)";
      pictureZoomContenair.style.transform = "scale(1)";
    }
  }
};

const sizeAndPositionElements = (
  e,
  blockContenairPictureZoom,
  contenairPictureZoom,
  pictureZoomBlock,
  contenairPrincipal,
  pictureZoom
) => {
  if (blockContenairPictureZoom) {
    blockContenairPictureZoom.style.top = `${
      e.clientY - blockContenairPictureZoom.clientHeight / 2
    }px`;
    blockContenairPictureZoom.style.left = `${
      e.clientX - blockContenairPictureZoom.clientWidth / 2
    }px`;

    contenairPictureZoom.style.cursor = "zoom-in";
    contenairPictureZoom.style.top = `${
      e.clientY - contenairPictureZoom.clientHeight / 2
    }px`;
    contenairPictureZoom.style.left = `${
      e.clientX - contenairPictureZoom.clientWidth / 2
    }px`;

    pictureZoomBlock.style.height = `${contenairPrincipal.clientHeight}px`;
    pictureZoomBlock.style.width = `${contenairPrincipal.clientWidth}px`;

    pictureZoom.style.height = `${contenairPrincipal.clientHeight}px`;
    pictureZoom.style.width = `${contenairPrincipal.clientWidth}px`;

    blockContenairPictureZoom.style.opacity = "1";
    contenairPictureZoom.style.opacity = "1";
  }
};

const effectInfoBulle = (
  e,
  imageContenairPrincipal,
  blockContenairPictureZoom,
  infoBulle,
  stateInfoBulleSet
) => {
  if (infoBulle) {
    infoBulle.style.top = `${
      e.clientY +
      blockContenairPictureZoom.clientHeight / 2 +
      infoBulle.offsetHeight / 2
    }px`;
    infoBulle.style.left = `${e.clientX - infoBulle.clientWidth / 2}px`;
    if (
      e.clientX -
        imageContenairPrincipal.getBoundingClientRect().x -
        +imageContenairPrincipal.clientWidth +
        blockContenairPictureZoom.clientWidth / 2 +
        10 >
      0
    ) {
      infoBulle.style.opacity = "0";
    } else if (
      e.clientX -
        imageContenairPrincipal.getBoundingClientRect().x -
        blockContenairPictureZoom.clientWidth / 2 -
        80 <
      0
    ) {
      infoBulle.style.opacity = "0";
    } else if (
      e.clientY -
        imageContenairPrincipal.getBoundingClientRect().y -
        imageContenairPrincipal.offsetHeight +
        blockContenairPictureZoom.offsetHeight / 2 +
        20 >
      0
    ) {
      infoBulle.style.opacity = "0";
    } else {
      infoBulle.style.opacity = "1";
    }
    setTimeout(() => {
      stateInfoBulleSet(false);
    }, 6800);
  }
};

const effectZoom = (
  e,
  contenairPrincipal,
  blockContenairPictureZoom,
  contenairPictureZoom,
  pictureZoomBlock,
  pictureZoom,
  infoBulle,
  stateDBset
) => {
  if (blockContenairPictureZoom) {
    if (
      e.clientX -
        contenairPrincipal.getBoundingClientRect().x -
        blockContenairPictureZoom.clientWidth / 2 >
        0 &&
      e.clientY -
        contenairPrincipal.getBoundingClientRect().y -
        blockContenairPictureZoom.clientHeight / 2 >
        0 &&
      e.clientX -
        contenairPrincipal.getBoundingClientRect().x -
        +contenairPrincipal.clientWidth +
        blockContenairPictureZoom.clientWidth / 2 <
        0 &&
      e.clientY -
        contenairPrincipal.getBoundingClientRect().y -
        +contenairPrincipal.clientHeight +
        blockContenairPictureZoom.clientHeight / 2 <
        0
    ) {
      blockContenairPictureZoom.style.top = `${
        e.clientY -
        contenairPrincipal.getBoundingClientRect().y -
        blockContenairPictureZoom.clientHeight / 2
      }px`;
      blockContenairPictureZoom.style.left = `${
        e.clientX -
        contenairPrincipal.getBoundingClientRect().x -
        blockContenairPictureZoom.clientWidth / 2
      }px`;

      contenairPictureZoom.style.top = `${
        e.clientY -
        contenairPrincipal.getBoundingClientRect().y -
        contenairPictureZoom.clientHeight / 2
      }px`;
      contenairPictureZoom.style.left = `${
        e.clientX -
        contenairPrincipal.getBoundingClientRect().x -
        contenairPictureZoom.clientWidth / 2
      }px`;

      pictureZoomBlock.style.marginTop = `-${
        e.clientY -
        contenairPrincipal.getBoundingClientRect().y -
        blockContenairPictureZoom.clientHeight / 2 +
        10
      }px`;
      pictureZoomBlock.style.marginLeft = `-${
        e.clientX -
        contenairPrincipal.getBoundingClientRect().x -
        blockContenairPictureZoom.clientWidth / 2 +
        10
      }px`;

      pictureZoom.style.marginTop = `-${
        e.clientY -
        contenairPrincipal.getBoundingClientRect().y -
        contenairPictureZoom.clientHeight / 2
      }px`;
      pictureZoom.style.marginLeft = `-${
        e.clientX -
        contenairPrincipal.getBoundingClientRect().x -
        contenairPictureZoom.clientWidth / 2
      }px`;
    } else {
      blockContenairPictureZoom.style.opacity = "0";
      contenairPictureZoom.style.opacity = "0";
      if (infoBulle) {
        infoBulle.style.opacity = "0";
      }
      blockContenairPictureZoom.style.transform = "scale(1)";
      contenairPictureZoom.style.transform = "scale(1)";
      stateDBset(false);
    }
  }
};

const OnZoomMMFunction = (
  e,
  idContenairPrincipal,
  idPictureContenairPrincipal,
  idBlockContenairPictureZoom,
  idContenairPictureZoom,
  idPictureZoomBlockContenair,
  idPictureZoomContenair,
  stateInfoBulleSet,
  stateDBset
) => {
  const contenairPrincipal = document.querySelector(`#${idContenairPrincipal}`);
  const imageContenairPrincipal = document.querySelector(
    `#${idPictureContenairPrincipal}`
  );
  const blockContenairPictureZoom = document.querySelector(
    `#${idBlockContenairPictureZoom}`
  );
  const contenairPictureZoom = document.querySelector(
    `#${idContenairPictureZoom}`
  );
  const pictureZoomBlock = document.querySelector(
    `#${idPictureZoomBlockContenair}`
  );
  const pictureZoom = document.querySelector(`#${idPictureZoomContenair}`);
  const infoBulle = document.querySelector(`#infoBulle`);
  if (
    !navigator.userAgent.match(/iPhone/i) &&
    !navigator.userAgent.match(/webOS/i) &&
    !navigator.userAgent.match(/Android/i) &&
    !navigator.userAgent.match(/iPad/i) &&
    !navigator.userAgent.match(/iPod/i) &&
    !navigator.userAgent.match(/BlackBerry/i) &&
    !navigator.userAgent.match(/Windows Phone/i)
  ) {
    if (window.innerWidth > 991) {
      sizeAndPositionElements(
        e,
        blockContenairPictureZoom,
        contenairPictureZoom,
        pictureZoomBlock,
        contenairPrincipal,
        pictureZoom
      );

      effectInfoBulle(
        e,
        imageContenairPrincipal,
        blockContenairPictureZoom,
        infoBulle,
        stateInfoBulleSet
      );

      effectZoom(
        e,
        contenairPrincipal,
        blockContenairPictureZoom,
        contenairPictureZoom,
        pictureZoomBlock,
        pictureZoom,
        infoBulle,
        stateDBset
      );
    }
  }
};

const EffectZoomDBFunction = (
  idBlockContenairPictureZoom,
  idContenairPictureZoom,
  stateDB,
  stateDBset,
  stateInfoBulleSet
) => {
  const blockContenairPictureZoom = document.querySelector(
    `#${idBlockContenairPictureZoom}`
  );
  const contenairPictureZoom = document.querySelector(
    `#${idContenairPictureZoom}`
  );

  if (
    !navigator.userAgent.match(/iPhone/i) &&
    !navigator.userAgent.match(/webOS/i) &&
    !navigator.userAgent.match(/Android/i) &&
    !navigator.userAgent.match(/iPad/i) &&
    !navigator.userAgent.match(/iPod/i) &&
    !navigator.userAgent.match(/BlackBerry/i) &&
    !navigator.userAgent.match(/Windows Phone/i)
  ) {
    if (window.innerWidth > 991) {
      if (!stateDB) {
        blockContenairPictureZoom.style.transform = "scale(2)";
        contenairPictureZoom.style.transform = "scale(2)";
        stateDBset(true);
        stateInfoBulleSet(false);
      }
      if (stateDB) {
        blockContenairPictureZoom.style.transform = "scale(1)";
        contenairPictureZoom.style.transform = "scale(1)";
        stateDBset(false);
      }
    }
  }
};

export { OnZoomMMFunction, EffectZoomDBFunction, EffectHover };
