import { gsap } from "gsap";
import FeuilleAutomne from "../assets/BlockPromotion/feuille-automne.png";
import FloconsNeige from "../assets/BlockPromotion/flocons-de-neige.png";
import Fleur1 from "../assets/BlockPromotion/fleur-1.png";
import Fleur2 from "../assets/BlockPromotion/fleur-2.png";
import Fleur3 from "../assets/BlockPromotion/fleur-3.png";
import Fleur4 from "../assets/BlockPromotion/fleur-4.png";
import { OffsetTopFunction } from "../functions/OffsetTopFunction";

const EffectBlockPromotionAutomn = () => {
  const screenY = window.scrollY + window.innerHeight;
  const blockPromotion = document.querySelector(
    "#contenairPictureBlockPromotion"
  );
  if (blockPromotion) {
    if (
      OffsetTopFunction(blockPromotion) -
        screenY +
        blockPromotion.offsetHeight <
        0 &&
      OffsetTopFunction(blockPromotion) -
        screenY +
        blockPromotion.offsetHeight >
        -10 &&
      !blockPromotion.classList.contains("notEffectBlock")
    ) {
      blockPromotion.classList.add("notEffectBlock");

      for (let i = 0; i < 300; i++) {
        const element = document.createElement("img");
        element.setAttribute("src", `${FeuilleAutomne}`);
        element.style.width = `50px`;
        element.style.height = `50px`;
        element.style.perspective = `1000px`;
        element.style.position = "absolute";
        if (window.innerWidth >= 991) {
          element.style.left = `${Math.floor(
            Math.random() * (10000, 100) - 1.5
          )}%`;
        }
        if (window.innerWidth < 991) {
          element.style.left = `${Math.floor(
            Math.random() * (10000, 100) - 5
          )}%`;
        }

        window.addEventListener("resize", () => {
          if (window.innerWidth >= 991) {
            element.style.left = `${Math.floor(
              Math.random() * (10000, 100) - 1.5
            )}%`;
            element.style.opacity = `0`;
          }
          if (window.innerWidth < 991) {
            element.style.left = `${Math.floor(
              Math.random() * (10000, 100) - 5
            )}%`;
            element.style.opacity = `0`;
          }
        });

        const dimensionEffect = blockPromotion.offsetHeight - 164;
        const TLCONF = gsap.timeline();
        TLCONF.fromTo(
          element,
          {
            y: "-300",
            rotationX: "random(90, -90)",
            rotationY: "random(90, -90)",
            rotationZ: "random(90, -90)",
          },
          {
            y: `${dimensionEffect}`,
            rotationX: "random(90, -90)",
            rotationY: "random(90, -90)",
            rotationZ: "random(90, -90)",
            duration: "random(2, 4)",
            delay: "random(0, 2)",
          }
        );
        blockPromotion.appendChild(element);
      }
    }
  }
};

const EffectBlockPromotionWinter = () => {
  const screenY = window.scrollY + window.innerHeight;
  const blockPromotion = document.querySelector(
    "#contenairPictureBlockPromotion"
  );

  if (blockPromotion) {
    if (
      OffsetTopFunction(blockPromotion) -
        screenY +
        blockPromotion.offsetHeight <
        0 &&
      OffsetTopFunction(blockPromotion) -
        screenY +
        blockPromotion.offsetHeight >
        -10 &&
      !blockPromotion.classList.contains("notEffectBlock")
    ) {
      blockPromotion.classList.add("notEffectBlock");

      for (let i = 0; i < 100; i++) {
        const element = document.createElement("img");
        element.setAttribute("src", `${FloconsNeige}`);
        element.style.width = `40px`;
        element.style.height = `40px`;
        element.style.perspective = `1000px`;
        element.style.position = "absolute";
        if (window.innerWidth >= 991) {
          element.style.left = `${Math.floor(
            Math.random() * (10000, 100) - 1.5
          )}%`;
        }
        if (window.innerWidth < 991) {
          element.style.left = `${Math.floor(
            Math.random() * (10000, 100) - 5
          )}%`;
        }

        window.addEventListener("resize", () => {
          if (window.innerWidth >= 991) {
            element.style.left = `${Math.floor(
              Math.random() * (10000, 100) - 1.5
            )}%`;
            element.style.opacity = `0`;
          }
          if (window.innerWidth < 991) {
            element.style.left = `${Math.floor(
              Math.random() * (10000, 100) - 5
            )}%`;
            element.style.opacity = `0`;
          }
        });

        const dimensionEffect = blockPromotion.offsetHeight - 164;
        const TLCONF = gsap.timeline();
        TLCONF.fromTo(
          element,
          {
            y: "-300",
            rotationX: "random(90, -90)",
            rotationY: "random(90, -90)",
            rotationZ: "random(90, -90)",
          },
          {
            y: `${dimensionEffect}`,
            rotationX: "random(90, -90)",
            rotationY: "random(90, -90)",
            rotationZ: "random(90, -90)",
            duration: "random(3, 6)",
            repeat: -1,
            delay: "random(0, 4)",
          }
        );
        blockPromotion.appendChild(element);
      }
    }
  }
};

const EffectBlockPromotionSpring = () => {
  const screenY = window.scrollY + window.innerHeight;
  const blockPromotion = document.querySelector(
    "#contenairPictureBlockPromotion"
  );
  const arrayFlower = [Fleur1, Fleur2, Fleur3, Fleur4];

  if (blockPromotion) {
    if (
      OffsetTopFunction(blockPromotion) -
        screenY +
        blockPromotion.offsetHeight <
        0 &&
      OffsetTopFunction(blockPromotion) -
        screenY +
        blockPromotion.offsetHeight >
        -10 &&
      !blockPromotion.classList.contains("notEffectBlock")
    ) {
      blockPromotion.classList.add("notEffectBlock");

      for (let i = 0; i < 200; i++) {
        const element = document.createElement("img");
        element.setAttribute(
          "src",
          arrayFlower[Math.floor(Math.random() * arrayFlower.length)]
        );
        element.style.width = `40px`;
        element.style.height = `40px`;
        element.style.perspective = `1000px`;
        element.style.position = "absolute";
        if (window.innerWidth >= 991) {
          element.style.left = `${Math.floor(
            Math.random() * (10000, 100) - 1.5
          )}%`;
        }
        if (window.innerWidth < 991) {
          element.style.left = `${Math.floor(
            Math.random() * (10000, 100) - 5
          )}%`;
        }

        window.addEventListener("resize", () => {
          if (window.innerWidth >= 991) {
            element.style.left = `${Math.floor(
              Math.random() * (10000, 100) - 1.5
            )}%`;
            element.style.opacity = `0`;
          }
          if (window.innerWidth < 991) {
            element.style.left = `${Math.floor(
              Math.random() * (10000, 100) - 5
            )}%`;
            element.style.opacity = `0`;
          }
        });

        const dimensionEffect = blockPromotion.offsetHeight - 164;
        const TLCONF = gsap.timeline();
        TLCONF.fromTo(
          element,
          {
            y: "-300",
            rotationX: "random(45, -45)",
            rotationY: "random(45, -45)",
            rotationZ: "random(45, -45)",
          },
          {
            y: `${dimensionEffect}`,
            rotationX: "random(45, -45)",
            rotationY: "random(45, -45)",
            rotationZ: "random(45, -45)",
            duration: "random(2, 4)",
            delay: "random(0, 2)",
          }
        );
        blockPromotion.appendChild(element);
      }
    }
  }
};

const EffectBlockPromotionSummer = () => {
  const screenY = window.scrollY + window.innerHeight;
  const blockPromotion = document.querySelector(
    "#contenairPictureBlockPromotion"
  );
  const arrayColor = ["pink", "violet", "yellow", "green", "red", "blue"];

  if (blockPromotion) {
    if (
      OffsetTopFunction(blockPromotion) -
        screenY +
        blockPromotion.offsetHeight <
        0 &&
      OffsetTopFunction(blockPromotion) -
        screenY +
        blockPromotion.offsetHeight >
        -10 &&
      !blockPromotion.classList.contains("notEffectBlock")
    ) {
      blockPromotion.classList.add("notEffectBlock");

      for (let i = 0; i < 500; i++) {
        const element = document.createElement("div");
        element.style.width = `10px`;
        element.style.height = `10px`;
        element.style.perspective = `1000px`;
        element.style.backgroundColor =
          arrayColor[Math.floor(Math.random() * arrayColor.length)];
        element.style.position = "absolute";
        element.style.left = `${Math.floor(Math.random() * (10000, 100))}%`;

        window.addEventListener("resize", () => {
          if (window.innerWidth >= 991) {
            element.style.left = `${Math.floor(
              Math.random() * (10000, 100) - 1.5
            )}%`;
            element.style.opacity = `0`;
          }
          if (window.innerWidth < 991) {
            element.style.left = `${Math.floor(
              Math.random() * (10000, 100) - 5
            )}%`;
            element.style.opacity = `0`;
          }
        });

        const dimensionEffect = blockPromotion.offsetHeight - 108;
        const TLCONF = gsap.timeline();
        TLCONF.fromTo(
          element,
          {
            y: "-100",
            rotationX: "random(90, -90)",
            rotationY: "random(90, -90)",
          },
          {
            y: `${dimensionEffect}`,
            rotationX: "random(90, -90)",
            rotationY: "random(90, -90)",
            opacity: 0,
            duration: "random(2, 4)",
            delay: "random(0, 2)",
          }
        );
        blockPromotion.appendChild(element);
      }
    }
  }
};

export {
  EffectBlockPromotionAutomn,
  EffectBlockPromotionWinter,
  EffectBlockPromotionSpring,
  EffectBlockPromotionSummer,
};
