const EffectTitlePageFunction = (idTitlePage) => {
  setTimeout(() => {
    const titlePage = document.querySelector(`#${idTitlePage}`);
    if (titlePage) {
      titlePage.style.transform = "scale(1)";
      titlePage.style.opacity = "1";
      titlePage.style.transition = "all 500ms";
      titlePage.style.transitionDelay = "1400ms";
    }
  }, 400);
};

export { EffectTitlePageFunction };
