import React, { useContext } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import "../../../style/Animation/KeyFrames/ModalForm.css";
import { ModalFormContext } from "../../../contexts/ModalForm";

const Button = styled.button.attrs({
  type: "button",
  value: "Envoyer",
  id: "buttonSubmit",
})`
  width: 40%;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #dfe3e7;
  border: 2px solid #667788;
  border-radius: 6px;
  font-size: 16px;
  font-family: "Russo One", sans-serif;
  color: grey;
  margin-top: 0px;
  margin-left: 30%;

  @media (max-width: 702px) {
    margin-top: -14px;
    font-size: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  @media (max-width: 375px) {
    font-size: 14px;
    width: 50%;
    margin-left: 25%;
  }
`;

export default function SubmitFormButton({
  setElementFormOne,
  setElementFormTwo,
  setElementFormThree,
  setElementFormFour,
  setElementFormFive,
  setElementFormSix,
  setLastNameValue,
  setFirstNameValue,
  setNumberPhoneValue,
  setMailValue,
  setPostalCodeValue,
  setContentMailValue,
  lastNameValue,
  firstNameValue,
  numberPhoneValue,
  mailValue,
  postalCodeValue,
  contentMailValue,
}) {
  const hoverButton = () => {
    const buttonSubmit = document.querySelector("#buttonSubmit");
    if (buttonSubmit) {
      if (
        lastNameValue &&
        firstNameValue &&
        numberPhoneValue &&
        mailValue &&
        postalCodeValue &&
        contentMailValue
      ) {
        buttonSubmit.style.transform = "scale(1.05)";
        buttonSubmit.style.transition = "300ms";
        buttonSubmit.style.cursor = "pointer";
      }
    }
  };

  const notHoverButton = () => {
    const buttonSubmit = document.querySelector("#buttonSubmit");
    if (buttonSubmit) {
      if (
        lastNameValue &&
        firstNameValue &&
        numberPhoneValue &&
        mailValue &&
        postalCodeValue &&
        contentMailValue
      ) {
        buttonSubmit.style.transform = "scale(1)";
        buttonSubmit.style.transition = "300ms";
        buttonSubmit.style.cursor = "auto";
      }
    }
  };

  const { displayModalForm } = useContext(ModalFormContext);

  const onClickCloseModalForm = () => {
    const modalForm = document.querySelector("#modalForm");
    const menuNavBar = document.querySelector("#blockNavAndMenu");

    modalForm.style.transform = "scale(1) translate(-50%, -46%)";
    modalForm.classList.remove("displayModalForm");
    modalForm.classList.add("hiddenModalForm");

    setTimeout(() => {
      displayModalForm("modalFormClose");
      menuNavBar.style.pointerEvents = "auto";
    }, 600);

    setTimeout(() => {
      displayModalForm("modalConfirmationDisplay");
    }, 800);

    setTimeout(() => {
      displayModalForm("contenairModalFormClose");
    }, 5500);

    setTimeout(() => {
      displayModalForm("modalConfirmationHidden");
    }, 5500);
  };

  const onClickCloseModalFormError = () => {
    const modalForm = document.querySelector("#modalForm");
    const menuNavBar = document.querySelector("#blockNavAndMenu");

    modalForm.style.transform = "scale(1) translate(-50%, -46%)";
    modalForm.classList.remove("displayModalForm");
    modalForm.classList.add("hiddenModalForm");

    setTimeout(() => {
      displayModalForm("modalFormClose");
      menuNavBar.style.pointerEvents = "auto";
    }, 600);

    setTimeout(() => {
      displayModalForm("modalConfirmationDisplayError");
    }, 800);

    setTimeout(() => {
      displayModalForm("contenairModalFormClose");
    }, 7000);

    setTimeout(() => {
      displayModalForm("modalConfirmationHiddenError");
    }, 7000);
  };

  const sendMail = () => {
    const serviceId = "service_yjq7n9q";
    const templateId = "template_rm5yu9j";
    const formContent = {
      lastname: lastNameValue,
      firstname: firstNameValue,
      numberphone: numberPhoneValue,
      email: mailValue,
      postalcode: postalCodeValue,
      message: contentMailValue,
    };

    emailjs
      .send(serviceId, templateId, formContent, process.env.REACT_APP_SECRETKEY)
      .then(
        (result) => {
          onClickCloseModalForm();
          setTimeout(() => {
            setLastNameValue(null);
            setFirstNameValue(null);
            setNumberPhoneValue(null);
            setMailValue(null);
            setPostalCodeValue(null);
            setContentMailValue(null);
          }, 300);
        },
        (error) => {
          onClickCloseModalFormError();
          setTimeout(() => {
            setLastNameValue(null);
            setFirstNameValue(null);
            setNumberPhoneValue(null);
            setMailValue(null);
            setPostalCodeValue(null);
            setContentMailValue(null);
          }, 300);
        }
      );
  };

  const handleSubmit = (e) => {
    const lastName = document.querySelector("#lastName");
    const firstName = document.querySelector("#firstName");
    const numberPhone = document.querySelector("#numberPhone");
    const mail = document.querySelector("#mail");
    const postalCode = document.querySelector("#postalCode");
    const mailContent = document.querySelector("#mailContent");
    const buttonSubmitClick = document.querySelector("#buttonSubmit");

    if (
      lastName.value.length >= 2 &&
      lastName.value.length <= 20 &&
      lastName.value.match("^[A-z-]*$")
    ) {
      setLastNameValue(lastName.value);
      lastName.style.border = "2px solid #667788";
    } else if (
      lastName.value.length >= 2 &&
      lastName.value.length <= 20 &&
      !lastName.value.match("^[A-z-]*$")
    ) {
      lastName.style.border = "2px solid red";
      setElementFormOne("Votre nom ne peut contenir que des lettres");
    } else if (lastName.value.length < 2) {
      lastName.style.border = "2px solid red";
      setElementFormOne("Votre nom doit contenir au moins deux lettres");
    }

    if (
      firstName.value.length >= 2 &&
      firstName.value.length <= 20 &&
      firstName.value.match("^[A-z-]*$")
    ) {
      setFirstNameValue(firstName.value);
      firstName.style.border = "2px solid #667788";
    } else if (
      firstName.value.length >= 2 &&
      firstName.value.length <= 20 &&
      !firstName.value.match("^[A-z-]*$")
    ) {
      firstName.style.border = "2px solid red";
      setElementFormTwo("Votre prénom ne peut contenir que des lettres");
    } else if (firstName.value.length < 2) {
      firstName.style.border = "2px solid red";
      setElementFormTwo("Votre nom doit contenir au moins deux lettres");
    }

    if (
      numberPhone.value.length === 10 &&
      numberPhone.value.match("^[0-9]*$")
    ) {
      setNumberPhoneValue(numberPhone.value);
      numberPhone.style.border = "2px solid #667788";
    } else if (
      numberPhone.value.length === 10 &&
      !numberPhone.value.match("^[0-9]*$")
    ) {
      numberPhone.style.border = "2px solid red";
      setElementFormThree(
        "Votre numéro de téléphone ne peut contenir que des chiffres"
      );
    } else if (
      numberPhone.value.length !== 10 &&
      numberPhone.value.match("^[0-9]*$")
    ) {
      numberPhone.style.border = "2px solid red";
      setElementFormThree(
        "Votre numéro de téléphone doit contenir dix chiffres"
      );
    }

    if (
      mail.value.match(
        '^(?:(?!.*?[.]{2})[a-zA-Z0-9](?:[a-zA-Z0-9.+!%-]{1,64}|)|"[a-zA-Z0-9.+!% -]{1,64}")@[a-zA-Z0-9][a-zA-Z0-9.-]+(.[a-z]{2,}|.[0-9]{1,})$'
      )
    ) {
      setMailValue(mail.value);
      mail.style.border = "2px solid #667788";
    } else if (
      !mail.value.match(
        '^(?:(?!.*?[.]{2})[a-zA-Z0-9](?:[a-zA-Z0-9.+!%-]{1,64}|)|"[a-zA-Z0-9.+!% -]{1,64}")@[a-zA-Z0-9][a-zA-Z0-9.-]+(.[a-z]{2,}|.[0-9]{1,})$'
      ) &&
      mail.value.length > 0
    ) {
      mail.style.border = "2px solid red";
      setElementFormFour("Adresse mail incorrecte");
    } else if (mail.value.length === 0) {
      mail.style.border = "2px solid red";
      setElementFormFour("Vous devez remplir ce champ");
    }

    if (postalCode.value.length === 5 && postalCode.value.match("^[0-9]*$")) {
      setPostalCodeValue(postalCode.value);
      postalCode.style.border = "2px solid #667788";
    } else if (
      postalCode.value.length === 5 &&
      !postalCode.value.match("^[0-9]*$")
    ) {
      postalCode.style.border = "2px solid red";
      setElementFormFive("Votre code postal ne peut contenir que des chiffres");
    } else if (
      postalCode.value.length !== 5 &&
      postalCode.value.match("^[0-9]*$")
    ) {
      postalCode.style.border = "2px solid red";
      setElementFormFive("Votre code postal doit contenir cinq chiffres");
    }

    if (mailContent.value.length <= 2000 && mailContent.value.length > 0) {
      setContentMailValue(mailContent.value);
      mailContent.style.border = "2px solid #667788";
    } else {
      mailContent.style.border = "2px solid red";
      mailContent.placeholder = "Soyez précis...";
    }
    if (
      lastNameValue &&
      firstNameValue &&
      numberPhoneValue &&
      mailValue &&
      postalCodeValue &&
      contentMailValue
    ) {
      buttonSubmitClick.style.backgroundColor = "#667788";
      buttonSubmitClick.style.transform = "scale(0.95)";
      buttonSubmitClick.style.transition = "150ms";

      setTimeout(() => {
        buttonSubmitClick.style.transform = "scale(1.05)";
        buttonSubmitClick.style.transition = "150ms";
      }, 150);

      sendMail();
    }
  };

  return (
    <>
      <Button
        aria-label="Clickez pour valider l'envoi du formulaire"
        onClick={(e) => handleSubmit(e)}
        onMouseMove={hoverButton}
        onMouseLeave={notHoverButton}
      >
        ENVOYER
      </Button>
    </>
  );
}
