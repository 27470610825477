import { createContext, useState } from "react";

export const TrashContext = createContext();

export function TrashContextProvider(props) {
  const [trashDisplay, setTrashDisplay] = useState(false);

  const displayTrash = (element) => {
    if (element === "trashEffect") {
      setTrashDisplay(true);
    }
    if (element === "trashNotEffect") {
      setTrashDisplay(false);
    }
  };

  return (
    <TrashContext.Provider value={{ trashDisplay, displayTrash }}>
      {props.children}
    </TrashContext.Provider>
  );
}
