import { OffsetTopFunction } from "./OffsetTopFunction";

const EffectSubSectionFunction = (elementClassName, location) => {
  const blockTitle = document.querySelectorAll(`.${elementClassName}`);
  const screenY = window.scrollY + window.innerHeight;
  if (window.location.pathname === location) {
    for (let block of blockTitle) {
      if (window.innerWidth > 991) {
        if (OffsetTopFunction(block) - screenY < -340) {
          block.style.transform = "translateX(0vw)";
          block.style.transition = "all 300ms";
          block.parentElement.children[2].style.transform = "translateX(0vw)";
          block.parentElement.children[2].style.transition = "all 300ms";
          block.parentElement.children[1].style.transform = "translateX(0vw)";
          block.parentElement.children[1].style.transition = "all 300ms";
        } else if (OffsetTopFunction(block) - screenY > -340) {
          block.style.transform = "translateX(-1vw)";
          block.style.transition = "all 300ms";
          block.parentElement.children[2].style.transform = "translateX(-1vw)";
          block.parentElement.children[2].style.transition = "all 300ms";
          block.parentElement.children[1].style.transform = "translateX(1vw)";
          block.parentElement.children[1].style.transition = "all 300ms";
        }
      }
      if (window.innerWidth > 568 && window.innerWidth <= 991) {
        if (OffsetTopFunction(block) - screenY < -340) {
          block.parentElement.children[1].style.transform = "translateX(0vw)";
          block.parentElement.children[1].style.transition = "all 300ms";
        } else if (OffsetTopFunction(block) - screenY > -340) {
          block.parentElement.children[1].style.transform = "translateX(1vw)";
          block.parentElement.children[1].style.transition = "all 300ms";
        }
      }
    }
  }
};

export { EffectSubSectionFunction };
