const sizes = {
  mobileXS: "290px",
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "480px",
  tabletM: "670px",
  tablet: "768px",
  laptopXXS: "991px",
  laptopS: "1140px",
  laptopM: "1294px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const devices = {
  mobileXS: `(max-width: ${sizes.mobileXS})`,
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tabletM: `(max-width: ${sizes.tabletM})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptopXXS: `(max-width: ${sizes.laptopXXS})`,
  laptopS: `(max-width: ${sizes.laptopS})`,
  laptopM: `(max-width: ${sizes.laptopM})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};
