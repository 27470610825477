import { createContext, useState } from "react";

export const NavBarMiniContext = createContext();

export function NavBarMiniContextProvider(props) {
  const [navBarMiniDisplay, setNavBarMiniDisplay] = useState(false);

  const displayNavBarMini = (element) => {
    if (element === "navBarMiniEffect") {
      setNavBarMiniDisplay(true);
    }
    if (element === "navBarMiniNotEffect") {
      setNavBarMiniDisplay(false);
    }
  };

  return (
    <NavBarMiniContext.Provider
      value={{ navBarMiniDisplay, displayNavBarMini }}
    >
      {props.children}
    </NavBarMiniContext.Provider>
  );
}
