import { createContext, useState } from "react";

export const ModalFormContext = createContext();

export function ModalFormContextProvider(props) {
  const [modalForm, setModalForm] = useState(false);
  const [contenairModalForm, setContenairModalForm] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [modalConfirmationError, setModalConfirmationError] = useState(false);

  const displayModalForm = (element) => {
    if (element === "modalFormDisplay") {
      setModalForm(true);
    }
    if (element === "modalFormClose") {
      setModalForm(false);
    }
    if (element === "contenairModalFormDisplay") {
      setContenairModalForm(true);
    }
    if (element === "contenairModalFormClose") {
      setContenairModalForm(false);
    }
    if (element === "modalConfirmationDisplay") {
      setModalConfirmation(true);
    }
    if (element === "modalConfirmationHidden") {
      setModalConfirmation(false);
    }
    if (element === "modalConfirmationDisplayError") {
      setModalConfirmationError(true);
    }
    if (element === "modalConfirmationHiddenError") {
      setModalConfirmationError(false);
    }
  };
  return (
    <ModalFormContext.Provider
      value={{
        modalForm,
        contenairModalForm,
        modalConfirmation,
        modalConfirmationError,
        displayModalForm,
      }}
    >
      {props.children}
    </ModalFormContext.Provider>
  );
}
