import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { NotEffectGlowButtonFunction } from "../../functions/EffectGlowButtonsFunction";
import { ButtonEffectScaleClickFunction } from "../../functions/ButtonClickEffectScale";
import { NotEffectArticleFunction } from "../../functions/EffectArticleFunction";
import { PageTransitionContext } from "../../contexts/PageTransition";
import { devices } from "../../style/Size";

const Contenair = styled.div``;

const BlockImage = styled.div`
  width: 100%;
  height: 460px;

  @media (max-height: 480px) {
    height: 76vh;
  }
`;

const BlockArticle = styled.div`
  width: 100%;
  background: linear-gradient(
    rgba(102, 119, 136, 0.94),
    rgba(102, 119, 136, 0.94)
  );
`;

const TitleArticle = styled.div`
  display: flex;
  min-width: 200px;
`;

const PTitleArticle = styled.h3`
  font-size: 24px;
  font-family: "Russo One", cursive, sans-serif;
  color: #ffffff;
  letter-spacing: 2px;
  font-weight: 500;
  padding-top: 14px;
  padding-bottom: 10px;
  margin: 0;
  margin-bottom: auto;
  margin-left: 5%;

  @media ${devices.mobileXS} {
    font-size: 22px;
  }
`;

const BlockText = styled.p`
  width: 90%;
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  font-weight: 100;
  color: #ffffff;
  text-align: justify;
  margin-left: 5%;
  opacity: 1;
  padding-bottom: 20px;

  @media ${devices.mobileXS} {
    font-size: 16px;
  }
  @media (max-height: 480px) {
    margin-top: 0;
  }
`;

const Button = styled.button.attrs({
  type: "button",
  value: "En savoir plus",
  className: "buttonArticleTopHome",
})`
  width: 52%;
  height: 40px;
  background-color: #556699;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  font-weight: 100;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: 24%;

  @media ${devices.mobileS} {
    width: 60%;
    margin-left: 20%;
  }
  @media (max-height: 480px) {
    width: 50%;
    margin-left: 25%;
    bottom: 12px;
  }
`;

export default function BodyArticleTopMobileScreen({
  classNameBlock,
  contentTitle,
  contentText,
  idButton,
  element1,
  element2,
  element3,
  element4,
  element5,
  element6,
}) {
  const { displayPageTransition } = useContext(PageTransitionContext);
  const navigate = useNavigate();

  const navigatePage = (
    route,
    element1,
    element2,
    element3,
    element4,
    element5
  ) => {
    const blockArticle = document.querySelector(`.${element1}`);
    const contenairArticle = document.querySelector(`#${element2}`);
    const PTitleArticle = document.querySelector(`#${element3}`);
    const PArticle = document.querySelector(`#${element4}`);
    const button = document.querySelector(`#${element5}`);

    NotEffectGlowButtonFunction("buttonArticleTopHome");
    ButtonEffectScaleClickFunction(button);
    button.style.pointerEvents = "none";
    button.style.cursor = "auto";

    if (window.innerWidth > 991) {
      setTimeout(() => {
        if (blockArticle) {
          NotEffectArticleFunction(
            blockArticle,
            contenairArticle,
            PTitleArticle,
            PArticle,
            button
          );
        }
      }, 500);
      setTimeout(() => {
        displayPageTransition("pageTransitionEffect");
      }, 1200);
      setTimeout(() => {
        displayPageTransition("pageTransitionNotEffect");
        window.scrollTo(0, 0);
        navigate(`${route}`);
      }, 2000);
    }
    if (window.innerWidth <= 991) {
      setTimeout(() => {
        if (blockArticle) {
          NotEffectArticleFunction(
            blockArticle,
            contenairArticle,
            PTitleArticle,
            PArticle,
            button
          );
        }
      }, 500);
      setTimeout(() => {
        displayPageTransition("pageTransitionEffect");
      }, 700);
      setTimeout(() => {
        displayPageTransition("pageTransitionNotEffect");
        window.scrollTo(0, 0);
        navigate(`${route}`);
      }, 1500);
    }
  };

  return (
    <Contenair>
      <BlockImage className={classNameBlock} />
      <BlockArticle>
        <TitleArticle>
          <PTitleArticle>{(contentTitle = [contentTitle])}</PTitleArticle>
        </TitleArticle>
        <BlockText>{(contentText = [contentText])}</BlockText>
        <Button
          id={idButton}
          onMouseLeave={() =>
            NotEffectGlowButtonFunction("buttonArticleTopHome")
          }
          onClick={() =>
            navigatePage(
              `${element1}`,
              `${element2}`,
              `${element3}`,
              `${element4}`,
              `${element5}`,
              `${element6}`
            )
          }
        >
          EN SAVOIR PLUS
        </Button>
      </BlockArticle>
    </Contenair>
  );
}
