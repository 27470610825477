import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ParagraphLi from "./ParagraphLi";
import {
  EffectNavBarNotHoverForParagraphFunction,
  EffectNavBarHoverForParagraphFunction,
} from "../../../functions/EffectNavBarFunction";
import { PageTransitionContext } from "../../../contexts/PageTransition";

const BlockSubMenu = styled.ul.attrs({
  id: "installationHidden",
  className: "subMenuBlock",
})`
  width: 160px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: #fcf7f7;
  border-top: 1px solid #333131;
  border-left: 1px solid #333131;
  border-right: 1px solid #333131;
  border-radius: 2px;
  cursor: pointer;
  font-size: 34px;
  position: absolute;
  top: 65px;
  transform: scaleY(0);
  transform-origin: top;
`;

export default function Installation() {
  const { displayPageTransition } = useContext(PageTransitionContext);
  const navigate = useNavigate();

  const functionForFunctionHover = (e) => {
    const paragraph = document.querySelector(`#${e.target.id}`);

    EffectNavBarHoverForParagraphFunction(
      `${paragraph.id}`,
      "installationHidden",
      "installation"
    );
  };

  const functionForFunctionNotHover = (e) => {
    const paragraph = document.querySelector(`#${e.target.id}`);
    EffectNavBarNotHoverForParagraphFunction(
      `${paragraph.id}`,
      "installationHidden",
      "installation"
    );
  };

  const onClickNavigate = (e, route) => {
    const allParagraph = document.querySelectorAll(".paragraph");
    const blockMenuUl = document.querySelector(".blockMenuUl");
    const li = document.querySelectorAll(".li");

    for (let i = 0; i < allParagraph.length; i++) {
      if (e.target.id === allParagraph[i].id) {
        allParagraph[i].parentElement.style.pointerEvents = "none";
        blockMenuUl.style.pointerEvents = "none";
        for (let element of li) {
          element.style.pointerEvents = "none";
        }
        EffectNavBarNotHoverForParagraphFunction(
          `${e.target.id}`,
          "installationHidden",
          "installation"
        );

        if (window.location.pathname !== `/installation/${e.target.id}`) {
          setTimeout(() => {
            displayPageTransition("pageTransitionEffect");
          }, 900);
          setTimeout(() => {
            displayPageTransition("pageTransitionNotEffect");
            window.scrollTo(0, 0);
            navigate(`${route}`);
          }, 1700);
        } else {
          const scrollToTop = () => {
            const c =
              document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
              window.requestAnimationFrame(scrollToTop);
              window.scrollTo(0, c - c / 8);
            }
          };
          setTimeout(() => {
            scrollToTop();
            allParagraph[i].parentElement.style.pointerEvents = "auto";
            blockMenuUl.style.pointerEvents = "auto";
            for (let element of li) {
              element.style.pointerEvents = "auto";
            }
          }, 600);
        }
      }
    }
  };

  return (
    <>
      <BlockSubMenu>
        <ParagraphLi
          idLi="toilettes"
          idLiHome="toilettes"
          marginTop="-2px"
          marginBottom="0"
          paddingTop="12px"
          paddingBottom="10px"
          onMouseEnter={functionForFunctionHover}
          onMouseLeave={functionForFunctionNotHover}
          onClick={(e) => onClickNavigate(e, "/installation/toilettes")}
          contentLi="Toilettes"
          contentLiHome="Toilettes"
        />
        <ParagraphLi
          idLi="lavabos"
          idLiHome="lavabos"
          marginTop="-10px"
          marginBottom="0"
          paddingTop="10px"
          paddingBottom="10px"
          onMouseEnter={functionForFunctionHover}
          onMouseLeave={functionForFunctionNotHover}
          onClick={(e) => onClickNavigate(e, "/installation/lavabos")}
          contentLi="Lavabos"
          contentLiHome="Lavabos"
        />
        <ParagraphLi
          idLi="bacs-a-douche"
          idLiHome="bacs-a-douche"
          marginTop="-10px"
          marginBottom="0"
          paddingTop="10px"
          paddingBottom="10px"
          onMouseEnter={functionForFunctionHover}
          onMouseLeave={functionForFunctionNotHover}
          onClick={(e) => onClickNavigate(e, "/installation/bacs-a-douche")}
          contentLi="Bacs à Douche"
          contentLiHome="Bacs à Douche"
        />
        <ParagraphLi
          idLi="baignoires"
          idLiHome="baignoires"
          marginTop="-10px"
          paddingTop="10px"
          onMouseEnter={functionForFunctionHover}
          onMouseLeave={functionForFunctionNotHover}
          onClick={(e) => onClickNavigate(e, "/installation/baignoires")}
          contentLi="Baignoires"
          contentLiHome="Baignoires"
        />
      </BlockSubMenu>
    </>
  );
}
