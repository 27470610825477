import React from "react";
import { styled } from "styled-components";
import { devices } from "../../../style/Size";

const BlockMenuInfos = styled.div.attrs({
  className: "blockMenuInfos",
})`
  width: 100%;
  height: 400px;
  border-radius: 10px;
  perspective: 1000px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(-90deg);
  transition-duration: 300ms;
  box-shadow: 0 0 8px black;
  cursor: default;

  @media (max-height: 480px) {
    height: 240px;
  }
`;

const FilterFiche = styled.div`
  width: 100%;
  height: 400px;
  opacity: 0.4;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  background: radial-gradient(circle at 50% 50%, #a0bedf, #1c3377);
  z-index: 10;

  @media (max-height: 480px) {
    height: 240px;
  }
`;

const MenuInfos = styled.div.attrs({
  className: "menuInfos",
})`
  width: 100%;
  height: 310px;
  overflow: hidden;
  position: absolute;
  top: 60px;
  z-index: 100;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  @media ${devices.mobileL} {
  }
  @media (max-height: 480px) {
    height: 160px;
    top: 24%;
  }
`;

const Infos = styled.div.attrs({
  className: "infos",
})`
  width: 84%;
  font-size: 18px;
  color: #553322;
  font-family: "Russo One", sans-serif;
  margin: auto;
  transition-duration: 10ms;
`;

const Prix = styled.div.attrs({
  className: "prixBigFiche",
})`
  width: 200px;
  background: radial-gradient(circle at 80% 50%, #da6060, #c24a4a);
  font-size: 20px;
  color: #ffffff;
  font-family: "Russo One", sans-serif;
  text-align: center;
  margin: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  position: absolute;
  z-index: 12;
  right: 20px;
  top: 10px;
  transform: translateX(-23vw);
  opacity: 1;
  transition-delay: 400ms;
  transition-duration: 500ms;

  @media ${devices.laptopL} {
    transform: translateX(-21vw);
  }
  @media ${devices.laptopM} {
    transform: translateX(-19vw);
  }
  @media ${devices.laptopS} {
    transform: translateX(-17vw);
  }
  @media ${devices.laptopXXS} {
    width: 180px;
    transform: translateX(-14vw);
  }
  @media (max-width: 864px) {
    transform: translateX(-12vw);
  }
  @media ${devices.tablet} {
    width: 160px;
  }
  @media (max-width: 684px) {
    transform: translateX(-36vw);
  }
  @media ${devices.mobileL} {
    transform: translateX(-30vw);
  }
  @media ${devices.mobileM} {
    width: 140px;
    font-size: 18px;
  }
  @media ${devices.mobileS} {
    width: 120px;
    transform: translateX(-28vw);
  }
  @media ${devices.mobileXS} {
    width: 120px;
    transform: translateX(-60px);
  }
`;

export default function BigFicheVersoBlock(props) {
  return (
    <BlockMenuInfos
      id={props.idBlockMenuInfos}
      className={props.className}
      onMouseMove={props.effect3dDisplay}
      onMouseLeave={props.notEffect3dDisplay}
    >
      <Prix>Sur Devis</Prix>
      <FilterFiche></FilterFiche>
      <MenuInfos>
        <Infos>{props.textFiche}</Infos>
      </MenuInfos>
    </BlockMenuInfos>
  );
}
