const NavBarDisplayFunction = (idHeaderContenair, routePath) => {
  const headerContenair = document.querySelector(`#${idHeaderContenair}`);
  const blockNavAndMenu = document.querySelector("#blockNavAndMenu");

  if (headerContenair) {
    if (
      headerContenair.getBoundingClientRect().y + headerContenair.offsetHeight <
        0 &&
      (window.location.pathname !== "/" ||
        window.location.pathname !== "/blog" ||
        window.location.pathname !== `/blog/${routePath}`)
    ) {
      blockNavAndMenu.style.position = "fixed";
      blockNavAndMenu.style.backgroundColor = "#667788";
    }
    if (
      headerContenair.getBoundingClientRect().y +
        headerContenair.offsetHeight >=
        0 &&
      (window.location.pathname !== "/" ||
        window.location.pathname !== "/blog" ||
        window.location.pathname !== `/blog/${routePath}`)
    ) {
      blockNavAndMenu.style.position = "absolute";
      blockNavAndMenu.style.backgroundColor = "#667788";
    }
    if (
      headerContenair.getBoundingClientRect().y + headerContenair.offsetHeight <
        0 &&
      (window.location.pathname === "/blog" ||
        window.location.pathname === `/blog/${routePath}`)
    ) {
      blockNavAndMenu.style.position = "fixed";
      blockNavAndMenu.style.backgroundColor = "#3c2114";
    }
    if (
      headerContenair.getBoundingClientRect().y +
        headerContenair.offsetHeight >=
        0 &&
      (window.location.pathname === "/blog" ||
        window.location.pathname === `/blog/${routePath}`)
    ) {
      blockNavAndMenu.style.position = "absolute";
      blockNavAndMenu.style.backgroundColor = "#3c2114";
    }
    if (
      headerContenair.getBoundingClientRect().y +
        headerContenair.offsetHeight >=
        0 &&
      window.location.pathname === "/"
    ) {
      blockNavAndMenu.style.position = "absolute";
      blockNavAndMenu.style.backgroundColor = "transparent";
    }
  }
};

export { NavBarDisplayFunction };
