import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { NavBarMiniContext } from "../../../contexts/NavBarMiniContext";
import "../../../style/Animation/KeyFrames/MiniMenuEffect.css";
import { devices } from "../../../style/Size";

const ContenairIconButton = styled.button.attrs({
  id: "buttonMiniMenu",
  type: "button",
  value: "Ouvrir Menu",
})`
  width: 34px;
  height: 30px;
  border: none;
  margin-right: 20px;
  margin-top: 12px;
  font-size: 24px;
  color: #ffffff;
  background-color: transparent;
  cursor: pointer;

  @media ${devices.tablet} {
    margin-top: 8px;
  }
  @media ${devices.mobileL} {
    font-size: 20px;
    margin-top: 4px;
    margin-right: 6px;
  }
  @media ${devices.mobileM} {
    font-size: 18px;
    margin-top: 6px;
    margin-right: 5px;
  }
  @media ${devices.mobileXS} {
    margin-right: 2px;
  }
`;

export default function NavBarMini() {
  const { navBarMiniDisplay, displayNavBarMini } =
    useContext(NavBarMiniContext);
  const [displayButtonMiniMenu, setDisplayButtonMiniMenu] = useState(false);

  const displayMenu = () => {
    displayNavBarMini("navBarMiniEffect");
  };

  useEffect(() => {
    if (navBarMiniDisplay) {
      const filterMiniMenu = document.querySelector("#filterMiniMenu");
      const contenairMiniMenu = document.querySelector("#contenairMiniMenu");

      filterMiniMenu.classList.remove("notDisplayFilterMiniMenu");
      contenairMiniMenu.classList.remove("notDisplayMiniMenu");
      filterMiniMenu.classList.add("displayFilterMiniMenu");
      contenairMiniMenu.classList.add("displayMiniMenu");
    }
  }, [navBarMiniDisplay]);

  useEffect(() => {
    if (window.innerWidth <= 1140) {
      setDisplayButtonMiniMenu(true);
    }
    if (
      window.innerWidth > 1140 &&
      !navigator.userAgent.match(/iPhone/i) &&
      !navigator.userAgent.match(/webOS/i) &&
      !navigator.userAgent.match(/Android/i) &&
      !navigator.userAgent.match(/iPad/i) &&
      !navigator.userAgent.match(/iPod/i) &&
      !navigator.userAgent.match(/BlackBerry/i) &&
      !navigator.userAgent.match(/Windows Phone/i)
    ) {
      setDisplayButtonMiniMenu(false);
    }
  }, [displayButtonMiniMenu]);

  window.addEventListener("resize", () => {
    if (window.innerWidth <= 1140) {
      setDisplayButtonMiniMenu(true);
    }
    if (
      window.innerWidth > 1140 &&
      !navigator.userAgent.match(/iPhone/i) &&
      !navigator.userAgent.match(/webOS/i) &&
      !navigator.userAgent.match(/Android/i) &&
      !navigator.userAgent.match(/iPad/i) &&
      !navigator.userAgent.match(/iPod/i) &&
      !navigator.userAgent.match(/BlackBerry/i) &&
      !navigator.userAgent.match(/Windows Phone/i)
    ) {
      setDisplayButtonMiniMenu(false);
    }
    if (
      window.innerWidth > 1140 &&
      (navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i))
    ) {
      setDisplayButtonMiniMenu(true);
    }
  });

  useEffect(() => {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setDisplayButtonMiniMenu(true);
    }
  }, [displayButtonMiniMenu]);

  window.addEventListener("popstate", () => {
    if (navBarMiniDisplay) {
      displayNavBarMini("navBarMiniNotEffect");
    }
  });

  return (
    <>
      {displayButtonMiniMenu && (
        <ContenairIconButton
          aria-label="Ouvrir menu"
          onClick={() => displayMenu()}
          // onTouchStart={() => displayMenu()}
        >
          <i className="fa-solid fa-bars"></i>
        </ContenairIconButton>
      )}
    </>
  );
}
