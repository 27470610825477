import React from "react";
import styled from "styled-components";
import { devices } from "../style/Size";

const ContenairTitlePage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 120px;
  background-color: #eaeaea;
  position: relative;
`;

const TitlePage = styled.h1`
  width: 80%;
  font-family: "Russo One", sans-serif;
  font-size: 34px;
  font-weight: 500;
  color: #667788;
  text-shadow: 1px 1px 1px pink;
  text-align: center;
  margin: 0;
  position: relative;
  top: 42px;
  transform: scale(1.1);
  opacity: 0.5;

  @media ${devices.laptopXXS} {
    font-size: 34px;
  }
  @media ${devices.tablet} {
    font-size: 32px;
  }
  @media ${devices.mobileL} {
    font-size: 30px;
  }
  @media ${devices.mobileM} {
    font-size: 28px;
  }
`;

export default function ContenairTitlePageSection(props) {
  return (
    <ContenairTitlePage style={{ backgroundColor: `${props.backgroundColor}` }}>
      <TitlePage id={props.idTitle}>{props.title}</TitlePage>
    </ContenairTitlePage>
  );
}
