import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import HeaderSubSection from "../components/Header/HeaderSubSection";
import BlockWaterHeater from "../components/BodyWaterHeater/BlockWaterHeater";
import LastBlockWaterHeater from "../components/BodyWaterHeater/LastBlockWaterHeater";
import Footer from "../components/Footer";
import WaterHeaterPicture from "../assets/Header/chauffe-eau-electrique.webp";
import ElectricWaterHeaterPicture from "../assets/WaterHeater/chauffe-eau-electrique.webp";
import GazWaterHeaterPicture from "../assets/WaterHeater/chaudiere-gaz.webp";
import HeatPumpPicture from "../assets/WaterHeater/pompe-a-chaleur.webp";
import { OffsetTopFunction } from "../functions/OffsetTopFunction";
import { NavBarDisplayFunction } from "../functions/NavBarDisplayScrollFunction";
import { ButtonWaterHeaterEffectScaleClickFunction } from "../functions/ButtonClickEffectScale";
import { NotEffectGlowButtonWaterHeaterFunction } from "../functions/EffectGlowButtonsFunction";
import { EffectTitlePageFunction } from "../functions/EffectTitlePageFunction";
import { PageTransitionContext } from "../contexts/PageTransition";
import { devices } from "../style/Size";

const Main = styled.main`
  width: 100%;
  position: relative;
  margin-top: 70px;

  @media ${devices.laptopXXS} {
    margin-top: 110px;
  }
  @media ${devices.tablet} {
    margin-top: 40px;
  }
`;

const BlockElementAndContenairImage = styled.section`
  width: 100%;
  position: relative;
  margin-top: 70px;

  @media ${devices.laptopXXS} {
    margin-top: 110px;
  }
  @media ${devices.tablet} {
    margin-top: 40px;
  }
`;

export default function WaterHeater() {
  const { displayPageTransition } = useContext(PageTransitionContext);

  useEffect(() => {
    EffectTitlePageFunction("titleWaterHeaterContenair");
    setTimeout(() => {
      EffectTitlePageFunction("idBlockElement");
    }, 600);
  }, []);

  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction("headerWaterHeaterContenair");
  });

  const EffectSubSectionWaterHeater = (elementClassName, location) => {
    const blockSection = document.querySelectorAll(`.${elementClassName}`);
    const screenY = window.scrollY + window.innerHeight;
    if (window.location.pathname === location) {
      if (window.innerWidth > 991) {
        for (let element of blockSection) {
          if (OffsetTopFunction(element) - screenY < -370) {
            element.children[0].style.transform = "translateX(0vw)";
            element.children[0].style.transition = "all 300ms";
            element.children[1].children[0].style.transform = "translateX(0vw)";
            element.children[1].children[0].style.transition = "all 300ms";
            element.children[1].children[1].style.transform = "translateX(0vw)";
            element.children[1].children[1].style.transition = "all 300ms";
            element.children[1].children[2].style.transform = "translateX(0vw)";
            element.children[1].children[2].style.transition = "all 300ms";
          } else if (OffsetTopFunction(element) - screenY > -370) {
            element.children[0].style.transform = "translateX(-1vw)";
            element.children[0].style.transition = "all 300ms";
            element.children[1].children[0].style.transform = "translateX(1vw)";
            element.children[1].children[0].style.transition = "all 300ms";
            element.children[1].children[1].style.transform =
              "translateX(-1vw)";
            element.children[1].children[1].style.transition = "all 300ms";
            element.children[1].children[2].style.transform =
              "translateX(-1vw)";
            element.children[1].children[2].style.transition = "all 300ms";
          }
        }
      }
      if (window.innerWidth > 694 && window.innerWidth <= 991) {
        for (let element of blockSection) {
          if (OffsetTopFunction(element) - screenY < -370) {
            element.children[1].children[0].style.transform = "translateX(0vw)";
            element.children[1].children[0].style.transition = "all 300ms";
          } else if (OffsetTopFunction(element) - screenY > -370) {
            element.children[1].children[0].style.transform = "translateX(1vw)";
            element.children[1].children[0].style.transition = "all 300ms";
          }
        }
      }
      if (window.innerWidth <= 694) {
        for (let element of blockSection) {
          element.children[1].children[0].style.transform = "translateX(0vw)";
          element.children[1].children[0].style.transition = "all 300ms";
        }
      }
    }
  };

  window.addEventListener("resize", () => {
    const blockSection = document.querySelectorAll(`.imageSection`);
    if (blockSection) {
      EffectSubSectionWaterHeater("contenairBlockElements");
      if (window.innerWidth <= 694) {
        for (let element of blockSection) {
          element.style.transform = "translateX(0vw)";
          element.style.transition = "all 300ms";
        }
      }
    }
  });

  useEffect(() => {
    const elementSection = document.querySelector(".contenairBlockElements");
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        window.addEventListener("scroll", () => {
          EffectSubSectionWaterHeater("contenairBlockElements", "/chauffe-eau");
        });
      }
    });
    observer.observe(elementSection);
  }, []);

  const navigateLink = (e, route) => {
    const buttonArticle = document.querySelectorAll(".buttonArticle");
    for (let button of buttonArticle) {
      if (e.target.id === button.id) {
        NotEffectGlowButtonWaterHeaterFunction(e, "buttonArticle");
        ButtonWaterHeaterEffectScaleClickFunction(e, button);
        button.style.pointerEvents = "none";
        button.style.cursor = "auto";

        setTimeout(() => {
          displayPageTransition("pageTransitionEffect");
        }, 700);

        setTimeout(() => {
          displayPageTransition("pageTransitionNotEffect");
          window.scrollTo(0, 0);
          window.location.pathname = `${route}`;
        }, 1500);
      }
    }
  };

  return (
    <>
      <HeaderSubSection
        id="headerWaterHeaterContenair"
        idTitle="titleWaterHeaterContenair"
        src={WaterHeaterPicture}
        titlePage="Chauffe-Eau"
        alt="Chauffe-eau électrique blanc de forme cylindrique posé sur mur en briques bleues"
      />
      <Main>
        <BlockElementAndContenairImage>
          <BlockWaterHeater
            idBlockTextAndButton="blockTextAndButtonSection"
            blockTextWaterHeater="blockTextWaterHeater"
            imageClassname="imageSectionWaterHeater"
            idBlockElement="idBlockElement"
            opacity="0.5"
            src={ElectricWaterHeaterPicture}
            alt="Grand et long chauffe-eau électrique sol au design noir et blanc placé dans un placard de la cuisine"
            title={[
              <strong key="1" style={{ fontWeight: "500" }}>
                Chauffe-eau Électriques
              </strong>,
            ]}
            text={[
              "Besoin d'installer ou de renouveler votre  ",
              <strong key="1" style={{ fontWeight: "100" }}>
                chauffe-eau électrique
              </strong>,
              " ? Que ce soit pour un matériel avec une ",
              <strong key="2" style={{ fontWeight: "100" }}>
                résistance blindée
              </strong>,
              ", avec une ",
              <strong key="3" style={{ fontWeight: "100" }}>
                résistance stéatite
              </strong>,
              ", ou bien pour le dernier cri de la technologie avec les ",
              <strong key="4" style={{ fontWeight: "100" }}>
                Duralis Connect
              </strong>,
              ", nous saurons vous conseiller et vous installer le produit le plus adapté à votre budget et à votre logement.",
              <br key="5" />,
              <br key="6" />,
              "Nos ",
              <strong key="7" style={{ fontWeight: "100" }}>
                plombiers-chauffagistes à Marseille
              </strong>,
              " se rendent sur place pour effectuer un devis 100% gratuit et personnalisé afin d'estimer le ",
              <strong key="8" style={{ fontWeight: "100" }}>
                coût et temps d'installation chauffagiste
              </strong>,
              ". Le temps de pose est généralement aux environs de 4 heures sans modification d'installation.",
              <br key="9" />,
            ]}
            onClick={(e) =>
              navigateLink(e, "/chauffe-eau/chauffe-eau-electriques")
            }
            // onTouchStart={(e) =>
            //   navigateLink(e, "/chauffe-eau/chauffe-eau-electriques")
            // }
          />
        </BlockElementAndContenairImage>
        <BlockElementAndContenairImage>
          <BlockWaterHeater
            imageClassname="imageSectionWaterHeater"
            src={GazWaterHeaterPicture}
            alt="Chauffe-eau gaz mural rectangulaire et compact blanc dans salle de bain de couleur blanche"
            title={[
              <strong key="1" style={{ fontWeight: "500" }}>
                Chauffe-eau Gaz – Chaudières
              </strong>,
            ]}
            text={[
              "Besoin d'installer ou de renouveler votre  ",
              <strong key="1" style={{ fontWeight: "100" }}>
                chauffe-eau gaz
              </strong>,
              " ou votre ",
              <strong key="2" style={{ fontWeight: "100" }}>
                chaudière
              </strong>,
              " ? Que ce soit pour un ",
              <strong key="3" style={{ fontWeight: "100" }}>
                chauffe-eau gaz
              </strong>,
              ", une ",
              <strong key="4" style={{ fontWeight: "100" }}>
                chaudière gaz
              </strong>,
              ", ou bien pour un ",
              <strong key="5" style={{ fontWeight: "100" }}>
                chauffe-eau gaz à condensation
              </strong>,
              ", nous saurons vous conseiller et vous installer le produit le plus adapté à votre budget et à votre logement.",
              <br key="6" />,
              <br key="7" />,
              "Nos ",
              <strong key="8" style={{ fontWeight: "100" }}>
                plombiers-chauffagistes à Marseille
              </strong>,
              " se rendent sur place pour effectuer un devis 100% gratuit et personnalisé afin d'estimer le ",
              <strong key="9" style={{ fontWeight: "100" }}>
                coût et temps d'installation chauffagiste
              </strong>,
              ". Le temps de pose est généralement aux environs de 4 heures sans modification d'installation.",
              <br key="10" />,
            ]}
            idButton="button2"
            onClick={(e) =>
              navigateLink(e, "/chauffe-eau/chauffe-eau-gaz-chaudieres")
            }
            // onTouchStart={(e) =>
            //   navigateLink(e, "/chauffe-eau/chauffe-eau-gaz-chaudieres")
            // }
          />
        </BlockElementAndContenairImage>
        <BlockElementAndContenairImage>
          <LastBlockWaterHeater
            imageClassname="imageSectionWaterHeater"
            src={HeatPumpPicture}
            alt="Pompe à chaleur sol, grande et longue, moderne noir et blanche, avec son groupe système dans un garage vaste et bien rangé"
            title={[
              <strong key="1" style={{ fontWeight: "500" }}>
                Pompes à Chaleur
              </strong>,
            ]}
            text={[
              "Vous avez entendu parlé de la haute technologie que sont les ",
              <strong key="1" style={{ fontWeight: "100" }}>
                pompes à chaleur
              </strong>,
              " ? Vous désirez installer un de ces produits dans votre logement ? Que ce soit pour une ",
              <strong key="2" style={{ fontWeight: "100" }}>
                pompe à chaleur air-eau
              </strong>,
              " ou une ",
              <strong key="3" style={{ fontWeight: "100" }}>
                pompe à chaleur air-air
              </strong>,
              ", nous saurons vous conseiller et vous installer le produit le plus adapté à votre budget et à votre logement.",
              <br key="4" />,
              <br key="5" />,
              "Nos ",
              <strong key="6" style={{ fontWeight: "100" }}>
                plombiers-chauffagistes à Marseille
              </strong>,
              " se rendent sur place pour effectuer un devis 100% gratuit et personnalisé afin d'estimer le ",
              <strong key="7" style={{ fontWeight: "100" }}>
                coût et temps d'installation chauffagiste
              </strong>,
              ".",
              <br key="8" />,
            ]}
            idButton="button3"
            onClick={(e) => navigateLink(e, "/chauffe-eau/pompes-a-chaleur")}
            // onTouchStart={(e) =>
            //   navigateLink(e, "/chauffe-eau/pompes-a-chaleur")
            // }
          />
        </BlockElementAndContenairImage>
      </Main>
      <Footer backgroundColor="#667788" />
    </>
  );
}
