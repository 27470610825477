import React, { useEffect } from "react";
import styled from "styled-components";
import Footer from "../../components/Footer";
import HeaderBlog from "../../components/Header/HeaderBlog";
import { NavBarDisplayFunction } from "../../functions/NavBarDisplayScrollFunction";
import { devices } from "../../style/Size";

const ContenairMain = styled.main`
  width: 94%;
  /* background-color: #ffffff; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 134px;
  margin-bottom: 200px;

  @media ${devices.laptopXXS} {
    margin-top: 114px;
    margin-bottom: 120px;
  }
  @media ${devices.mobileL} {
    margin-top: 100px;
    margin-bottom: 80px;
  }
  @media (max-height: 480px) {
    margin-bottom: 80px;
  }
`;

const TitleArticle = styled.h1`
  font-size: 34px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  padding-top: 10px;

  @media ${devices.tablet} {
    font-size: 32px;
  }
  @media ${devices.mobileL} {
    font-size: 30px;
  }
  @media ${devices.mobileM} {
    font-size: 28px;
  }
`;

const SubTitleArticle = styled.h2`
  font-size: 28px;
  font-family: "Russo One", sans-serif;
  color: #553322;

  @media ${devices.tablet} {
    font-size: 26px;
  }
  @media ${devices.mobileL} {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
`;

const TextArticle = styled.h2`
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  text-align: justify;
  margin-bottom: 40px;

  @media ${devices.mobileL} {
    margin-bottom: 50px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

export default function TuyauxPlomberie() {
  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction(
      "headerBlogContenair",
      "les-differents-tuyaux-en-plomberie"
    );
  });

  return (
    <>
      <HeaderBlog />
      <ContenairMain>
        <TitleArticle>
          Les Différents Types de Tuyaux Utilisés en Plomberie et Leurs
          Avantages
        </TitleArticle>
        <TextArticle>
          Lorsque vous envisagez un projet de plomberie, choisir le bon type de
          tuyaux est essentiel pour assurer la durabilité et l'efficacité de
          votre système. Il existe plusieurs matériaux de tuyauterie disponibles
          sur le marché, chacun avec ses propres avantages et inconvénients.
          Dans cet article, nous examinerons les différents types de tuyaux
          couramment utilisés en plomberie et les avantages qu'ils offrent.
        </TextArticle>
        <section>
          <SubTitleArticle>Tuyaux en cuivre</SubTitleArticle>
          <TextArticle>
            Les tuyaux en cuivre sont parmi les plus populaires dans l'industrie
            de la plomberie pour plusieurs raisons. Ils sont durables,
            résistants à la corrosion et peuvent résister à des températures
            élevées. De plus, le cuivre est un matériau non toxique, ce qui le
            rend sûr pour l'eau potable. Les tuyaux en cuivre sont également
            faciles à installer et offrent une longue durée de vie, ce qui en
            fait un choix rentable à long terme pour de nombreux projets de
            plomberie.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Tuyaux en PVC</SubTitleArticle>
          <TextArticle>
            Les tuyaux en PVC (polychlorure de vinyle) sont largement utilisés
            pour les systèmes d'approvisionnement en eau et d'évacuation des
            eaux usées. Ils sont légers, faciles à manipuler et résistants à la
            corrosion, ce qui les rend idéaux pour une utilisation en extérieur.
            De plus, les tuyaux en PVC sont abordables et offrent une bonne
            résistance aux produits chimiques, ce qui les rend adaptés à une
            variété d'applications domestiques et commerciales.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Tuyaux en PEX</SubTitleArticle>
          <TextArticle>
            Le PEX (polyéthylène réticulé) est devenu populaire ces dernières
            années en raison de sa flexibilité et de sa facilité d'installation.
            Les tuyaux en PEX sont résistants aux températures extrêmes et aux
            produits chimiques, ce qui en fait un choix polyvalent pour les
            systèmes de plomberie. De plus, le PEX est moins cher que le cuivre
            et nécessite moins de raccords, ce qui réduit les risques de fuites
            et de corrosion.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Tuyaux en acier galvanisé </SubTitleArticle>
          <TextArticle>
            Les tuyaux en acier galvanisé étaient autrefois couramment utilisés
            dans les systèmes de plomberie, mais ils ont été largement remplacés
            par d'autres matériaux en raison de leurs inconvénients. Bien qu'ils
            soient résistants à la corrosion, les tuyaux en acier galvanisé sont
            sujets à la rouille et à l'accumulation de dépôts, ce qui peut
            réduire le débit d'eau et entraîner des problèmes de pression. De
            plus, l'installation de tuyaux en acier galvanisé peut être plus
            complexe et nécessiter des compétences spécialisées.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Conclusion</SubTitleArticle>
          <TextArticle>
            Le choix du bon type de tuyaux dépendra des besoins spécifiques de
            votre projet de plomberie, ainsi que de votre budget et de vos
            préférences personnelles. En examinant attentivement les avantages
            de chaque matériau, vous pourrez prendre une décision éclairée qui
            garantira le bon fonctionnement et la durabilité de votre système de
            plomberie pour les années à venir. En conclusion, que vous optiez
            pour des tuyaux en cuivre traditionnels, des tuyaux en PVC modernes
            ou des tuyaux en PEX innovants, il est important de choisir un
            matériau qui répond à vos besoins en matière de performance, de
            durabilité et de budget.
          </TextArticle>
        </section>
      </ContenairMain>
      <Footer marginTop="180px" backgroundColor="#3c2114" />
    </>
  );
}
