import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import TrashElement from "./components/ModalUrgence/TrashElement";
import ListeMenuNavBarMini from "./components/NavBar/NavBarMini/ListeMenuNavBarMini";
import { NavBarMiniContext } from "./contexts/NavBarMiniContext";
import AirConditioning from "./pages/AirConditioning";
import Bathroom from "./pages/Bathroom";
import Bathtub from "./pages/Bathtub";
import Blog from "./pages/Blog";
import Adoucisseur from "./pages/BlogArticles/Adoucisseur";
import DesembouageRadiateur from "./pages/BlogArticles/DesembouageRadiateur";
import EntretienCanalisations from "./pages/BlogArticles/EntretienCanalisations";
import EntretienClimatisation from "./pages/BlogArticles/EntretienClimatisation";
import FuiteWater from "./pages/BlogArticles/FuiteWater";
import HiddenFuiteWater from "./pages/BlogArticles/HiddenFuiteWater";
import MaPrimeAdapt from "./pages/BlogArticles/MaPrimeAdapt";
import PompesChaleur from "./pages/BlogArticles/PompesChaleur";
import RefoulementToilettes from "./pages/BlogArticles/RefoulementToilettes";
import ThermodynamicWH from "./pages/BlogArticles/ThermodynamicWH";
import TuyauxPlomberie from "./pages/BlogArticles/TuyauxPlomberie";
import WaterHeaterMaintenance from "./pages/BlogArticles/WaterHeaterMaintenance";
import WaterHeaterNoReservoir from "./pages/BlogArticles/WaterHeaterNoReservoir";
import EffectTransitionPageDisplay from "./pages/ContextPages/EffectTransitionPageDisplay";
import EffectTransitionPageNotDisplay from "./pages/ContextPages/EffectTransitionPageNotDisplay";
import ModalFormPage from "./pages/ContextPages/ModalFormPage";
import ElectricWaterHeater from "./pages/ElectricWaterHeater";
import GazWaterHeater from "./pages/GazWaterHeater";
import HeatPump from "./pages/HeatPump";
import Home from "./pages/Home";
import Installation from "./pages/Installation";
import NotFoundPage from "./pages/NotFoundPage";
import Toilet from "./pages/Toilet";
import TrayToShower from "./pages/TrayToShower";
import Troubleshooting from "./pages/Troubleshooting";
import Washbasin from "./pages/Washbasin";
import WaterHeater from "./pages/WaterHeater";
import GlobalStyle from "./style/Body";

function App() {
  const { navBarMiniDisplay } = useContext(NavBarMiniContext);

  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    const filterMiniMenu = document.querySelector("#filterMiniMenu");
    const contenairMiniMenu = document.querySelector("#contenairMiniMenu");
    const spanLiSubMenuInstallation = document.querySelectorAll(
      ".spanLiSubMenuInstallation"
    );
    const spanLiSubMenuWaterHeater = document.querySelectorAll(
      ".spanLiSubMenuWaterHeater"
    );

    if (navBarMiniDisplay) {
      if (
        blockNavAndMenu.style.backgroundColor === "" ||
        blockNavAndMenu.style.backgroundColor === "transparent" ||
        blockNavAndMenu.style.backgroundColor === "rgb(60, 33, 20)"
      ) {
        if (contenairMiniMenu) {
          filterMiniMenu.style.background =
            "linear-gradient(to left, rgb(85, 51, 34, 0.5), rgb(85, 51, 34, 0.5))";
          contenairMiniMenu.style.backgroundColor = "#553322";
          for (let span of spanLiSubMenuInstallation) {
            span.style.backgroundColor = "#553322";
            span.style.transition = "0ms";
          }
          for (let span of spanLiSubMenuWaterHeater) {
            span.style.backgroundColor = "#553322";
            span.style.transition = "0ms";
          }
        }
      }
      if (blockNavAndMenu.style.backgroundColor === "rgb(102, 119, 136)") {
        if (contenairMiniMenu) {
          filterMiniMenu.style.background =
            "linear-gradient(to left, rgba(102, 119, 136, 0.5), rgba(102, 119, 136, 0.5))";
          contenairMiniMenu.style.backgroundColor = "#465764";
          for (let span of spanLiSubMenuInstallation) {
            span.style.backgroundColor = "#465764";
            span.style.transition = "0ms";
          }
          for (let span of spanLiSubMenuWaterHeater) {
            span.style.backgroundColor = "#465764";
            span.style.transition = "0ms";
          }
        }
      }
    }
  }, [navBarMiniDisplay]);

  window.addEventListener("scroll", () => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    const filterMiniMenu = document.querySelector("#filterMiniMenu");
    const contenairMiniMenu = document.querySelector("#contenairMiniMenu");
    const spanLiSubMenuInstallation = document.querySelectorAll(
      ".spanLiSubMenuInstallation"
    );
    const spanLiSubMenuWaterHeater = document.querySelectorAll(
      ".spanLiSubMenuWaterHeater"
    );

    if (navBarMiniDisplay) {
      if (
        blockNavAndMenu.style.backgroundColor === "" ||
        blockNavAndMenu.style.backgroundColor === "transparent" ||
        blockNavAndMenu.style.backgroundColor === "rgb(60, 33, 20)"
      ) {
        if (contenairMiniMenu) {
          filterMiniMenu.style.background =
            "linear-gradient(to left, rgb(85, 51, 34, 0.5), rgb(85, 51, 34, 0.5))";
          contenairMiniMenu.style.backgroundColor = "#553322";
          for (let span of spanLiSubMenuInstallation) {
            span.style.backgroundColor = "#553322";
          }
          for (let span of spanLiSubMenuWaterHeater) {
            span.style.backgroundColor = "#553322";
          }
        }
      }
      if (blockNavAndMenu.style.backgroundColor === "rgb(102, 119, 136)") {
        if (contenairMiniMenu) {
          filterMiniMenu.style.background =
            "linear-gradient(to left, rgba(102, 119, 136, 0.5), rgba(102, 119, 136, 0.5))";
          contenairMiniMenu.style.backgroundColor = "#465764";
          for (let span of spanLiSubMenuInstallation) {
            span.style.backgroundColor = "#465764";
          }
          for (let span of spanLiSubMenuWaterHeater) {
            span.style.backgroundColor = "#465764";
          }
        }
      }
    }
  });

  return (
    <div className="App">
      <>
        <EffectTransitionPageDisplay />
        <EffectTransitionPageNotDisplay />
        <ModalFormPage />
        <TrashElement />
        <ListeMenuNavBarMini />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/depannage" element={<Troubleshooting />} />
          <Route path="/installation" element={<Installation />} />
          <Route path="/installation/toilettes" element={<Toilet />} />
          <Route path="/installation/lavabos" element={<Washbasin />} />
          <Route
            path="/installation/bacs-a-douche"
            element={<TrayToShower />}
          />
          <Route path="/installation/baignoires" element={<Bathtub />} />
          <Route path="/chauffe-eau" element={<WaterHeater />} />
          <Route
            path="/chauffe-eau/chauffe-eau-electriques"
            element={<ElectricWaterHeater />}
          />
          <Route
            path="/chauffe-eau/chauffe-eau-gaz-chaudieres"
            element={<GazWaterHeater />}
          />
          <Route path="/chauffe-eau/pompes-a-chaleur" element={<HeatPump />} />
          <Route path="/climatisation" element={<AirConditioning />} />
          <Route path="/salles-de-bain" element={<Bathroom />} />
          <Route path="/blog" element={<Blog />} />
          <Route
            path="/blog/comment-entretenir-son-chauffe-eau"
            element={<WaterHeaterMaintenance />}
          />
          <Route
            path="/blog/comment-prendre-soin-de-ses-canalisations"
            element={<EntretienCanalisations />}
          />
          <Route
            path="/blog/comment-entretenir-sa-climatisation"
            element={<EntretienClimatisation />}
          />
          <Route
            path="/blog/desembouage-radiateur"
            element={<DesembouageRadiateur />}
          />
          <Route
            path="/blog/les-avantages-de-la-detection-de-fuites-par-camera"
            element={<FuiteWater />}
          />
          <Route
            path="/blog/les-signes-des-fuites-d'eau"
            element={<HiddenFuiteWater />}
          />
          <Route
            path="/blog/les-adoucisseurs-d-eau"
            element={<Adoucisseur />}
          />
          <Route path="/blog/ma-prime-adapt" element={<MaPrimeAdapt />} />
          <Route
            path="/blog/les-pompes-a-chaleur"
            element={<PompesChaleur />}
          />
          <Route
            path="/blog/les-differents-tuyaux-en-plomberie"
            element={<TuyauxPlomberie />}
          />
          <Route
            path="/blog/les-chauffes-eau-thermodynamiques"
            element={<ThermodynamicWH />}
          />
          <Route
            path="/blog/que-faire-en-cas-de-toilettes-qui-refoulent"
            element={<RefoulementToilettes />}
          />
          <Route
            path="/blog/les-chauffes-eau-sans-reservoir"
            element={<WaterHeaterNoReservoir />}
          />
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
        <GlobalStyle />
      </>
    </div>
  );
}

export default App;
