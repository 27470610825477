import React, { useEffect } from "react";
import styled from "styled-components";
import Footer from "../../components/Footer";
import HeaderBlog from "../../components/Header/HeaderBlog";
import { NavBarDisplayFunction } from "../../functions/NavBarDisplayScrollFunction";
import { devices } from "../../style/Size";

const ContenairMain = styled.main`
  width: 94%;
  /* background-color: #ffffff; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 134px;
  margin-bottom: 200px;

  @media ${devices.laptopXXS} {
    margin-top: 114px;
    margin-bottom: 120px;
  }
  @media ${devices.mobileL} {
    margin-top: 100px;
    margin-bottom: 80px;
  }
  @media (max-height: 480px) {
    margin-bottom: 80px;
  }
`;

const TitleArticle = styled.h1`
  font-size: 34px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  padding-top: 10px;

  @media ${devices.tablet} {
    font-size: 32px;
  }
  @media ${devices.mobileL} {
    font-size: 30px;
  }
  @media ${devices.mobileM} {
    font-size: 28px;
  }
`;

const SubTitleArticle = styled.h2`
  font-size: 28px;
  font-family: "Russo One", sans-serif;
  color: #553322;

  @media ${devices.tablet} {
    font-size: 26px;
  }
  @media ${devices.mobileL} {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
`;

const TextArticle = styled.h2`
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  text-align: justify;
  margin-bottom: 40px;

  @media ${devices.mobileL} {
    margin-bottom: 50px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

export default function WaterHeaterNoReservoir() {
  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction(
      "headerBlogContenair",
      "les-chauffes-eau-sans-reservoir"
    );
  });

  return (
    <>
      <HeaderBlog />
      <ContenairMain>
        <TitleArticle>
          Les Avantages des Systèmes de Chauffage Sans Réservoir
        </TitleArticle>
        <TextArticle>
          Les systèmes de chauffage sans réservoir, également connus sous le nom
          de chauffe-eau sans réservoir ou chauffe-eau instantané, gagnent en
          popularité en tant qu'alternative efficace aux chauffe-eau
          traditionnels avec réservoir. Ces systèmes offrent plusieurs avantages
          distincts qui les rendent attrayants pour les propriétaires soucieux
          de l'efficacité énergétique et de la commodité. Dans cet article, nous
          examinerons en détail les principaux avantages des systèmes de
          chauffage sans réservoir.
        </TextArticle>
        <section>
          <SubTitleArticle>1. Taille Compacte et Gain d'Espace</SubTitleArticle>
          <TextArticle>
            Les chauffe-eau sans réservoir sont remarquablement compacts par
            rapport aux modèles à réservoir. Contrairement aux chauffe-eau
            traditionnels encombrants qui occupent souvent une grande partie de
            l'espace dans une buanderie ou un placard, les chauffe-eau sans
            réservoir sont souvent montés au mur ou placés dans des espaces plus
            restreints. Cette compacité permet aux propriétaires de libérer de
            précieux espaces de stockage et de profiter d'une plus grande
            flexibilité dans la disposition de leur espace.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>2. Économies d'Énergie</SubTitleArticle>
          <TextArticle>
            Un avantage majeur des chauffe-eau sans réservoir est leur
            efficacité énergétique accrue par rapport aux chauffe-eau
            traditionnels. Contrairement aux réservoirs qui maintiennent une
            grande quantité d'eau chaude en permanence, les systèmes sans
            réservoir ne chauffent l'eau que lorsque cela est nécessaire. Cela
            réduit considérablement les pertes de chaleur et permet des
            économies sur la facture d'électricité ou de gaz.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            3. Approvisionnement Illimité en Eau Chaude
          </SubTitleArticle>
          <TextArticle>
            L'un des inconvénients des chauffe-eau avec réservoir est la
            possibilité de se retrouver sans eau chaude lorsque le réservoir est
            vidé. Les chauffe-eau sans réservoir éliminent ce problème en
            fournissant de l'eau chaude à la demande. Que ce soit pour une
            douche matinale prolongée ou pour remplir une grande baignoire, les
            systèmes sans réservoir peuvent fournir un approvisionnement continu
            en eau chaude sans risque de pénurie.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>4. Durabilité et Longévité</SubTitleArticle>
          <TextArticle>
            Les chauffe-eau sans réservoir sont généralement conçus pour être
            durables et avoir une longue durée de vie. En l'absence de réservoir
            sujet à la corrosion et aux fuites potentielles, ces systèmes
            peuvent durer plus longtemps que les chauffe-eau traditionnels. De
            plus, de nombreux modèles de chauffe-eau sans réservoir sont dotés
            de composants de haute qualité qui nécessitent peu d'entretien, ce
            qui réduit les coûts à long terme pour les propriétaires.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            5. Contrôle Précis de la Température
          </SubTitleArticle>
          <TextArticle>
            Les chauffe-eau sans réservoir offrent un contrôle précis de la
            température de l'eau. Les utilisateurs peuvent régler facilement la
            température désirée à l'aide des commandes intégrées, ce qui
            garantit une expérience de douche ou de bain confortable et
            sécuritaire.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Conclusion</SubTitleArticle>
          <TextArticle>
            En conclusion, les systèmes de chauffage sans réservoir présentent
            de nombreux avantages par rapport aux chauffe-eau traditionnels avec
            réservoir. Ils offrent une solution compacte, économe en énergie, et
            durable pour répondre aux besoins en eau chaude des foyers modernes.
            Si vous envisagez de remplacer votre chauffe-eau ou de mettre à
            niveau votre système de chauffage, envisagez sérieusement les
            avantages des chauffe-eau sans réservoir pour améliorer l'efficacité
            et le confort de votre maison.
            <br />
            <br />
            En investissant dans un chauffe-eau sans réservoir, vous pouvez non
            seulement réduire votre empreinte énergétique, mais également
            profiter d'une source d'eau chaude fiable et instantanée pour
            répondre aux besoins quotidiens de votre famille. L'Entreprise
            Navarro située à Marseille saura réaliser vos travaux avec qualité.
            Appelez nous pour plus de renseignements.
          </TextArticle>
        </section>
      </ContenairMain>
      <Footer marginTop="180px" backgroundColor="#3c2114" />
    </>
  );
}
