import React, { useContext } from "react";
import styled from "styled-components";
import {
  EffectGlowButtonFunction,
  NotEffectGlowButtonQuoteFunction,
  HoverButtonQuoteFunction,
} from "../../functions/EffectGlowButtonsFunction";
import { ButtonEffectScaleClickFunction } from "../../functions/ButtonClickEffectScale";
import { ModalFormContext } from "../../contexts/ModalForm";
import { devices } from "../../style/Size";

const ContenairButton = styled.div`
  width: 100%;
  height: 200px;
  position: relative;

  @media ${devices.mobileL} {
    height: 160px;
  }
  @media (max-height: 480px) {
    height: 220px;
  }
`;

const Button = styled.button.attrs({
  type: "button",
  value: "Faire un devis gratuit",
  className: "buttonQuote",
})`
  width: 480px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: nowrap;
  background: radial-gradient(circle at 70% 50%, #556699, #556699);
  border-radius: 6px;
  border: none;
  color: #ffffff;
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  font-weight: 100;
  cursor: pointer;
  position: absolute;
  left: calc(50% - 240px);

  @media ${devices.tablet} {
    width: 400px;
    left: calc(50% - 200px);
  }
  @media ${devices.mobileL} {
    font-size: 14px;
    width: 340px;
    left: calc(50% - 170px);
    top: 20px;
  }
  @media ${devices.mobileM} {
    width: 300px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 12.4px;
    left: calc(50% - 150px);
  }
  @media ${devices.mobileS} {
    width: 270px;
    left: calc(50% - 135px);
    font-size: 12px;
  }
  @media ${devices.mobileXS} {
    padding-left: 0;
    padding-right: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 94%;
    left: 3%;
  }
  @media (max-height: 480px) {
    top: 80px;
  }
`;

export default function ButtonQuote(props) {
  const { displayModalForm } = useContext(ModalFormContext);

  const onClickDisplayModal = () => {
    const button = document.querySelector("#buttonQuote");

    NotEffectGlowButtonQuoteFunction("buttonQuote");
    ButtonEffectScaleClickFunction(button);
    button.style.pointerEvents = "none";

    setTimeout(() => {
      displayModalForm("modalFormDisplay");
      displayModalForm("contenairModalFormDisplay");
    }, 300);

    setTimeout(() => {
      button.style.pointerEvents = "auto";
    }, 600);
  };

  return (
    <ContenairButton>
      <Button
        id="buttonQuote"
        onMouseMove={(e) => EffectGlowButtonFunction(e, "buttonQuote")}
        onMouseLeave={() => NotEffectGlowButtonQuoteFunction("buttonQuote")}
        onMouseEnter={(e) => HoverButtonQuoteFunction(e, "buttonQuote")}
        onClick={onClickDisplayModal}
      >
        Réaliser un devis – 100% Gratuit
      </Button>
    </ContenairButton>
  );
}
