import React, { useEffect } from "react";
import styled from "styled-components";
import Footer from "../../components/Footer";
import HeaderBlog from "../../components/Header/HeaderBlog";
import { NavBarDisplayFunction } from "../../functions/NavBarDisplayScrollFunction";
import { devices } from "../../style/Size";

const ContenairMain = styled.main`
  width: 94%;
  /* background-color: #ffffff; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 134px;
  margin-bottom: 200px;

  @media ${devices.laptopXXS} {
    margin-top: 114px;
    margin-bottom: 120px;
  }
  @media ${devices.mobileL} {
    margin-top: 100px;
    margin-bottom: 80px;
  }
  @media (max-height: 480px) {
    margin-bottom: 80px;
  }
`;

const TitleArticle = styled.h1`
  font-size: 34px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  padding-top: 10px;

  @media ${devices.tablet} {
    font-size: 32px;
  }
  @media ${devices.mobileL} {
    font-size: 30px;
  }
  @media ${devices.mobileM} {
    font-size: 28px;
  }
`;

const SubTitleArticle = styled.h2`
  font-size: 28px;
  font-family: "Russo One", sans-serif;
  color: #553322;

  @media ${devices.tablet} {
    font-size: 26px;
  }
  @media ${devices.mobileL} {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
`;

const TextArticle = styled.h2`
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  text-align: justify;
  margin-bottom: 40px;

  @media ${devices.mobileL} {
    margin-bottom: 50px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

export default function ThermodynamicWH() {
  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction(
      "headerBlogContenair",
      "les-chauffes-eau-thermodynamiques"
    );
  });

  return (
    <>
      <HeaderBlog />
      <ContenairMain>
        <TitleArticle>Les Chauffes-eau Thermodynamiques</TitleArticle>
        <TextArticle>
          Les chauffe-eau thermodynamiques sont une solution innovante et
          efficace pour chauffer l'eau tout en réduisant la consommation
          d'énergie. Dans cet article, nous allons explorer en profondeur le
          fonctionnement, les avantages, les inconvénients et les considérations
          importantes concernant ces systèmes.
        </TextArticle>
        <section>
          <SubTitleArticle>
            Qu'est-ce qu'un chauffe-eau thermodynamique ?
          </SubTitleArticle>
          <TextArticle>
            Un chauffe-eau thermodynamique utilise la chaleur de l'air ambiant
            pour chauffer l'eau. Contrairement aux chauffe-eau traditionnels qui
            utilisent des résistances électriques ou des brûleurs à gaz, les
            chauffe-eau thermodynamiques captent la chaleur présente dans l'air
            extérieur, même à des températures relativement basses, pour
            chauffer un fluide caloporteur. Ce fluide transfère ensuite la
            chaleur à l'eau du réservoir, chauffant ainsi l'eau de manière
            efficace.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Comment fonctionne un chauffe-eau thermodynamique ?
          </SubTitleArticle>
          <TextArticle>
            1. Captation de chaleur : Le système extrait la chaleur de l'air
            extérieur à l'aide d'un dispositif semblable à un réfrigérateur
            inversé. <br />
            <br />
            2. Transfert de chaleur : La chaleur extraite est transférée à un
            fluide caloporteur circulant dans un circuit fermé. <br />
            <br />
            3. Chauffage de l'eau : Le fluide caloporteur chaud transfère sa
            chaleur à l'eau stockée dans le réservoir du chauffe-eau.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Avantages des chauffe-eau thermodynamiques
          </SubTitleArticle>
          <TextArticle>
            – Efficacité énergétique : Ils sont beaucoup plus économes en
            énergie que les chauffe-eau traditionnels, réduisant ainsi les coûts
            de chauffage de l'eau. <br />
            <br />
            – Faibles émissions de CO2 : En utilisant la chaleur de l'air plutôt
            que de produire de la chaleur directement, ils contribuent à réduire
            les émissions de carbone. <br />
            <br />– Adaptabilité : Ils peuvent être installés à l'intérieur ou à
            l'extérieur, en fonction de l'espace disponible.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Inconvénients à prendre en compte </SubTitleArticle>
          <TextArticle>
            – Dépendance à la température extérieure : Le rendement peut
            diminuer par temps froid.
            <br />
            <br />– Coût initial : Ils peuvent être plus chers à l'achat et à
            l'installation par rapport aux chauffe-eau conventionnels.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            Considérations importantes avant l'achat
          </SubTitleArticle>
          <TextArticle>
            – Climat local : Leur efficacité dépend du climat de la région. Ils
            fonctionnent mieux dans des zones avec des températures modérées.
            <br />
            <br />
            – Espace disponible : Assurez-vous d'avoir l'espace nécessaire pour
            l'installation du chauffe-eau thermodynamique.
            <br />
            <br />– Coûts et économies à long terme : Calculez les économies
            potentielles sur la facture énergétique pour déterminer le retour
            sur investissement.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Conclusion</SubTitleArticle>
          <TextArticle>
            Les chauffe-eau thermodynamiques sont une option intéressante pour
            chauffer l'eau de manière écologique et économique. Avant de prendre
            votre décision, évaluez vos besoins en chauffage d'eau, votre budget
            et les conditions climatiques de votre région. Consultez un
            professionnel pour déterminer si un chauffe-eau thermodynamique
            convient à votre domicile.
            <br />
            <br /> En investissant dans un chauffe-eau thermodynamique, vous
            contribuez à réduire votre empreinte carbone tout en réalisant des
            économies sur vos factures d'énergie. Choisissez une solution qui
            allie confort, efficacité et durabilité pour votre système de
            chauffage d'eau !
          </TextArticle>
        </section>
      </ContenairMain>
      <Footer marginTop="180px" backgroundColor="#3c2114" />
    </>
  );
}
