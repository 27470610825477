import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import HeaderSubSection from "../components/Header/HeaderSubSection";
import BodySubSection from "../components/SubSection/BodySubSection";
import LastBlockSubSection from "../components/SubSection/LastBlockSubSection";
import ButtonQuote from "../components/Buttons/ButtonQuote";
import Footer from "../components/Footer";
import ToiletPicture from "../assets/Installation/wc.webp";
import WCclassique from "../assets/Toilets/wc-classique.webp";
import WCeconomique from "../assets/Toilets/wc-economique.webp";
import WCdesign from "../assets/Toilets/wc-design.webp";
import { NavBarDisplayFunction } from "../functions/NavBarDisplayScrollFunction";
import { EffectSubSectionFunction } from "../functions/EffectSubSectionFunction";
import { EffectTitlePageFunction } from "../functions/EffectTitlePageFunction";
import { devices } from "../style/Size";

const ContenairMain = styled.main`
  width: 100%;
  position: relative;
  margin-top: 70px;

  @media ${devices.laptopXXS} {
    margin-top: 110px;
  }
  @media ${devices.tablet} {
    margin-top: 40px;
  }
`;

export default function Toilet() {
  useEffect(() => {
    EffectTitlePageFunction("titleToiletContenair");
    setTimeout(() => {
      EffectTitlePageFunction("idBlockElement");
    }, 600);
  }, []);

  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction("headerToiletContenair");
  });

  useEffect(() => {
    const titleSection = document.querySelector("#blockTitle");

    if (titleSection) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          window.addEventListener("scroll", () => {
            EffectSubSectionFunction("blockTitle", "/installation/toilettes");
          });
        }
      });
      observer.observe(titleSection);
    }
  }, []);

  window.addEventListener("resize", () => {
    const blockSection = document.querySelectorAll(`.imageSection`);
    if (blockSection) {
      EffectSubSectionFunction("blockTitle", "/installation/toilettes");
      if (window.innerWidth <= 568) {
        for (let element of blockSection) {
          element.style.transform = "translateX(0vw)";
          element.style.transition = "all 300ms";
        }
      }
    }
  });

  return (
    <>
      <HeaderSubSection
        id="headerToiletContenair"
        idTitle="titleToiletContenair"
        src={ToiletPicture}
        alt="WC balnc classique dans sanitaire toilettes de couleur marron laquée"
        titlePage="W-C"
      />
      <ContenairMain>
        <BodySubSection
          idText="textSection"
          imageClassname="imageSection"
          idBlockElement="idBlockElement"
          opacity="0.5"
          src={WCclassique}
          alt="WC classique entrée de gamme en céramique blanc dans salle de bain peinture grise style béton"
          title="Les Classiques"
          text={[
            "Des ",
            <strong key="1" style={{ fontWeight: "100" }}>
              WC entrée de gamme
            </strong>,
            ". Idéals pour des toilettes simples. Efficaces et durables. Un confort agréable. Avec bride. Contenance de 3 à 6 litres. Niveau sonore correct.",
            <br key="2" />,
            <br key="3" />,
            "Plusieurs références sont disponibles, avec un choix assez varié de dimensions et de formes. Le poussoir est cependant rudimentaire, avec un unique système de vidange, qui ne permet pas une économie d'eau lors du tirage des chasses. Sa pose est relativement facile et rapide. Son coût d'installation se trouve ainsi amoindri comparé à des modèles plus aboutis ou perfectionnés. ",
            <br key="4" />,
            <br key="5" />,
            "Un investissement pour du moyen terme, ou dans un logement locatif ou secondaire.",
            <br key="6" />,
            <br key="7" />,
            <strong key="8" style={{ fontWeight: "100" }}>
              Nos plombiers à Marseille
            </strong>,
            " vous installent un de ces produits en deux heures environ.",
            <br key="9" />,
            <br key="10" />,
            "Le constructeur que nous vous conseillons pour cette gamme de produits et avec lequel nous travaillons habituellement, et duquel nos clients sont satisfaits est  ",
            <Link
              key="11"
              to="https://www.jacobdelafon.fr/wcbidet/wc-poser"
              target="_blank"
            >
              Jacob Delafon
            </Link>,
            ".",
          ]}
        />
        <BodySubSection
          imageClassname="imageSection"
          src={WCeconomique}
          alt="WC moderne et classe en céramique blanc dans salle de bain aux carrelages grandes dalles style ardoise beige"
          title="Les Économiques & Conforts"
          text={[
            "Des ",
            <strong key="1" style={{ fontWeight: "100" }}>
              WC
            </strong>,
            " aboutis et raffinés. Parfait pour de belles toilettes. ",
            <strong key="2" style={{ fontWeight: "100" }}>
              WC résistants et efficaces
            </strong>,
            ". Une qualité de porcelaine supérieure. Une durée de vie allongée. Un design bien pensé et bien modelé. Un sens du détail. Contenance de 3 à 6 litres. Niveau sonore réduit.",
            <br key="3" />,
            <br key="4" />,
            "Un large choix de dimensions, de formes et de coloris est disponible. Possibilité de sans-bride. Des références de WC suspendus avec bâti support complet pré-monté avec réservoir encastré. Le poussoir comporte un double système de vidange, un petit et un grand, ce qui présente un aspect économique significatif sur le long terme.",
            <br key="5" />,
            <br key="6" />,
            "La pose de ",
            <strong key="7" style={{ fontWeight: "100" }}>
              WC suspendu
            </strong>,
            " peut comporter plusieurs paramètres à prendre en compte afin de pouvoir étudier son ",
            <strong key="8" style={{ fontWeight: "100" }}>
              coût et temps d'installation plomberie
            </strong>,
            ".",
            <br key="9" />,
            <br key="10" />,
            "Nos ",
            <strong key="11" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " réalisent l'installation en deux heures environ, sauf pour les ",
            <strong key="12" style={{ fontWeight: "100" }}>
              WC suspendus
            </strong>,
            " pour lesquels il faut prévoir un temps d'installation d'environ 1 jour/1 jour et demi.",
            <br key="13" />,
            <br key="14" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="15"
              to="https://catalog.geberit.fr/fr-FR/systems/CH2_386924/products"
              target="_blank"
            >
              Geberit
            </Link>,
            ", et ",
            <Link
              key="17"
              to="https://www.jacobdelafon.fr/wcbidet/wc-suspendu"
              target="_blank"
            >
              Jacob Delafon
            </Link>,
            ".",
          ]}
        />
        <LastBlockSubSection
          imageClassname="imageSection"
          src={WCdesign}
          alt="WC intelligent lavant design noir avec néon bleu avec commandes murales dans salle de bain style ancien très raffiné"
          title={[
            "Les Design - ",
            <strong key="1" style={{ fontWeight: "500" }}>
              WC Suspendus
            </strong>,
            " – ",
            <strong key="2" style={{ fontWeight: "500" }}>
              WC Lavants
            </strong>,
          ]}
          text={[
            "Le top du top. Des ",
            <strong key="1" style={{ fontWeight: "100" }}>
              WC de luxe
            </strong>,
            ", sublimes et à l'esthétique subtile. Une ",
            <strong key="2" style={{ fontWeight: "100" }}>
              qualité de céramique
            </strong>,
            " perfectionnée. Une durée de vie plus que conséquente. ",
            <strong key="3" style={{ fontWeight: "100" }}>
              WC sans bride
            </strong>,
            ". Contenance de 3 à 6 litre. Niveau sonore quasi imperceptible. Une ",
            <strong key="4" style={{ fontWeight: "100" }}>
              économie d'eau
            </strong>,
            " optimale.",
            <br key="5" />,
            <br key="6" />,
            "Une multitude de choix de dimensions, de formes et de coloris. ",
            <strong key="7" style={{ fontWeight: "100" }}>
              WC suspendus auto-portant
            </strong>,
            " haut de gamme. ",
            <strong key="8" style={{ fontWeight: "100" }}>
              Toilettes intelligentes
            </strong>,
            " high-tech avec multiples options.",
            <br key="9" />,
            <br key="10" />,
            "Le temps de pose et d'installation varie en fonction de l'architecture de votre pièce d'aisance, quant aux ",
            <strong key="11" style={{ fontWeight: "100" }}>
              Toilettes intelligentes
            </strong>,
            ", une étude des raccordements électriques doit être appliquée.",
            <br key="12" />,
            <br key="13" />,
            "Nos ",
            <strong key="14" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " se rendent sur place pour étudier le ",
            <strong key="15" style={{ fontWeight: "100" }}>
              coût et temps d'installation plomberie
            </strong>,
            " pour le produit que vous désirez.",
            <br key="16" />,
            <br key="17" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="18"
              to="https://www.geberit.fr/produits/produits-pour-salle-de-bains/geberit-aquaclean/"
              target="_blank"
            >
              Geberit
            </Link>,
            " et ",
            <Link
              key="19"
              to="https://www.grohe.fr/fr_fr/wc-lavant/"
              target="_blank"
            >
              Grohe
            </Link>,
            ".",
          ]}
        />
        <ButtonQuote />
      </ContenairMain>
      <Footer backgroundColor="#667788" />
    </>
  );
}
