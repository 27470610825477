import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import "../../style/BackgroundImages/PageTransition.css";
import "../../style/Animation/KeyFrames/TransitionPage.css";
import { PageTransitionContext } from "../../contexts/PageTransition";
import { devices } from "../../style/Size";

const PageDisplay = styled.div.attrs({
  className: "transitionPage",
})`
  width: 100%;
  height: 100vh;
  background-color: #f7f8fa;
  position: fixed;
  z-index: 1500;
  transform: translateX(-100%);

  @media ${devices.mobileXS} {
    height: 100%;
  }
`;

const ContenairEffect = styled.div.attrs({
  id: "blockImagePageTransitionEffect",
})`
  width: 30rem;
  height: 30rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;

  @media ${devices.tablet} {
    width: 20rem;
    height: 20rem;
  }
  @media ${devices.mobileL} {
    width: 18rem;
    height: 18rem;
  }
  @media ${devices.mobileM} {
    width: 16rem;
    height: 16rem;
  }
  @media ${devices.mobileS} {
    width: 14rem;
    height: 14rem;
  }
  @media ${devices.mobileXS} {
    width: 12rem;
    height: 12rem;
  }
  @media (max-height: 480px) {
    width: 12rem;
    height: 12rem;
  }
`;

export default function EffectTransitionPages() {
  const { pageTransition } = useContext(PageTransitionContext);

  useEffect(() => {
    const transitionPage = document.querySelector(".transitionPage");

    if (transitionPage) {
      if (window.innerWidth > 2330) {
        transitionPage.style.left = `0`;
        transitionPage.style.width = `${window.innerWidth}px`;
      }
      if (window.innerWidth <= 2330) {
        transitionPage.style.right = `0`;
        transitionPage.style.width = `100%`;
      }
    }
  }, [pageTransition]);

  window.addEventListener("resize", () => {
    const transitionPage = document.querySelector(".transitionPage");

    if (transitionPage) {
      if (window.innerWidth > 2330) {
        transitionPage.style.left = `0`;
        transitionPage.style.width = `${window.innerWidth}px`;
      }
      if (window.innerWidth <= 2330) {
        transitionPage.style.right = `0`;
        transitionPage.style.width = `100%`;
      }
    }
  });

  return (
    <>
      {pageTransition.pageDisplay && (
        <PageDisplay>
          <ContenairEffect />
        </PageDisplay>
      )}
    </>
  );
}
