import React, { useEffect } from "react";
import styled from "styled-components";
import HeaderBlog from "../../components/Header/HeaderBlog";
import Footer from "../../components/Footer";
import { NavBarDisplayFunction } from "../../functions/NavBarDisplayScrollFunction";
import { devices } from "../../style/Size";

const ContenairMain = styled.main`
  width: 94%;
  /* background-color: #ffffff; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 134px;
  margin-bottom: 200px;

  @media ${devices.laptopXXS} {
    margin-top: 114px;
    margin-bottom: 120px;
  }
  @media ${devices.mobileL} {
    margin-top: 100px;
    margin-bottom: 80px;
  }
  @media (max-height: 480px) {
    margin-bottom: 80px;
  }
`;

const TitleArticle = styled.h1`
  font-size: 34px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  padding-top: 10px;

  @media ${devices.tablet} {
    font-size: 32px;
  }
  @media ${devices.mobileL} {
    font-size: 30px;
  }
  @media ${devices.mobileM} {
    font-size: 28px;
  }
`;

const SubTitleArticle = styled.h2`
  font-size: 28px;
  font-family: "Russo One", sans-serif;
  color: #553322;

  @media ${devices.tablet} {
    font-size: 26px;
  }
  @media ${devices.mobileL} {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
`;

const TextArticle = styled.h2`
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  text-align: justify;
  margin-bottom: 40px;

  @media ${devices.mobileL} {
    margin-bottom: 50px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

export default function Adoucisseur() {
  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction("headerBlogContenair", "les-adoucisseurs-d-eau");
  });

  return (
    <>
      <HeaderBlog />
      <ContenairMain>
        <TitleArticle>
          Optimisez Votre Eau avec les Adoucisseurs : Les Alliés Incontournables
          pour une Plomberie Durable
        </TitleArticle>
        <TextArticle>
          L'eau dure peut poser de sérieux problèmes dans nos foyers. Les dépôts
          de <strong>calcaire</strong> peuvent endommager vos appareils
          ménagers, réduire l'efficacité de vos systèmes de chauffage, et même
          affecter la qualité de votre peau et de vos cheveux. C'est là
          qu'interviennent les <strong>adoucisseurs d'eau</strong>. Ces
          dispositifs ingénieux peuvent transformer votre eau dure en une
          ressource plus douce et plus bénéfique. Découvrez comment ces alliés
          de plomberie peuvent améliorer votre quotidien.
        </TextArticle>
        <section>
          <SubTitleArticle>
            Les Fondements des Adoucisseurs d'Eau
          </SubTitleArticle>
          <TextArticle>
            Les <strong>adoucisseurs d'eau</strong> sont des appareils conçus
            pour éliminer les minéraux tels que le <strong>calcium</strong> et
            le <strong>magnésium</strong> responsables de la dureté de l'eau.
            Installés directement sur votre système de plomberie, ces
            dispositifs utilisent une résine échangeuse d'ions pour remplacer
            les minéraux indésirables par des ions <strong>sodium</strong>. Le
            résultat ? Une eau plus douce, plus propice à la préservation de vos
            canalisations et de vos appareils ménagers.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Avantages des Adoucisseurs d'Eau</SubTitleArticle>
          <TextArticle>
            <strong>Protection des Appareils Ménagers</strong> :
            <br />
            <br />
            En réduisant la formation de dépôts de <strong>calcaire</strong>,
            les adoucisseurs prolongent la durée de vie de vos appareils tels
            que le lave-vaisselle, la machine à laver et le chauffe-eau. Moins
            de calcaire signifie moins d'entretien et des performances accrues.
            <br />
            <br />
            <strong>Peau et Cheveux Plus Sains</strong> :
            <br />
            <br />
            Une eau adoucie est plus douce pour la peau et les cheveux. En
            éliminant les minéraux responsables de la sécheresse, vous pouvez
            dire adieu aux problèmes de peau sèche et de cheveux ternes.
            <br />
            <br />
            <strong>Économies d'Énergie</strong> :
            <br />
            <br />
            Les appareils utilisant de l'eau adoucie sont plus économes en
            énergie. Les systèmes de chauffage fonctionnent plus efficacement,
            ce qui se traduit par des factures d'énergie moins élevées.
            <br />
            <br />
            <strong>Canalisations Plus Propres</strong> :
            <br />
            <br />
            Moins de dépôts de <strong>calcaire</strong> signifie des
            canalisations plus propres. Vous réduisez ainsi le risque de
            blocages et d'obstructions, améliorant la circulation de l'eau dans
            tout votre système de plomberie.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Installation et Entretien</SubTitleArticle>
          <TextArticle>
            L'installation d'un <strong>adoucisseur d'eau</strong> nécessite
            l'intervention d'un professionnel de la plomberie. Ce spécialiste
            évaluera la dureté de votre eau et vous conseillera sur le modèle le
            mieux adapté à vos besoins. L'entretien régulier de l'
            <strong>adoucisseur</strong> est essentiel pour assurer son bon
            fonctionnement. Cela implique généralement le rechargement
            périodique de la résine échangeuse d'ions et la vérification des
            niveaux de <strong>sel</strong>.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Conclusion</SubTitleArticle>
          <TextArticle>
            Investir dans un <strong>adoucisseur d'eau</strong> peut être un
            choix judicieux pour améliorer la qualité de votre eau domestique et
            prolonger la durée de vie de vos appareils. Protégez votre
            plomberie, réduisez votre empreinte écologique et profitez des
            nombreux avantages d'une eau adoucie au quotidien. Prenez soin de
            votre maison en optant pour une solution d'adoucissement de l'eau –
            un investissement durable pour le bien-être de votre foyer.
          </TextArticle>
        </section>
      </ContenairMain>
      <Footer marginTop="180px" backgroundColor="#3c2114" />
    </>
  );
}
