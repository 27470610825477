import React from "react";
import BodyArticleTopHome from "../BodyArticleHome/BodyArticleTopHome";
export default function BlockArticleTop() {
  return (
    <>
      <BodyArticleTopHome
        classNameBlock="blockArticle1"
        idBlock="blockArticle1"
        idContenair="contenairArticle1"
        idPTitleArticle="pTitleArticle1"
        idArticle="article1"
        idPArticle="pArticle1"
        idButton="button1"
        element1="/depannage"
        element2="blockArticle1"
        element3="contenairArticle1"
        element4="pTitleArticle1"
        element5="pArticle1"
        element6="button1"
        contentTitle="Dépannage"
        contentText={[
          "Un ",
          <strong key="1" style={{ fontWeight: "100" }}>
            WC qui fuit
          </strong>,
          " ? Un ",
          <strong key="2" style={{ fontWeight: "100" }}>
            mécanisme de chasse
          </strong>,
          " vieux, défaillant, cassé ? ",
          "Une ",
          <strong key="3" style={{ fontWeight: "100" }}>
            fuite de votre chauffe-eau
          </strong>,
          " ? Ou votre ",
          <strong key="4" style={{ fontWeight: "100" }}>
            chauffe-eau ne chauffe plus
          </strong>,
          " ? Un ",
          <strong key="5" style={{ fontWeight: "100" }}>
            problème chaudière
          </strong>,
          " ? Un ",
          <strong key="6" style={{ fontWeight: "100" }}>
            code erreur chaudière
          </strong>,
          " ? ",
          <strong key="7" style={{ fontWeight: "100" }}>
            Pas d'eau chaude de votre chaudière gaz
          </strong>,
          " ? Besoin d'un ",
          <strong key="8" style={{ fontWeight: "100" }}>
            détartrage de votre chauffe-eau ou de votre chaudière
          </strong>,
          " ? Ou d'un ",
          <strong key="9" style={{ fontWeight: "100" }}>
            débouchage de canalisation
          </strong>,
          " ?",
          <br key="10" />,
          <br key="11" />,
          "Pour quelconque ",
          <strong key="12" style={{ fontWeight: "100" }}>
            urgence plomberie ou chauffagiste
          </strong>,
          ", nos experts ",
          <strong key="13" style={{ fontWeight: "100" }}>
            plombiers à Marseille
          </strong>,
          " interviennent dans toute la ville de ",
          <strong key="14" style={{ fontWeight: "100" }}>
            Marseille
          </strong>,
          " mais aussi ses environs (",
          <strong key="15" style={{ fontWeight: "100" }}>
            Allauch
          </strong>,
          ", ",
          <strong key="16" style={{ fontWeight: "100" }}>
            {" "}
            Plan-de-Cuques
          </strong>,
          ", ",
          <strong key="17" style={{ fontWeight: "100" }}>
            {" "}
            La Bouilladisse
          </strong>,
          ", ",
          <strong key="18" style={{ fontWeight: "100" }}>
            {" "}
            Cuges-les-Pins
          </strong>,
          ", ",
          <strong key="19" style={{ fontWeight: "100" }}>
            {" "}
            Peynier
          </strong>,
          ", ",
          <strong key="20" style={{ fontWeight: "100" }}>
            {" "}
            Aubagne
          </strong>,
          ", ",
          <strong key="21" style={{ fontWeight: "100" }}>
            {" "}
            Aix-en-Provence
          </strong>,
          ", ",
          <strong key="22" style={{ fontWeight: "100" }}>
            {" "}
            Marignane
          </strong>,
          ", ",
          <strong key="23" style={{ fontWeight: "100" }}>
            {" "}
            Cassis
          </strong>,
          ", ",
          <strong key="24" style={{ fontWeight: "100" }}>
            {" "}
            La Ciotat
          </strong>,
          ") pour appliquer un travail de qualité et garanti, ce à un coût intervention plombier attractif, honnête, responsable.",
        ]}
      />

      <BodyArticleTopHome
        classNameBlock="blockArticle2"
        idBlock="blockArticle2"
        idContenair="contenairArticle2"
        idPTitleArticle="pTitleArticle2"
        idArticle="article2"
        idPArticle="pArticle2"
        idButton="button2"
        element1="/installation"
        element2="blockArticle2"
        element3="contenairArticle2"
        element4="pTitleArticle2"
        element5="pArticle2"
        element6="button2"
        contentTitle="Installation"
        contentText={[
          "Vous projetez une ",
          <strong key="1" style={{ fontWeight: "100" }}>
            rénovation de votre salle de bain
          </strong>,
          " ? De ",
          <strong key="2" style={{ fontWeight: "100" }}>
            renouveler votre cuisine
          </strong>,
          " ? Ou simplement une ",
          <strong key="3" style={{ fontWeight: "100" }}>
            modification de votre installation de tuyauterie gaz ou de
            tuyauterie sanitaire
          </strong>,
          " ? Nous nous occupons de tout ! ",
          <br key="4" />,
          <br key="5" />,
          <strong key="6" style={{ fontWeight: "100" }}>
            Installation plomberie sanitaire
          </strong>,
          ", ",
          <strong key="7" style={{ fontWeight: "100" }}>
            plomberie sanitaire PVC
          </strong>,
          ", ",
          <strong key="8" style={{ fontWeight: "100" }}>
            pose robinetterie
          </strong>,
          ", ",
          <strong key="9" style={{ fontWeight: "100" }}>
            pose lavabo
          </strong>,
          ", ",
          <strong key="10" style={{ fontWeight: "100" }}>
            pose meuble lavabo
          </strong>,
          ", ",
          <strong key="11" style={{ fontWeight: "100" }}>
            installation douche
          </strong>,
          ", ",
          <strong key="12" style={{ fontWeight: "100" }}>
            installation cabine de douche
          </strong>,
          ", ",
          <strong key="13" style={{ fontWeight: "100" }}>
            installation accessoires de douche
          </strong>,
          ", ",
          <strong key="14" style={{ fontWeight: "100" }}>
            installation baignoire
          </strong>,
          ", ",
          <strong key="15" style={{ fontWeight: "100" }}>
            tuyauterie évier cuisine
          </strong>,
          ", ",
          <strong key="16" style={{ fontWeight: "100" }}>
            tuyauterie gaz
          </strong>,
          "... Demandez un ",
          <strong key="17" style={{ fontWeight: "100" }}>
            devis 100% gratuit
          </strong>,
          ", personnalisé et simplifié pour évaluer nos offres en fonction des paramètres et des produits désirés. Nos ",
          <strong key="18" style={{ fontWeight: "100" }}>
            tarifs de main d’œuvre plomberie
          </strong>,
          " vous seront attractifs, et nous bénéficions de prix préférentiels constructeurs qui vous font faire des économies conséquentes !",
          <br key="19" />,
          <br key="20" />,
          "Nos experts ",
          <strong key="21" style={{ fontWeight: "100" }}>
            plombiers à Marseille
          </strong>,
          " interviennent dans toute la ville de ",
          <strong key="22" style={{ fontWeight: "100" }}>
            Marseille
          </strong>,
          " mais aussi ses environs (",
          <strong key="23" style={{ fontWeight: "100" }}>
            Allauch
          </strong>,
          ", ",
          <strong key="24" style={{ fontWeight: "100" }}>
            {" "}
            Plan-de-Cuques
          </strong>,
          ", ",
          <strong key="25" style={{ fontWeight: "100" }}>
            {" "}
            La Bouilladisse
          </strong>,
          ", ",
          <strong key="26" style={{ fontWeight: "100" }}>
            {" "}
            Cuges-les-Pins
          </strong>,
          ", ",
          <strong key="27" style={{ fontWeight: "100" }}>
            {" "}
            Peynier
          </strong>,
          ", ",
          <strong key="28" style={{ fontWeight: "100" }}>
            {" "}
            Aubagne
          </strong>,
          ", ",
          <strong key="29" style={{ fontWeight: "100" }}>
            {" "}
            Aix-en-Provence
          </strong>,
          ", ",
          <strong key="30" style={{ fontWeight: "100" }}>
            {" "}
            Marignane
          </strong>,
          ", ",
          <strong key="31" style={{ fontWeight: "100" }}>
            {" "}
            Cassis
          </strong>,
          ", ",
          <strong key="32" style={{ fontWeight: "100" }}>
            {" "}
            La Ciotat
          </strong>,
          ") pour appliquer un travail de qualité et garanti, ce à un coût intervention plombier attractif, honnête, responsable.",
        ]}
      />

      <BodyArticleTopHome
        classNameBlock="blockArticle3"
        idBlock="blockArticle3"
        idContenair="contenairArticle3"
        idPTitleArticle="pTitleArticle3"
        idArticle="article3"
        idPArticle="pArticle3"
        idButton="button3"
        element1="/chauffe-eau"
        element2="blockArticle3"
        element3="contenairArticle3"
        element4="pTitleArticle3"
        element5="pArticle3"
        element6="button3"
        contentTitle="Chauffe-eau"
        contentText={[
          "Que ce soit pour un chauffe-eau électrique, appelé communément ",
          <strong key="1" style={{ fontWeight: "100" }}>
            « ballon d'eau chaude »
          </strong>,
          " un ",
          <strong key="2" style={{ fontWeight: "100" }}>
            chauffe-eau gaz
          </strong>,
          ", un ",
          <strong key="3" style={{ fontWeight: "100" }}>
            chauffe-eau instantané électrique
          </strong>,
          ", ou un ",
          <strong key="4" style={{ fontWeight: "100" }}>
            chauffe-eau instantané gaz
          </strong>,
          ", nous installons tout matériel de ce type allant d'une contenance de 50 litres, idéal pour une personne seule dans un petit studio, jusque 300 litres, adéquate pour plus de quatre personnes dans une vaste maison.",
          <br key="5" />,
          <br key="6" />,
          "Nous réalisons également la pose de ",
          <strong key="7" style={{ fontWeight: "100" }}>
            chauffe-eau sous-évier
          </strong>,
          " et de ",
          <strong key="8" style={{ fontWeight: "100" }}>
            chauffe-eau sur-évier
          </strong>,
          ", pour une alimentation en eau chaude ciblée et quasi constante. Aussi, nous pratiquons l'",
          <strong key="9" style={{ fontWeight: "100" }}>
            installation de chaudière gaz
          </strong>,
          ", de ",
          <strong key="10" style={{ fontWeight: "100" }}>
            chaudière électrique
          </strong>,
          ", ainsi que de ",
          <strong key="11" style={{ fontWeight: "100" }}>
            radiateurs
          </strong>,
          ". Si vous souhaitez le nec plus ultra en matière de ",
          <strong key="12" style={{ fontWeight: "100" }}>
            chauffage réversible
          </strong>,
          ", nous vous conseillons alors les ",
          <strong key="13" style={{ fontWeight: "100" }}>
            pompes à chaleur air-air
          </strong>,
          " ou les ",
          <strong key="14" style={{ fontWeight: "100" }}>
            pompes à chaleur air-eau
          </strong>,
          ", dont nous nous chargeons bien-sûr de l'installation la plus qualitative au meilleur prix. Tous nos travaux comprennent la ",
          <strong key="15" style={{ fontWeight: "100" }}>
            pose de canalisations en cuivre, PER, ou multicouches
          </strong>,
          ".",
          <br key="16" />,
          <br key="17" />,
          "Nos experts ",
          <strong key="18" style={{ fontWeight: "100" }}>
            chauffagistes à Marseille
          </strong>,
          " interviennent dans toute la ville de ",
          <strong key="19" style={{ fontWeight: "100" }}>
            Marseille
          </strong>,
          " mais aussi ses environs (",
          <strong key="20" style={{ fontWeight: "100" }}>
            Allauch
          </strong>,
          ", ",
          <strong key="21" style={{ fontWeight: "100" }}>
            {" "}
            Plan-de-Cuques
          </strong>,
          ", ",
          <strong key="22" style={{ fontWeight: "100" }}>
            {" "}
            La Bouilladisse
          </strong>,
          ", ",
          <strong key="23" style={{ fontWeight: "100" }}>
            {" "}
            Cuges-les-Pins
          </strong>,
          ", ",
          <strong key="24" style={{ fontWeight: "100" }}>
            {" "}
            Peynier
          </strong>,
          ", ",
          <strong key="25" style={{ fontWeight: "100" }}>
            {" "}
            Aubagne
          </strong>,
          ", ",
          <strong key="26" style={{ fontWeight: "100" }}>
            {" "}
            Aix-en-Provence
          </strong>,
          ", ",
          <strong key="27" style={{ fontWeight: "100" }}>
            {" "}
            Marignane
          </strong>,
          ", ",
          <strong key="28" style={{ fontWeight: "100" }}>
            {" "}
            Cassis
          </strong>,
          ", ",
          <strong key="29" style={{ fontWeight: "100" }}>
            {" "}
            La Ciotat
          </strong>,
          ") pour appliquer un travail de qualité et garanti, ce à un coût intervention plombier attractif, honnête, responsable.",
          <br key="30" />,
          <br key="31" />,
          "Demandez un devis 100% gratuit et personnalisé !",
        ]}
      />

      <BodyArticleTopHome
        classNameBlock="blockArticle4"
        idBlock="blockArticle4"
        idContenair="contenairArticle4"
        idPTitleArticle="pTitleArticle4"
        idArticle="article4"
        idPArticle="pArticle4"
        idButton="button4"
        element1="/climatisation"
        element2="blockArticle4"
        element3="contenairArticle4"
        element4="pTitleArticle4"
        element5="pArticle4"
        element6="button4"
        contentTitle="Climatisation"
        contentText={[
          "Vous envisagez équiper votre logement ou votre commerce d'un système de ",
          <strong key="1" style={{ fontWeight: "100" }}>
            climatisation
          </strong>,
          " efficace et adapté à votre surface et à votre budget ? Nous vous proposons la solution la plus appropriée à vos besoins avec une large gamme de références. ",
          <br key="2" />,
          <br key="3" />,
          <strong key="4" style={{ fontWeight: "100" }}>
            Climatisation mural split,
          </strong>,
          <strong key="5" style={{ fontWeight: "100" }}>
            {" "}
            climatisation console,
          </strong>,
          <strong key="6" style={{ fontWeight: "100" }}>
            {" "}
            climatisation monobloc,
          </strong>,
          <strong key="7" style={{ fontWeight: "100" }}>
            {" "}
            climatisation réversible,
          </strong>,
          <strong key="8" style={{ fontWeight: "100" }}>
            {" "}
            climatisation plafonnier,
          </strong>,
          <strong key="9" style={{ fontWeight: "100" }}>
            {" "}
            climatisation gainable,
          </strong>,
          " parmi tant de nombreux modèles et de nombreuses marques de qualité et garanties, trouvez la ",
          <strong key="10" style={{ fontWeight: "100" }}>
            climatisation
          </strong>,
          " qui vous correspond pour pouvoir enfin respirer dans la fraîcheur de votre intérieur pendant nos caniculaires périodes estivales si intenses à Marseille. Nos installations sont rapides et efficaces. Vivre dans un environnement agréable et léger n'est plus un luxe !",
          <br key="11" />,
          <br key="12" />,
          "Nos experts ",
          <strong key="13" style={{ fontWeight: "100" }}>
            plombiers à Marseille
          </strong>,
          " interviennent dans toute la ville de ",
          <strong key="14" style={{ fontWeight: "100" }}>
            Marseille
          </strong>,
          " mais aussi ses environs (",
          <strong key="15" style={{ fontWeight: "100" }}>
            Allauch
          </strong>,
          ", ",
          <strong key="16" style={{ fontWeight: "100" }}>
            {" "}
            Plan-de-Cuques
          </strong>,
          ", ",
          <strong key="17" style={{ fontWeight: "100" }}>
            {" "}
            La Bouilladisse
          </strong>,
          ", ",
          <strong key="18" style={{ fontWeight: "100" }}>
            {" "}
            Cuges-les-Pins
          </strong>,
          ", ",
          <strong key="19" style={{ fontWeight: "100" }}>
            {" "}
            Peynier
          </strong>,
          ", ",
          <strong key="20" style={{ fontWeight: "100" }}>
            {" "}
            Aubagne
          </strong>,
          ", ",
          <strong key="21" style={{ fontWeight: "100" }}>
            {" "}
            Aix-en-Provence
          </strong>,
          ", ",
          <strong key="22" style={{ fontWeight: "100" }}>
            {" "}
            Marignane
          </strong>,
          ", ",
          <strong key="23" style={{ fontWeight: "100" }}>
            {" "}
            Cassis
          </strong>,
          ", ",
          <strong key="24" style={{ fontWeight: "100" }}>
            {" "}
            La Ciotat
          </strong>,
          ") pour appliquer un travail de qualité et garanti, ce à un coût intervention plombier attractif, honnête, responsable.",
          <br key="25" />,
          <br key="26" />,
          "Demandez un devis 100% gratuit et personnalisé !",
        ]}
      />
    </>
  );
}
