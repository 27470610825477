import { OffsetTopFunction } from "./OffsetTopFunction";

const ButtonEffectScaleClickFunction = (button) => {
  button.style.transform = `scale(0.95)`;
  button.style.opacity = `0.9`;
  button.style.transition = `100ms`;
  setTimeout(() => {
    button.style.transform = `scale(1.05)`;
    button.style.opacity = `1`;
    button.style.transition = `100ms`;
  }, 150);
};

const ButtonBlogEffectScaleClickFunction = (button) => {
  button.style.transform = `scale(0.95)`;
  button.style.opacity = `0.9`;
  button.style.transition = `100ms`;
  setTimeout(() => {
    button.style.transform = `scale(1.02)`;
    button.style.opacity = `1`;
    button.style.transition = `100ms`;
  }, 150);
};

const ButtonWaterHeaterEffectScaleClickFunction = (e, button) => {
  const screenY = window.scrollY + window.innerHeight;
  if (
    OffsetTopFunction(button.parentElement.parentElement) - screenY < -370 &&
    e.target.id === button.id
  ) {
    button.style.transform = `scale(0.95) translateX(0vw)`;
    button.style.opacity = `0.9`;
    button.style.transition = `100ms`;
    setTimeout(() => {
      button.style.transform = `scale(1.02) translateX(0vw)`;
      button.style.opacity = `1`;
      button.style.transition = `100ms`;
    }, 150);
  }
  if (
    OffsetTopFunction(button.parentElement.parentElement) - screenY > -370 &&
    e.target.id === button.id
  ) {
    button.style.transform = `scale(0.95) translateX(-1vw)`;
    button.style.opacity = `0.9`;
    button.style.transition = `100ms`;
    setTimeout(() => {
      button.style.transform = `scale(1.02) translateX(-1vw)`;
      button.style.opacity = `1`;
      button.style.transition = `100ms`;
    }, 150);
  }
};

export {
  ButtonEffectScaleClickFunction,
  ButtonBlogEffectScaleClickFunction,
  ButtonWaterHeaterEffectScaleClickFunction,
};
