import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ButtonMiniMenuClose from "../../Buttons/ButtonMiniMenuClose";
import {
  EffectLiSubMenuDisplayFunction,
  EffectLiSubMenuCloseFunction,
} from "../../../functions/EffectListeMiniMenuFunction";
import { NavBarMiniContext } from "../../../contexts/NavBarMiniContext";
import { PageTransitionContext } from "../../../contexts/PageTransition";
import ListeSubMenu from "./ListeSubMenu";
import { devices } from "../../../style/Size";

const Filter = styled.div.attrs({
  id: "filterMiniMenu",
})`
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to left,
    rgba(102, 119, 136, 0.5),
    rgba(102, 119, 136, 0.5)
  );
  position: fixed;
  z-index: 700;
  cursor: auto;
  transform: translateX(100%);
  overflow: auto;

  @media (max-width: 250px) {
    height: 100%;
  }
`;

const ContenairMiniMenu = styled.div.attrs({
  id: "contenairMiniMenu",
})`
  width: 300px;
  height: 100%;
  background-color: #465764;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 750;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  @media ${devices.mobileM} {
    width: 250px;
  }
  @media ${devices.mobileXS} {
    width: 200px;
  }
`;

const MenuUl = styled.ul`
  width: 100%;
  height: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 42px;
`;

const Li = styled.li`
  display: inline-block;
  width: 80%;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: left;
  letter-spacing: 0.5px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  margin-left: 5%;
  /* cursor: pointer; */
  position: relative;
  /* 
  @media ${devices.tablet} {
    font-size: 17px;
  } */
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
  /* @media ${devices.mobileM} {
    font-size: 14px;
  } */
`;

const BlockIcons = styled.ul`
  display: flex;
  justify-content: left;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: 12px;
  margin-top: 6px;
`;

const P1 = styled.li.attrs({
  className: "paragraphIcons",
})`
  margin-top: 6px;
  margin-left: 6px;
  margin-right: 12px;
  color: #ffffff;
  font-size: 28px;
  cursor: pointer;
  white-space: nowrap;
  @media ${devices.laptopL} {
    font-size: 24px;
  }
`;

const ContenairBlog = styled.li`
  width: 64px;
  color: #ffffff;
  font-size: 28px;
  font-family: "Playfair", serif;
  margin-left: 14px;
  margin-top: 18px;
  cursor: pointer;
  &::after {
    display: block;
    content: "";
    width: 114%;
    min-height: 2px;
    background-color: #ffffff;
    margin-top: 2px;
    transform: scaleX(0);
    transform-origin: left;
    transition: 200ms ease;
  }
  &:hover::after {
    transform: scaleX(1);
  }
  @media ${devices.laptopL} {
    font-size: 26px;
    width: 58px;
  }
  @media ${devices.laptopM} {
    font-size: 24px;
    width: 52px;
  }
`;

export default function ListeMenuNavBarMini() {
  const { navBarMiniDisplay, displayNavBarMini } =
    useContext(NavBarMiniContext);
  const { displayPageTransition } = useContext(PageTransitionContext);
  const [subMenuInstallation, setSubMenuInstallation] = useState(false);
  const [subMenuWaterHeater, setSubMenuWaterHeater] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const iconOne = document.querySelector(`#iconOneInstallation`);
    const iconTwo = document.querySelector(`#iconTwoWaterHeater`);
    const spanLiSubMenuInstallation = document.querySelectorAll(
      `.spanLiSubMenuInstallation`
    );
    const spanLiSubMenuWaterHeater = document.querySelectorAll(
      `.spanLiSubMenuWaterHeater`
    );

    if (iconOne && iconTwo) {
      if (window.innerWidth < 769) {
        iconOne.style.fontSize = "16px";
        iconTwo.style.fontSize = "16px";

        if (spanLiSubMenuInstallation) {
          for (let span of spanLiSubMenuInstallation) {
            span.style.top = "24px";
          }
          if (spanLiSubMenuWaterHeater) {
            for (let span of spanLiSubMenuWaterHeater) {
              span.style.top = "24px";
            }
          }
        }
      }
      if (window.innerWidth < 376) {
        iconOne.style.fontSize = "14px";
        iconTwo.style.fontSize = "14px";

        if (spanLiSubMenuInstallation) {
          for (let span of spanLiSubMenuInstallation) {
            span.style.top = "22px";
          }
          if (spanLiSubMenuWaterHeater) {
            for (let span of spanLiSubMenuWaterHeater) {
              span.style.top = "22px";
            }
          }
        }
      }
    }
  }, [subMenuInstallation, subMenuWaterHeater]);

  window.addEventListener("resize", () => {
    const iconOne = document.querySelector(`#iconOneInstallation`);
    const iconTwo = document.querySelector(`#iconTwoWaterHeater`);
    const spanLiSubMenuInstallation = document.querySelectorAll(
      `.spanLiSubMenuInstallation`
    );
    const spanLiSubMenuWaterHeater = document.querySelectorAll(
      `.spanLiSubMenuWaterHeater`
    );

    if (iconOne && iconTwo) {
      if (window.innerWidth < 769) {
        iconOne.style.fontSize = "16px";
        iconTwo.style.fontSize = "16px";

        if (spanLiSubMenuInstallation) {
          for (let span of spanLiSubMenuInstallation) {
            span.style.top = "24px";
          }
          if (spanLiSubMenuWaterHeater) {
            for (let span of spanLiSubMenuWaterHeater) {
              span.style.top = "24px";
            }
          }
        }
      }
      if (window.innerWidth <= 374) {
        iconOne.style.fontSize = "14px";
        iconTwo.style.fontSize = "14px";

        if (spanLiSubMenuInstallation) {
          for (let span of spanLiSubMenuInstallation) {
            span.style.top = "22px";
          }
          if (spanLiSubMenuWaterHeater) {
            for (let span of spanLiSubMenuWaterHeater) {
              span.style.top = "22px";
            }
          }
        }
      }
      if (window.innerWidth >= 769) {
        iconOne.style.fontSize = "17px";
        iconTwo.style.fontSize = "17px";
      }
    }
  });

  const navigateLi = (e, idLi, route) => {
    const li = document.querySelector(`#${idLi}`);
    const filterMiniMenu = document.querySelector("#filterMiniMenu");
    const contenairMiniMenu = document.querySelector("#contenairMiniMenu");

    if (e.target.id === li.id) {
      if (route !== window.location.pathname) {
        if (!subMenuInstallation && !subMenuWaterHeater) {
          if (window.innerWidth > 991) {
            setTimeout(() => {
              filterMiniMenu.classList.add("notDisplayFilterMiniMenu");
              contenairMiniMenu.classList.add("notDisplayMiniMenu");
            }, 500);
            setTimeout(() => {
              displayNavBarMini("navBarMiniNotEffect");
            }, 1100);
            setTimeout(() => {
              displayPageTransition("pageTransitionEffect");
            }, 1600);
            setTimeout(() => {
              displayPageTransition("pageTransitionNotEffect");
              window.scrollTo(0, 0);
              navigate(`${route}`);
            }, 2400);
          }
          if (window.innerWidth > 480 && window.innerWidth <= 991) {
            setTimeout(() => {
              filterMiniMenu.classList.add("notDisplayFilterMiniMenu");
              contenairMiniMenu.classList.add("notDisplayMiniMenu");
            }, 500);
            setTimeout(() => {
              displayNavBarMini("navBarMiniNotEffect");
            }, 1100);
            setTimeout(() => {
              displayPageTransition("pageTransitionEffect");
            }, 1600);
            setTimeout(() => {
              displayPageTransition("pageTransitionNotEffect");
              window.scrollTo(0, 0);
              navigate(`${route}`);
            }, 2200);
          }
          if (window.innerWidth < 480) {
            setTimeout(() => {
              filterMiniMenu.classList.add("notDisplayFilterMiniMenu");
              contenairMiniMenu.classList.add("notDisplayMiniMenu");
            }, 500);
            setTimeout(() => {
              displayNavBarMini("navBarMiniNotEffect");
            }, 1100);
            setTimeout(() => {
              displayPageTransition("pageTransitionEffect");
            }, 1600);
            setTimeout(() => {
              displayPageTransition("pageTransitionNotEffect");
              window.scrollTo(0, 0);
              navigate(`${route}`);
            }, 2100);
          }
        }
        if (subMenuInstallation) {
          EffectLiSubMenuCloseFunction(
            "subMenuUlInstallation",
            "iconOneInstallation",
            "liSubMenuInstallation",
            "spanLiSubMenuInstallation",
            subMenuInstallation,
            setSubMenuInstallation
          );
          if (window.innerWidth > 991) {
            setTimeout(() => {
              filterMiniMenu.classList.add("notDisplayFilterMiniMenu");
              contenairMiniMenu.classList.add("notDisplayMiniMenu");
            }, 1000);
            setTimeout(() => {
              displayNavBarMini("navBarMiniNotEffect");
            }, 1600);
            setTimeout(() => {
              displayPageTransition("pageTransitionEffect");
            }, 2100);
            setTimeout(() => {
              displayPageTransition("pageTransitionNotEffect");
              window.scrollTo(0, 0);
              navigate(`${route}`);
            }, 2900);
          }
          if (window.innerWidth > 480 && window.innerWidth <= 991) {
            setTimeout(() => {
              filterMiniMenu.classList.add("notDisplayFilterMiniMenu");
              contenairMiniMenu.classList.add("notDisplayMiniMenu");
            }, 1000);
            setTimeout(() => {
              displayNavBarMini("navBarMiniNotEffect");
            }, 1600);
            setTimeout(() => {
              displayPageTransition("pageTransitionEffect");
            }, 2100);
            setTimeout(() => {
              displayPageTransition("pageTransitionNotEffect");
              window.scrollTo(0, 0);
              navigate(`${route}`);
            }, 2700);
          }
          if (window.innerWidth < 480) {
            setTimeout(() => {
              filterMiniMenu.classList.add("notDisplayFilterMiniMenu");
              contenairMiniMenu.classList.add("notDisplayMiniMenu");
            }, 1000);
            setTimeout(() => {
              displayNavBarMini("navBarMiniNotEffect");
            }, 1600);
            setTimeout(() => {
              displayPageTransition("pageTransitionEffect");
            }, 2100);
            setTimeout(() => {
              displayPageTransition("pageTransitionNotEffect");
              window.scrollTo(0, 0);
              navigate(`${route}`);
            }, 2600);
          }
        }
        if (subMenuWaterHeater) {
          EffectLiSubMenuCloseFunction(
            "subMenuUlWaterHeater",
            "iconTwoWaterHeater",
            "liSubMenuWaterHeater",
            "spanLiSubMenuWaterHeater",
            subMenuWaterHeater,
            setSubMenuWaterHeater
          );
          if (window.innerWidth > 991) {
            setTimeout(() => {
              filterMiniMenu.classList.add("notDisplayFilterMiniMenu");
              contenairMiniMenu.classList.add("notDisplayMiniMenu");
            }, 1000);
            setTimeout(() => {
              displayNavBarMini("navBarMiniNotEffect");
            }, 1600);
            setTimeout(() => {
              displayPageTransition("pageTransitionEffect");
            }, 2100);
            setTimeout(() => {
              displayPageTransition("pageTransitionNotEffect");
              window.scrollTo(0, 0);
              navigate(`${route}`);
            }, 2700);
          }
          if (window.innerWidth > 480 && window.innerWidth <= 991) {
            setTimeout(() => {
              filterMiniMenu.classList.add("notDisplayFilterMiniMenu");
              contenairMiniMenu.classList.add("notDisplayMiniMenu");
            }, 1000);
            setTimeout(() => {
              displayNavBarMini("navBarMiniNotEffect");
            }, 1600);
            setTimeout(() => {
              displayPageTransition("pageTransitionEffect");
            }, 2100);
            setTimeout(() => {
              displayPageTransition("pageTransitionNotEffect");
              window.scrollTo(0, 0);
              navigate(`${route}`);
            }, 2700);
          }
          if (window.innerWidth < 480) {
            setTimeout(() => {
              filterMiniMenu.classList.add("notDisplayFilterMiniMenu");
              contenairMiniMenu.classList.add("notDisplayMiniMenu");
            }, 1000);
            setTimeout(() => {
              displayNavBarMini("navBarMiniNotEffect");
            }, 1600);
            setTimeout(() => {
              displayPageTransition("pageTransitionEffect");
            }, 2100);
            setTimeout(() => {
              displayPageTransition("pageTransitionNotEffect");
              window.scrollTo(0, 0);
              navigate(`${route}`);
            }, 2600);
          }
        }
      }
      if (route === window.location.pathname) {
        const scrollToTop = () => {
          const c =
            document.documentElement.scrollTop || document.body.scrollTop;
          if (c > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, c - c / 8);
          }
        };
        scrollToTop();
        setTimeout(() => {
          filterMiniMenu.classList.add("notDisplayFilterMiniMenu");
          contenairMiniMenu.classList.add("notDisplayMiniMenu");
        }, 800);
        setTimeout(() => {
          displayNavBarMini("navBarMiniNotEffect");
        }, 2100);
      }
    }
  };

  const displaySubMenuByIcon = (e) => {
    if (e.target.id === "iconOneInstallation") {
      EffectLiSubMenuDisplayFunction(
        "subMenuUlInstallation",
        "iconOneInstallation",
        "liSubMenuInstallation",
        "spanLiSubMenuInstallation",
        subMenuInstallation,
        setSubMenuInstallation,
        "160"
      );
      EffectLiSubMenuCloseFunction(
        "subMenuUlInstallation",
        "iconOneInstallation",
        "liSubMenuInstallation",
        "spanLiSubMenuInstallation",
        subMenuInstallation,
        setSubMenuInstallation
      );
      EffectLiSubMenuCloseFunction(
        "subMenuUlWaterHeater",
        "iconTwoWaterHeater",
        "liSubMenuWaterHeater",
        "spanLiSubMenuWaterHeater",
        subMenuWaterHeater,
        setSubMenuWaterHeater
      );
    }
    if (e.target.id === "iconTwoWaterHeater") {
      EffectLiSubMenuDisplayFunction(
        "subMenuUlWaterHeater",
        "iconTwoWaterHeater",
        "liSubMenuWaterHeater",
        "spanLiSubMenuWaterHeater",
        subMenuWaterHeater,
        setSubMenuWaterHeater,
        "120"
      );
      EffectLiSubMenuCloseFunction(
        "subMenuUlWaterHeater",
        "iconTwoWaterHeater",
        "liSubMenuWaterHeater",
        "spanLiSubMenuWaterHeater",
        subMenuWaterHeater,
        setSubMenuWaterHeater
      );
      EffectLiSubMenuCloseFunction(
        "subMenuUlInstallation",
        "iconOneInstallation",
        "liSubMenuInstallation",
        "spanLiSubMenuInstallation",
        subMenuInstallation,
        setSubMenuInstallation
      );
    }
  };

  const onClickNavigate = (e, classNameLi, route) => {
    const filterMiniMenu = document.querySelector("#filterMiniMenu");
    const contenairMiniMenu = document.querySelector("#contenairMiniMenu");

    const allLi = document.querySelectorAll(`.${classNameLi}`);
    if (allLi) {
      for (let li of allLi) {
        if (e.target.id === li.id) {
          if (subMenuInstallation) {
            EffectLiSubMenuCloseFunction(
              "subMenuUlInstallation",
              "iconOneInstallation",
              "liSubMenuInstallation",
              "spanLiSubMenuInstallation",
              subMenuInstallation,
              setSubMenuInstallation
            );
          }
          if (subMenuWaterHeater) {
            EffectLiSubMenuCloseFunction(
              "subMenuUlWaterHeater",
              "iconTwoWaterHeater",
              "liSubMenuWaterHeater",
              "spanLiSubMenuWaterHeater",
              subMenuWaterHeater,
              setSubMenuWaterHeater
            );
          }
          setTimeout(() => {
            filterMiniMenu.classList.add("notDisplayFilterMiniMenu");
            contenairMiniMenu.classList.add("notDisplayMiniMenu");
          }, 1000);
          setTimeout(() => {
            displayNavBarMini("navBarMiniNotEffect");
          }, 1600);
          setTimeout(() => {
            displayPageTransition("pageTransitionEffect");
          }, 2100);
          setTimeout(() => {
            displayPageTransition("pageTransitionNotEffect");
            window.scrollTo(0, 0);
            navigate(`${route}${li.id}`);
          }, 2900);
        }
      }
    }
  };

  return (
    <>
      {navBarMiniDisplay && (
        <div>
          <Filter />
          <ContenairMiniMenu>
            <ButtonMiniMenuClose
              subMenuInstallation={subMenuInstallation}
              setSubMenuInstallation={setSubMenuInstallation}
              subMenuWaterHeater={subMenuWaterHeater}
              setSubMenuWaterHeater={setSubMenuWaterHeater}
            />
            <MenuUl>
              <Li>
                <p
                  id="home"
                  style={{
                    padding: "0",
                    margin: "0",
                    cursor: "pointer",
                    width: "50%",
                  }}
                  onClick={(e) => navigateLi(e, "home", "/")}
                  // onTouchStart={(e) => navigateLi(e, "home", "/")}
                >
                  Accueil
                </p>
              </Li>
              <Li>
                <p
                  id="troubleshooting"
                  style={{
                    padding: "0",
                    margin: "0",
                    cursor: "pointer",
                    width: "60%",
                  }}
                  onClick={(e) =>
                    navigateLi(e, "troubleshooting", "/depannage")
                  }
                  // onTouchStart={(e) =>
                  //   navigateLi(e, "troubleshooting", "/depannage")
                  // }
                >
                  Dépannage
                </p>
              </Li>
              <Li>
                <p
                  id="installation"
                  style={{
                    padding: "0",
                    margin: "0",
                    cursor: "pointer",
                    width: "64%",
                  }}
                  onClick={(e) =>
                    navigateLi(e, "installation", "/installation")
                  }
                  // onTouchStart={(e) =>
                  //   navigateLi(e, "installation", "/installation")
                  // }
                >
                  Installation
                </p>
              </Li>
              <i
                id="iconOneInstallation"
                onClick={(e) => displaySubMenuByIcon(e)}
                // onTouchStart={(e) => displaySubMenuByIcon(e)}
                style={{
                  transform: "translateY(1px)",
                  marginRight: "10px",
                  color: "#FFFFFF",
                  cursor: "pointer",
                }}
                className="fa-solid fa-angle-down"
              ></i>
              <ListeSubMenu
                idSubMenu="subMenuUlInstallation"
                LiSubMenuClassName="liSubMenuInstallation"
                spanLiSubMenuClassName="spanLiSubMenuInstallation"
                textLiOne="Toilettes"
                textLiTwo="Lavabos"
                textLiThree="Bacs à Douche"
                textLiFour="Baignoires"
                idLiOne="toilettes"
                idLiTwo="lavabos"
                idLiThree="bacs-a-douche"
                idLiFour="baignoires"
                onClick={(e) =>
                  onClickNavigate(e, "liSubMenuInstallation", "installation/")
                }
                // onTouchStart={(e) =>
                //   onClickNavigate(e, "liSubMenuInstallation", "installation/")
                // }
              />
              <Li>
                <p
                  id="waterHeater"
                  style={{
                    padding: "0",
                    margin: "0",
                    cursor: "pointer",
                    width: "67%",
                  }}
                  onClick={(e) => navigateLi(e, "waterHeater", "/chauffe-eau")}
                  // onTouchStart={(e) =>
                  //   navigateLi(e, "waterHeater", "/chauffe-eau")
                  // }
                >
                  Chauffe-eau
                </p>
              </Li>
              <i
                id="iconTwoWaterHeater"
                onClick={(e) => displaySubMenuByIcon(e)}
                // onTouchStart={(e) => displaySubMenuByIcon(e)}
                style={{
                  transform: "translateY(1px)",
                  marginRight: "10px",
                  color: "#FFFFFF",
                  cursor: "pointer",
                }}
                className="fa-solid fa-angle-down"
              ></i>
              <ListeSubMenu
                idSubMenu="subMenuUlWaterHeater"
                LiSubMenuClassName="liSubMenuWaterHeater"
                spanLiSubMenuClassName="spanLiSubMenuWaterHeater"
                textLiOne="Électriques"
                textLiTwo="Gaz – Chaudières"
                textLiThree="Pompes à Chaleur"
                display="none"
                idLiOne="chauffe-eau-electriques"
                idLiTwo="chauffe-eau-gaz-chaudieres"
                idLiThree="pompes-a-chaleur"
                onClick={(e) =>
                  onClickNavigate(e, "liSubMenuWaterHeater", "chauffe-eau/")
                }
                // onTouchStart={(e) =>
                //   onClickNavigate(e, "liSubMenuWaterHeater", "chauffe-eau/")
                // }
              />
              <Li>
                <p
                  id="airConditioning"
                  style={{
                    padding: "0",
                    margin: "0",
                    cursor: "pointer",
                    width: "70%",
                  }}
                  onClick={(e) =>
                    navigateLi(e, "airConditioning", "/climatisation")
                  }
                  // onTouchStart={(e) =>
                  //   navigateLi(e, "airConditioning", "/climatisation")
                  // }
                >
                  Climatisation
                </p>
              </Li>
              <Li>
                <p
                  id="bathroom"
                  style={{
                    padding: "0",
                    margin: "0",
                    cursor: "pointer",
                    width: "78%",
                  }}
                  onClick={(e) => navigateLi(e, "bathroom", "/salles-de-bain")}
                  // onTouchStart={(e) =>
                  //   navigateLi(e, "bathroom", "/salles-de-bain")
                  // }
                >
                  Salles de Bain
                </p>
              </Li>
              <BlockIcons>
                <P1>
                  <i className="fa-brands fa-square-facebook"></i>
                </P1>
                <P1>
                  <i className="fa-brands fa-instagram"></i>
                </P1>
                <P1>
                  <i className="fa-brands fa-linkedin"></i>
                </P1>
              </BlockIcons>
              <ContenairBlog
                id="blog"
                onClick={(e) => navigateLi(e, "blog", "/blog")}
                // onTouchStart={(e) => navigateLi(e, "blog", "/blog")}
              >
                @blog
              </ContenairBlog>
            </MenuUl>
          </ContenairMiniMenu>
        </div>
      )}
    </>
  );
}
