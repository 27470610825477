import React from "react";
import styled from "styled-components";
import LogoPG from "../../assets/Labels/label-professionnel-du-gaz-gaz-naturel-propane.webp";
import LogoRGE from "../../assets/Labels/label-rge-reconnu-garant-de-lenvironnement.webp";
import LogoH from "../../assets/Labels/logo-handibat.webp";
import { devices } from "../../style/Size";

const Column4 = styled.div`
  width: 18%;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 50px;
  @media ${devices.laptopS} {
    margin-left: 0px;
  }
  @media ${devices.laptopXXS} {
    width: 200px;
    margin-left: 20px;
  }
  @media ${devices.mobileS} {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const ColumnTitle = styled.p`
  width: 100%;
  font-size: 20px;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  @media ${devices.laptopL} {
    font-size: 18px;
  }
  @media ${devices.laptopS} {
    font-size: 16px;
  }
  @media ${devices.laptopXXS} {
    width: 200px;
    margin-bottom: 10px;
  }
  @media ${devices.mobileS} {
    width: 100%;
  }
`;

const ContenairAllElements = styled.div`
  @media ${devices.laptopXXS} {
    display: flex;
    justify-content: space-around;
  }
  @media ${devices.laptopXXS} {
    flex-direction: column;
    margin-left: 30px;
  }
  @media ${devices.mobileS} {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
`;
const ContenairLogoAndText = styled.div`
  display: flex;
  width: 100%;
  margin-top: 28px;
  margin-bottom: 20px;
  @media ${devices.laptopXXS} {
    width: 20%;
    margin-top: 12px;
  }
  @media ${devices.mobileS} {
    width: 100%;
  }
  @media (min-width: 1474px) {
    margin-left: 1vw;
  }
  @media (min-width: 1750px) {
    margin-left: 2vw;
  }
  @media (min-width: 2028px) {
    margin-left: 2.8vw;
  }
`;

const ContenairLogo = styled.div`
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  @media ${devices.laptopS} {
    min-width: 50px;
    max-width: 50px;
    height: 50px;
  }
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TextLogo = styled.p`
  font-size: 16px;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  letter-spacing: 1px;
  margin: 0;
  padding-left: 10px;
  @media ${devices.laptopL} {
    font-size: 14px;
  }
  @media ${devices.laptopS} {
    font-size: 12.8px;
  }
`;

export default function ColumnFour() {
  return (
    <Column4>
      <ColumnTitle>NOS LABELS</ColumnTitle>
      <ContenairAllElements>
        <ContenairLogoAndText>
          <ContenairLogo>
            <Logo src={LogoPG} alt="Logo Professionnel du Gaz" />
          </ContenairLogo>
          <TextLogo>Professionnel du Gaz</TextLogo>
        </ContenairLogoAndText>
        <ContenairLogoAndText>
          <ContenairLogo>
            <Logo src={LogoRGE} alt="Logo Reconnu garant de l'environnement" />
          </ContenairLogo>
          <TextLogo>
            Reconnu Garant de <br />
            l'Environnement
          </TextLogo>
        </ContenairLogoAndText>
        <ContenairLogoAndText>
          <ContenairLogo>
            <Logo src={LogoH} alt="Logo Handibat" />
          </ContenairLogo>
          <TextLogo>Handibat</TextLogo>
        </ContenairLogoAndText>
      </ContenairAllElements>
    </Column4>
  );
}
