import React from "react";
import styled from "styled-components";
import {
  EffectGlowButtonFunction,
  NotEffectGlowButtonFunction,
  HoverButtonFunction,
} from "../../functions/EffectGlowButtonsFunction";
import { devices } from "../../style/Size";

const Button = styled.button.attrs({
  type: "button",
  value: "VOIR CES RÉFÉRENCES",
  className: "buttonSectionInstallation",
})`
  width: 320px;
  height: 40px;
  border: none;
  background-color: #556699;
  border: 2px solid #ffffff;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  font-weight: 100;
  color: #ffffff;
  position: absolute;
  right: 20px;
  bottom: 24px;
  cursor: pointer;

  @media ${devices.laptopS} {
    width: 280px;
  }
  @media (max-width: 934px) {
    width: 40%;
    right: 30%;
    bottom: 16px;
  }
  @media ${devices.mobileL} {
    width: 52%;
    right: 24%;
    bottom: 12px;
  }
  @media (max-height: 480px) {
    width: 50%;
    right: 25%;
    bottom: 12px;
  }
`;

export default function ButtonInstallationOrBlog(props) {
  return (
    <Button
      className="buttonSection"
      id={props.idButton}
      onClick={props.onClick}
      // onTouchStart={props.onTouchStart}
      onMouseMove={(e) =>
        EffectGlowButtonFunction(e, "buttonSectionInstallation")
      }
      onMouseLeave={() =>
        NotEffectGlowButtonFunction("buttonSectionInstallation")
      }
      onMouseEnter={(e) => HoverButtonFunction(e, "buttonSectionInstallation")}
    >
      DÉCOUVRIR
    </Button>
  );
}
