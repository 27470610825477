import React, { useEffect } from "react";
import styled from "styled-components";
import HeaderBlog from "../../components/Header/HeaderBlog";
import Footer from "../../components/Footer";
import { NavBarDisplayFunction } from "../../functions/NavBarDisplayScrollFunction";
import { devices } from "../../style/Size";

const ContenairMain = styled.main`
  width: 94%;
  /* background-color: #ffffff; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 134px;
  margin-bottom: 200px;

  @media ${devices.laptopXXS} {
    margin-top: 114px;
    margin-bottom: 120px;
  }
  @media ${devices.mobileL} {
    margin-top: 100px;
    margin-bottom: 80px;
  }
  @media (max-height: 480px) {
    margin-bottom: 80px;
  }
`;

const TitleArticle = styled.h1`
  font-size: 34px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  padding-top: 10px;

  @media ${devices.tablet} {
    font-size: 32px;
  }
  @media ${devices.mobileL} {
    font-size: 30px;
  }
  @media ${devices.mobileM} {
    font-size: 28px;
  }
`;

const SubTitleArticle = styled.h2`
  font-size: 28px;
  font-family: "Russo One", sans-serif;
  color: #553322;

  @media ${devices.tablet} {
    font-size: 26px;
  }
  @media ${devices.mobileL} {
    font-size: 24px;
  }
  @media ${devices.mobileM} {
    font-size: 22px;
  }
`;

const TextArticle = styled.h2`
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  color: #553322;
  text-align: justify;
  margin-bottom: 40px;

  @media ${devices.mobileL} {
    margin-bottom: 50px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

export default function RefoulementToilettes() {
  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction(
      "headerBlogContenair",
      "que-faire-en-cas-de-toilettes-qui-refoulent"
    );
  });

  return (
    <>
      <HeaderBlog />
      <ContenairMain>
        <TitleArticle>Que Faire en Cas de Toilettes Qui Refoulent</TitleArticle>
        <TextArticle>
          Le refoulement des toilettes peut être un problème alarmant et
          inconfortable dans une maison. Lorsque les toilettes refoulent, il est
          important de prendre des mesures immédiates pour éviter tout dégât
          supplémentaire et rétablir le bon fonctionnement du système de
          plomberie. Dans cet article, nous allons examiner les étapes à suivre
          en cas de toilettes qui refoulent.
        </TextArticle>
        <section>
          <SubTitleArticle>
            1. Arrêtez l'utilisation des toilettes
          </SubTitleArticle>
          <TextArticle>
            Dès que vous remarquez que les toilettes refoulent, arrêtez
            immédiatement de les utiliser pour éviter tout débordement ou dégât
            d'eau supplémentaire. Il est crucial de limiter l'ajout d'eau dans
            la cuvette pour empêcher la situation de s'aggraver.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            2. Ne pas tirer la chasse d'eau à nouveau d'Eau
          </SubTitleArticle>
          <TextArticle>
            Si les toilettes sont sur le point de refouler, évitez de tirer la
            chasse d'eau à nouveau, car cela pourrait aggraver le problème en
            faisant monter le niveau d'eau dans la cuvette.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            3. Vérifiez le niveau d'eau dans la cuvette
          </SubTitleArticle>
          <TextArticle>
            Si l'eau dans la cuvette des toilettes monte anormalement ou
            commence à refouler, surveillez attentivement le niveau d'eau. Si le
            niveau continue de monter, le refoulement risque de se produire.
            Soyez prêt à intervenir rapidement.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>4. Utilisez une ventouse</SubTitleArticle>
          <TextArticle>
            Utilisez une ventouse pour tenter de débloquer le problème. Placez
            fermement la ventouse sur le trou de vidange de la cuvette et pompez
            vigoureusement plusieurs fois pour créer un vide qui pourrait
            déloger l'obstruction.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>5. Essayez un produit déboucheur</SubTitleArticle>
          <TextArticle>
            Si la ventouse ne fonctionne pas, envisagez d'utiliser un produit
            déboucheur spécialement conçu pour les toilettes. Suivez
            attentivement les instructions sur l'emballage pour éviter tout
            dommage aux canalisations.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>
            6. Inspectez la canalisation d'évacuation
          </SubTitleArticle>
          <TextArticle>
            Si le problème persiste malgré vos efforts, il est possible que
            l'obstruction se trouve plus loin dans la canalisation d'évacuation.
            Dans ce cas, vous devrez peut-être faire appel à un plombier
            professionnel équipé d'outils spéciaux pour inspecter et dégager la
            canalisation.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>7. Prévention future</SubTitleArticle>
          <TextArticle>
            Une fois le problème résolu, envisagez d'adopter des pratiques
            préventives pour éviter les refoulements futurs. Évitez de jeter des
            objets non biodégradables dans les toilettes et assurez-vous que
            seuls du papier toilette et des déchets solubles sont éliminés de
            manière appropriée.
          </TextArticle>
        </section>
        <section>
          <SubTitleArticle>Conclusion</SubTitleArticle>
          <TextArticle>
            En cas de refoulement des toilettes, il est crucial d'agir
            rapidement pour éviter tout dégât d'eau et assurer le bon
            fonctionnement de votre système de plomberie. En suivant les étapes
            mentionnées ci-dessus, vous pouvez souvent résoudre le problème par
            vous-même. Cependant, si le refoulement persiste ou si vous avez des
            inquiétudes quant à l'état de vos canalisations, n'hésitez pas à
            faire appel à un professionnel de la plomberie pour une assistance
            spécialisée. L'Entreprise Navarro située à Marseille saura résoudre
            votre problème étape avec succès. Appelez nous pour plus de
            renseignements.
          </TextArticle>
        </section>
      </ContenairMain>
      <Footer marginTop="180px" backgroundColor="#3c2114" />
    </>
  );
}
