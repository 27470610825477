import React, { useContext } from "react";
import styled from "styled-components";
import "../../style/Animation/KeyFrames/MiniMenuEffect.css";
import { EffectLiSubMenuCloseFunction } from "../../functions/EffectListeMiniMenuFunction";
import { NavBarMiniContext } from "../../contexts/NavBarMiniContext";

const Button = styled.button.attrs({
  type: "button",
  value: "Fermer Menu",
})`
  width: 30px;
  height: 30px;
  border: none;
  font-size: 13.2px;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 6px;
`;

export default function ButtonMiniMenuClose({
  subMenuInstallation,
  setSubMenuInstallation,
  subMenuWaterHeater,
  setSubMenuWaterHeater,
}) {
  const { displayNavBarMini } = useContext(NavBarMiniContext);

  const closeMenu = () => {
    const filterMiniMenu = document.querySelector("#filterMiniMenu");
    const contenairMiniMenu = document.querySelector("#contenairMiniMenu");

    if (!subMenuInstallation && !subMenuWaterHeater) {
      filterMiniMenu.classList.add("notDisplayFilterMiniMenu");
      contenairMiniMenu.classList.add("notDisplayMiniMenu");
      setTimeout(() => {
        displayNavBarMini("navBarMiniNotEffect");
      }, 600);
    }
    if (subMenuInstallation) {
      EffectLiSubMenuCloseFunction(
        "subMenuUlInstallation",
        "iconOneInstallation",
        "liSubMenuInstallation",
        "spanLiSubMenuInstallation",
        subMenuInstallation,
        setSubMenuInstallation
      );
      setTimeout(() => {
        filterMiniMenu.classList.add("notDisplayFilterMiniMenu");
        contenairMiniMenu.classList.add("notDisplayMiniMenu");
      }, 1000);
      setTimeout(() => {
        displayNavBarMini("navBarMiniNotEffect");
      }, 1600);
    }
    if (subMenuWaterHeater) {
      EffectLiSubMenuCloseFunction(
        "subMenuUlWaterHeater",
        "iconTwoWaterHeater",
        "liSubMenuWaterHeater",
        "spanLiSubMenuWaterHeater",
        subMenuWaterHeater,
        setSubMenuWaterHeater
      );
      setTimeout(() => {
        filterMiniMenu.classList.add("notDisplayFilterMiniMenu");
        contenairMiniMenu.classList.add("notDisplayMiniMenu");
      }, 1000);
      setTimeout(() => {
        displayNavBarMini("navBarMiniNotEffect");
      }, 1600);
    }
  };

  return (
    <Button
      aria-label="Fermer menu"
      onClick={() => closeMenu()}
      // onTouchStart={() => closeMenu()}
    >
      <i
        style={{
          fontSize: "20px",
          color: "#FFFFFF",
          position: "absolute",
          top: "5px",
          left: "7.5px",
        }}
        className="fa-solid fa-xmark"
      ></i>
    </Button>
  );
}
