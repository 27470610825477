import React, { useRef } from "react";
import styled from "styled-components";
import { devices } from "../../style/Size";

const LastName = styled.input`
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  margin: 0;
  margin-top: 8px;
  border: 2px solid #667788;
  box-sizing: border-box;
  background-color: #dfe3e7;
  border-radius: 2px;
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  font-weight: 100;
  color: #000000;
  outline: none;

  @media ${devices.laptopXXS} {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 40px;
    margin-top: 6px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

export default function InputLastName({
  elementFormOne,
  setElementFormOne,
  setLastNameValue,
}) {
  const refLastName = useRef();

  const onKeyUpForm = (e) => {
    if (e.target.id === "lastName") {
      if (elementFormOne && refLastName.current.value.length < 2) {
        setElementFormOne("Votre nom ne peut contenir moins de deux lettres");
        refLastName.current.style.border = "2px solid red";
        setLastNameValue(false);
      }
      if (refLastName.current.value.length < 2) {
        setLastNameValue(false);
      }
      if (refLastName.current.value.length > 20) {
        setElementFormOne("Votre nom ne peut contenir plus de vingt lettres");
        refLastName.current.style.border = "2px solid red";
        setLastNameValue(false);
      }
      if (
        refLastName.current.value.length >= 2 &&
        refLastName.current.value.length <= 20
      ) {
        setElementFormOne(false);
        refLastName.current.style.border = "2px solid #667788";
        setLastNameValue(refLastName.current.value);

        for (let letter of refLastName.current.value) {
          if (!letter.match("^[A-z-]*$")) {
            setElementFormOne("Votre nom ne peut contenir que des lettres");
            refLastName.current.style.border = "2px solid red";
            setLastNameValue(false);
          } else if (letter.match("^[A-z-]*$")) {
            setElementFormOne(false);
            refLastName.current.style.border = "2px solid #667788";
            setLastNameValue(refLastName.current.value);
          }
        }
      }
    }
  };

  const onKeyDownForm = (e) => {
    if (e.target.id === "lastName") {
      if (refLastName.current.value.length > 20) {
        if (e.code !== "Backspace" && e.code !== "Delete") {
          e.preventDefault();
          setLastNameValue(false);
        }
      }
      for (let letter of refLastName.current.value) {
        if (
          !letter.match("^[A-z-]*$") &&
          e.code !== "Backspace" &&
          e.code !== "Delete"
        ) {
          e.preventDefault();
          setLastNameValue(false);
        }
      }
    }
  };

  const onBlurForm = (e) => {
    if (e.target.id === "lastName") {
      if (refLastName.current.value.length < 2) {
        setElementFormOne("Votre nom ne peut contenir moins de deux lettres");
        refLastName.current.style.border = "2px solid red";
        setLastNameValue(false);
      }
      for (let letter of refLastName.current.value) {
        if (
          !letter.match("^[A-z-]*$") &&
          e.code !== "Backspace" &&
          e.code !== "Delete"
        ) {
          setElementFormOne("Votre nom ne peut contenir que des lettres");
          refLastName.current.style.border = "2px solid red";
          setLastNameValue(false);
        }
      }
    }
  };

  return (
    <LastName
      aria-label="Votre nom"
      ref={refLastName}
      id="lastName"
      className="elementForm"
      placeholder="Nom"
      onKeyUp={(e) => onKeyUpForm(e)}
      onKeyDown={(e) => onKeyDownForm(e)}
      onBlur={(e) => onBlurForm(e)}
    ></LastName>
  );
}
