import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Troubleshooting from "./MenuNavBar/Troubleshooting";
import Installation from "./MenuNavBar/Installation";
import AirConditionning from "./MenuNavBar/AirConditionning";
import Bathroom from "./MenuNavBar/Bathroom";
import WaterHeater from "./MenuNavBar/WaterHeater";
import "../../style/Animation/NavBar.css";
import {
  EffectNavBarEnterFunction,
  EffectNavBarLeaveFunction,
} from "../../functions/EffectNavBarFunction";
import { PageTransitionContext } from "../../contexts/PageTransition";
import { devices } from "../../style/Size";

const ContenairMenu = styled.div.attrs({
  className: "contenairMenu",
})`
  margin-right: 20px;

  @media ${devices.laptopM} {
    margin-right: 10px;
  }
`;

const Ul = styled.ul.attrs({
  className: "blockMenuUl groupElementsDisplayMenu",
})`
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 4px;
`;

const Li = styled.li.attrs({
  className: "li subMenu groupElementsDisplayMenu",
})`
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  margin-bottom: 8px;
  margin-top: 10px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  position: relative;
  left: 2px;
  cursor: pointer;
  &::after {
    display: block;
    content: "";
    width: 2px;
    height: 17px;
    background-color: #ffffff;
    position: absolute;
    right: -1px;
    top: 50%;
    transform: translateY(-70%);
  }
  &::before {
    display: block;
    content: "";
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transition: 300ms;
    z-index: -1;
  }
  &:hover::before {
    transform: scaleX(1);
  }
  &:hover {
    color: #553322;
  }
`;

const LiBathroom = styled.li.attrs({
  className: "li subMenu groupElementsDisplayMenu",
})`
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  margin-bottom: 8px;
  margin-top: 10px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  position: relative;
  left: 2px;
  cursor: pointer;
  &::before {
    display: block;
    content: "";
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transition: 300ms;
    z-index: -1;
  }
  &:hover::before {
    transform: scaleX(1);
  }
  &:hover {
    color: #553322;
  }
`;

const BlockMenuDisplay = styled.div.attrs({
  className: "blockSubMenuDisplay",
})`
  display: flex;
`;

export default function ListeMenu() {
  const { displayPageTransition } = useContext(PageTransitionContext);
  const [blockListeMenu, setBlockListeMenu] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.innerWidth <= 1140) {
      setBlockListeMenu(false);
    }
    if (
      window.innerWidth > 1140 &&
      !navigator.userAgent.match(/iPhone/i) &&
      !navigator.userAgent.match(/webOS/i) &&
      !navigator.userAgent.match(/Android/i) &&
      !navigator.userAgent.match(/iPad/i) &&
      !navigator.userAgent.match(/iPod/i) &&
      !navigator.userAgent.match(/BlackBerry/i) &&
      !navigator.userAgent.match(/Windows Phone/i)
    ) {
      setBlockListeMenu(true);
    }
    if (
      window.innerWidth > 1140 &&
      (navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i))
    ) {
      setBlockListeMenu(false);
    }
  }, [blockListeMenu]);

  window.addEventListener("resize", () => {
    if (window.innerWidth <= 1140) {
      setBlockListeMenu(false);
    }
    if (
      window.innerWidth > 1140 &&
      !navigator.userAgent.match(/iPhone/i) &&
      !navigator.userAgent.match(/webOS/i) &&
      !navigator.userAgent.match(/Android/i) &&
      !navigator.userAgent.match(/iPad/i) &&
      !navigator.userAgent.match(/iPod/i) &&
      !navigator.userAgent.match(/BlackBerry/i) &&
      !navigator.userAgent.match(/Windows Phone/i)
    ) {
      setBlockListeMenu(true);
    }
    if (
      window.innerWidth > 1140 &&
      (navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i))
    ) {
      setBlockListeMenu(false);
    }
  });

  useEffect(() => {
    const liDepannage = document.querySelector("#depannage");

    const liInstallation = document.querySelector("#installation");
    const menuInstallationHidden = document.querySelector(
      "#installationHidden"
    );

    const liChauffeEau = document.querySelector("#chauffe-eau");
    const menuChauffeEauHidden = document.querySelector("#waterHeaterHidden");

    const liClimatisation = document.querySelector("#climatisation");

    const liSalleDeBain = document.querySelector("#salles-de-bain");

    if (blockListeMenu) {
      setTimeout(() => {
        liDepannage.style.minWidth = `${liDepannage.clientWidth - 20}px`;

        menuInstallationHidden.style.left = `${
          liInstallation.getBoundingClientRect().x -
          (menuInstallationHidden.clientWidth - liInstallation.clientWidth) / 2
        }px`;
        liInstallation.style.minWidth = `${liInstallation.clientWidth - 20}px`;

        menuChauffeEauHidden.style.left = `${
          liChauffeEau.getBoundingClientRect().x -
          (menuChauffeEauHidden.clientWidth - liChauffeEau.clientWidth) / 2
        }px`;
        liChauffeEau.style.minWidth = `${liChauffeEau.clientWidth - 20}px`;

        liClimatisation.style.minWidth = `${
          liClimatisation.clientWidth - 20
        }px`;

        liSalleDeBain.style.minWidth = `${liSalleDeBain.clientWidth - 20}px`;
      }, 100);
    }
  }, [blockListeMenu]);

  window.addEventListener("resize", () => {
    const liInstallation = document.querySelector("#installation");
    const menuInstallationHidden = document.querySelector(
      "#installationHidden"
    );

    const liChauffeEau = document.querySelector("#chauffe-eau");
    const menuChauffeEauHidden = document.querySelector("#waterHeaterHidden");

    if (liInstallation && liChauffeEau) {
      menuInstallationHidden.style.left = `${
        liInstallation.getBoundingClientRect().x -
        (menuInstallationHidden.clientWidth - liInstallation.clientWidth) / 2
      }px`;

      menuChauffeEauHidden.style.left = `${
        liChauffeEau.getBoundingClientRect().x -
        (menuChauffeEauHidden.clientWidth - liChauffeEau.clientWidth) / 2
      }px`;
    }
  });

  const FunctionLinkHome = (e, route) => {
    const li = document.querySelectorAll(".li");

    if (window.location.pathname === `/` && e.target.id === "accueil") {
      const scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
          window.requestAnimationFrame(scrollToTop);
          window.scrollTo(0, c - c / 8);
        }
      };
      scrollToTop();
    } else {
      for (let element of li) {
        element.style.pointerEvents = "none";
        element.style.cursor = "auto";
      }
      setTimeout(() => {
        displayPageTransition("pageTransitionEffect");
      }, 400);
      setTimeout(() => {
        displayPageTransition("pageTransitionNotEffect");
        window.scrollTo(0, 0);
        navigate(`${route}`);
      }, 1200);
    }
  };

  const FunctionLink = (e, id, route) => {
    const idElement = document.querySelector(`#${id}`);

    if (window.location.pathname === `/${idElement.id}`) {
      const scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
          window.requestAnimationFrame(scrollToTop);
          window.scrollTo(0, c - c / 8);
        }
      };
      scrollToTop();
    }
    if (
      e.target.id === idElement.id &&
      window.location.pathname !== `/${idElement.id}`
    ) {
      const li = document.querySelectorAll(".li");

      li[0].style.pointerEvents = "none";
      li[0].style.cursor = "auto";
      li[1].style.pointerEvents = "none";
      li[1].style.cursor = "auto";
      li[2].style.pointerEvents = "none";
      li[2].style.cursor = "auto";
      li[3].style.pointerEvents = "none";
      li[3].style.cursor = "auto";
      li[4].style.pointerEvents = "none";
      li[4].style.cursor = "auto";
      li[5].style.pointerEvents = "none";
      li[5].style.cursor = "auto";

      setTimeout(() => {
        displayPageTransition("pageTransitionEffect");
      }, 400);
      setTimeout(() => {
        displayPageTransition("pageTransitionNotEffect");
        window.scrollTo(0, 0);
        navigate(`${route}`);
      }, 1200);
    }
  };

  return (
    <>
      {blockListeMenu && (
        <ContenairMenu>
          <Ul>
            <Li id="accueil" onClick={(e) => FunctionLinkHome(e, "/")}>
              Accueil
            </Li>
            <Li
              id="depannage"
              onClick={(e) => FunctionLink(e, "depannage", "/depannage")}
            >
              Dépannage
            </Li>
            <Li
              id="installation"
              onMouseEnter={(e) =>
                EffectNavBarEnterFunction(
                  e,
                  "installation",
                  "installationHidden"
                )
              }
              onMouseLeave={(e) =>
                EffectNavBarLeaveFunction(
                  e,
                  "installation",
                  "installationHidden"
                )
              }
              onClick={(e) => FunctionLink(e, "installation", "/installation")}
            >
              Installation
            </Li>
            <Li
              id="chauffe-eau"
              onMouseEnter={(e) =>
                EffectNavBarEnterFunction(e, "chauffe-eau", "waterHeaterHidden")
              }
              onMouseLeave={(e) =>
                EffectNavBarLeaveFunction(e, "chauffe-eau", "waterHeaterHidden")
              }
              onClick={(e) => FunctionLink(e, "chauffe-eau", "/chauffe-eau")}
            >
              Chauffe-Eau
            </Li>
            <Li
              id="climatisation"
              onClick={(e) =>
                FunctionLink(e, "climatisation", "/climatisation")
              }
            >
              Climatisation
            </Li>
            <LiBathroom
              id="salles-de-bain"
              onClick={(e) =>
                FunctionLink(e, "salles-de-bain", "/salles-de-bain")
              }
            >
              Salle de bain
            </LiBathroom>
          </Ul>
          <BlockMenuDisplay>
            <Troubleshooting />
            <Installation />
            <WaterHeater />
            <AirConditionning />
            <Bathroom />
          </BlockMenuDisplay>
        </ContenairMenu>
      )}
    </>
  );
}
