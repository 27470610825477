import React from "react";
import styled from "styled-components";
import { devices } from "../../style/Size";

const CopyrightElement = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  margin: 0;
  padding-top: 40px;
  padding-bottom: 20px;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  letter-spacing: 1px;
  text-align: center;
  margin-top: -6px;
  @media ${devices.laptopL} {
    font-size: 16px;
  }
  @media ${devices.laptopS} {
    font-size: 14px;
  }
  @media ${devices.mobileL} {
    font-size: 12px;
    flex-direction: column;
  }
  @media ${devices.mobileM} {
    font-size: 12px;
  }
  @media ${devices.mobileS} {
    font-size: 10px;
  }
`;

export default function Copyright(props) {
  return (
    <CopyrightElement
      style={{
        backgroundColor: props.backgroundColor,
      }}
    >
      <p style={{ padding: "0", margin: "0" }}>© 2023 Entreprise Navarro.</p>
      <p style={{ padding: "0", margin: "0" }}> Tous droits réservés.</p>
    </CopyrightElement>
  );
}
