import React from "react";
import styled from "styled-components";
import ButtonBlog from "../Buttons/ButtonBlog";
import { devices } from "../../style/Size";

const ContenairBlockBlog = styled.div.attrs({
  className: "contenairBlockBlog",
})`
  width: 90%;
  height: 400px;
  position: relative;
  transform: translateY(60px) scale(1.05);
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 80px; */

  @media ${devices.tablet} {
    height: 820px;
    /* margin-top: 20px; */
  }
  @media ${devices.tabletM} {
    height: 840px;
  }
`;

const BlockInstallation = styled.div`
  width: 100%;
  height: 360px;
  border-radius: 10px;
  border: 4px solid #3c2114;
  box-sizing: border-box;
  background: linear-gradient(
    to left bottom,
    #3c2114,
    7%,
    #553322,
    45%,
    #633620,
    70%,
    #75452c
  );
  position: absolute;

  @media ${devices.tablet} {
    height: 760px;
  }
  @media ${devices.tabletM} {
    height: 780px;
  }
`;

const ContenairText = styled.div`
  display: flex;
  justify-content: space-around;
  width: 96%;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;

  @media ${devices.tablet} {
    flex-direction: column-reverse;
  }
`;

const BlockTitle = styled.h2`
  font-size: 22px;
  width: 94%;
  color: #ffffff;
  font-family: "Russo One", sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  margin-left: 2%;

  @media ${devices.tabletM} {
    height: 54px;
  }
  @media ${devices.mobileL} {
    font-size: 20px;
  }
  @media (max-width: 390px) {
    max-width: 290px;
  }
  @media (max-width: 345px) {
    max-width: 240px;
  }
  @media ${devices.mobileS} {
    max-width: 200px;
  }
`;

const Text = styled.p`
  width: calc(100% - 320px);
  height: 280px;
  /* line-height: 22px;
  max-height: calc(20px * 14); */
  font-size: 18px;
  color: #ffffff;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  text-align: justify;
  margin: 0 auto;
  margin-top: 6px;
  padding-bottom: 6px;
  padding-right: 16px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* overflow: hidden;
  text-overflow: ellipsis;
  position: relative; */
  /* &::after {
    content: "...";
    position: absolute;
    bottom: 0;
    right: 0;
  } */

  @media ${devices.tablet} {
    width: 98%;
    height: 276px;
    margin-top: 16px;
    padding-right: 0px;
  }
  @media (max-width: 544px) {
    height: 274px;
  }
  @media ${devices.mobileL} {
    height: 282px;
  }
  @media ${devices.mobileM} {
    height: 284px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

const ContenairImage = styled.div`
  width: 320px;
  height: 216px;
  margin-top: 10px;

  @media ${devices.tablet} {
    width: 100%;
    height: 320px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default function BlockBlog(props) {
  return (
    <ContenairBlockBlog
      style={{
        paddingBottom: `${props.paddingBottom}`,
        top: `${props.top}`,
      }}
      className="contenairBlockBlog"
    >
      <BlockInstallation>
        <BlockTitle>{props.title}</BlockTitle>
        <ContenairText>
          <Text>{props.text}</Text>
          <ContenairImage>
            <Image
              src={props.src}
              alt={props.alt}
              style={{ backgroundColor: props.backgroundColor }}
            />
          </ContenairImage>
        </ContenairText>
        <ButtonBlog
          idButton={props.idButton}
          onClick={props.onClick}
          categories={props.categories}
        />
      </BlockInstallation>
    </ContenairBlockBlog>
  );
}
