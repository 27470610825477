import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
   body { 
   margin: 0;
   padding: 0;
   min-width: 250px;
   max-width: 2330px;
   position: relative;
}

@media (min-width: 2330px) {
   body { 
      margin-left: auto;
      margin-right: auto;
   }
}
`;
export default GlobalStyle;
