import React, { useRef } from "react";
import styled from "styled-components";
import { devices } from "../../style/Size";

const FirstName = styled.input`
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  margin: 0;
  margin-top: 8px;
  border: 2px solid #667788;
  box-sizing: border-box;
  background-color: #dfe3e7;
  border-radius: 2px;
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  font-weight: 100;
  color: #000000;
  outline: none;

  @media ${devices.laptopXXS} {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 40px;
    margin-top: 6px;
  }
  @media (max-width: 702px) {
    margin-top: 4px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

export default function InputfirstName({
  elementFormTwo,
  setElementFormTwo,
  setFirstNameValue,
}) {
  const refFirstName = useRef();

  const onKeyUpForm = (e) => {
    if (e.target.id === "firstName") {
      if (elementFormTwo && refFirstName.current.value.length < 2) {
        setElementFormTwo(
          "Votre prénom ne peut contenir moins de deux lettreas"
        );
        refFirstName.current.style.border = "2px solid red";
        setFirstNameValue(false);
      }
      if (refFirstName.current.value.length < 2) {
        setFirstNameValue(false);
      }
      if (refFirstName.current.value.length > 20) {
        setElementFormTwo(
          "Votre prénom ne peut contenir plus de vingt lettres"
        );
        refFirstName.current.style.border = "2px solid red";
        setFirstNameValue(false);
      }
      if (
        refFirstName.current.value.length >= 2 &&
        refFirstName.current.value.length <= 20
      ) {
        setElementFormTwo(false);
        refFirstName.current.style.border = "2px solid #667788";
        setFirstNameValue(refFirstName.current.value);

        for (let letter of refFirstName.current.value) {
          if (!letter.match("^[A-z-]*$")) {
            setElementFormTwo("Votre prénom ne peut contenir que des lettres");
            refFirstName.current.style.border = "2px solid red";
            setFirstNameValue(false);
          } else if (letter.match("^[A-z-]*$")) {
            setElementFormTwo(false);
            refFirstName.current.style.border = "2px solid #667788";
            setFirstNameValue(refFirstName.current.value);
          }
        }
      }
    }
  };

  const onKeyDownForm = (e) => {
    if (e.target.id === "firstName") {
      if (refFirstName.current.value.length > 20) {
        if (e.code !== "Backspace" && e.code !== "Delete") {
          e.preventDefault();
          setFirstNameValue(false);
        }
      }
      for (let letter of refFirstName.current.value) {
        if (
          !letter.match("^[A-z-]*$") &&
          e.code !== "Backspace" &&
          e.code !== "Delete"
        ) {
          e.preventDefault();
          setFirstNameValue(false);
        }
      }
    }
  };

  const onBlurForm = (e) => {
    if (e.target.id === "firstName") {
      if (refFirstName.current.value.length < 2) {
        setElementFormTwo(
          "Votre prénom ne peut contenir moins de deux lettres"
        );
        refFirstName.current.style.border = "2px solid red";
        setFirstNameValue(false);
      }
      for (let letter of refFirstName.current.value) {
        if (
          !letter.match("^[A-z-]*$") &&
          e.code !== "Backspace" &&
          e.code !== "Delete"
        ) {
          setElementFormTwo("Votre nom ne peut contenir que des lettres");
          refFirstName.current.style.border = "2px solid red";
          setFirstNameValue(false);
        }
      }
    }
  };

  return (
    <FirstName
      aria-label="Votre prénom"
      ref={refFirstName}
      id="firstName"
      className="elementForm"
      placeholder="Prénom"
      onKeyUp={(e) => onKeyUpForm(e)}
      onKeyDown={(e) => onKeyDownForm(e)}
      onBlur={(e) => onBlurForm(e)}
    ></FirstName>
  );
}
