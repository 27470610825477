import React, { useContext } from "react";
import styled from "styled-components";
import { ModalFormContext } from "../../../contexts/ModalForm";
import { devices } from "../../../style/Size";

const ButtonClose = styled.button.attrs({
  type: "button,",
})`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  z-index: 2;
  position: absolute;
  top: 6px;
  right: 14px;
  &:hover + .iconClose {
    filter: brightness(1.1);
    transform: scale(1.1);
    transition: all 140ms ease-in-out;
  }

  @media ${devices.laptopXXS} {
    top: 4px;
    right: 4px;
  }
`;

const IconP = styled.p.attrs({
  className: "iconClose",
})`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #667788;
  position: absolute;
  top: -10px;
  right: 14px;
  z-index: 1;

  @media ${devices.laptopXXS} {
    top: -12px;
    right: 4px;
  }
`;

export default function ButtonCloseModalForm({
  setElementFormOne,
  setElementFormTwo,
  setElementFormThree,
  setElementFormFour,
  setElementFormFive,
  setElementFormSix,
  // lastNameValue,
  // firstNameValue,
  // numberPhoneValue,
  // mailValue,
  // postalCodeValue,
  // contentMailValue,
  setLastNameValue,
  setFirstNameValue,
  setNumberPhoneValue,
  setMailValue,
  setPostalCodeValue,
  setContentMailValue,
}) {
  const { displayModalForm } = useContext(ModalFormContext);

  const onClickCloseModalForm = (e) => {
    e.preventDefault();
    const modalForm = document.querySelector("#modalForm");
    const menuNavBar = document.querySelector("#blockNavAndMenu");
    const iconClose = document.querySelector("#iconClose");
    iconClose.style.transform = "scale(0.9)";
    iconClose.style.transition = "200ms";
    setTimeout(() => {
      iconClose.style.transform = "scale(1.1)";
      iconClose.style.transition = "200ms";
    }, 200);
    setTimeout(() => {
      modalForm.style.transform = "scale(1) translate(-50%, -46%)";
      modalForm.classList.remove("displayModalForm");
      modalForm.classList.add("hiddenModalForm");
    }, 500);

    // const lastName = document.querySelector("#lastName");
    // const firstName = document.querySelector("#firstName");
    // const numberPhone = document.querySelector("#numberPhone");
    // const mail = document.querySelector("#mail");
    // const postalCode = document.querySelector("#postalCode");
    // const mailContent = document.querySelector("#mailContent");

    setTimeout(() => {
      displayModalForm("modalFormClose");
      displayModalForm("contenairModalFormClose");
      menuNavBar.style.pointerEvents = "auto";
      setElementFormOne(false);
      setElementFormTwo(false);
      setElementFormThree(false);
      setElementFormFour(false);
      setElementFormFive(false);
      setElementFormSix(false);
      // lastName.value = null;
      // firstName.value = null;
      // numberPhoneValue = null;
      // mailValue = null;
      // postalCodeValue = null;
      // contentMailValue = null;
      setLastNameValue(null);
      setFirstNameValue(null);
      setNumberPhoneValue(null);
      setMailValue(null);
      setPostalCodeValue(null);
      setContentMailValue(null);
    }, 1100);
  };

  return (
    <>
      <ButtonClose
        onClick={(e) => onClickCloseModalForm(e)}
        // onTouchStart={(e) => onClickCloseModalForm(e)}
      />
      <IconP id="iconClose">
        <i
          style={{
            fontSize: "20px",
            color: "#FFFFFF",
            position: "absolute",
            top: "5px",
            left: "7.5px",
          }}
          className="fa-solid fa-xmark"
        ></i>
      </IconP>
    </>
  );
}
