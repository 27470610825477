import React, { useEffect } from "react";
import { styled } from "styled-components";
import { devices } from "../../../style/Size";

const ContenairBlockFicheRecto = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 10px;
  transform: scale(1.2);
  opacity: 0;

  @media (max-height: 480px) {
    height: 240px;
  }
`;

const BlockFiche = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 10px;
  perspective: 1000px;
  position: absolute;
  overflow: hidden;

  @media (max-height: 480px) {
    height: 240px;
  }
`;

const FilterFiche = styled.div`
  width: 100.2%;
  height: 400px;
  opacity: 0.4;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  @media (max-height: 480px) {
    height: 240px;
  }
`;

const ButtonDetail = styled.button.attrs({
  type: "button",
  value: "Voir",
  className: "buttonFiche",
})`
  width: 40%;
  height: 10%;
  background: radial-gradient(circle at 70% 50%, #be7a54, #553322);
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 200;
  transform: translate(-50%, -50%);
  opacity: 0.85;
  &:hover {
    transform: translate(-50%, -50%) scale(1.05);
    opacity: 9.5;
  }

  @media ${devices.tablet} {
    font-size: 16px;
  }
  @media (max-width: 684px) {
    width: 100%;
    margin-left: 0%;
  }
  @media ${devices.mobileM} {
    font-size: 14px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;

  @media (max-height: 480px) {
    object-fit: contain;
  }
`;

export default function FicheRectoBlock(props) {
  useEffect(() => {
    const imageWaterHeater = document.querySelector("#idImageGazWaterHeater");
    const idImageChaudiere = document.querySelector("#idImageChaudiere");
    const idImagePipeWC = document.querySelector("#idImagePipeWC");

    if (idImagePipeWC) {
      if (window.innerWidth > 1200 && window.innerHeight > 480) {
        idImagePipeWC.style.height = "calc(100% + 160px)";
      }
      if (window.innerWidth > 1710 && window.innerHeight > 480) {
        idImagePipeWC.style.height = "calc(100% + 200px)";
      }
      if (window.innerWidth <= 1140 && window.innerHeight > 480) {
        idImagePipeWC.style.height = "calc(100% + 280px)";
      }
      if (window.innerWidth <= 684 && window.innerHeight > 480) {
        idImagePipeWC.style.height = "calc(100% + 380px)";
      }
      if (window.innerWidth <= 480 && window.innerHeight > 480) {
        idImagePipeWC.style.height = "calc(100% + 160px)";
      }
    }
    if (imageWaterHeater) {
      if (window.innerWidth < 1110 && window.innerHeight > 480) {
        imageWaterHeater.style.width = "calc(100% + 160px)";
        imageWaterHeater.style.position = "absolute";
        imageWaterHeater.style.right = "-160px";
        imageWaterHeater.style.transform = "scaleX(-1)";
      } else {
        imageWaterHeater.style.width = "100%";
        imageWaterHeater.style.position = "static";
        imageWaterHeater.style.right = "0px";
        imageWaterHeater.style.transform = "scaleX(1)";
      }
    }
    if (idImageChaudiere) {
      if (window.innerWidth >= 1540 && window.innerHeight > 480) {
        idImageChaudiere.style.height = "calc(100% + 160px)";
      }
      if (window.innerWidth > 2000 && window.innerHeight > 480) {
        idImageChaudiere.style.height = "calc(100% + 240px)";
      }
      if (window.innerWidth < 1540 && window.innerHeight > 480) {
        idImageChaudiere.style.height = "100%";
      }
    }
  });

  window.addEventListener("resize", () => {
    const imageGazWaterHeater = document.querySelector(
      "#idImageGazWaterHeater"
    );
    const imageChaudiere = document.querySelector("#idImageChaudiere");
    const imagePipeWC = document.querySelector("#idImagePipeWC");
    const imageThermostat = document.querySelector("#idImageThermostat");
    if (imagePipeWC) {
      if (window.innerWidth > 1200 && window.innerHeight > 480) {
        imagePipeWC.style.height = "calc(100% + 160px)";
      }
      if (window.innerWidth > 1710 && window.innerHeight > 480) {
        imagePipeWC.style.height = "calc(100% + 200px)";
      }
      if (window.innerWidth <= 1140 && window.innerHeight > 480) {
        imagePipeWC.style.height = "calc(100% + 280px)";
      }
      if (window.innerWidth <= 684 && window.innerHeight > 480) {
        imagePipeWC.style.height = "calc(100% + 380px)";
      }
      if (window.innerWidth <= 480 && window.innerHeight > 480) {
        imagePipeWC.style.height = "calc(100% + 160px)";
      }
    }

    if (imageThermostat) {
      if (window.innerWidth <= 1000 && window.innerHeight > 480) {
        imageThermostat.style.width = "calc(100% + 100px)";
        imageThermostat.style.position = "absolute";
        imageThermostat.style.right = "-100px";
      }
      if (
        window.innerWidth > 1000 &&
        window.innerWidth < 1140 &&
        window.innerHeight > 480
      ) {
        imageThermostat.style.width = "100%";
        imageThermostat.style.position = "static";
        imageThermostat.style.right = "0px";
      }
      if (window.innerWidth >= 1140 && window.innerHeight > 480) {
        imageThermostat.style.width = "calc(100% + 100px)";
        imageThermostat.style.position = "absolute";
        imageThermostat.style.right = "-100px";
      }
    }

    if (imageGazWaterHeater) {
      if (window.innerWidth <= 1110 && window.innerHeight > 480) {
        imageGazWaterHeater.style.width = "calc(100% + 160px)";
        imageGazWaterHeater.style.position = "absolute";
        imageGazWaterHeater.style.right = "-160px";
        imageGazWaterHeater.style.transform = "scaleX(-1)";
      }
      if (window.innerWidth > 1110 && window.innerHeight > 480) {
        imageGazWaterHeater.style.width = "100%";
        imageGazWaterHeater.style.position = "static";
        imageGazWaterHeater.style.right = "0px";
        imageGazWaterHeater.style.transform = "scaleX(1)";
      }
    }

    if (imageChaudiere) {
      if (window.innerWidth >= 1540 && window.innerHeight > 480) {
        imageChaudiere.style.height = "calc(100% + 160px)";
      }
      if (window.innerWidth > 2000 && window.innerHeight > 480) {
        imageChaudiere.style.height = "calc(100% + 240px)";
      }
      if (window.innerWidth < 1540 && window.innerHeight > 480) {
        imageChaudiere.style.height = "100%";
      }
    }
  });

  return (
    <ContenairBlockFicheRecto
      id={props.idBlockTopOrBottomWarp}
      className={props.classNameContenair}
    >
      <BlockFiche className={props.className}>
        <FilterFiche
          style={{
            background: "radial-gradient(circle at top left, #a0bedf, #1c3377)",
            transform: "scaleX(-1)",
          }}
          onMouseMove={props.effect3d}
          onMouseLeave={props.notEffect3d}
          onMouseEnter={props.notDisplayFicheVerso}
          id={props.idFiltre}
        />
        {props.hover ? (
          <>
            <ButtonDetail
              id={props.idButton}
              onMouseMove={props.effectButton3d}
              onClick={props.effectInfos}
              onMouseLeave={props.notEffectButton3d}
            >
              VOIR
            </ButtonDetail>
          </>
        ) : null}
        <Image
          id={props.idImage}
          src={props.image}
          alt={props.alt}
          fetchpriority="high"
        ></Image>
      </BlockFiche>
    </ContenairBlockFicheRecto>
  );
}
