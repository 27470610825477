import React from "react";
import styled from "styled-components";
import {
  EffectGlowButtonFunction,
  NotEffectGlowButtonWaterHeaterFunction,
  HoverButtonWaterHeaterFunction,
} from "../../functions/EffectGlowButtonsFunction";
import { devices } from "../../style/Size";

const Button = styled.button.attrs({
  type: "button",
  value: "VOIR CES RÉFÉRENCES",
  className: "buttonArticle",
})`
  width: 60%;
  background-color: #556699;
  border: 2px solid #ffffff;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  font-weight: 100;
  color: #ffffff;
  padding-top: 14px;
  padding-bottom: 14px;
  margin-left: 2%;
  cursor: pointer;
  transform: translateX(-1vw);

  @media ${devices.laptopM} {
    font-size: 14px;
  }
  @media ${devices.laptopXXS} {
    transform: translateX(0vw);
    margin-left: 20%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media ${devices.tablet} {
    width: 60%;
    margin-left: 20%;
  }
  @media ${devices.mobileL} {
    width: 74%;
    margin-left: 13%;
  }
  @media ${devices.mobileM} {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  @media ${devices.mobileS} {
    width: 84%;
    margin-left: 8%;
  }
  @media ${devices.mobileXS} {
    padding-left: 0;
    padding-right: 0;
    padding-top: 6px;
    padding-bottom: 6px;
    width: 90%;
    margin-left: 5%;
  }
`;

export default function ButtonWaterHeater(props) {
  return (
    <Button
      id={props.idButton}
      onClick={props.onClick}
      // onTouchStart={props.onTouchStart}
      onMouseMove={(e) => EffectGlowButtonFunction(e, "buttonArticle")}
      onMouseLeave={(e) =>
        NotEffectGlowButtonWaterHeaterFunction(e, "buttonArticle")
      }
      onMouseEnter={(e) => HoverButtonWaterHeaterFunction(e, "buttonArticle")}
    >
      VOIR CES RÉFÉRENCES
    </Button>
  );
}
