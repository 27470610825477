import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import HeaderSubSection from "../components/Header/HeaderSubSection";
import BodySubSection from "../components/SubSection/BodySubSection";
import LastBlockSubSection from "../components/SubSection/LastBlockSubSection";
import ButtonQuote from "../components/Buttons/ButtonQuote";
import Footer from "../components/Footer";
import GazWaterHeaterPicture from "../assets/Installation/chauffe-eau-gaz.webp";
import CEG from "../assets/GazWaterHeater/chauffe-eau-gaz.webp";
import ChaudiereGaz from "../assets/GazWaterHeater/chaudiere-gaz.webp";
import ChaudiereGazCondensation from "../assets/GazWaterHeater/chaudiere-gaz-a-condensation.webp";
import { NavBarDisplayFunction } from "../functions/NavBarDisplayScrollFunction";
import { EffectSubSectionFunction } from "../functions/EffectSubSectionFunction";
import { EffectTitlePageFunction } from "../functions/EffectTitlePageFunction";
import { devices } from "../style/Size";

const ContenairMain = styled.main`
  width: 100%;
  position: relative;
  margin-top: 70px;

  @media ${devices.laptopXXS} {
    margin-top: 110px;
  }
  @media ${devices.tablet} {
    margin-top: 40px;
  }
`;

export default function GazWaterHeater() {
  useEffect(() => {
    EffectTitlePageFunction("titleGazWaterHeaterContenair");
    setTimeout(() => {
      EffectTitlePageFunction("idBlockElement");
    }, 600);
  }, []);

  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction("headerGazWaterHeaterContenair");
  });

  useEffect(() => {
    const titleSection = document.querySelector("#blockTitle");
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        window.addEventListener("scroll", () => {
          EffectSubSectionFunction(
            "blockTitle",
            "/chauffe-eau/chauffe-eau-gaz-chaudieres"
          );
        });
      }
    });
    observer.observe(titleSection);
  }, []);

  window.addEventListener("resize", () => {
    const blockSection = document.querySelectorAll(`.imageSection`);
    if (blockSection) {
      EffectSubSectionFunction(
        "blockTitle",
        "/chauffe-eau/chauffe-eau-gaz-chaudieres"
      );
      if (window.innerWidth <= 568) {
        for (let element of blockSection) {
          element.style.transform = "translateX(0vw)";
          element.style.transition = "all 300ms";
        }
      }
    }
  });

  return (
    <>
      <HeaderSubSection
        id="headerGazWaterHeaterContenair"
        idTitle="titleGazWaterHeaterContenair"
        src={GazWaterHeaterPicture}
        alt="Chauffe-eau gaz mural rectangulaire et compact blanc dans salle de bain de couleur blanche"
        titlePage="Chauffe-eau Gaz – Chaudières"
      />
      <ContenairMain>
        <BodySubSection
          idText="textSection"
          imageClassname="imageSection"
          idBlockElement="idBlockElement"
          opacity="0.5"
          src={CEG}
          alt="Chauffe-eau gaz mural rectangulaire et compact blanc dans salle de bain de couleur blanche"
          title={[
            "Les ",
            <strong key="1" style={{ fontWeight: "500" }}>
              Chauffe-Eau Gaz
            </strong>,
          ]}
          text={[
            <strong key="1" style={{ fontWeight: "100" }}>
              Chauffe-eau gaz avec veilleuse ou sans veilleuse
            </strong>,
            ". ",
            <strong key="2" style={{ fontWeight: "100" }}>
              Distribution instantanée d'eau chaude
            </strong>,
            ". Différents systèmes d'évacuation des fumées : cheminée, VMC individuelle ou collective, ventouse. Différents systèmes de puissances sont disponibles. Le plus généralement, les ",
            <strong key="3" style={{ fontWeight: "100" }}>
              chauffe-eau gaz
            </strong>,
            " proposent un débit spécifique de 11 litres/min à 1ΔT 25 degrés Celsius.",
            <br key="4" />,
            <br key="5" />,
            "Nos ",
            <strong key="6" style={{ fontWeight: "100" }}>
              chauffagistes à Marseille
            </strong>,
            " possèdent l'habilitation ",
            <strong key="7" style={{ fontWeight: "100" }}>
              Professionnels du Gaz
            </strong>,
            ", et se rendent sur place pour effectuer un ",
            <strong key="8" style={{ fontWeight: "100" }}>
              devis 100% gratuit et personnalisé
            </strong>,
            " afin d'étudier le ",
            <strong key="9" style={{ fontWeight: "100" }}>
              coût et temps d'installation chauffagiste
            </strong>,
            ". L'entreprise Navarro est également ",
            <strong key="10" style={{ fontWeight: "100" }}>
              Partenaire GRDF
            </strong>,
            ". ",
            <br key="11" />,
            <br key="12" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="13"
              to="https://www.saunierduval.fr/particulier/nos-produits/chauffe-eau/chauffe-bain-gaz/"
              target="_blank"
            >
              Saunier Duval
            </Link>,
            " et ",
            <Link
              key="14"
              to="https://www.elmleblanc.fr/ocs/elm-leblanc/chauffe-bains-gaz-878558-c/"
              target="_blank"
            >
              ELM Leblanc
            </Link>,
            ".",
          ]}
        />
        <BodySubSection
          imageClassname="imageSection"
          src={ChaudiereGaz}
          alt="Chauffe-eau gaz mural rectangulaire et compact blanc dans cuisine encastré dans meuble"
          title={[
            "Les ",
            <strong key="1" style={{ fontWeight: "500" }}>
              Chaudières Gaz
            </strong>,
          ]}
          text={[
            <strong key="1" style={{ fontWeight: "100" }}>
              Chaudières gaz
            </strong>,
            " toutes gammes. ",
            <strong key="2" style={{ fontWeight: "100" }}>
              Distribution instantanée d'eau chaude
            </strong>,
            ". Différents systèmes d'évacuation des fumées : cheminée, VMC individuelle ou collective, système bas-nox. Différentes technologies sont disponibles pour la distribution d'eau chaude : micro-accumulation, ou mini accumulation avec ballon de 3 litres intégré. Alimentation ",
            <strong key="3" style={{ fontWeight: "100" }}>
              radiateurs
            </strong>,
            ". La solution idéale pour une rénovation de votre ",
            <strong key="4" style={{ fontWeight: "100" }}>
              système chauffagiste
            </strong>,
            ". Différentes puissances sont au choix selon le nombre de radiateurs, le nombre de personnes, et la superficie du logement.",
            <br key="5" />,
            <br key="6" />,
            "Nos ",
            <strong key="7" style={{ fontWeight: "100" }}>
              chauffagistes à Marseille
            </strong>,
            " possèdent l'habilitation ",
            <strong key="8" style={{ fontWeight: "100" }}>
              Professionnels du Gaz
            </strong>,
            ", et se rendent sur place pour effectuer un ",
            <strong key="9" style={{ fontWeight: "100" }}>
              devis 100% gratuit et personnalisé
            </strong>,
            " afin d'étudier le ",
            <strong key="10" style={{ fontWeight: "100" }}>
              coût et temps d'installation chauffagiste
            </strong>,
            ". L'entreprise Navarro est également ",
            <strong key="11" style={{ fontWeight: "100" }}>
              Partenaire GRDF
            </strong>,
            ". ",
            <br key="12" />,
            <br key="13" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="14"
              to="https://www.dedietrich-thermique.fr/recherche?actimage_search%5Bfamily%5D%5B%5D=5625&actimage_search%5Bfamily%5D%5B%5D=5626&actimage_search%5BvalidateSearchFilter%5D=&actimage_search%5Bkeyword%5D=chauffe-eau&actimage_search%5Bezxform_token%5D=WiQZoRpdXfGCFC0wwrcQQg_s8y0HGTt8akbIDUzj9Zw"
              target="_blank"
            >
              De Dietrich
            </Link>,
            ", ",
            <Link
              key="15"
              to="https://www.saunierduval.fr/particulier/nos-produits/chaudiere-gaz/chaudiere-gaz-basse-temperature/"
              target="_blank"
            >
              Saunier Duval
            </Link>,
            ", et ",
            <Link
              key="16"
              to="https://www.elmleblanc.fr/ocs/elm-leblanc/chaudieres-gaz-bas-nox-878557-c/"
              target="_blank"
            >
              ELM Leblanc
            </Link>,
            ".",
          ]}
        />
        <LastBlockSubSection
          imageClassname="imageSection"
          src={ChaudiereGazCondensation}
          alt="Grande chaudière à condensation blanche et cylindrique dans vaste garage très bien rangé"
          title={[
            "Les ",
            <strong key="1" style={{ fontWeight: "500" }}>
              Chaudières Gaz à Condensation
            </strong>,
          ]}
          text={[
            <strong key="1" style={{ fontWeight: "100" }}>
              Chaudière gaz à condensation
            </strong>,
            ". Installation murale ou au sol. ",
            <strong key="2" style={{ fontWeight: "100" }}>
              Distribution instantanée d'eau chaude
            </strong>,
            ". Différentes puissances sont disponibles. Le plus généralement, les ",
            <strong key="3" style={{ fontWeight: "100" }}>
              chaudières à condensation
            </strong>,
            " proposent une puissance avec accumulation dynamique de 42 litres. Économie d'énergie significative selon l'isolation du logement. Plusieurs corps de chauffe sont au choix selon la gamme choisie : en aluminium, pour les entrées de gamme, en acier, pour le moyen de gamme, en inox, pour le haut de gamme.",
            <br key="4" />,
            <br key="5" />,
            "Nos ",
            <strong key="6" style={{ fontWeight: "100" }}>
              chauffagistes à Marseille
            </strong>,
            " possèdent l'habilitation ",
            <strong key="7" style={{ fontWeight: "100" }}>
              Professionnels du Gaz
            </strong>,
            ", et se rendent sur place pour effectuer un ",
            <strong key="8" style={{ fontWeight: "100" }}>
              devis 100% gratuit et personnalisé
            </strong>,
            " afin d'étudier le ",
            <strong key="9" style={{ fontWeight: "100" }}>
              coût et temps d'installation chauffagiste
            </strong>,
            ". L'entreprise Navarro est également ",
            <strong key="10" style={{ fontWeight: "100" }}>
              Partenaire GRDF
            </strong>,
            ". ",
            <br key="11" />,
            <br key="12" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="13"
              to="https://www.saunierduval.fr/particulier/nos-produits/chaudiere-gaz/chaudiere-gaz-a-condensation/"
              target="_blank"
            >
              Saunier Duval
            </Link>,
            " et ",
            <Link
              key="14"
              to="https://www.elmleblanc.fr/ocs/elm-leblanc/chaudieres-gaz-a-condensation-853672-c/"
              target="_blank"
            >
              ELM Leblanc
            </Link>,
            ".",
          ]}
        />
        <ButtonQuote />
      </ContenairMain>
      <Footer backgroundColor="#667788" />
    </>
  );
}
