import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import HeaderSubSection from "../components/Header/HeaderSubSection";
import BodySubSection from "../components/SubSection/BodySubSection";
import LastBlockSubSection from "../components/SubSection/LastBlockSubSection";
import ButtonQuote from "../components/Buttons/ButtonQuote";
import Footer from "../components/Footer";
import BathtubPicture from "../assets/Installation/baignoire.avif";
import BathtubClassic from "../assets/Bathtub/baignoire-classique.webp";
import BathtubMust from "../assets/Bathtub/baignoire-complet.webp";
import BathtubTop from "../assets/Bathtub/bain-a-bulles.webp";
import { EffectSubSectionFunction } from "../functions/EffectSubSectionFunction";
import { NavBarDisplayFunction } from "../functions/NavBarDisplayScrollFunction";
import { EffectTitlePageFunction } from "../functions/EffectTitlePageFunction";
import { devices } from "../style/Size";

const ContenairMain = styled.main`
  width: 100%;
  position: relative;
  margin-top: 70px;

  @media ${devices.laptopXXS} {
    margin-top: 110px;
  }
  @media ${devices.tablet} {
    margin-top: 40px;
  }
`;

export default function Bathtub() {
  useEffect(() => {
    EffectTitlePageFunction("titleBathtubContenair");
    setTimeout(() => {
      EffectTitlePageFunction("idBlockElement");
    }, 600);
  }, []);

  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction("headerBathtubContenair");
  });

  useEffect(() => {
    const titleSection = document.querySelector("#blockTitle");
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        window.addEventListener("scroll", () => {
          EffectSubSectionFunction("blockTitle", "/installation/baignoires");
        });
      }
    });
    observer.observe(titleSection);
  }, []);

  window.addEventListener("resize", () => {
    const blockSection = document.querySelectorAll(`.imageSection`);
    if (blockSection) {
      EffectSubSectionFunction("blockTitle", "/installation/baignoires");
      if (window.innerWidth <= 568) {
        for (let element of blockSection) {
          element.style.transform = "translateX(0vw)";
          element.style.transition = "all 300ms";
        }
      }
    }
  });

  return (
    <>
      <HeaderSubSection
        id="headerBathtubContenair"
        idTitle="titleBathtubContenair"
        src={BathtubPicture}
        alt="Baignoire rectangulaire blanche confortable aux parois couvrées par plaques en plâtres style béton"
        titlePage="Baignoires"
      />
      <ContenairMain>
        <BodySubSection
          idText="textSection"
          imageClassname="imageSection"
          idBlockElement="idBlockElement"
          opacity="0.5"
          src={BathtubClassic}
          alt="Baignoire classique rectangulaire en céramique blanc dans salle de bain simple"
          title="Les Classiques"
          text={[
            "Des ",
            <strong key="1" style={{ fontWeight: "100" }}>
              baignoires classiques
            </strong>,
            ". Surface lisse et rigide. En céramique avec traitement anti-bactérien naturel spécifique pour une meilleure hygiène et facilité d'entretien. Plusieurs dimensions pour une contenance moyenne. Un confort agréable. Coloris monochrome blanc.",
            <br key="2" />,
            <br key="3" />,
            "Toutes nos ",
            <strong key="4" style={{ fontWeight: "100" }}>
              baignoires
            </strong>,
            " peuvent être accompagnées d'un habillage en bois, PVC, ou maçonné et carrelé, ainsi que de ",
            <strong key="5" style={{ fontWeight: "100" }}>
              robinetterie
            </strong>,
            ".",
            <br key="6" />,
            <br key="7" />,
            " Nos ",
            <strong key="8" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " se rendent sur place pour effectuer un ",
            <strong key="9" style={{ fontWeight: "100" }}>
              devis 100% gratuit et personnalisé
            </strong>,
            " afin d'estimer le ",
            <strong key="10" style={{ fontWeight: "100" }}>
              coût et temps d'installation plomberie
            </strong>,
            ". Le temps de pose pour une baignoire seule sans remplacement ou avec remplacement varie selon la configuration des travaux à effectuer.",
            <br key="11" />,
            <br key="12" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="13"
              to="https://www.jacobdelafon.fr/bainbalneo/baignoire"
              target="_blank"
            >
              Jacob Delafon
            </Link>,
            " et ",
            <Link
              key="14"
              to="https://www.kinedo.com/produits/combines-douche-bain/"
              target="_blank"
            >
              Kinedo
            </Link>,
            ".",
          ]}
        />
        <BodySubSection
          imageClassname="imageSection"
          src={BathtubMust}
          alt="Baignoire classique rectangulaire en céramique blanc avec revêtement en bois clair mat lissé"
          title="Les Conforts"
          text={[
            "Des ",
            <strong key="1" style={{ fontWeight: "100" }}>
              baignoires de qualité
            </strong>,
            ". Robustesse du matériau. Absence de déformation sous-charge. Surface lisse et brillante. En céramique avec traitement anti-bactérien biocote pour une meilleure hygiène et facilité d'entretien. ",
            <strong key="2" style={{ fontWeight: "100" }}>
              Baignoire auto-portante
            </strong>,
            ". Plusieurs dimensions pour une contenance moyenne à grande. Divers coloris et formes.",
            <br key="3" />,
            <br key="4" />,
            "Toutes nos ",
            <strong key="5" style={{ fontWeight: "100" }}>
              baignoires
            </strong>,
            " peuvent être accompagnées d'un habillage en bois, PVC, ou maçonné et carrelé, ainsi que de ",
            <strong key="6" style={{ fontWeight: "100" }}>
              robinetterie
            </strong>,
            ".",
            <br key="7" />,
            <br key="8" />,
            " Nos ",
            <strong key="9" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " se rendent sur place pour effectuer un ",
            <strong key="10" style={{ fontWeight: "100" }}>
              devis 100% gratuit et personnalisé
            </strong>,
            " afin d'estimer le ",
            <strong key="11" style={{ fontWeight: "100" }}>
              coût et temps d'installation plomberie
            </strong>,
            ". Le temps de pose pour une baignoire seule sans remplacement ou avec remplacement varie selon la configuration des travaux à effectuer.",
            <br key="12" />,
            <br key="13" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="14"
              to="https://www.jacobdelafon.fr/bainbalneo/baignoire"
              target="_blank"
            >
              Jacob Delafon
            </Link>,
            " et ",
            <Link
              key="15"
              to="https://www.kinedo.com/produits/combines-douche-bain/"
              target="_blank"
            >
              Kinedo
            </Link>,
            ".",
          ]}
        />
        <LastBlockSubSection
          imageClassname="imageSection"
          src={BathtubTop}
          alt="Bain à bulles rectangulaire en céramique blanc avec repose-tête et lumières dans revêtement style ardoise bleu nuit"
          title={[
            <strong style={{ fontWeight: "500" }} key="1">
              Baignoires à l'ancienne
            </strong>,
            " – ",
            <strong style={{ fontWeight: "500" }} key="2">
              Bain à bulles
            </strong>,
            " – ",
            <strong style={{ fontWeight: "500" }} key="3">
              Hydro-massant
            </strong>,
          ]}
          text={[
            "Le nec plus ultra. Extrêmement solide et confortable. Surface lisse et brillante. Excellente conservation de la chaleur. En céramique avec traitement anti-bactérien biocote pour une meilleure hygiène et facilité d'entretien, ou en fonte émaillé. ",
            <strong key="1" style={{ fontWeight: "100" }}>
              Baignoire auto-portante
            </strong>,
            ". Plusieurs dimensions, matériaux, coloris et formes sont au choix. ",
            <strong key="2" style={{ fontWeight: "100" }}>
              Baignoires à l'ancienne
            </strong>,
            ", ",
            <strong key="3" style={{ fontWeight: "100" }}>
              bain à bulles
            </strong>,
            ", ",
            <strong key="4" style={{ fontWeight: "100" }}>
              hydro-massant
            </strong>,
            ", ",
            " plusieurs styles et options sont disponibles.",
            <br key="5" />,
            <br key="6" />,
            "Toutes nos ",
            <strong key="7" style={{ fontWeight: "100" }}>
              baignoires
            </strong>,
            " peuvent être accompagnées d'un habillage en bois, PVC, ou maçonné et carrelé, ainsi que de ",
            <strong key="8" style={{ fontWeight: "100" }}>
              robinetterie
            </strong>,
            ".",
            <br key="9" />,
            <br key="10" />,
            " Nos ",
            <strong key="11" style={{ fontWeight: "100" }}>
              plombiers à Marseille
            </strong>,
            " se rendent sur place pour effectuer un ",
            <strong key="12" style={{ fontWeight: "100" }}>
              devis 100% gratuit et personnalisé
            </strong>,
            " afin d'estimer le ",
            <strong key="13" style={{ fontWeight: "100" }}>
              coût et temps d'installation plomberie
            </strong>,
            ". Le temps de pose pour une baignoire seule sans remplacement ou avec remplacement varie selon la configuration des travaux à effectuer.",
            <br key="14" />,
            <br key="15" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produits et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont ",
            <Link
              key="16"
              to="https://www.jacobdelafon.fr/bainbalneo/balneo"
              target="_blank"
            >
              Jacob Delafon
            </Link>,
            " et ",
            <Link
              key="17"
              to="https://www.kinedo.com/baignoire-balneo"
              target="_blank"
            >
              Kinedo
            </Link>,
            ".",
          ]}
        />
        <ButtonQuote />
      </ContenairMain>
      <Footer marginTop="300px" backgroundColor="#667788" />
    </>
  );
}
