const EffectLiSubMenuDisplayFunction = (
  subMenuId,
  iconOneInstallationClassName,
  liSubMenuClassName,
  spanLiClassName,
  stateSubMenu,
  setSubMenuState,
  heightContenair
) => {
  const subMenu = document.querySelector(`#${subMenuId}`);
  const icon = document.querySelector(`#${iconOneInstallationClassName}`);
  const liSubMenu = document.querySelectorAll(`.${liSubMenuClassName}`);
  const spanLi = document.querySelectorAll(`.${spanLiClassName}`);

  if (subMenu) {
    if (!stateSubMenu) {
      subMenu.style.height = `${heightContenair}px`;
      subMenu.style.transform = "scaleY(1)";
      subMenu.style.transition = "300ms";
      for (let li of liSubMenu) {
        li.style.opacity = "1";
      }
      setTimeout(() => {
        for (let li of liSubMenu) {
          li.style.transform = "translateY(0px)";
          li.style.transition = "300ms";
        }
        for (let span of spanLi) {
          span.style.transform = "translateY(8px)";
          span.style.transition = "300ms";
        }
        icon.style.transform = "rotate(180deg)";
        icon.style.transition = "300ms";
      }, 500);
      setSubMenuState(true);
    }
  }
};

const EffectLiSubMenuCloseFunction = (
  subMenuId,
  iconOneInstallationClassName,
  liSubMenuClassName,
  spanLiClassName,
  stateSubMenu,
  setSubMenuState
) => {
  const subMenu = document.querySelector(`#${subMenuId}`);
  const icon = document.querySelector(`#${iconOneInstallationClassName}`);
  const liSubMenu = document.querySelectorAll(`.${liSubMenuClassName}`);
  const spanLi = document.querySelectorAll(`.${spanLiClassName}`);

  if (stateSubMenu) {
    for (let li of liSubMenu) {
      li.style.transform = "translateY(8px)";
      li.style.transition = "300ms";
    }
    for (let span of spanLi) {
      span.style.transform = "translateY(0px)";
      span.style.transition = "300ms";
    }
    for (let span of spanLi) {
      span.style.transform = "translateY(0px)";
      span.style.transition = "300ms";
    }
    setTimeout(() => {
      for (let li of liSubMenu) {
        li.style.opacity = "0";
        li.style.transition = "50ms";
      }
      subMenu.style.height = "0px";
      subMenu.style.transform = "scaleY(0)";
      subMenu.style.transition = "300ms";
      icon.style.transform = "rotate(0deg)";
      icon.style.transition = "300ms";
    }, 500);
    setSubMenuState(false);
  }
};

export { EffectLiSubMenuDisplayFunction, EffectLiSubMenuCloseFunction };
