import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  EffectGlowButtonFunction,
  NotEffectGlowButtonFunction,
  HoverButtonFunction,
} from "../../functions/EffectGlowButtonsFunction";
import { ButtonEffectScaleClickFunction } from "../../functions/ButtonClickEffectScale";
import { NotEffectArticleFunction } from "../../functions/EffectArticleFunction";
import { PageTransitionContext } from "../../contexts/PageTransition";
import { devices } from "../../style/Size";

const Button = styled.button.attrs({
  type: "button",
  value: "En savoir plus",
  className: "buttonArticleTopHome",
})`
  width: 24%;
  /* min-width: 170px; */
  height: 40px;
  background-color: #556699;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  font-weight: 100;
  color: #ffffff;
  cursor: pointer;
  position: absolute;
  bottom: 18px;
  right: 3%;
  opacity: 0;
  transform: translateY(24%);

  @media ${devices.laptopXXS} {
    bottom: 14px;
  }
  @media ${devices.tablet} {
    width: 40%;
    right: 30%;
  }
  @media ${devices.mobileL} {
    width: 52%;
    right: 24%;
    bottom: 12px;
  }
  @media ${devices.mobileS} {
    width: 60%;
    right: 20%;
  }
  @media (max-height: 480px) {
    width: 50%;
    right: 25%;
    bottom: 12px;
  }
`;

export default function ButtonArticleTopHome(props) {
  const { displayPageTransition } = useContext(PageTransitionContext);
  const navigate = useNavigate();

  const navigatePage = (
    route,
    element1,
    element2,
    element3,
    element4,
    element5
  ) => {
    const blockArticle = document.querySelector(`.${element1}`);
    const contenairArticle = document.querySelector(`#${element2}`);
    const PTitleArticle = document.querySelector(`#${element3}`);
    const PArticle = document.querySelector(`#${element4}`);
    const button = document.querySelector(`#${element5}`);

    NotEffectGlowButtonFunction("buttonArticleTopHome");
    ButtonEffectScaleClickFunction(button);
    button.style.pointerEvents = "none";
    button.style.cursor = "auto";

    if (window.innerWidth > 991) {
      setTimeout(() => {
        if (blockArticle) {
          NotEffectArticleFunction(
            blockArticle,
            contenairArticle,
            PTitleArticle,
            PArticle,
            button
          );
        }
      }, 500);
      setTimeout(() => {
        displayPageTransition("pageTransitionEffect");
      }, 1200);
      setTimeout(() => {
        displayPageTransition("pageTransitionNotEffect");
        window.scrollTo(0, 0);
        navigate(`${route}`);
      }, 2000);
    }
    if (window.innerWidth <= 991) {
      setTimeout(() => {
        if (blockArticle) {
          NotEffectArticleFunction(
            blockArticle,
            contenairArticle,
            PTitleArticle,
            PArticle,
            button
          );
        }
      }, 500);
      setTimeout(() => {
        displayPageTransition("pageTransitionEffect");
      }, 1700);
      setTimeout(() => {
        displayPageTransition("pageTransitionNotEffect");
        window.scrollTo(0, 0);
        navigate(`${route}`);
      }, 2500);
    }
  };

  // const navigatePageMiniDevice = (
  //   route,
  //   element1,
  //   element2,
  //   element3,
  //   element4,
  //   element5
  // ) => {
  //   const blockArticle = document.querySelector(`.${element1}`);
  //   const contenairArticle = document.querySelector(`#${element2}`);
  //   const PTitleArticle = document.querySelector(`#${element3}`);
  //   const PArticle = document.querySelector(`#${element4}`);
  //   const button = document.querySelector(`#${element5}`);

  //   ButtonEffectScaleClickFunction(button);
  //   // NotEffectGlowButtonFunction("buttonArticleTopHome");
  //   button.style.pointerEvents = "none";
  //   button.style.cursor = "auto";

  //   setTimeout(() => {
  //     if (blockArticle) {
  //       NotEffectArticleFunction(
  //         blockArticle,
  //         contenairArticle,
  //         PTitleArticle,
  //         PArticle,
  //         button
  //       );
  //     }
  //   }, 500);
  //   setTimeout(() => {
  //     displayPageTransition("pageTransitionEffect");
  //   }, 1000);
  //   setTimeout(() => {
  //     displayPageTransition("pageTransitionNotEffect");
  //     window.scrollTo(0, 0);
  //     navigate(`${route}`);
  //   }, 1400);
  // };

  return (
    <Button
      id={props.idButton}
      onMouseMove={(e) => EffectGlowButtonFunction(e, "buttonArticleTopHome")}
      onMouseLeave={() => NotEffectGlowButtonFunction("buttonArticleTopHome")}
      onMouseEnter={(e) => HoverButtonFunction(e, "buttonArticleTopHome")}
      onClick={() =>
        navigatePage(
          `${props.element1}`,
          `${props.element2}`,
          `${props.element3}`,
          `${props.element4}`,
          `${props.element5}`,
          `${props.element6}`
        )
      }
      // onTouchStart={() =>
      //   navigatePageMiniDevice(
      //     `${props.element1}`,
      //     `${props.element2}`,
      //     `${props.element3}`,
      //     `${props.element4}`,
      //     `${props.element5}`,
      //     `${props.element6}`
      //   )
      // }
    >
      EN SAVOIR PLUS
    </Button>
  );
}
