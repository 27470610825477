import React from "react";
import styled from "styled-components";
import { devices } from "../../style/Size";

const Column1 = styled.div`
  width: 18%;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 50px;
  @media ${devices.laptopXXS} {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
  }
  @media ${devices.mobileL} {
    flex-direction: column;
    width: 200px;
  }
  @media ${devices.mobileS} {
    width: 94%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ColumnTitle = styled.p`
  font-size: 20px;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  @media ${devices.laptopL} {
    font-size: 18px;
  }
  @media ${devices.laptopS} {
    font-size: 16px;
  }
  @media ${devices.laptopXXS} {
    width: 200px;
  }
  @media ${devices.mobileS} {
    width: 100%;
  }
`;

const ContenairAllElementsTop = styled.div`
  @media ${devices.mobileL} {
    flex-direction: column;
  }
`;

const OpenTitle = styled.p`
  width: 100%;
  font-size: 16px;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  margin: 0;
  padding-bottom: 10px;
  text-align: center;
  white-space: nowrap;
  &::after {
    display: block;
    content: "";
    width: 20%;
    height: 2px;
    background-color: #ffffff;
    margin-left: auto;
    margin-right: auto;
  }

  @media ${devices.laptopL} {
    font-size: 14px;
  }
  @media ${devices.laptopS} {
    font-size: 12.8px;
  }
`;

const ContenairDivsParagraphs = styled.div`
  display: flex;
  width: 100%;
`;

const DivParagraphs = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const ParagraphDays = styled.p`
  width: 106%;
  font-size: 14px;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  padding: 4px;
  margin: 0;
  margin-left: -20px;
  text-align: end;
  white-space: nowrap;
  @media ${devices.laptopL} {
    font-size: 12.8px;
  }
  @media ${devices.laptopS} {
    font-size: 12px;
  }
`;

const ParagraphHours = styled.p`
  width: 90%;
  font-size: 14px;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  padding: 4px;
  margin: 0;
  margin-left: 2px;
  white-space: nowrap;
  @media ${devices.laptopL} {
    font-size: 12.8px;
  }
  @media ${devices.laptopS} {
    font-size: 12px;
  }
`;

const ParagraphLieu = styled.p`
  font-size: 16px;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  margin: 0;
  margin-top: 20px;
  text-align: center;
  @media ${devices.laptopL} {
    font-size: 14px;
  }
  @media ${devices.laptopS} {
    font-size: 12.8px;
  }
  @media ${devices.laptopXXS} {
    margin-left: 38px;
    margin-top: 54px;
  }
  @media ${devices.mobileL} {
    margin-left: 0px;
    margin-top: 20px;
  }
`;

export default function ColumnOne() {
  return (
    <Column1>
      <ContenairAllElementsTop>
        <ColumnTitle>INFOS</ColumnTitle>
        <OpenTitle>Ouverture</OpenTitle>
        <ContenairDivsParagraphs>
          <DivParagraphs>
            <ParagraphDays>Lundi :</ParagraphDays>
            <ParagraphDays>Mardi :</ParagraphDays>
            <ParagraphDays>Mercredi :</ParagraphDays>
            <ParagraphDays>Jeudi :</ParagraphDays>
            <ParagraphDays>Vendredi :</ParagraphDays>
          </DivParagraphs>
          <DivParagraphs>
            <ParagraphHours>8h - 18h</ParagraphHours>
            <ParagraphHours>8h - 18h</ParagraphHours>
            <ParagraphHours>8h - 18h</ParagraphHours>
            <ParagraphHours>8h - 18h</ParagraphHours>
            <ParagraphHours>8h - 18h</ParagraphHours>
          </DivParagraphs>
        </ContenairDivsParagraphs>
      </ContenairAllElementsTop>
      <ParagraphLieu>
        <strong style={{ fontWeight: "100" }}>Marseille</strong>
        <br />
        <strong style={{ fontWeight: "100" }}>Allauch</strong>
        <br />
        <strong style={{ fontWeight: "100" }}>Plan-de-Cuques</strong>
        <br />
        <strong style={{ fontWeight: "100" }}>La Bouilladisse</strong>
        <br />
        <strong style={{ fontWeight: "100" }}>Cuges-les-Pins</strong>
        <br />
        <strong style={{ fontWeight: "100" }}>Peynier</strong>
        <br />
        <strong style={{ fontWeight: "100" }}>Aubagne</strong>
        <br />
        <strong style={{ fontWeight: "100" }}>Marignane</strong>
        <br />
        <strong style={{ fontWeight: "100" }}>Aix-en-Provence</strong>
        <br />
        <strong style={{ fontWeight: "100" }}>Cassis</strong>
        <br />
        <strong style={{ fontWeight: "100" }}>La Ciotat</strong>
      </ParagraphLieu>
    </Column1>
  );
}
