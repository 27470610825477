import { createContext, useState } from "react";

export const PageTransitionContext = createContext();

export function PageTransitionContextProvider(props) {
  const [pageTransition, setPageTransition] = useState({
    pageDisplay: false,
    pageNotDisplay: true,
  });

  const displayPageTransition = (element) => {
    if (element === "pageTransitionEffect") {
      setPageTransition({
        pageDisplay: true,
        pageNotDisplay: false,
      });
    }
    if (element === "pageTransitionNotEffect") {
      setPageTransition({
        pageDisplay: false,
        pageNotDisplay: true,
      });
    }
  };

  return (
    <PageTransitionContext.Provider
      value={{ pageTransition, displayPageTransition }}
    >
      {props.children}
    </PageTransitionContext.Provider>
  );
}
