import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { PageTransitionContextProvider } from './contexts/PageTransition';
import { ModalFormContextProvider } from './contexts/ModalForm';
import { TrashContextProvider } from './contexts/TrashContext';
import { NavBarMiniContextProvider } from './contexts/NavBarMiniContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <PageTransitionContextProvider>
            <ModalFormContextProvider>
                <TrashContextProvider>
                    <NavBarMiniContextProvider>
                        <App />
                    </NavBarMiniContextProvider>
                </TrashContextProvider>
            </ModalFormContextProvider>
        </PageTransitionContextProvider>
    </BrowserRouter>
);

