import React from "react";
import styled from "styled-components";
import ButtonWaterHeater from "../Buttons/ButtonWaterHeater";
import { devices } from "../../style/Size";

const ContenairBlockElement = styled.div.attrs({
  className: "contenairBlockElements",
})`
  width: 96%;
  max-width: 1740px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 140px;
  position: relative;

  @media ${devices.laptopXXS} {
    padding-bottom: 0px;
  }
`;

const BlockElement = styled.div`
  height: 370px;
  position: relative;

  @media ${devices.laptopXXS} {
    padding-bottom: 80px;
  }
  @media ${devices.tablet} {
    padding-bottom: 140px;
  }
  @media (max-width: 734px) {
    padding-bottom: 160px;
  }
  @media (max-width: 694px) {
    height: 100%;
    padding-bottom: 70px;
  }
  @media ${devices.mobileL} {
    padding-bottom: 50px;
  }
`;

const BlockTitle = styled.h2.attrs({
  id: "blockTitleWaterHeaver",
  className: "blockTitleWaterHeaver",
})`
  font-size: 30px;
  color: #667788;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  padding: 0;
  margin: 0;
  transform: translateX(-1vw);

  @media ${devices.laptopXXS} {
    transform: translateX(0vw);
  }
  @media ${devices.tablet} {
    font-size: 24px;
  }
  @media ${devices.mobileS} {
    font-size: 22px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  height: 270px;
  color: #667788;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  text-align: justify;
  padding-bottom: 40px;
  transform: translateX(-1vw);

  @media ${devices.laptopXXS} {
    transform: translateX(0vw);
  }
  @media (max-width: 947px) {
    height: 295px;
  }
  @media (max-width: 837px) {
    height: 315px;
  }
  @media (max-width: 750px) {
    height: 335px;
  }
  @media (max-width: 734px) {
    height: 355px;
  }
  @media (max-width: 694px) {
    height: 100%;
    padding-bottom: 20px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

const Image = styled.img`
  width: 34%;
  height: 370px;
  object-fit: cover;
  float: right;
  margin-left: 20px;
  margin-top: 5px;
  transform: translateX(1vw);

  @media ${devices.laptopXXS} {
    margin-bottom: 1px;
    margin-left: 10px;
    height: 240px;
  }
  @media (max-width: 972px) {
    height: 260px;
  }
  @media (max-width: 947px) {
    height: 295px;
  }
  @media (max-width: 837px) {
    height: 315px;
  }
  @media (max-width: 750px) {
    height: 335px;
  }
  @media (max-width: 734px) {
    height: 355px;
  }
  @media (max-width: 694px) {
    width: 100%;
    margin-bottom: 10px;
    transform: translateX(0vw);
  }
`;

export default function BlockWaterHeater(props) {
  return (
    <ContenairBlockElement
      id={props.idBlockElement}
      style={{ opacity: props.opacity }}
    >
      <BlockTitle>{props.title}</BlockTitle>
      <BlockElement id={props.idBlockTextAndButton}>
        <Image
          className={props.imageClassname}
          src={props.src}
          alt={props.alt}
        />
        <Text id={props.blockTextWaterHeater}>{props.text}</Text>
        <ButtonWaterHeater
          idButton={props.idButton}
          onClick={props.onClick}
          onTouchStart={props.onTouchStart}
        />
      </BlockElement>
    </ContenairBlockElement>
  );
}
