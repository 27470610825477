import React from "react";
import styled from "styled-components";
import { devices } from "../../style/Size";

const Column2 = styled.div`
  width: 18%;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 50px;
  @media ${devices.laptopS} {
    margin-left: 10px;
  }
  @media ${devices.laptopXXS} {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  @media ${devices.mobileL} {
    width: 200px;
    flex-direction: column;
    text-align: center;
  }
  @media ${devices.mobileS} {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const ColumnTitle = styled.p`
  font-size: 20px;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  @media ${devices.laptopL} {
    font-size: 18px;
  }
  @media ${devices.laptopS} {
    font-size: 16px;
  }
  @media ${devices.laptopXXS} {
    width: 200px;
  }
  @media ${devices.mobileS} {
    width: 100%;
  }
`;

const ContenairAllElementsTop = styled.div`
  @media ${devices.laptopXXS} {
    text-align: center;
  }
  @media (min-width: 1474px) {
    text-align: center;
  }
`;

const ContenairAllElementsMiddle = styled.div`
  @media ${devices.laptopXXS} {
    text-align: center;
  }
  @media (min-width: 1474px) {
    text-align: center;
  }
`;

const ContenairAllElementsLast = styled.div`
  @media ${devices.laptopXXS} {
    display: flex;
    margin-top: 50px;
  }
  @media ${devices.tablet} {
    flex-direction: column;
  }
  @media ${devices.mobileL} {
    margin-top: 4px;
  }
  @media (min-width: 1474px) {
    text-align: center;
  }
`;

const ParagrahsContact = styled.p`
  font-size: 14px;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  letter-spacing: 1px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0;
  white-space: nowrap;
  @media ${devices.laptopL} {
    margin-left: 0px;
  }
  @media ${devices.laptopS} {
    font-size: 12.8px;
  }
  @media ${devices.laptopXXS} {
    width: 200px;
  }
  @media ${devices.mobileL} {
    width: 100%;
  }
  @media ${devices.mobileS} {
    width: 100%;
  }
`;

const ParagrahsNumberPhone = styled.p`
  font-size: 14px;
  font-family: "Russo One", sans-serif;
  color: #ffffff;
  letter-spacing: 1px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0;
  white-space: nowrap;

  @media ${devices.laptopS} {
    font-size: 12.8px;
  }
  @media ${devices.laptopXXS} {
    margin-left: 84px;
  }
  @media ${devices.tablet} {
    margin-left: 34px;
  }
  @media ${devices.mobileL} {
    font-size: 14px;
    margin-left: 0px;
  }
`;

const ParagrahsContactMail = styled.p`
  font-size: 18px;
  font-family: "Playfair", serif;
  color: #ffffff;
  letter-spacing: 1px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0;
  white-space: nowrap;

  @media ${devices.laptopL} {
    font-size: 14px;
  }
  @media ${devices.laptopXXS} {
    margin-left: 84px;
  }
  @media ${devices.tablet} {
    margin-left: 34px;
  }
  @media ${devices.mobileL} {
    font-size: 14px;
    margin-left: 0px;
  }
`;

export default function ColumnTwo() {
  return (
    <Column2>
      <ContenairAllElementsTop>
        <ColumnTitle>CONTACT</ColumnTitle>
        <ParagrahsContact>ENTREPRISE NAVARRO</ParagrahsContact>
        <ParagrahsContact>
          72, avenue Frédéric Mistral
          <br />
          13380 Plan-De-Cuques
        </ParagrahsContact>
      </ContenairAllElementsTop>
      <ContenairAllElementsLast>
        <ContenairAllElementsMiddle>
          <ParagrahsNumberPhone>04 26 85 06 82</ParagrahsNumberPhone>
          <ParagrahsNumberPhone>06 25 99 63 59</ParagrahsNumberPhone>
        </ContenairAllElementsMiddle>
        <ParagrahsContactMail>
          @entreprisenavarro@hotmail.fr
        </ParagrahsContactMail>
      </ContenairAllElementsLast>
    </Column2>
  );
}
