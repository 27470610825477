import React, { useState, useEffect } from "react";
import styled from "styled-components";
import NavBar from "../NavBar/NavBar";
import WC from "../../assets/Installation/wc.webp";
import Lavabo from "../../assets/Installation/m-lavabo.webp";
import BacDouche from "../../assets/Installation/m-douche-receveur.webp";
import Baignoire from "../../assets/Installation/m-baignoire.avif";
import CEE from "../../assets/Installation/m-chauffe-eau-electrique.webp";
import CEG from "../../assets/Installation/chauffe-eau-gaz.webp";
import PompeChaleur from "../../assets/Installation/m-pompe-a-chaleur.webp";
import Climatisation from "../../assets/Installation/m-climatisation.webp";
import { devices } from "../../style/Size";

const ContenairHeaderBlog = styled.div.attrs({
  id: "headerBlogContenair",
})`
  width: 100%;
  height: calc(100vh - 124px);
  background-color: #553322;
  position: relative;
  top: 62px;
  margin-bottom: 240px;

  @media ${devices.tablet} {
    height: 50vh;
    top: 32px;
    margin-bottom: 140px;
  }
`;

const ContenairTop = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: calc(50% - 4px);
  position: relative;
  top: 2px;
`;

const ContenairBottom = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: calc(50% - 4px);
  position: relative;
  top: 5px;
`;

const Picture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const BlockImage1 = styled.div`
  width: calc(15% - 4px);
  height: 100%;
`;

const BlockImage2 = styled.div`
  width: calc(25% - 4px);
  height: 100%;
`;

const BlockImage3 = styled.div`
  width: calc(25% - 4px);
  height: 100%;
`;

const BlockImage4 = styled.div`
  width: calc(35% - 4px);
  height: 100%;
`;

const BlockImage5 = styled.div`
  width: calc(21% - 4px);
  height: 100%;
`;

const BlockImage6 = styled.div`
  width: calc(21% - 4px);
  height: 100%;
`;

const BlockImage7 = styled.div`
  width: calc(26% - 4px);
  height: 100%;
`;

const BlockImage8 = styled.div`
  width: calc(32% - 4px);
  height: 100%;
`;

const HeaderContenair = styled.div.attrs({
  id: "headerBlogArticleContenair",
})`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #3c2114;
`;

const BlockImageHeader = styled.div.attrs({
  className: "contenairParallax",
})`
  width: 100%;
  height: 100%;
  position: relative;
`;

const ContenairImage = styled.div.attrs({
  className: "parallax",
})`
  width: 100%;
  height: 100%;
`;

const ContenairTitle = styled.div`
  width: 100%;
  height: 62px;
  background-color: #3c2114;
  position: absolute;
  bottom: -62px;

  @media ${devices.tablet} {
    bottom: -52px;
    height: 52px;
  }
`;

const Title = styled.h1`
  font-family: "Playfair", serif;
  font-size: 34px;
  color: #ffffff;
  text-align: center;
  padding: 0;
  margin: 0;
  position: relative;
  top: 8px;

  @media ${devices.tablet} {
    font-size: 32px;
    top: 6px;
  }
  @media ${devices.mobileL} {
    font-size: 30px;
  }
  @media ${devices.mobileM} {
    font-size: 28px;
  }
`;

export default function HeaderBlog() {
  const [sizeForImageHeader, setSizeForImageHeader] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 906) {
      setSizeForImageHeader(true);
    }
    if (window.innerWidth <= 906) {
      setSizeForImageHeader(false);
    }
  }, []);

  window.addEventListener("resize", () => {
    if (window.innerWidth > 906) {
      setSizeForImageHeader(true);
    }
    if (window.innerWidth <= 906) {
      setSizeForImageHeader(false);
    }
  });

  return (
    <header>
      <NavBar backgroundColor="#3c2114" />
      <ContenairHeaderBlog>
        {sizeForImageHeader ? (
          <>
            <ContenairTop>
              <BlockImage1>
                <Picture
                  src={WC}
                  alt="WC balnc classique dans sanitaire toilettes de couleur marron laquée"
                />
              </BlockImage1>
              <BlockImage2>
                <Picture
                  src={Lavabo}
                  alt="Lavabo blanc rectangulaire raffiné sur meuble de salle de bain dans pièce aux carrelages rectangulaires blancs"
                />
              </BlockImage2>
              <BlockImage3>
                <Picture
                  src={BacDouche}
                  alt="Douche vitrée avec receveur de douche rectangulaire blanc dans salle de bain raffinée"
                />
              </BlockImage3>
              <BlockImage4>
                <Picture
                  src={Baignoire}
                  alt="Baignoire rectangulaire blanche confortable aux parois couvrées par plaques en plâtres style béton"
                />
              </BlockImage4>
            </ContenairTop>
            <ContenairBottom>
              <BlockImage5>
                <Picture
                  src={CEE}
                  alt="Grand et long chauffe-eau électrique sol au design noir et blanc placé dans un placard de la cuisine"
                />
              </BlockImage5>
              <BlockImage6>
                <Picture
                  src={CEG}
                  alt="Chauffe-eau gaz mural rectangulaire et compact blanc dans salle de bain de couleur blanche"
                />
              </BlockImage6>
              <BlockImage7>
                <Picture
                  src={PompeChaleur}
                  alt="Pompe à chaleur sol, grande et longue, moderne noir et blanche, avec son groupe système dans un garage vaste et bien rangé"
                />
              </BlockImage7>
              <BlockImage8>
                <Picture
                  src={Climatisation}
                  alt="Climatisation murale blanche avec son groupe système"
                />
              </BlockImage8>
            </ContenairBottom>
          </>
        ) : (
          <HeaderContenair>
            <BlockImageHeader>
              <ContenairImage />
            </BlockImageHeader>
          </HeaderContenair>
        )}
        <ContenairTitle>
          <Title>@Blog</Title>
        </ContenairTitle>
      </ContenairHeaderBlog>
    </header>
  );
}
