const ParallaxFunction = (classNameParallax, classNameContenairParallax) => {
  const parallax = document.querySelector(`.${classNameParallax}`);
  const contenairParallax = document.querySelector(
    `.${classNameContenairParallax}`
  );
  if (parallax && contenairParallax) {
    parallax.style.backgroundPositionY = window.scrollY / 2 + "px";
    contenairParallax.style.height = 100 + "vh" - -window.scrollY / 2 + "px";
  }
};

export { ParallaxFunction };
