import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PageTransitionContext } from "../../../contexts/PageTransition";
import { devices } from "../../../style/Size";

const ContenairBlog = styled.div`
  color: #ffffff;
  font-size: 26px;
  font-family: "Playfair", serif;
  cursor: pointer;
  &::after {
    display: block;
    content: "";
    width: 100%;
    min-height: 2px;
    background-color: #ffffff;
    margin-top: 1px;
    transform: scaleX(0);
    transform-origin: left;
    transition: 200ms ease;
  }
  &:hover::after {
    transform: scaleX(1);
  }
  @media ${devices.laptopL} {
    font-size: 24px;
  }
  @media ${devices.laptopM} {
    font-size: 22px;
  }
`;

export default function Pblog() {
  const { displayPageTransition } = useContext(PageTransitionContext);
  const navigate = useNavigate();

  const navigateLink = (route) => {
    if (window.innerWidth > 991) {
      setTimeout(() => {
        displayPageTransition("pageTransitionEffect");
      }, 500);
      setTimeout(() => {
        displayPageTransition("pageTransitionNotEffect");
        window.scrollTo(0, 0);
        navigate(`${route}`);
      }, 1300);
    }
    if (window.innerWidth > 480 && window.innerWidth <= 991) {
      setTimeout(() => {
        displayPageTransition("pageTransitionEffect");
      }, 500);
      setTimeout(() => {
        displayPageTransition("pageTransitionNotEffect");
        window.scrollTo(0, 0);
        navigate(`${route}`);
      }, 1100);
    }
    if (window.innerWidth < 480) {
      setTimeout(() => {
        displayPageTransition("pageTransitionEffect");
      }, 500);
      setTimeout(() => {
        displayPageTransition("pageTransitionNotEffect");
        window.scrollTo(0, 0);
        navigate(`${route}`);
      }, 1000);
    }
  };

  return (
    <ContenairBlog
      onClick={() => navigateLink("/blog")}
      // onTouchStart={() => navigateLink("/blog")}
    >
      @blog
    </ContenairBlog>
  );
}
