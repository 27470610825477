import React from "react";
import styled from "styled-components";
import { devices } from "../../../style/Size";

const SubMenuUl = styled.ul`
  width: 100%;
  height: 0px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
  transform: scaleY(0);
  transform-origin: top;
`;

const LiSubMenu = styled.li`
  display: inline-block;
  width: 80%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  letter-spacing: 0.5px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  margin-left: 15%;
  cursor: pointer;
  position: relative;
  transform: translateY(8px);
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

const SpanLi = styled.span`
  display: block;
  content: "";
  width: 100%;
  height: 12px;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 22px;
`;

export default function ListeSubMenu(props) {
  return (
    <SubMenuUl id={props.idSubMenu}>
      <LiSubMenu
        className={props.LiSubMenuClassName}
        onClick={props.onClick}
        // onTouchStart={props.onTouchStart}
        id={props.idLiOne}
      >
        {props.textLiOne}
        <SpanLi className={props.spanLiSubMenuClassName}></SpanLi>
      </LiSubMenu>

      <LiSubMenu
        className={props.LiSubMenuClassName}
        onClick={props.onClick}
        // onTouchStart={props.onTouchStart}
        id={props.idLiTwo}
      >
        {props.textLiTwo}
        <SpanLi className={props.spanLiSubMenuClassName}></SpanLi>
      </LiSubMenu>

      <LiSubMenu
        className={props.LiSubMenuClassName}
        onClick={props.onClick}
        // onTouchStart={props.onTouchStart}
        id={props.idLiThree}
      >
        {props.textLiThree}
        <SpanLi className={props.spanLiSubMenuClassName}></SpanLi>
      </LiSubMenu>
      <LiSubMenu
        style={{ display: `${props.display}` }}
        className={props.LiSubMenuClassName}
        id={props.idLiFour}
        onClick={props.onClick}
        // onTouchStart={props.onTouchStart}
      >
        {props.textLiFour}
        <SpanLi className={props.spanLiSubMenuClassName}></SpanLi>
      </LiSubMenu>
    </SubMenuUl>
  );
}
