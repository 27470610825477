import React from "react";
import styled from "styled-components";
import ColumnOne from "./Footer/ColumnOne";
import ColumnTwo from "./Footer/ColumnTwo";
import ColumnThree from "./Footer/ColumnThree";
import ColumnFour from "./Footer/ColumnFour";
import ColumnFive from "./Footer/ColumnFive";
import SectionServices from "./Footer/SectionServices";
import Copyright from "./Footer/Copyright";
import { devices } from "../style/Size";

const ContenairFooter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 100px;

  @media ${devices.laptopXXS} {
    flex-direction: column;
    padding-bottom: 40px;
  }
`;

export default function Footer(props) {
  return (
    <footer>
      <ContenairFooter
        style={{
          backgroundColor: props.backgroundColor,
        }}
      >
        <ColumnOne />
        <ColumnTwo />
        <ColumnThree />
        <ColumnFour />
        <ColumnFive />
      </ContenairFooter>
      <SectionServices backgroundColor={props.backgroundColor} />
      <Copyright backgroundColor={props.backgroundColor} />
    </footer>
  );
}
