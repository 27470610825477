import React from "react";
import styled from "styled-components";
import {
  EffectGlowButtonFunction,
  NotEffectGlowButtonFunction,
} from "../../functions/EffectGlowButtonsFunction";

const ContenairBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const BlockMobile = styled.div.attrs({
  className: "blockMobile",
})`
  width: calc(60% - 4px);
  height: 40px;
  border: 2px solid #556699;
  background-color: #556699;
  border-radius: 8px;
  margin-top: -10px;
  text-align: center;

  @media (max-width: 768px) {
    margin-top: 6px;
  }
  @media (max-width: 490px) {
    margin-top: 0px;
    height: 36px;
    width: calc(52% - 4px);
  }
  @media (max-width: 427px) {
    margin-top: -8px;
  }
  @media (max-width: 404px) {
    height: 32px;
    margin-top: 0px;
    width: calc(56% - 4px);
  }
  @media (max-width: 346px) {
    height: 32px;
    margin-top: 0px;
    width: calc(70% - 4px);
  }
  @media (max-width: 290px) {
    width: calc(90% - 4px);
  }
`;

const PLogo = styled.p`
  font-size: 20px;
  color: #ffffff;
  font-family: "Russo One", sans-serif;
  margin-top: 8px;

  @media (max-width: 427px) {
    font-size: 18px;
  }
  @media (max-width: 404px) {
    margin-top: 6px;
  }
`;

export default function BlockNumberPhone() {
  const effectHover = () => {
    const blockMobile = document.querySelector(".blockMobile");
    blockMobile.style.transform = "scale(1.075)";
    blockMobile.style.transition = "150ms";
  };

  return (
    <ContenairBlock>
      <BlockMobile
        onMouseMove={(e) => EffectGlowButtonFunction(e, "blockMobile")}
        onMouseLeave={(e) => NotEffectGlowButtonFunction("blockMobile")}
        onMouseEnter={effectHover}
      >
        <a style={{ textDecoration: "none" }} href="tel:+33625996359">
          <PLogo>
            <i
              style={{ color: "#FFFFFF", marginRight: "8px" }}
              className="fa-solid fa-mobile-screen-button"
            ></i>
            06 25 99 63 59
          </PLogo>
        </a>
      </BlockMobile>
    </ContenairBlock>
  );
}
