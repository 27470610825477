import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { OffsetTopFunction } from "../functions/OffsetTopFunction";
import { devices } from "../style/Size";

const Contenair = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  padding-top: 40px;
  padding-bottom: 80px;
  @media ${devices.laptopXXS} {
    width: 90%;
    padding-bottom: 60px;
  }
  @media ${devices.tablet} {
    padding-bottom: 20px;
  }
  @media ${devices.mobileL} {
    padding-top: 20px;
  }
  @media (max-height: 480px) {
    padding-bottom: 20px;
    padding-top: 0px;
  }
`;

const Title = styled.h2.attrs({
  id: "titlePresentation",
})`
  font-size: 30px;
  font-family: "Russo One", sans-serif;
  color: #3c2114;
  white-space: nowrap;
  opacity: 0;
  transform: translateX(-30%);

  @media ${devices.tablet} {
    font-size: 24px;
  }
  @media ${devices.mobileL} {
    font-size: 22px;
  }
`;

const Text = styled.p.attrs({
  id: "textPresentation",
})`
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  color: #3c2114;
  text-align: justify;
  opacity: 0;
  transform: translateX(70%);

  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

export default function PresentationEntreprise() {
  useEffect(() => {
    const titlePresentation = document.querySelector("#titlePresentation");
    const textPresentation = document.querySelector("#textPresentation");

    if (window.innerWidth <= 375) {
      titlePresentation.style.opacity = "1";
      titlePresentation.style.transform = "translateX(0px)";
      titlePresentation.style.transition = "1000ms";
      textPresentation.style.opacity = "1";
      textPresentation.style.transform = "translateX(0px)";
      textPresentation.style.transition = "1000ms";
    }
  }, []);

  window.addEventListener("scroll", () => {
    const titlePresentation = document.querySelector("#titlePresentation");
    const textPresentation = document.querySelector("#textPresentation");

    const screenY = window.scrollY + window.innerHeight;
    if (titlePresentation) {
      if (
        OffsetTopFunction(titlePresentation) +
          titlePresentation.clientHeight * 4 -
          screenY <
        0
      ) {
        titlePresentation.style.opacity = "1";
        titlePresentation.style.transform = "translateX(0px)";
        titlePresentation.style.transition = "1000ms";
        textPresentation.style.opacity = "1";
        textPresentation.style.transform = "translateX(0px)";
        textPresentation.style.transition = "1000ms";
      }
    }
  });

  return (
    <Contenair>
      <Title>Qui sommes-nous ?</Title>
      <Text>
        <strong style={{ fontWeight: "100" }}>
          Entreprise de plomberie située à Marseille
        </strong>{" "}
        et créée en 2006, elle est la consécration d'un métier qui m'a été
        transmis par mon père,{" "}
        <strong style={{ fontWeight: "100" }}>
          artisan plombier-chauffagiste
        </strong>{" "}
        durant toute sa carrière professionnelle.
        <br />
        <br />
        Tout jeune déjà, épris de curiosité pour ce métier de
        <strong style={{ fontWeight: "100" }}>
          {" "}
          plombier-chauffagiste
        </strong>{" "}
        qui l'animait tant, heureux je l'accompagnais, lors des vacances
        scolaires, des week-ends, de beaucoup de mes temps libres, dans ses{" "}
        <strong style={{ fontWeight: "100" }}>
          dépannages et installations de chauffe-eau
        </strong>
        , de <strong style={{ fontWeight: "100" }}>toilettes</strong>, de{" "}
        <strong style={{ fontWeight: "100" }}>lavabos</strong>, de{" "}
        <strong style={{ fontWeight: "100" }}>bacs à douche</strong>, de{" "}
        <strong style={{ fontWeight: "100" }}>baignoires</strong>, le regardant
        avec admiration être dans une concentration intensive afin de réaliser
        avec attention, rigueur et minutie, ses travaux pour lesquels il gagnait
        fierté à honorer. Nul plus normal me fut alors, me voilà âgé de tout
        juste 15 ans, d'entamer à ses côtés un pré-apprentissage en tant que
        <strong style={{ fontWeight: "100" }}> plombier-chauffagiste</strong>.
        <br />
        <br />
        Trois ans plus tard, mon diplôme de{" "}
        <strong style={{ fontWeight: "100" }}>plombier-chauffagiste </strong>en
        poche, des expériences concrètes en main, est ainsi né le plus
        naturellement du monde, un partenariat de travail père-fils. Je ne le
        remercierais jamais assez pour le rôle crucial et déterminant qu'il a
        joué dans la construction de ma vie. Plus que de m'apprendre les
        moindres ficelles (ou
        <strong style={{ fontWeight: "100" }}> filasse</strong> 🤓) du métier,
        il me transmit des valeurs humaines essentielles, comme le
        perfectionnisme du travail, la volonté, l'honnêteté, l’empathie. «
        Toujours se mettre à la place des gens » lui arrive-t-il encore de me
        dire parfois, bien que nous savons tous deux que c'est une sagesse que
        j'applique au quotidien.
        <br />
        <br />
        Pour cette raison-même que la philosophie de l'
        <strong style={{ fontWeight: "100" }}>Entreprise Navarro</strong> est
        d'appliquer un travail honnête pour une tarification honnête. Tous nos
        <strong style={{ fontWeight: "100" }}> plombiers</strong> ont la même
        façon de penser. Des
        <strong style={{ fontWeight: "100" }}> plombiers honnêtes</strong>, des
        <strong style={{ fontWeight: "100" }}> plombiers</strong> compréhensifs,
        des <strong style={{ fontWeight: "100" }}>plombiers</strong> cordiaux,
        des <strong style={{ fontWeight: "100" }}>plombiers </strong>
        respectueux de leur métier et des gens. Nous nous efforçons d'intervenir
        au plus vite, de conforter les attentes de nos clients, ce pour le plus
        grand nombre. Pour cela que nous desservons tous les arrondissements de
        <strong style={{ fontWeight: "100" }}> Marseille</strong>, et les
        secteurs comme <strong style={{ fontWeight: "100" }}>Allauch</strong>,
        <strong style={{ fontWeight: "100" }}> Plan-de-Cuques</strong>,
        <strong style={{ fontWeight: "100" }}> La Bouilladisse</strong>,
        <strong style={{ fontWeight: "100" }}> Cuges-les-Pins</strong>,
        <strong style={{ fontWeight: "100" }}> Peynier</strong>,
        <strong style={{ fontWeight: "100" }}> Aubagne</strong>,
        <strong style={{ fontWeight: "100" }}> Aix-en-Provence</strong>,
        <strong style={{ fontWeight: "100" }}> Marignane</strong>,
        <strong style={{ fontWeight: "100" }}> Cassis</strong>,
        <strong style={{ fontWeight: "100" }}> La Ciotat</strong>.
        <br />
        <br />
        Autant de savoirs-être que je m'évertue à transmettre, certes, à mes
        propres enfants, mais aussi à mes proches, comme aux apprentis que
        j'apprécie à former à ce métier de la même manière que mon père a su le
        faire pour moi.
        <br />
        <br />
        <br />
        Christophe Navarro
        <br />
        <br />
        N'hésitez pas à consulter nos avis clients en cliquant
        <Link
          style={{ color: "#3c2114" }}
          to="https://www.google.fr/maps/place/Plomberie+Christophe+NAVARRO+-+Plombier+Chauffagiste+Climatisation+13/@43.5407595,5.0218235,9z/data=!4m8!3m7!1s0x12c9be67f5cefd5b:0x182609bba483a763!8m2!3d43.5407595!4d5.0218235!9m1!1b1!16s%2Fg%2F11b6d6m9pj?entry=ttu"
          target="blanck"
        >
          {" "}
          ici{" "}
        </Link>
        ! 🙂
      </Text>
    </Contenair>
  );
}
