import { OffsetTopFunction } from "./OffsetTopFunction";

const EffectGlowButtonFunction = (e, button) => {
  const buttonArticle = document.querySelectorAll(`.${button}`);
  for (let button of buttonArticle) {
    let elRect = button.getBoundingClientRect();
    let x = e.clientX - elRect.x;
    let y = e.clientY - elRect.y;

    let glowX = (x / elRect.width) * 100;
    let glowY = (y / elRect.height) * 100;

    if (e.target.id === button.id && window.location.pathname !== "/blog") {
      button.style.background = `radial-gradient(circle at ${glowX}% ${glowY}%, #9ca2b5, #556699)`;
    } else if (
      e.target.id === button.id &&
      window.location.pathname === "/blog"
    ) {
      button.style.background = `radial-gradient(circle at ${glowX}% ${glowY}%, #FFFFFF, #FFFFFF)`;
    }
  }
};

const NotEffectGlowButtonFunction = (button) => {
  const buttonArticle = document.querySelectorAll(`.${button}`);

  for (let button of buttonArticle) {
    if (window.location.pathname !== "/blog") {
      button.style.background = "linear-gradient(#556699, #556699)";
      button.style.transform = `scale(1)`;
      button.style.transition = `150ms`;
    } else if (window.location.pathname === "/blog") {
      button.style.background = "linear-gradient(#FFFFFF, #FFFFFF)";
      button.style.transform = `scale(1)`;
      button.style.transition = `150ms`;
    }
  }
};

const NotEffectGlowButtonQuoteFunction = (button) => {
  const buttonArticle = document.querySelectorAll(`.${button}`);

  for (let button of buttonArticle) {
    if (window.location.pathname !== "/blog") {
      button.style.background = "linear-gradient(#556699, #556699)";
      button.style.transform = `scale(1)`;
      button.style.transition = `150ms`;
    } else if (window.location.pathname === "/blog") {
      button.style.background = "linear-gradient(#FFFFFF, #FFFFFF)";
      button.style.transform = `scale(1)`;
      button.style.transition = `150ms`;
    }
  }
};

const NotEffectGlowButtonWaterHeaterFunction = (e, button) => {
  const buttonArticle = document.querySelectorAll(`.${button}`);
  const screenY = window.scrollY + window.innerHeight;
  for (let button of buttonArticle) {
    if (
      OffsetTopFunction(button.parentElement.parentElement) - screenY < -370 &&
      e.target.id === button.id
    ) {
      button.style.background = "linear-gradient(#556699, #556699)";
      button.style.transform = `scale(1) translateX(0vw)`;
      button.style.transition = `300ms`;
    }
    if (
      OffsetTopFunction(button.parentElement.parentElement) - screenY > -370 &&
      e.target.id === button.id
    ) {
      button.style.background = "linear-gradient(#556699, #556699)";
      button.style.transform = `scale(1) translateX(-1vw)`;
      button.style.transition = `300ms`;
    }
  }
};

const HoverButtonFunction = (e, button) => {
  const buttonArticle = document.querySelectorAll(`.${button}`);
  for (let button of buttonArticle) {
    if (e.target.id === button.id) {
      button.style.transform = `scale(1.05)`;
      button.style.transition = `300ms`;
    }
  }
};
const HoverButtonBlogFunction = (e, button) => {
  const buttonArticle = document.querySelectorAll(`.${button}`);
  for (let button of buttonArticle) {
    if (e.target.id === button.id) {
      button.style.transform = `scale(1.02)`;
      button.style.transition = `300ms`;
    }
  }
};

const HoverButtonQuoteFunction = (e, button) => {
  const buttonArticle = document.querySelectorAll(`.${button}`);
  for (let button of buttonArticle) {
    if (e.target.id === button.id) {
      button.style.transform = `scale(1.05)`;
      button.style.transition = `300ms`;
    }
  }
};

const HoverButtonWaterHeaterFunction = (e, button) => {
  const buttonArticle = document.querySelectorAll(`.${button}`);
  const screenY = window.scrollY + window.innerHeight;
  for (let button of buttonArticle) {
    if (
      OffsetTopFunction(button.parentElement.parentElement) - screenY < -370 &&
      e.target.id === button.id
    ) {
      button.style.transform = `scale(1.02) translateX(0vw)`;
      button.style.transition = `300ms`;
    }
    if (
      OffsetTopFunction(button.parentElement.parentElement) - screenY > -370 &&
      e.target.id === button.id
    ) {
      button.style.transform = `scale(1) translateX(-1vw)`;
      button.style.transition = `300ms`;
    }
  }
};

export {
  EffectGlowButtonFunction,
  NotEffectGlowButtonFunction,
  NotEffectGlowButtonWaterHeaterFunction,
  HoverButtonFunction,
  HoverButtonBlogFunction,
  HoverButtonWaterHeaterFunction,
  NotEffectGlowButtonQuoteFunction,
  HoverButtonQuoteFunction,
};
