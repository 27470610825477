import React, { useRef } from "react";
import styled from "styled-components";
import { devices } from "../../style/Size";

const PostalCode = styled.input`
  width: 96%;
  padding-top: 12px;
  padding-bottom: 12px;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  border: 2px solid #667788;
  box-sizing: border-box;
  background-color: #dfe3e7;
  border-radius: 2px;
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  font-weight: 100;
  color: #000000;
  outline: none;

  @media ${devices.laptopXXS} {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 40px;
    margin-top: 6px;
  }
  @media (max-width: 702px) {
    width: 92%;
    margin-left: 4%;
    margin-top: 4px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

export default function InputPostalCode({
  setElementFormFive,
  setPostalCodeValue,
}) {
  const refPostalCode = useRef();

  const onKeyUpForm = (e) => {
    if (e.target.id === "postalCode") {
      if (refPostalCode.current.value.length > 5) {
        setElementFormFive(
          "Votre code postal ne peut contenir plus de cinq chiffres"
        );
        refPostalCode.current.style.border = "2px solid red";
        setPostalCodeValue(false);
      }
      if (refPostalCode.current.value.length < 5) {
        setPostalCodeValue(false);
      }
      for (let letter of refPostalCode.current.value) {
        if (!letter.match("^[0-9]*$")) {
          setElementFormFive(
            "Votre code postal ne peut contenir que des chiffres"
          );
          refPostalCode.current.style.border = "2px solid red";
          setPostalCodeValue(false);
        }
        if (
          refPostalCode.current.value.length < 5 &&
          letter.match("^[0-9]*$")
        ) {
          setElementFormFive(false);
          refPostalCode.current.style.border = "2px solid #667788";
          setPostalCodeValue(false);
        }
        if (
          refPostalCode.current.value.length === 5 &&
          letter.match("^[0-9]*$")
        ) {
          setElementFormFive(false);
          refPostalCode.current.style.border = "2px solid #667788";
          setPostalCodeValue(refPostalCode.current.value);
        }
      }
    }
  };

  const onKeyDownForm = (e) => {
    if (e.target.id === "postalCode") {
      if (refPostalCode.current.value.length > 5) {
        if (e.code !== "Backspace" && e.code !== "Delete") {
          e.preventDefault();
          setPostalCodeValue(false);
        }
      }
      for (let letter of refPostalCode.current.value) {
        if (
          !letter.match("^[0-9]*$") &&
          e.code !== "Backspace" &&
          e.code !== "Delete"
        ) {
          e.preventDefault();
          setPostalCodeValue(false);
        }
      }
    }
  };

  const onBlurForm = (e) => {
    if (e.target.id === "postalCode") {
      if (refPostalCode.current.value.length !== 5) {
        for (let letter of refPostalCode.current.value) {
          if (!letter.match("^[0-9]*$")) {
            setElementFormFive(
              "Votre code postal ne peut contenir que des chiffres"
            );
            refPostalCode.current.style.border = "2px solid red";
            setPostalCodeValue(false);
          } else if (letter.match("^[0-9]*$")) {
            setElementFormFive("Votre code postal doit contenir cinq chiffres");
            refPostalCode.current.style.border = "2px solid red";
            setPostalCodeValue(false);
          }
        }
      }
    }
  };

  return (
    <PostalCode
      aria-label="Votre code postal"
      ref={refPostalCode}
      id="postalCode"
      className="elementForm"
      placeholder="Code Postal"
      onKeyUp={(e) => onKeyUpForm(e)}
      onKeyDown={(e) => onKeyDownForm(e)}
      onBlur={(e) => onBlurForm(e)}
    ></PostalCode>
  );
}
