import React, { useEffect } from "react";
import styled from "styled-components";
import HeaderSubSection from "../components/Header/HeaderSubSection";
import BodySubSection from "../components/SubSection/BodySubSection";
import LastBlockSubSection from "../components/SubSection/LastBlockSubSection";
import ButtonQuote from "../components/Buttons/ButtonQuote";
import Footer from "../components/Footer";
import AirConditioningPicture from "../assets/AirConditioning/climatisation.webp";
import AirConditioningNormal from "../assets/AirConditioning/m-climatisation.webp";
import AirConditioningModerne from "../assets/AirConditioning/climatisation-moderne.webp";
import AirConditioningGainable from "../assets/AirConditioning/climatisation-gainable.webp";
import { NavBarDisplayFunction } from "../functions/NavBarDisplayScrollFunction";
import { EffectSubSectionFunction } from "../functions/EffectSubSectionFunction";
import { EffectTitlePageFunction } from "../functions/EffectTitlePageFunction";
import { devices } from "../style/Size";

const ContenairMain = styled.main`
  width: 100%;
  position: relative;
  margin-top: 70px;

  @media ${devices.laptopXXS} {
    margin-top: 110px;
  }
  @media ${devices.tablet} {
    margin-top: 40px;
  }
`;

export default function AirConditioning() {
  useEffect(() => {
    EffectTitlePageFunction("titleClimatisationContenair");
    setTimeout(() => {
      EffectTitlePageFunction("idBlockElement");
    }, 600);
  }, []);

  useEffect(() => {
    const blockNavAndMenu = document.querySelector("#blockNavAndMenu");
    blockNavAndMenu.style.pointerEvents = "none";
    setTimeout(() => {
      blockNavAndMenu.style.pointerEvents = "auto";
    }, 3000);
  }, []);

  window.addEventListener("load", () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener("scroll", () => {
    NavBarDisplayFunction("headerClimatisationContenair");
  });

  useEffect(() => {
    const titleSection = document.querySelector("#blockTitle");
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        window.addEventListener("scroll", () => {
          EffectSubSectionFunction("blockTitle", "/climatisation");
        });
      }
    });
    observer.observe(titleSection);
  }, []);

  window.addEventListener("resize", () => {
    const blockSection = document.querySelectorAll(`.imageSection`);
    if (blockSection) {
      EffectSubSectionFunction("blockTitle", "/climatisation");
      if (window.innerWidth <= 568) {
        for (let element of blockSection) {
          element.style.transform = "translateX(0vw)";
          element.style.transition = "all 300ms";
        }
      }
    }
  });

  return (
    <>
      <HeaderSubSection
        id="headerClimatisationContenair"
        idTitle="titleClimatisationContenair"
        src={AirConditioningPicture}
        alt="Climatisation murale blanche avec son groupe système"
        titlePage="Climatisation"
      />
      <ContenairMain>
        <BodySubSection
          idText="textSection"
          imageClassname="imageSection"
          idBlockElement="idBlockElement"
          opacity="0.5"
          src={AirConditioningNormal}
          alt="Climatisation murale blanche avec son groupe système"
          title="Les Entrées De Gamme"
          text={[
            <strong key="1" style={{ fontWeight: "100" }}>
              Climatisations split mural
            </strong>,
            ". Esthétique simple. Coloris blanc. Niveau sonore convenable. Consommation énergétique correcte. Utilisation réversible. Absence de connexion Wi-Fi. Possibilité de poses d'accessoires : ",
            <strong key="2" style={{ fontWeight: "100" }}>
              goulottes
            </strong>,
            ", ",
            <strong key="3" style={{ fontWeight: "100" }}>
              cache groupe extérieur
            </strong>,
            "... Plusieurs modèles sont disponibles. Idéal pour les petits budgets.",
            <br key="4" />,
            <br key="5" />,
            "Nos experts ",
            <strong key="6" style={{ fontWeight: "100" }}>
              climaticiens à Marseille
            </strong>,
            " se rendent sur place pour effectuer un ",
            <strong key="7" style={{ fontWeight: "100" }}>
              devis 100% gratuit et personnalisé
            </strong>,
            " afin d'estimer le ",
            <strong key="8" style={{ fontWeight: "100" }}>
              coût et temps d'installation climatisation
            </strong>,
            ". Le temps de pose est généralement aux environs de 4 heures.",
            <br key="9" />,
            <br key="10" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produit et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont Mitsubishi, Daikin, Toshiba et Panasonic.",
          ]}
        />
        <BodySubSection
          imageClassname="imageSection"
          src={AirConditioningModerne}
          alt="Climatisation murale moderne de couleur grise"
          title="Les Milieux Et Hauts De Gamme"
          text={[
            <strong key="1" style={{ fontWeight: "100" }}>
              Climatisations split mural ou console
            </strong>,
            ". Design bien étudié et travaillé. Plusieurs coloris et finitions. Niveau sonore considérablement réduit. Excellente performance énergétique. Utilisation réversible. Possibilité de connexion Wi-Fi. Possibilité de poses d'accessoires : ",
            <strong key="2" style={{ fontWeight: "100" }}>
              goulottes
            </strong>,
            ", ",
            <strong key="3" style={{ fontWeight: "100" }}>
              cache groupe extérieur
            </strong>,
            "... Plusieurs modèles sont disponibles.Parfait pour un investissement sur le long terme.",
            <br key="4" />,
            <br key="5" />,
            "Nos experts ",
            <strong key="6" style={{ fontWeight: "100" }}>
              climaticiens à Marseille
            </strong>,
            " se rendent sur place pour effectuer un ",
            <strong key="7" style={{ fontWeight: "100" }}>
              devis 100% gratuit et personnalisé
            </strong>,
            " afin d'estimer le ",
            <strong key="8" style={{ fontWeight: "100" }}>
              coût et temps d'installation climatisation
            </strong>,
            ". Le temps de pose est généralement aux environs de 4 heures.",
            <br key="9" />,
            <br key="10" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produit et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont Mitsubishi, Daikin, Toshiba et Panasonic.",
          ]}
        />
        <LastBlockSubSection
          imageClassname="imageSection"
          src={AirConditioningGainable}
          alt="Vue de haut d'un Système de gainables reliés dans plusieurs pièces avec leur bouche d'aération correspondantes"
          title={[
            <strong key="1" style={{ fontWeight: "500" }}>
              Climatisations Guainables
            </strong>,
          ]}
          text={[
            "Installation plafond avec grilles de soufflage. Parfaite discrétion. Une meilleure répartition de la fraîcheur ou de la chaleur dans toutes les pièces. Régulateur température airzone. Utilisation réversible. Possibilité de connexion Wi-Fi. Le must en matière de ",
            <strong key="1" style={{ fontWeight: "100" }}>
              climatisations
            </strong>,
            ".",
            <br key="2" />,
            <br key="3" />,
            "Nos experts ",
            <strong key="4" style={{ fontWeight: "100" }}>
              climaticiens à Marseille
            </strong>,
            " se rendent sur place pour effectuer un ",
            <strong key="5" style={{ fontWeight: "100" }}>
              devis 100% gratuit et personnalisé
            </strong>,
            " afin d'estimer le ",
            <strong key="6" style={{ fontWeight: "100" }}>
              coût et temps d'installation climatisation
            </strong>,
            ". Le temps de pose est généralement aux environs de 2/3 jours.",
            <br key="7" />,
            <br key="8" />,
            "Les constructeurs que nous vous conseillons pour cette gamme de produit et avec lesquels nous travaillons habituellement, et desquels nos clients sont satisfaits sont Mitsubishi, Daikin, Toshiba et Panasonic.",
          ]}
        />
        <ButtonQuote />
      </ContenairMain>
      <Footer backgroundColor="#667788" />
    </>
  );
}
