import React, { useRef } from "react";
import styled from "styled-components";
import { devices } from "../../style/Size";

const Mail = styled.input`
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  margin: 0;
  margin-top: 8px;
  border: 2px solid #667788;
  box-sizing: border-box;
  background-color: #dfe3e7;
  border-radius: 2px;
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  font-weight: 100;
  color: #000000;
  outline: none;

  @media ${devices.laptopXXS} {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 40px;
    margin-top: 6px;
  }
  @media (max-width: 702px) {
    margin-top: 4px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

export default function InputMail({ setElementFormFour, setMailValue }) {
  const refMail = useRef();

  const onKeyUpForm = (e) => {
    if (e.target.id === "mail") {
      if (
        refMail.current.value.match(
          '^(?:(?!.*?[.]{2})[a-zA-Z0-9](?:[a-zA-Z0-9.+!%-]{1,64}|)|"[a-zA-Z0-9.+!% -]{1,64}")@[a-zA-Z0-9][a-zA-Z0-9.-]+(.[a-z]{2,}|.[0-9]{1,})$'
        )
      ) {
        setElementFormFour(false);
        refMail.current.style.border = "2px solid #667788";
        setMailValue(refMail.current.value);
      }
      if (
        refMail.current.value &&
        !refMail.current.value.match(
          '^(?:(?!.*?[.]{2})[a-zA-Z0-9](?:[a-zA-Z0-9.+!%-]{1,64}|)|"[a-zA-Z0-9.+!% -]{1,64}")@[a-zA-Z0-9][a-zA-Z0-9.-]+(.[a-z]{2,}|.[0-9]{1,})$'
        )
      ) {
        setElementFormFour("Adresse mail incorrecte");
        refMail.current.style.border = "2px solid red";
        setMailValue(false);
      }
    }
  };

  const onBlurForm = (e) => {
    if (e.target.id === "mail") {
      if (
        !refMail.current.value.match(
          '^(?:(?!.*?[.]{2})[a-zA-Z0-9](?:[a-zA-Z0-9.+!%-]{1,64}|)|"[a-zA-Z0-9.+!% -]{1,64}")@[a-zA-Z0-9][a-zA-Z0-9.-]+(.[a-z]{2,}|.[0-9]{1,})$'
        )
      ) {
        refMail.current.style.border = "2px solid red";
        setElementFormFour("Adresse mail incorrecte");
        setMailValue(false);
      } else {
        setElementFormFour(false);
        refMail.current.style.border = "2px solid #667788";
        setMailValue(refMail.current.value);
      }
    }
  };

  return (
    <Mail
      aria-label="Votre email"
      ref={refMail}
      id="mail"
      className="elementForm"
      placeholder="Adresse Mail"
      type="email"
      onKeyUp={(e) => onKeyUpForm(e)}
      onBlur={(e) => onBlurForm(e)}
    ></Mail>
  );
}
