import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BlockImageAndZoomElement from "../BlockImageAndZoomElement/BlockImageAndZoomElement";
import BlockBathroomPicture1 from "../../assets/Bathroom/salle-de-bain-11.webp";
import BlockBathroomPicture2 from "../../assets/Bathroom/salle-de-bain-10.webp";
import BlockBathroomPicture3 from "../../assets/Bathroom/salle-de-bain-7.webp";
import BlockBathroomPicture4 from "../../assets/Bathroom/salle-de-bain-9.webp";
import {
  EffectGlowButtonFunction,
  NotEffectGlowButtonFunction,
  HoverButtonFunction,
} from "../../functions/EffectGlowButtonsFunction";
import { ButtonEffectScaleClickFunction } from "../../functions/ButtonClickEffectScale";
import { PageTransitionContext } from "../../contexts/PageTransition";
import { devices } from "../../style/Size";

const SectionSallesDeBain = styled.section`
  width: 100%;
  margin-top: 160px;
  @media ${devices.laptopXXS} {
    margin-top: 10px;
    background-color: #553322;
  }
  @media ${devices.mobileL} {
    margin-top: 0;
  }
  @media (max-height: 480px) {
    margin-top: 0px;
  }
`;

const TitleSection = styled.h3`
  width: 326px;
  font-size: 36px;
  font-family: "Playfair", serif;
  color: #553322;
  text-shadow: 1px 1px 2px pink;
  margin: 0;
  padding: 0;
  margin-left: 40px;
  white-space: nowrap;
  cursor: default;
  &::after {
    display: block;
    content: "";
    width: 326px;
    height: 3px;
    background-color: #553322;
    box-shadow: 1px 1px 2px pink;
  }

  @media ${devices.laptopXXS} {
    width: 100%;
    font-size: 24px;
    margin-left: 0;
    padding-top: 10px;
    padding-bottom: 8px;
    margin-bottom: -10px;
    color: #ffffff;
    text-align: center;
    background-color: #553322;
    text-shadow: 0px 0px 0px transparent;
    &::after {
      display: block;
      content: "";
      width: 0;
      height: 0;
      background-color: transparent;
      box-shadow: 0px 0px 0px transparent;
    }
  }
  @media ${devices.mobileL} {
    font-size: 22px;
    padding-top: 12px;
  }
`;

const ContenairImagesSalleDeBain = styled.div.attrs({
  id: "contenairImagesBathroom",
})`
  display: flex;
  justify-content: space-around;
  width: 94%;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;

  @media ${devices.laptopXXS} {
    width: 100%;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    margin-top: 14px;
  }
`;

const ContenairButton = styled.div`
  display: flex;
  width: 100%;
  height: 200px;
  background-color: #ffffff;

  @media ${devices.laptopXXS} {
    height: 160px;
  }
  @media ${devices.mobileL} {
    height: 180px;
  }
`;

const Button = styled.button.attrs({
  type: "button",
  value: "Voir nos salles de bain",
  className: "buttonArticleBathroom",
})`
  width: 270px;
  height: 40px;
  background-color: #556699;
  border: 2px solid #667788;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  font-weight: 100;
  color: #ffffff;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 40px;
  cursor: pointer;

  @media ${devices.laptopXXS} {
    margin-top: 30px;
    width: 250px;
  }
  @media ${devices.tablet} {
    margin-right: 20px;
  }
  @media ${devices.tabletM} {
    height: 40px;
    margin-right: 20px;
  }
  @media ${devices.mobileL} {
    width: 90%;
    height: 38px;
    margin: auto;
    margin-top: 28px;
  }
  @media ${devices.mobileM} {
    height: 36px;
  }
  @media ${devices.mobileS} {
    height: 34px;
  }
  @media ${devices.mobileXS} {
    min-width: 40px;
    font-size: 13px;
  }
  @media (max-height: 480px) {
    width: 52%;
    margin: auto;
    margin-top: 28px;
  }
`;

export default function BlockBathroom() {
  const { displayPageTransition } = useContext(PageTransitionContext);
  const [zoomOne, setZoomOne] = useState(false);
  const [zoomTwo, setZoomTwo] = useState(false);
  const [zoomThree, setZoomThree] = useState(false);
  const [zoomFour, setZoomFour] = useState(false);
  const [doubleClick, setDoubleClick] = useState(false);
  const [infoBulleState, setInfoBulleState] = useState(true);
  const navigate = useNavigate();

  const navigateLink = () => {
    const button = document.querySelector("#buttonBathrooms");

    NotEffectGlowButtonFunction("buttonArticleBathroom");
    ButtonEffectScaleClickFunction(button);
    button.style.pointerEvents = "none";
    button.style.cursor = "auto";

    if (window.innerWidth > 991) {
      setTimeout(() => {
        displayPageTransition("pageTransitionEffect");
      }, 400);
      setTimeout(() => {
        displayPageTransition("pageTransitionNotEffect");
        window.scrollTo(0, 0);
        navigate(`/salles-de-bain`);
      }, 1200);
    }
    if (window.innerWidth > 480 && window.innerWidth <= 991) {
      setTimeout(() => {
        displayPageTransition("pageTransitionEffect");
      }, 400);
      setTimeout(() => {
        displayPageTransition("pageTransitionNotEffect");
        window.scrollTo(0, 0);
        navigate(`/salles-de-bain`);
      }, 1000);
    }
    if (window.innerWidth < 480) {
      setTimeout(() => {
        displayPageTransition("pageTransitionEffect");
      }, 400);
      setTimeout(() => {
        displayPageTransition("pageTransitionNotEffect");
        window.scrollTo(0, 0);
        navigate(`/salles-de-bain`);
      }, 900);
    }
  };

  return (
    <>
      <SectionSallesDeBain id="contenairImagesSalleDeBain">
        <TitleSection>NOS SALLES DE BAIN</TitleSection>
        <ContenairImagesSalleDeBain>
          <BlockImageAndZoomElement
            stateZoom={zoomOne}
            stateZoomSet={setZoomOne}
            stateDB={doubleClick}
            stateDBset={setDoubleClick}
            stateInfoBulle={infoBulleState}
            stateInfoBulleSet={setInfoBulleState}
            imageContenair={BlockBathroomPicture1}
            idContenairPrincipal="idContenairPrincipalOne"
            idPictureContenairPrincipal="idPictureContenairPrincipalOne"
            idBlockContenairPictureZoom="idBlockContenairPictureZoomOne"
            idPictureZoomBlockContenair="idPictureZoomBlockContenairOne"
            idContenairPictureZoom="idContenairPictureZoomOne"
            idPictureZoomContenair="idPictureZoomContenairOne"
            alt="Salle de bain complète blanche très grands carreaux marbrées avec bac à douche céramique blanc, paroi de douche, colonne de douche dorée, WC suspendu, long meuble de salle de bain noir, grand lavabo bol ovale blanc, robinet mitigeur, miroir led"
          />
          <BlockImageAndZoomElement
            stateZoom={zoomTwo}
            stateZoomSet={setZoomTwo}
            stateDB={doubleClick}
            stateDBset={setDoubleClick}
            stateInfoBulle={infoBulleState}
            stateInfoBulleSet={setInfoBulleState}
            imageContenair={BlockBathroomPicture2}
            idContenairPrincipal="idContenairPrincipalTwo"
            idPictureContenairPrincipal="idPictureContenairPrincipalTwo"
            idBlockContenairPictureZoom="idBlockContenairPictureZoomTwo"
            idPictureZoomBlockContenair="idPictureZoomBlockContenairTwo"
            idContenairPictureZoom="idContenairPictureZoomTwo"
            idPictureZoomContenair="idPictureZoomContenairTwo"
            alt="Salle de bain d'appoint complète carreaux noirs marbrés avec bac à douche marbre, paroi de douche, colonne de douche, meuble de salle de bain bois clair, lavabo blanc, robinet mitigeur, miroir rond à LED anti-buée, sèche-serviette noir, WC suspendu"
          />
          <BlockImageAndZoomElement
            stateZoom={zoomThree}
            stateZoomSet={setZoomThree}
            stateDB={doubleClick}
            stateDBset={setDoubleClick}
            stateInfoBulle={infoBulleState}
            stateInfoBulleSet={setInfoBulleState}
            imageContenair={BlockBathroomPicture3}
            idContenairPrincipal="idContenairPrincipalThree"
            idPictureContenairPrincipal="idPictureContenairPrincipalThree"
            idBlockContenairPictureZoom="idBlockContenairPictureZoomThree"
            idPictureZoomBlockContenair="idPictureZoomBlockContenairThree"
            idContenairPictureZoom="idContenairPictureZoomThree"
            idPictureZoomContenair="idPictureZoomContenairTwo"
            alt="Salle de bain complète carreaux effet pierre romaine avec bac à douche céramique blanc, paroi de douche, colonne de douche, meuble de salle de bain, lavabo bol blanc, robinet mitigeur, miroir rond, sèche-serviette blanc, baignoire d'angle blanche balnéo"
          />
          <BlockImageAndZoomElement
            stateZoom={zoomFour}
            stateZoomSet={setZoomFour}
            stateDB={doubleClick}
            stateDBset={setDoubleClick}
            stateInfoBulle={infoBulleState}
            stateInfoBulleSet={setInfoBulleState}
            imageContenair={BlockBathroomPicture4}
            idContenairPrincipal="idContenairPrincipalFour"
            idPictureContenairPrincipal="idPictureContenairPrincipalFour"
            idBlockContenairPictureZoom="idBlockContenairPictureZoomFour"
            idPictureZoomBlockContenair="idPictureZoomBlockContenairFour"
            idContenairPictureZoom="idContenairPictureZoomFour"
            idPictureZoomContenair="idPictureZoomContenairFour"
            alt="Salle de bain complète carreaux effet pierre romaine avec bac à douche céramique blanc, paroi de douche, colonne de douche, meuble de salle de bain bois clair, lavabo bol blanc, robinet mitigeur, miroir rond, sèche-serviette blanc"
          />
        </ContenairImagesSalleDeBain>
        <ContenairButton>
          <Button
            id="buttonBathrooms"
            onMouseMove={(e) =>
              EffectGlowButtonFunction(e, "buttonArticleBathroom")
            }
            onMouseLeave={() =>
              NotEffectGlowButtonFunction("buttonArticleBathroom")
            }
            onMouseEnter={(e) =>
              HoverButtonFunction(e, "buttonArticleBathroom")
            }
            onClick={navigateLink}
            // onTouchStart={navigateLink}
          >
            VOIR NOS SALLES DE BAIN
          </Button>
        </ContenairButton>
      </SectionSallesDeBain>
    </>
  );
}
