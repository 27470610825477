import React, { useRef } from "react";
import styled from "styled-components";
import { devices } from "../../style/Size";

const MailContent = styled.textarea`
  width: 96%;
  height: 200px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 12px;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border: 2px solid #667788;
  box-sizing: border-box;
  background-color: #dfe3e7;
  border-radius: 2px;
  font-size: 18px;
  font-family: "Russo One", sans-serif;
  font-weight: 100;
  color: #000000;
  outline: none;
  resize: none;
  cursor: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #667788;
    border-radius: 4px;
  }

  @media ${devices.laptopXXS} {
    height: 160px;
    margin-top: 2px;
  }
  @media (max-width: 702px) {
    width: 92%;
    margin-left: 4%;
    margin-top: 4px;
  }
  @media ${devices.mobileXS} {
    font-size: 16px;
  }
`;

const AlertTextArea = styled.p`
  width: 96%;
  font-size: 12px;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
  padding: 0;
  padding-top: 12px;
  margin: 0;
  text-align: right;
`;

export default function InputMailContent({
  setElementFormSix,
  elementFormSix,
  setContentMailValue,
}) {
  const refMailContent = useRef();

  const onKeyUpForm = (e) => {
    if (e.target.id === "mailContent") {
      setElementFormSix(refMailContent.current.value.length);
      if (refMailContent.current.value.length > 2000) {
        const alertTextArea = document.querySelector("#alertTextArea");
        alertTextArea.style.color = "red";
        setContentMailValue(false);
      }
      if (refMailContent.current.value.length <= 2000) {
        const alertTextArea = document.querySelector("#alertTextArea");
        alertTextArea.style.color = "black";
        refMailContent.current.style.border = "2px solid #667788";
        setContentMailValue(refMailContent.current.value);
      }
    }
  };

  const onKeyDownForm = (e) => {
    if (e.target.id === "mailContent") {
      if (refMailContent.current.value.length > 2000) {
        if (e.code !== "Backspace" && e.code !== "Delete") {
          e.preventDefault();
          setContentMailValue(false);
        }
      }
      if (refMailContent.current.value.length <= 2000) {
        setElementFormSix(refMailContent.current.value.length);
      }
    }
  };

  return (
    <>
      <MailContent
        aria-label="Descriptif de votre demande"
        ref={refMailContent}
        id="mailContent"
        className="elementForm"
        placeholder="Décrivez-nous votre projet..."
        onKeyUp={(e) => onKeyUpForm(e)}
        onKeyDown={(e) => onKeyDownForm(e)}
      ></MailContent>
      <AlertTextArea id="alertTextArea">{elementFormSix}/2000</AlertTextArea>
    </>
  );
}
